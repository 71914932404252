import { Test } from "../../../model/entities/Test";
import React from "react";
import { ListRowTr, ListRowTd } from "../../common/styles/ListRowStyle";
import * as H from "history";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";

type Props = {
  test: Test;
  history: H.History;
};

class TestResultListRow extends React.Component<Props, {}> {
  render() {
    const test = this.props.test;
    return (
      <ListRowTr>
        <ListRowTd>{test.academic_level.name}</ListRowTd>
        <ListRowTd>{test.test_number}</ListRowTd>
        <ListRowTd>
          {test.average_score !== null
            ? (Math.round(test.average_score * 10) / 10).toFixed(1)
            : "-"}
        </ListRowTd>
        <ListRowTd>
          {test.participant_number !== null
            ? test.participant_number + "名"
            : "-"}
        </ListRowTd>
        <ListRowTd>
          {dayjs(Date.parse(test.from_date)).format("YYYY/MM/DD")}
          <br />～{dayjs(Date.parse(test.to_date)).format("YYYY/MM/DD")}
        </ListRowTd>
        <ListRowTd>
          <Link
            to={
              "/client/practical_test_results/questions/" +
              test.id +
              "/" +
              test.test_number +
              "/" +
              test.academic_level.id
            }
          >
            <FontAwesomeIcon icon="info-circle" style={DetailIconStyle} />
          </Link>
        </ListRowTd>
        <ListRowTd>
          <Link
            to={
              "/client/practical_test_results/" +
              test.id +
              "/" +
              test.test_number +
              "/" +
              test.academic_level.id
            }
          >
            <FontAwesomeIcon icon="info-circle" style={DetailIconStyle} />
          </Link>
        </ListRowTd>
      </ListRowTr>
    );
  }
}

const DetailIconStyle: CSSProperties = {
  color: "rgba(112, 112, 112, 1)",
  fontSize: "48px"
};

export default TestResultListRow;
