import styled from "styled-components";
import { ContentBackground } from "../../../../common/Colors";

const leftPercent = "43%";
const rightPercent = "57%";

export const Container = styled.div`
  display: flex;
  position: absolute;
  left: 370px;
  top: 24px;
  right: 24px;
  bottom: 140px;
`;

export const LeftContainer = styled.div`
  width: ${leftPercent};
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const RightContainer = styled.div`
  width: ${rightPercent};
  height: 100%;
`;

export const LeftContainerTop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TestResultIcon = styled.img`
  height: 100px;
`;

export const LeftContainerTitle = styled.p`
  font-weight: bold;
  font-size: 28px;
`;

export const LeftContainerBorder = styled.div`
  height: 4px;
  background: black;
  margin: 0px 72px;
`;

export const ChartContainer = styled.div`
  margin: auto;
  display: table;
`;

export const UserInformationContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 315px;
  width: 300px;
  text-align: center;
  padding: 5px;
  border: solid 3px black;
  font-size: 17px;
`;

export const UnderLineName = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

export const RightScoreContainer = styled.div`
  position: absolute;
  right: 0;
  top: 16px;
  width: 300px;
  text-align: center;
  padding: 5px;
  border: solid 3px black;
  font-size: 17px;
`;

export const RightQuestionContainer = styled.div`
  width: ${rightPercent};
  position: absolute;
  bottom: 3px;
  top: 206px;
  display: flex;
`;

export const RightQuestionTableContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  width: 53%;
  border: solid 2px black;
  background: ${ContentBackground};
`;

export const RightQuestionContentContainer = styled.div`
  overflow-y: scroll;
  height: 100%;
  width: 47%;
  padding: 0 16px;
  font-weight: bold;
  background: ${ContentBackground};
`;
