import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  img {
    display: block;
    width: 100%;
  }
`;

export const ChartContainer = styled.div`
  & > .recharts-wrapper {
    width: 100% !important;
    height: auto !important;
    > svg {
      width: 100%;
      height: auto;
    }
  }
`;

export const RowHeight = "31px";

export const ElementRowTop = styled.div`
  position: absolute;
  top: -17px;
  font-size: 10px;
  background: #808080;
  padding: 0 6px;
  color: white;
`;

export const ElementRow = styled.div`
  height: ${RowHeight};
`;

export const AxisElement = styled.div`
  margin-left: 84px;
  margin-right: 36px;
`;

export const BarElement = styled.div<{ width: string; color: string }>`
  background: linear-gradient(to right, ${props => props.color}, #fff);
  height: 22px;
  width: ${props => props.width};
  border: solid 1px black;
  border-radius: 4px;
  z-index: 1;
`;

export const TargetArea = styled.div`
  position: absolute;
  width: 67px;
  background: rgba(0, 0, 0, 0);
  top: -3px;
  right: 38px;
  bottom: 33px;
  border: dotted 2px #eca186;
`;

export const TargetAreaLabel = styled.div`
  position: absolute;
  top: -19px;
  left: 353px;
`;

export const TargetPoint = styled.div<{ target: number }>`
  position: relative;
  width: 10px;
  height: 10px;
  background: yellow;
  top: -19px;
  /* 最小が83px、最大が402px */
  left: ${props => 63.8 * props.target + 83 + "px"};
  border: solid 1px black;
  border-radius: 5px;
  z-index: 2;
`;

export const TargetLine = styled.div<{ value: number; target: number }>`
  position: relative;
  height: 4px;
  border: dotted 2px #e9973f;
  top: -26px;
  /* barchartの始点が88px、最大終点が407px */
  left: ${props => 63.5 * props.value + 88 + "px"};
  width: ${props =>
    props.target - props.value < 0
      ? "0px"
      : (props.target - props.value) * 63.5 + "px"};
`;

export const TargetLegendArea = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: 320px;
`;

export const TargetPointLegend = styled.div`
  width: 10px;
  height: 10px;
  background: yellow;
  border: solid 1px black;
  border-radius: 5px;
`;
