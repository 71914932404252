import * as React from "react";
import { Container, RadarChartContainer } from "./styled";
import { Text } from "../../../common/Text";
import { Color } from "../../../../styles";
import { Section } from "../Section";
import { StudyTime as StudyTimeType, Element } from "../../types";
import { Spacer } from "../../../common/Spacer";
import { Flex } from "../../../common/Flex";
import { PieChart, Pie, Cell, Legend } from "recharts";
import {
  VocabularyColor,
  GrammerColor,
  StructureColor,
  LogicColor,
  KnowledgeColor,
  SpeedColor
} from "../../../common/Colors";

const COLORS = [
  VocabularyColor,
  GrammerColor,
  StructureColor,
  LogicColor,
  KnowledgeColor,
  SpeedColor
];

type Props = {
  studyTime: StudyTimeType;
  elements: Element[];
};

const StudyTime: React.FunctionComponent<Props> = ({ studyTime, elements }) => {
  const data = elements
    // importanceがnullの場合はデータなしとみなす
    .filter(element => element.importance !== null)
    .map(element => ({
      name: element.name_ja,
      value: element.importance
    }));
  return (
    <Container>
      <Section title="●目標学習時間・ 推奨学習割合">
        <Flex display="flex" justifyContent="center">
          <div>
            <Text weight="bold" size="10px">
              1日の目標学習時間
            </Text>
            <Text weight="bold" size="40px" color="#e5643b">
              {studyTime.daily_h}
              <Text weight="bold" size="30px" component="span" color="#e5643b">
                h
              </Text>
            </Text>
            <Spacer pt={5} />
            <Text weight="bold" size="10px">
              今月の目標学習時間
            </Text>
            <Text weight="bold" size="25px" color={Color.GRAY_LIGHT}>
              {studyTime.monthly_h}h
              <Text
                weight="bold"
                size="15px"
                component="span"
                color={Color.GRAY_LIGHT}
              >
                以上
              </Text>
            </Text>
          </div>
          <RadarChartContainer>
            <PieChart width={200} height={300}>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                {data.map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend />
            </PieChart>
          </RadarChartContainer>
        </Flex>
      </Section>
    </Container>
  );
};

export { StudyTime };
