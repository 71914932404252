import styled from "styled-components";
import React from "react";
import { User } from "../../model/entities/User";
import * as H from "history";
import ProfileArea from "./ProfileArea";
import WrapContent from "./WrapContent";
import Sidebar from "./Sidebar";
import SidebarButton from "./SidebarButton";
import {
  haveCoachRead,
  haveStudentRead,
  haveTestResultRead,
  haveAuthoritySetRead
} from "../../utils/checkAuthority";
import { AuthoritySet } from "../../model/entities/AuthoritySet";
import SelectEditModal from "../accountEdit/SelectEditModal";
import { Link } from "react-router-dom";

const WrapBackgroundStyle = styled.div<{ color: string }>`
  &&& {
    background: ${props => props.color};
    height: 100vh;
    min-width: 1232px;
    min-height: 718px;
  }
`;

type Props = {
  history: H.History;
  profile: User | null;
  color: string;
  sidebarColor: string | null;
  selectedSidebar:
    | null
    | "coach"
    | "student"
    | "practical_test_result"
    | "training_test_result"
    | "authority";
};

type DefaultProps = {
  color: string;
  sidebarColor: string | null;
  selectedSidebar:
    | null
    | "coach"
    | "student"
    | "practical_test_result"
    | "training_test_result"
    | "authority";
};

type State = {
  isEditModalOpen: boolean;
};

export class WrapBackground extends React.Component<Props, State> {
  public static defaultProps: Partial<DefaultProps> = {
    color: "white",
    sidebarColor: null,
    selectedSidebar: null
  };

  constructor(props) {
    super(props);

    this.state = {
      isEditModalOpen: false
    };
  }

  render() {
    return (
      <WrapBackgroundStyle color={this.props.color}>
        {this.getWrapElement()}
      </WrapBackgroundStyle>
    );
  }

  getWrapElement() {
    return (
      <>
        <SelectEditModal
          onClose={this.editModalClose.bind(this)}
          isOpen={this.state.isEditModalOpen}
          history={this.props.history}
        ></SelectEditModal>
        <Logo history={this.props.history} profile={this.props.profile} />
        <ProfileArea
          history={this.props.history}
          profile={this.props.profile}
        />
        <WrapContent id="wrapContent">
          {this.getSidebar()}
          {this.props.children}
        </WrapContent>
      </>
    );
  }

  editModalClose() {
    this.setState({ isEditModalOpen: false });
  }

  editModalOpen() {
    this.setState({ isEditModalOpen: true });
  }

  getSidebar() {
    if (this.props.profile === null || this.props.selectedSidebar === null) {
      return <></>;
    }
    switch (this.props.profile.user_type.name) {
      case "coach":
        return this.getClientSidebar(this.props.profile.authority_set!);
      case "student":
        return (
          <Sidebar color={this.props.sidebarColor!}>
            <SidebarButton
              titles={["実用英語力テスト", "結果一覧"]}
              isSelected={
                this.props.selectedSidebar === "practical_test_result"
              }
              history={this.props.history}
              link="/student/practical_test_results"
            />
            <SidebarButton
              titles={["トレーニングアプリ", "結果一覧"]}
              isSelected={this.props.selectedSidebar === "training_test_result"}
              history={this.props.history}
              link="/student/training_test_results"
            />
            <SidebarButton
              titles={["設定変更"]}
              isSelected={false}
              history={this.props.history}
              onClick={this.editModalOpen.bind(this)}
            />
          </Sidebar>
        );
    }
    return <></>;
  }

  getClientSidebar(authority_set: AuthoritySet) {
    const sidebars: JSX.Element[] = [];
    if (haveCoachRead(authority_set)) {
      sidebars.push(
        <SidebarButton
          titles={["管理者一覧"]}
          key={sidebars.length}
          isSelected={this.props.selectedSidebar === "coach"}
          history={this.props.history}
          link="/client/coaches"
        />
      );
    }

    if (haveStudentRead(authority_set)) {
      sidebars.push(
        <SidebarButton
          titles={["ユーザー一覧"]}
          key={sidebars.length}
          isSelected={this.props.selectedSidebar === "student"}
          history={this.props.history}
          link="/client/students"
        />
      );
    }

    if (haveTestResultRead(authority_set)) {
      sidebars.push(
        <SidebarButton
          titles={["実用英語力テスト", "結果一覧"]}
          key={sidebars.length}
          isSelected={this.props.selectedSidebar === "practical_test_result"}
          history={this.props.history}
          link="/client/practical_test_results"
        />
      );
    }

    if (haveTestResultRead(authority_set)) {
      sidebars.push(
        <SidebarButton
          titles={["トレーニングアプリ", "結果一覧"]}
          key={sidebars.length}
          isSelected={this.props.selectedSidebar === "training_test_result"}
          history={this.props.history}
          link="/client/training_test_results"
        />
      );
    }

    if (haveAuthoritySetRead(authority_set)) {
      sidebars.push(
        <SidebarButton
          titles={["権限一覧"]}
          key={sidebars.length}
          isSelected={this.props.selectedSidebar === "authority"}
          history={this.props.history}
          link="/client/authorities"
        />
      );
    }

    sidebars.push(
      <SidebarButton
        titles={["設定変更"]}
        key={sidebars.length}
        isSelected={false}
        history={this.props.history}
        onClick={this.editModalOpen.bind(this)}
      />
    );
    return <Sidebar color={this.props.sidebarColor!}>{sidebars}</Sidebar>;
  }
}

const LogoStyle = styled.img`
  height: 70px;
  margin-top: 16px;
  margin-left: 32px;
  cursor: pointer;
`;

type LogoProps = {
  history: H.History;
  profile: User | null;
};

export class Logo extends React.Component<LogoProps, {}> {
  render() {
    let link: string = "#";
    if (this.props.profile?.user_type?.name === "coach") {
      link = "/client";
    } else if (this.props.profile?.user_type?.name === "student") {
      link = "/student";
    }
    return (
      <Link to={link}>
        <LogoStyle src="/assets/logo_AI.png" alt="logo" />
      </Link>
    );
  }
}
