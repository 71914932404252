import * as React from "react";
import { Container, Header, Content } from "./styled";
import { Text } from "../../../common/Text";
import { Color } from "../../../../styles";

type Align = "left" | "center" | "right";

type Props = {
  title: string;
  titleAlign?: Align;
  background?: string;
};

const Section: React.FunctionComponent<Props> = ({
  children,
  title,
  titleAlign = "left",
  background = Color.GRAY_LIGHT
}) => {
  return (
    <Container>
      <Header background={background}>
        <Text weight="bold" color={Color.WHITE} align={titleAlign} size="13px">
          {title}
        </Text>
      </Header>
      <Content>{children}</Content>
    </Container>
  );
};

export { Section };
