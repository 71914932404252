import React from "react";
import { WrapBackground } from "../../common/WrapBackground";
import { get } from "../../../model/api/Request";
import TrainingTestResultListContent from "./TrainingTestResultListContent";
import {
  TestResultBackground,
  TestResultSidebarBackground
} from "../../common/Colors";
import PagingArea from "../../common/PagingArea";
import { RouteComponentProps } from "react-router-dom";
import { commonErrorHandle } from "../../../utils/errorHandle";
import { Test } from "../../../model/entities/Test";
import LoadingOverlay from "../../common/LoadingOverlay";
import { Profile } from "../../../utils/profile";
import { TopRightArea, FilterButton } from "../../common/styles/TopButton";
import FilterTrainingTestResultModal from "./FilterTrainingTestResultModal";
import {
  extractPage,
  extractSortBy,
  extractOrder,
  extractGrammarField,
  extractLevel,
  extractTestNumber
} from "../../../utils/query";
import {
  pagingLimit,
  queryPageKey,
  querySortByKey,
  queryOrderKey,
  SortBy,
  Order,
  queryGrammarFieldKey,
  queryTestNumberKey,
  queryLevelKey
} from "../../../utils/Constant";
import querystring from "querystring";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  maxPage: number;
  isLoading: boolean;
  tests: Test[];
  isFilterModalOpen: boolean;
};

class TrainingTestResultList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      maxPage: 0,
      isLoading: false,
      tests: [],
      isFilterModalOpen: false
    };
  }

  componentDidMount() {
    this.getInit();
  }

  render() {
    return (
      <>
        <FilterTrainingTestResultModal
          isOpen={this.state.isFilterModalOpen}
          onClose={this.onFilterClose.bind(this)}
          onSearch={this.searchTestResults.bind(this)}
          query={this.getQuery()}
        />
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapBackground
          color={TestResultBackground}
          history={this.props.history}
          profile={this.props.profile}
          selectedSidebar="training_test_result"
          sidebarColor={TestResultSidebarBackground}
        >
          <FilterButton
            onClick={this.onFilterOpen.bind(this)}
            isTestResultDetail={false}
          >
            検索・フィルター
          </FilterButton>
          <TopRightArea>
            <PagingArea
              page={extractPage(this.getQuery())}
              maxPage={this.state.maxPage}
              onMostBack={this.onMostBackPage.bind(this)}
              onBack={this.onBackPage.bind(this)}
              onNext={this.onNextPage.bind(this)}
              onMostNest={this.onMostNextPage.bind(this)}
            />
          </TopRightArea>
          <TrainingTestResultListContent
            history={this.props.history}
            tests={this.state.tests}
            onChangeSort={this.onChangeSort.bind(this)}
            sortBy={extractSortBy(this.getQuery())}
            order={extractOrder(this.getQuery())}
          />
        </WrapBackground>
      </>
    );
  }

  getInit() {
    this.setState({ isLoading: true });
    let query = "";
    query += `limit=${pagingLimit}`;
    query += `&${queryPageKey}=${extractPage(this.getQuery())}`;

    if (extractOrder(this.getQuery())) {
      query += `&${queryOrderKey}=${extractOrder(this.getQuery())}`;
    }
    if (extractSortBy(this.getQuery())) {
      query += `&${querySortByKey}=${extractSortBy(this.getQuery())}`;
    }
    if (extractGrammarField(this.getQuery()).length !== 0) {
      const authorityQuery = extractGrammarField(this.getQuery())
        .map(i => {
          return `${queryGrammarFieldKey}=${i}`;
        })
        .join("&");
      query += `&${authorityQuery}`;
    }
    if (extractLevel(this.getQuery())) {
      query += `&${queryLevelKey}=${extractLevel(this.getQuery())}`;
    }
    if (extractTestNumber(this.getQuery())) {
      query += `&${queryTestNumberKey}=${extractTestNumber(this.getQuery())}`;
    }
    Promise.all([
      get("/training-tests/count?" + query),
      get("/training-tests?" + query)
    ])
      .then(responses => {
        this.setState({
          maxPage: Math.ceil(responses[0].result.count / pagingLimit),
          isLoading: false,
          tests: responses[1].result
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onFilterOpen() {
    this.setState({ isFilterModalOpen: true });
  }

  onFilterClose() {
    this.setState({ isFilterModalOpen: false });
  }

  onMostNextPage() {
    const page = extractPage(this.getQuery());
    if (page === this.state.maxPage) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(this.state.maxPage);
    this.pushWithQuery(query);
  }

  onNextPage() {
    const page = extractPage(this.getQuery());
    if (page === this.state.maxPage) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(page + 1);
    this.pushWithQuery(query);
  }

  onBackPage() {
    const page = extractPage(this.getQuery());
    if (page === 1) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(page - 1);
    this.pushWithQuery(query);
  }

  onMostBackPage() {
    const page = extractPage(this.getQuery());
    if (page === 1) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(1);
    this.pushWithQuery(query);
  }

  onChangeSort(sortBy: SortBy, order: Order) {
    const query = this.getQuery();
    query[querySortByKey] = sortBy;
    query[queryOrderKey] = order;
    this.pushWithQuery(query);
  }

  searchTestResults(query: string) {
    let queryDict: { [name: string]: string | string[] } = {};
    if (extractSortBy(this.getQuery())) {
      queryDict[querySortByKey] = extractSortBy(this.getQuery());
    }
    if (extractOrder(this.getQuery())) {
      queryDict[queryOrderKey] = extractOrder(this.getQuery());
    }
    queryDict[queryPageKey] = "1";
    queryDict = Object.assign(queryDict, querystring.parse(query));
    this.pushWithQuery(queryDict);
  }

  getQuery(): { [name: string]: string | string[] } {
    const query = querystring.parse(this.props.location.search.slice(1));
    return query as { [name: string]: string | string[] };
  }

  pushWithQuery(query: { [name: string]: string | string[] }) {
    this.props.history.push(
      this.props.location.pathname + "?" + querystring.stringify(query)
    );
  }
}

export default TrainingTestResultList;
