import { CustomError } from "../model/api/CustomError";
import * as H from "history";

export function commonErrorHandle(error: Error, history: H.History) {
  if (error instanceof CustomError) {
    const customError = error as CustomError;
    if (customError.status >= 400 && customError.status < 500) {
      history.push("/400");
      return;
    }
    history.push("/500");
    return;
  }
  history.push("/500");
}
