import * as React from "react";
import {
  CircleContainer,
  CircleBackground,
  Progress,
  ProgressFill,
  ChildrenContainer,
  Children
} from "./styled";
import { Text } from "../../../common/Text";
import { Spacer } from "../../../common/Spacer";

const ScoreCircle: React.FunctionComponent<{ score: number }> = ({ score }) => {
  const rotate = (360 * score) / 100;
  return (
    <CircleContainer className={score > 50 ? "gt-50" : ""}>
      <CircleBackground />
      <Progress>
        <ProgressFill rotate={rotate} />
      </Progress>
      <ChildrenContainer>
        <Children>
          <Text size="10px" weight="bold" lineHeight="1">
            score
          </Text>
          <Spacer pt={0.5} />
          <Text size="20px" weight="bold" lineHeight="1">
            {score}
          </Text>
        </Children>
      </ChildrenContainer>
    </CircleContainer>
  );
};

export { ScoreCircle };
