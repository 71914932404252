import styled from "styled-components";
import { StudentButtonColor } from "../../../common/Colors";

export const Th = styled.th`
  background: rgba(112, 112, 112, 1);
  color: white;
  font-size: 18px;
  border: solid 5px rgba(239, 239, 239, 1);
  border-collapse: separate;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const Tr = styled.tr`
  background: white;
  height: 64px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
`;

export const EditButton = styled.button<{ top: string }>`
  position: absolute;
  left: 364px;
  top: ${props => props.top};
  background: ${StudentButtonColor};
  padding: 2px;
  width: 210px;
  height: 40px;
  color: white;
  border-radius: 8px;
  font-size: 22px;
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  &:focus {
    outline: 0;
  }
  cursor: ${props => (props.disabled ? "auto" : "pointer")};
  border: none;
`;

export const ListContentElement = styled.div<{ top: string; bottom: string }>`
  position: absolute;
  left: 344px;
  right: 40px;
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  overflow-y: scroll;
  padding: 0px 16px 16px 16px;
`;

export const RowEditButton = styled.button`
  background: ${StudentButtonColor};
  border: none;
  color: white;
  font-size: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  width: 122px;
  height: 32px;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const RowPullDownEditButton = styled.button`
  background: ${StudentButtonColor};
  border: none;
  color: white;
  font-size: 20px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 9px;
  border-radius: 8px;
  width: 129px;
  height: 32px;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;
