import {
  TestResult,
  StudentResult
} from "../../../../model/entities/TestResult";
import React from "react";
import StudentTrainingTestResultDetailListRow from "./StudentTrainingTestResultDetailListRow";
import StudentTrainingTestContent from "./StudentTrainingTestContent";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  ListContentTable,
  ListContentTh,
  TestName
} from "./styles/StudentTestResultDetailListContentStyles";
import * as H from "history";

type State = {};

type Props = {
  history: H.History;
  testResults: StudentResult[];
  testContent: TestResult | null;
};

class StudentTrainingTestResultDetailListContent extends React.Component<
  Props,
  State
> {
  render() {
    const listRows = this.props.testResults.map((testResult: StudentResult) => (
      <StudentTrainingTestResultDetailListRow
        key={testResult.id}
        testResult={testResult}
        history={this.props.history}
      />
    ));
    return (
      <>
        <TestName>{this.props.testContent?.name}</TestName>
        <StudentTrainingTestContent testContent={this.props.testContent} />
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh enableClick={false}>受験回数</ListContentTh>
                <ListContentTh enableClick={false}>得点</ListContentTh>
                <ListContentTh enableClick={false}>偏差値</ListContentTh>
                <ListContentTh enableClick={false}>順位</ListContentTh>
                <ListContentTh enableClick={false}>解答時間</ListContentTh>
                <ListContentTh enableClick={false}>受験日</ListContentTh>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement className="scrollListElement">
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }
}

export default StudentTrainingTestResultDetailListContent;
