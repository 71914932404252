export class CustomError extends Error {
  status: number;
  code: string;
  constructor(m: string, status: number, code: string) {
    super(m);
    this.status = status;
    this.code = code;
    Object.setPrototypeOf(this, new.target.prototype);
  }
}
