import React from "react";
import WrapLoginArea from "../login/styles/WrapLoginArea";
import LoginArea from "../login/styles/LoginArea";
import LoginInput from "../login/styles/LoginInput";
import LoginButton from "../login/styles/LoginButton";
import Logo from "../login/styles/Logo";
import { LoginButtonColor } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { put } from "../../model/api/Request";
import LoadingOverlay from "../common/LoadingOverlay";
import { commonErrorHandle } from "../../utils/errorHandle";
import { isValidPassword } from "../../utils/validation";
import { CustomError } from "../../model/api/CustomError";
import { Profile } from "../../utils/profile";
import ProfileArea from "../common/ProfileArea";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  isLoading: boolean;
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

class PasswordEdit extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      oldPassword: "",
      newPassword: "",
      newPasswordConfirm: ""
    };
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapLoginArea>
          <Logo />
          <LoginArea color="white">
            <LoginInput
              type="password"
              placeholder="旧パスワード"
              index={0}
              onChange={this.onChangeOldPassword.bind(this)}
            />
            <LoginInput
              type="password"
              placeholder="新パスワード"
              index={1}
              onChange={this.onChangeNewPassword.bind(this)}
            />
            <LoginInput
              type="password"
              placeholder="新パスワード(確認)"
              index={2}
              onChange={this.onChangeNewPasswordConfirm.bind(this)}
            />
            <LoginButton
              color={LoginButtonColor}
              onClick={this.onEditButton.bind(this)}
            >
              変更
            </LoginButton>
          </LoginArea>
        </WrapLoginArea>
        <ProfileArea
          history={this.props.history}
          profile={this.props.profile}
        />
      </>
    );
  }

  onChangeOldPassword(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ oldPassword: e.target.value });
  }

  onChangeNewPassword(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ newPassword: e.target.value });
  }

  onChangeNewPasswordConfirm(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ newPasswordConfirm: e.target.value });
  }

  onEditButton() {
    if (!isValidPassword(this.state.newPassword)) {
      alert(
        "パスワードは大文字・小文字・数字・記号を1つ以上含んだ8文字以上を設定してください"
      );
      return;
    }
    if (this.state.newPassword !== this.state.newPasswordConfirm) {
      alert("確認のパスワードは新パスワードと同じものを入力してください");
      return;
    }
    this.setState({ isLoading: true });
    put("/profile", {
      password: {
        previous: this.state.oldPassword,
        proposed: this.state.newPassword
      }
    })
      .then(res => {
        this.setState({ isLoading: false });
        alert("変更が完了しました。");
        this.props.history.goBack();
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (error instanceof CustomError) {
          const customError = error as CustomError;
          if (customError.code === "InvalidAuthenticationError") {
            alert("旧パスワードが間違えています。");
            return;
          } else if (customError.code === "LimitExceededError") {
            alert(
              "パスワード失敗回数の制限を超えました。時間をおいて再度お試しください"
            );
            return;
          }
        }
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default PasswordEdit;
