import { StudentResult } from "../../../../model/entities/TestResult";
import React from "react";
import {
  ListRowTr,
  ListRowTrIdBorder,
  ListRowTd
} from "../../../common/styles/ListRowStyle";
import * as H from "history";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

type Props = {
  testResult: StudentResult;
  history: H.History;
};

class StudentTrainingTestResultDetailListRow extends React.Component<
  Props,
  {}
> {
  render() {
    const test = this.props.testResult;
    return (
      <ListRowTr>
        <ListRowTd>
          <Link to={"/student/training_test_results/questions/" + test.id}>
            <ListRowTrIdBorder>
              {test.nth_test_taking_count}回目
            </ListRowTrIdBorder>
          </Link>
        </ListRowTd>
        <ListRowTd>{test.score}</ListRowTd>
        <ListRowTd>
          {test.deviation_value !== null
            ? (Math.round(test.deviation_value * 10) / 10).toFixed(1)
            : "-"}
        </ListRowTd>
        <ListRowTd>{test.rank !== null ? test.rank + "位" : "-"}</ListRowTd>
        <ListRowTd>
          {Math.floor((test.spent_time_ms ?? 0) / 1000 / 60)}分
          {Math.floor(((test.spent_time_ms ?? 0) / 1000) % 60)}秒
        </ListRowTd>
        <ListRowTd>
          {dayjs(Date.parse(test.do_datetime)).format("YYYY/MM/DD")}
        </ListRowTd>
      </ListRowTr>
    );
  }
}

export default StudentTrainingTestResultDetailListRow;
