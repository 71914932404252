import React from "react";
import UserEditModal, { UserType } from "../modal/UserEditModal";
import {
  Title,
  Body,
  BodyName,
  TargetTitle,
  TargetBody,
  TargetBodySpan,
  Level,
  ExternalEvaluateTitle,
  ExternalEvaluateArea,
  ExternalEvaluateHeaderFlex,
  ExternalEvaluateHeader,
  ExternalEvaluateContent,
  ExternalEvaluateContentElement,
  ExternalEvaluateContentValue,
  TestResultArea,
  TestResultHeaderFlex,
  TestResultHeader,
  TestResultContent,
  TestResultContentElement,
  TestResultContentValue,
  ButtonArea,
  Button,
  MinifyMonthlyReport
} from "./styles/StudentDetailContentStyle";
import { match, Link } from "react-router-dom";
import { get } from "../../model/api/Request";
import { User } from "../../model/entities/User";
import { Test } from "../../model/entities/Student";
import { ExternalEval, EvalLevel } from "../../model/entities/ExternalEval";
import * as H from "history";
import dayjs from "dayjs";
import LoadingOverlay from "../common/LoadingOverlay";
import { RecordResponse } from "../MonthlyReport/types";
import MonthlyReport from "../MonthlyReport";
import PagingArea from "../common/PagingArea";
import { commonErrorHandle } from "../../utils/errorHandle";
import { haveStudentEdit, haveStudentDelete } from "../../utils/checkAuthority";
import { BlackButton, StudentButtonColor } from "../common/Colors";
import { ResizeObserver } from "resize-observer";

type Props = {
  match: match<{ id: string }>;
  history: H.History;
  location: H.Location;
  profile: User | null;
};

type State = {
  user: User | null;
  isEditModalOpen: boolean;
  isLoading: boolean;
  externalEvalResults: ExternalEval[] | null;
  records: RecordResponse[];
  selectRecordIndex: number;
};

class StudentDetailContent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isEditModalOpen: false,
      isLoading: false,
      externalEvalResults: null,
      records: [],
      selectRecordIndex: 0
    };
  }

  componentDidMount() {
    this.getInit();
  }

  componentDidUpdate() {
    // WrapContentのサイズに応じて、個票サイズを変更
    const wrapContent = document.getElementById("wrapContent");
    const minifyReport = document.getElementById("minifyMonthlyReport");
    const pagingArea = document.getElementById("pagingArea");
    if (wrapContent && minifyReport && pagingArea) {
      // ResizeObserverがデフォルトでtype scriptに定義されてないので、それまでthird partyライブラリを使用
      const resizeObserver = new ResizeObserver(entries => {
        const wrapContentLeft = 16;
        const reportLeft = 663;
        const reportRight = 16;
        const reportWidth = 1680;
        const maxRatio = 0.88;
        let ratio =
          (wrapContent.clientWidth - reportLeft - reportRight) / reportWidth;
        if (ratio > maxRatio) {
          ratio = maxRatio;
        }
        const reducedWidth = minifyReport.clientWidth * ratio;
        const dx =
          reportLeft -
          (wrapContent.clientWidth - reducedWidth) / 2 -
          wrapContentLeft;
        const reducedHeight = minifyReport.clientHeight * ratio;
        const dy = minifyReport.clientHeight / 2 - reducedHeight / 2 - 16;
        minifyReport.style.transform =
          "translate(" + dx + "px, " + -dy + "px) scale(" + ratio + ")";
        pagingArea.style.position = "absolute";
        pagingArea.style.top = reducedHeight + 32 + "px";
        pagingArea.style.right = "32px";
      });
      resizeObserver.observe(wrapContent!);
    }
  }

  render() {
    const coach =
      this.state.user?.student?.coach !== null
        ? this.state.user?.student?.coach?.family_name_ja +
          " " +
          this.state.user?.student?.coach?.first_name_ja
        : "-";
    const nextTarget =
      (this.state.user?.student?.targets.length ?? 0) >= 1
        ? this.state.user?.student?.targets[0]
        : null;
    const lastTarget =
      (this.state.user?.student?.targets.length ?? 0) >= 2
        ? this.state.user?.student?.targets[
            this.state.user?.student?.targets.length - 1
          ]
        : null;

    const externalEvaluations = this.state.externalEvalResults?.map(
      (externalEvalResult: ExternalEval) => {
        const highestLevelResult = this.getHighestEvalResult(
          externalEvalResult.levels
        );
        return (
          <ExternalEvaluateContent key={externalEvalResult.id}>
            <ExternalEvaluateContentValue>
              {externalEvalResult.name}
            </ExternalEvaluateContentValue>
            <ExternalEvaluateContentValue>
              {highestLevelResult?.name || "-"}
            </ExternalEvaluateContentValue>
            <ExternalEvaluateContentValue>
              {highestLevelResult?.academic_level.name || "-"}
            </ExternalEvaluateContentValue>
            <ExternalEvaluateContentValue>
              {highestLevelResult?.result?.got_date || "-"}
            </ExternalEvaluateContentValue>
          </ExternalEvaluateContent>
        );
      }
    );

    const testResults = this.state.user?.student?.tests.map((test: Test) => (
      <Link
        to={"/client/practical_test_results/student/" + test.result.id}
        style={{ textDecoration: "none" }}
        key={test.result.id}
      >
        <TestResultContent>
          <TestResultContentValue>
            {dayjs(test.result.do_datetime).format("YYYY-MM-DD")}
          </TestResultContentValue>
          <TestResultContentValue>{test.name}</TestResultContentValue>
        </TestResultContent>
      </Link>
    ));

    let startDate = "";
    let endDate = "";
    if (this.state.user != null) {
      startDate = dayjs(this.state.user.created_at).format("YYYY/MM/DD");
      endDate = dayjs(this.state.user.affiliation_client.valid_date).format(
        "YYYY/MM/DD"
      );
    }
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <Title>プロフィール詳細</Title>
        <Body isTitle={true} index={0}>
          所属
        </Body>
        <Body isTitle={false} index={0}>
          {this.state.user?.affiliation_division.name}
        </Body>
        <Body isTitle={true} index={2}>
          学年
        </Body>
        <Body isTitle={false} index={2}>
          {this.state.user?.affiliation_role?.name}
        </Body>
        <Body isTitle={true} index={4}>
          生徒ID
        </Body>
        <Body isTitle={false} index={4}>
          {this.state.user?.login_id}
        </Body>
        <Body isTitle={true} index={7}>
          生徒氏名
        </Body>
        <BodyName isTitle={false} index={7}>
          {(this.state.user?.family_name_ja || "") +
            " " +
            (this.state.user?.first_name_ja || "")}
        </BodyName>
        <Body isTitle={true} index={10}>
          担当コーチ
        </Body>
        <Body isTitle={false} index={10}>
          {coach}
        </Body>
        <Body isTitle={true} index={12}>
          利用期間
        </Body>
        <Body isTitle={false} index={12}>
          {startDate}~
          <br />
          {endDate}
        </Body>
        <TargetTitle isLastTarget={true}>最終目標</TargetTitle>
        <TargetBody isLastTarget={true}>
          <TargetBodySpan>{lastTarget?.name}</TargetBodySpan>
        </TargetBody>
        <TargetTitle isLastTarget={false}>次の目標</TargetTitle>
        <TargetBody isLastTarget={false}>
          <TargetBodySpan>{nextTarget?.name}</TargetBodySpan>
        </TargetBody>
        <Level>Lv.{this.state.user?.student?.academic_level.name}</Level>
        <ExternalEvaluateTitle>取得資格情報</ExternalEvaluateTitle>
        <ExternalEvaluateArea>
          <ExternalEvaluateHeaderFlex>
            <ExternalEvaluateHeader>ジャンル</ExternalEvaluateHeader>
            <ExternalEvaluateHeader>取得</ExternalEvaluateHeader>
            <ExternalEvaluateHeader>対応レベル</ExternalEvaluateHeader>
            <ExternalEvaluateHeader>取得日</ExternalEvaluateHeader>
          </ExternalEvaluateHeaderFlex>
          <ExternalEvaluateContentElement>
            {externalEvaluations}
          </ExternalEvaluateContentElement>
        </ExternalEvaluateArea>
        <TestResultArea>
          <TestResultHeaderFlex>
            <TestResultHeader>受験日</TestResultHeader>
            <TestResultHeader>テスト名</TestResultHeader>
          </TestResultHeaderFlex>
          <TestResultContentElement>{testResults}</TestResultContentElement>
        </TestResultArea>
        {this.state.records.length > 0 && (
          <MinifyMonthlyReport id="minifyMonthlyReport">
            <MonthlyReport
              history={this.props.history}
              match={this.props.match}
              location={this.props.location}
              record={this.state.records[this.state.selectRecordIndex]}
            />
          </MinifyMonthlyReport>
        )}
        {this.state.records.length > 0 && (
          <PagingArea
            page={this.state.selectRecordIndex + 1}
            maxPage={this.state.records.length}
            onMostBack={this.onReportMostBackPage.bind(this)}
            onBack={this.onReportBackPage.bind(this)}
            onNext={this.onReportNextPage.bind(this)}
            onMostNest={this.onReportMostNextPage.bind(this)}
          />
        )}
        <ButtonArea>
          <Link
            to={
              "/client/reports/" +
              this.state.records[this.state.selectRecordIndex]?.id
            }
          >
            <Button background={BlackButton}>全画面表示</Button>
          </Link>
          {this.props.profile?.authority_set &&
            haveStudentEdit(this.props.profile?.authority_set) && (
              <Button
                background={StudentButtonColor}
                onClick={this.onEdit.bind(this)}
              >
                基本情報編集
              </Button>
            )}
          <Link to={"/client/students/" + this.state.user?.id + "/targets"}>
            <Button background={StudentButtonColor}>目標/取得資格</Button>
          </Link>
          <Link to={"/client/students"}>
            <Button background={BlackButton}>ユーザー一覧へ</Button>
          </Link>
        </ButtonArea>
        {this.state.user !== null && (
          <UserEditModal
            history={this.props.history}
            user={this.state.user}
            userType={UserType.student}
            isOpen={this.state.isEditModalOpen}
            onClose={this.onEditModalClose.bind(this)}
            canDelete={
              (this.props.profile?.authority_set &&
                haveStudentDelete(this.props.profile?.authority_set)) ??
              false
            }
          ></UserEditModal>
        )}
      </>
    );
  }

  onEditModalClose() {
    this.getStudent();
    this.setState({
      isEditModalOpen: false
    });
  }

  onEdit() {
    this.setState({
      isEditModalOpen: true
    });
  }

  onReportMostBackPage() {
    if (this.state.selectRecordIndex === 0) {
      return;
    }
    this.setState({ selectRecordIndex: 0 });
  }

  onReportBackPage() {
    if (this.state.selectRecordIndex === 0) {
      return;
    }
    this.setState({ selectRecordIndex: this.state.selectRecordIndex - 1 });
  }

  onReportNextPage() {
    if (this.state.selectRecordIndex === this.state.records.length - 1) {
      return;
    }
    this.setState({ selectRecordIndex: this.state.selectRecordIndex + 1 });
  }

  onReportMostNextPage() {
    if (this.state.selectRecordIndex === this.state.records.length - 1) {
      return;
    }
    this.setState({ selectRecordIndex: this.state.records.length - 1 });
  }

  getHighestEvalResult(levels: EvalLevel[]): EvalLevel | undefined {
    const highestLevel = levels.filter(level => {
      return Object.keys(level.result).length;
    });
    return highestLevel[highestLevel.length - 1];
  }

  getInit() {
    this.setState({
      isLoading: true
    });
    const id = this.props.match.params.id;
    Promise.all([
      get("/students/" + id),
      get("/students/" + id + "/records?order=desc"),
      get("/students/" + id + "/external-eval-results")
    ])
      .then(responses => {
        const user: User = responses[0].result;
        const records: RecordResponse[] = responses[1].result
        const externalEvalResults: ExternalEval[] = responses[2].result;
        this.setState({
          user: user,
          records: records,
          externalEvalResults: externalEvalResults,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  getStudent() {
    this.setState({
      isLoading: true
    });
    const id = this.props.match.params.id;
    get("/students/" + id)
      .then(res => {
        const user: User = res.result;
        this.setState({
          user: user,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default StudentDetailContent;
