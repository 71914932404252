import React from "react";
import WrapLoginArea from "./styles/WrapLoginArea";
import LoginArea from "./styles/LoginArea";
import LoginInput from "./styles/LoginInput";
import LoginButton from "./styles/LoginButton";
import Logo from "./styles/Logo";
import { LoginButtonColor } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { post, get } from "./../../model/api/Request";
import LoadingOverlay from "../common/LoadingOverlay";
import { commonErrorHandle } from "../../utils/errorHandle";
import { BottomLabel } from "./styles/BottomLabel";
import { Profile } from "../../utils/profile";
import ProfileArea from "../common/ProfileArea";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  isLoading: boolean;
};

class Verify extends React.Component<Props, State> {
  code: string = "";

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapLoginArea>
          <Logo />
          <LoginArea color="white">
            <LoginInput
              type="text"
              placeholder="認証コード"
              index={0}
              onChange={this.onChangeCode.bind(this)}
            />
            <LoginButton
              color={LoginButtonColor}
              onClick={this.onLoginButton.bind(this)}
            >
              認証
            </LoginButton>
            <BottomLabel>
              認証コードを再送する場合は
              <span
                onClick={this.resendVerifyCode.bind(this)}
                style={{
                  color: "#0000EE",
                  cursor: "pointer",
                  textDecoration: "underline"
                }}
              >
                こちら
              </span>
            </BottomLabel>
            <BottomLabel>
              メールアドレスを再度変更する場合は
              <span
                onClick={this.onRechangeEmail.bind(this)}
                style={{
                  color: "#0000EE",
                  cursor: "pointer",
                  textDecoration: "underline"
                }}
              >
                こちら
              </span>
            </BottomLabel>
          </LoginArea>
        </WrapLoginArea>
        <ProfileArea
          history={this.props.history}
          profile={this.props.profile}
        />
      </>
    );
  }

  onRechangeEmail() {
    this.props.history.push("/email/change");
  }

  resendVerifyCode() {
    this.setState({ isLoading: true });
    get("/profile/email/verify")
      .then(res => {
        this.setState({ isLoading: false });
        alert("認証コードを再送しました。");
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onChangeCode(e: React.ChangeEvent<HTMLInputElement>) {
    this.code = e.target.value;
  }

  onLoginButton() {
    this.setState({ isLoading: true });
    post("/profile/email/verify", {
      code: this.code
    })
      .then(res => {
        alert("メールアドレスの認証が完了しました。");
        if (this.props.profile.user_type.name === "student") {
          this.props.history.push("/student");
        } else if (this.props.profile.user_type.name === "coach") {
          this.props.history.push("/client");
        }
      })

      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default Verify;
