import styled from "styled-components";
import { Color, Space } from "../../../../styles";

export const Container = styled.div`
  background-color: ${Color.WHITE};
`;

export const Header = styled.div<{ background: string }>`
  padding: ${Space * 0.5}px ${Space * 2}px;
  background-color: ${({ background }) => background};
  white-space: nowrap;
`;

export const Content = styled.div`
  padding: ${Space * 1}px;
  border: 1px solid ${Color.GRAY_DARK};
`;
