import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-appearance: none;
  }

  html,
  body {
    font-size: 14px;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    width: 100%;
    height: 100%;
    font-family: 'Noto Sans JP', sans-serif;
  }

  button {
    font-family: 'Noto Sans JP', sans-serif;
  }

  #root {
    width: 100%;
    height: 100%;
  }
`;
