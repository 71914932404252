import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  img {
    display: block;
    width: 100%;
  }
`;

export const LevelContainer = styled.div`
  position: absolute;
  top: 0;
  left: 6%;
  width: 26.5%;
  height: 0;
  padding-top: 14%;
  background-color: #f2f2f2;
  border: 4px solid #d84236;
  border-radius: 8px;
`;

export const LeveTextContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
`;

export const LevelBottomContainer = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  top: 35%;
  left: 6%;
  width: 26.5%;
  height: 36px;
`;

export const BorderBox = styled.div<{ height: string; right: string }>`
  position: absolute;
  bottom: 26.5%;
  right: ${({ right }) => right};
  width: 9%;
  height: ${({ height }) => height};
  border: 1px dashed #d84236;
  border-radius: 4px;
`;
