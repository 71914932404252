import React from "react";
import WrapLoginArea from "./styles/WrapLoginArea";
import LoginArea from "./styles/LoginArea";
import LoginInput from "./styles/LoginInput";
import LoginButton from "./styles/LoginButton";
import Logo from "./styles/Logo";
import { LoginButtonColor } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { put } from "../../model/api/Request";
import { CustomError } from "../../model/api/CustomError";
import LoadingOverlay from "../common/LoadingOverlay";
import {
  isEmail,
  isExistSpace,
  isExistFullWidthCharacter
} from "../../utils/validation";
import { commonErrorHandle } from "../../utils/errorHandle";
import { Profile } from "../../utils/profile";
import ProfileArea from "../common/ProfileArea";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  isLoading: boolean;
};

class ActivateInfo extends React.Component<Props, State> {
  id: string = "";
  email: string = "";

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapLoginArea>
          <Logo />
          <LoginArea color="white">
            <LoginInput
              type="text"
              placeholder="新しいID"
              index={0}
              onChange={this.onChangeId.bind(this)}
            />
            <LoginInput
              type="text"
              placeholder="Email"
              index={1}
              onChange={this.onChangeEmail.bind(this)}
            />
            <LoginButton
              color={LoginButtonColor}
              onClick={this.onActivateButton.bind(this)}
            >
              アクティベート
            </LoginButton>
          </LoginArea>
        </WrapLoginArea>
        <ProfileArea
          history={this.props.history}
          profile={this.props.profile}
        />
      </>
    );
  }

  onChangeId(e: React.ChangeEvent<HTMLInputElement>) {
    this.id = e.target.value;
  }

  onChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    this.email = e.target.value;
  }

  onActivateButton() {
    const errorMessages: string[] = [];

    if (!isEmail(this.email)) {
      errorMessages.push("・ メールアドレスの形式が不正です。");
    }

    if (this.id === "") {
      errorMessages.push("・ ログインIDを入力してください。");
    }

    if (isExistSpace(this.id)) {
      errorMessages.push("・ ログインIDにスペースを含むことはできません。");
    }

    if (isExistFullWidthCharacter(this.id)) {
      errorMessages.push("・ ログインIDに全角文字を使用することはできません。");
    }

    if (errorMessages.length !== 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    const params: { [index: string]: string } = {};
    params["email"] = this.email;
    params["login_id"] = this.id;
    this.setState({ isLoading: true });
    put("/profile", { user_attributes: params })
      .then(response => {
        this.setState({ isLoading: false });
        this.props.history.push("/verify");
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (error instanceof CustomError) {
          const customError = error as CustomError;

          if (customError.code === "UserAlreadyExistsError") {
            alert("既に存在するユーザーIDです。");
            return;
          }
          commonErrorHandle(error, this.props.history);
        }
      });
  }
}

export default ActivateInfo;
