import styled from "styled-components";
import { BlackButton, SubStringColor } from "./../Colors";

export const Element = styled.div`
  float: right;
  border-radius: 24px;
  border: 4px solid white;
  margin: 12px;
  height: 88px;
`;
export const LogouButton = styled.button`
  float: right;
  margin: 18px 16px;
  background: ${BlackButton};
  border: none;
  color: white;
  border-radius: 10px;
  height: 44px;
  width: 140px;
  font-size: 24px;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;
export const StringElement = styled.div`
  float: left;
  text-align: center;
  margin-left: 20px;
  margin-right: 32px;
  margin-top: 8px;
  color: ${SubStringColor};
`;
export const Affiliation = styled.div``;
export const Name = styled.div`
  font-size: 32px;
  font-weight: bold;
`;
