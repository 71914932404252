import styled from "styled-components";
import { Color, Space } from "../../../../styles";

export const Container = styled.div`
  margin: -${Space * 0.5}px 0 0;
  padding: 0 ${Space}px;
`;

export const StatusLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

function getPosition(value: number) {
  // minが2.5%, maxが97.5%
  return `${11.875 * value - 9.375}%`;
}

export const CurrentStatus = styled.div<{ value: number }>`
  position: absolute;
  top: 50%;
  left: ${({ value }) => getPosition(value)};
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #9d88ac;
`;

export const CirclesContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${Space * 2}px 0;
`;

export const Circle = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid ${Color.GRAY_DARK};
`;
