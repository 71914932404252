import styled from "styled-components";
import { PopupFrame } from "../../common/Colors";

export const ModalStyle = {
  overlay: {
    zIndex: 99
  },
  content: {
    top: "50%",
    left: "50%",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "600px",
    border: PopupFrame,
    borderRadius: "48px"
  }
};

export const TitleLabel = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: rgba(112, 112, 112, 1);
  margin-left: 16px;
`;

export const BodyLabel = styled.p`
  font-size: 18px;
  color: rgba(112, 112, 112, 1);
  margin-left: 16px;
`;

export const Input = styled.input`
  margin-left: 16px;
  margin-bottom: 12px;
  width: 92%;
  padding: 12px 8px;
  font-size: 18px;
  border: solid 1px rgba(112, 112, 112, 1);
  border-radius: 8px;
  background: ${props => (props.disabled ? "rgba(232, 232, 232, 1)" : "white")};
  &:focus {
    outline: 0;
  }
`;

export const InputCheckbox = styled.input`
  margin-left: 18px;
  margin-right: 16px;
  cursor: pointer;

  :before {
    content: "";
    margin-top: -4px;
    margin-left: -2px;
    width: 18px;
    height: 18px;
    display: block;
    border: 2px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  :after {
    float: left;
    margin-top: -15px;
    margin-left: 2px;
    font-size: 1.3em;
    line-height: 0.8;
    color: #09ad7e;
    font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
  }

  :not(:checked):after {
    content: "";
  }
  :checked:after {
    content: "\\2713\\0020";
  }
  :hover:before {
    border: 2px solid #4778d9;
  }

  :focus {
    outline: 0;
  }
`;

export const InputCheckboxLabel = styled.span`
  font-size: 18px;
`;

export const InputNameArea = styled.div`
  display: flex;
`;

export const InputNameElement = styled.input`
  margin-left: 16px;
  margin-bottom: 12px;
  width: 44.5%;
  padding: 12px 8px;
  font-size: 18px;
  border: solid 1px rgba(112, 112, 112, 1);
  border-radius: 8px;
  &:focus {
    outline: 0;
  }
`;

export const ButtonArea = styled.div`
  text-align: center;
  margin: 12px;
`;

export const DisablableButton = styled.button<{
  background: string;
  isActive: boolean;
}>`
  margin-right: 12px;
  margin-left: 12px;
  color: white;
  background: ${props => props.background};
  border: none;
  font-size: 17px;
  border-radius: 12px;
  padding: 12px 16px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
  opacity: ${props => (props.isActive ? 1 : 0.5)};
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const Button = styled.button<{
  background: string;
}>`
  margin-right: 12px;
  margin-left: 12px;
  color: white;
  background: ${props => props.background};
  border: none;
  font-size: 18px;
  border-radius: 12px;
  padding: 12px 48px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const CloseButtonImage = styled.img`
  width: 32px;
  height: 32px;
`;

export const SelectElementArea = styled.div<{ scrollable: boolean }>`
  overflow-y: ${props => (props.scrollable ? "scroll" : "visible")};
  max-height: 400px;
`;

export const SelectElementDiv = styled.div`
  text-align: -webkit-center;
  margin-bottom: 12px;
  width: 100%;
`;

export const SelectElement = styled.button<{ isSelected: boolean }>`
  width: 85%;
  font-size: 26px;
  text-align: center;
  border: solid 1px rgba(112, 112, 112, 1);
  border-radius: 8px;
  background: ${props => (props.isSelected ? "rgba(0, 0, 0, 0.1)" : "white")};
  padding: 8px 0;
  cursor: pointer;
  outline: none;
  &:active {
    color: black;
  }
`;
