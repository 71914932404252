import styled from "styled-components";
import { SidebarBorder } from "./Colors";

const Sidebar = styled.div<{ color: string }>`
  background: ${props => props.color};
  height: 96%;
  width: 320px;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin-left: 24px;
  padding: 16px 0;
  border-radius: 32px;
  border-style: solid;
  border-width: 1px;
  border-color: ${SidebarBorder};
  text-align: center;
  overflow: hidden;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export default Sidebar;
