import React from "react";
import { User } from "../../model/entities/User";
import { ListRowTr, ListRowTd } from "../common/styles/ListRowStyle";
import { InputCheckbox } from "./styles/StudentEditModalStyle";

type Props = {
  isChecked: boolean;
  student: User;
  onCheckboxChanged: (isCheck: boolean, studnet: User) => void;
};

type State = {};

class StudentEditModalListRow extends React.Component<Props, State> {
  render() {
    const coach =
      Object.keys(this.props.student.student!).length !== 0 &&
      this.props.student.student!.coach! !== null
        ? this.props.student.student!.coach!.family_name_ja +
          " " +
          this.props.student.student!.coach!.first_name_ja
        : "-";
    return (
      <ListRowTr>
        <ListRowTd>{this.props.student.affiliation_division.name}</ListRowTd>
        <ListRowTd>
          {this.props.student.family_name_ja +
            " " +
            this.props.student.first_name_ja}
        </ListRowTd>
        <ListRowTd>{coach}</ListRowTd>
        <ListRowTd>
          <InputCheckbox
            type="checkbox"
            onChange={this.onCheckboxChanged.bind(this)}
            checked={this.props.isChecked}
          ></InputCheckbox>
        </ListRowTd>
      </ListRowTr>
    );
  }

  onCheckboxChanged(e: React.ChangeEvent<HTMLInputElement>) {
    this.props.onCheckboxChanged(e.target.checked, this.props.student);
  }
}

export default StudentEditModalListRow;
