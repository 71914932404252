import styled from "styled-components";
import { Color } from "../../../../styles";

export const Container = styled.div``;

export const ChartContainer = styled.div`
  & > .recharts-wrapper {
    margin-top: -26%;
    width: 100% !important;
    height: auto !important;
    > svg {
      width: 100%;
      height: auto;
    }
  }
  .custom-name-label {
    font-weight: bold;
    fill: ${Color.WHITE};
  }
  .custom-score-label {
    font-weight: bold;
    fill: ${Color.GRAY_LIGHT};
  }
`;
