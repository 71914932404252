import React from "react";
import { WrapBackground } from "../common/WrapBackground";
import StudentListContent from "./StudentListContent";
import { get } from "../../model/api/Request";
import {
  StudentBackground,
  StudentButtonColor,
  StudentSidebarBackground
} from "../common/Colors";
import {
  TopButton,
  FilterButton,
  TopRightArea
} from "../common/styles/TopButton";
import PagingArea from "../common/PagingArea";
import { RouteComponentProps } from "react-router-dom";
import UserCreateModal, { UserType } from "../modal/UserCreateModal";
import { User } from "../../model/entities/User";
import { EnableCount } from "../../model/entities/EnableCount";
import { commonErrorHandle } from "../../utils/errorHandle";
import { haveStudentCreate } from "../../utils/checkAuthority";
import {
  pagingLimit,
  queryPageKey,
  querySortByKey,
  queryOrderKey,
  queryAffiliationDivisionKey,
  queryAffiliationDivisionNameKey,
  SortBy,
  Order,
  queryFirstNameKey,
  queryFamilyNameKey,
  queryCoachKey,
  queryCoachFirstNameKey,
  queryCoachFamilyNameKey,
  queryTargetTypeKey,
  queryAcademicLevelKey,
  queryExternalEvalKey,
  queryExternalEvalAndKey
} from "../../utils/Constant";
import LoadingOverlay from "../common/LoadingOverlay";
import { Profile } from "../../utils/profile";
import FilterStudentModal from "./FilterStudentModal";
import {
  extractPage,
  extractSortBy,
  extractOrder,
  extractDivition,
  extractDivisionName,
  extractFirstName,
  extractFamilyName,
  extractCoach,
  extractCoachFamilyName,
  extractCoachFirstName,
  extractTargetType,
  extractExternalEval,
  extractExternalEvalSearchType,
  extractAcademicLevel
} from "../../utils/query";
import querystring from "querystring";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  isCreateModalOpen: boolean;
  maxPage: number;
  students: User[];
  isLoading: boolean;
  isFilterModalOpen: boolean;
  enableCount: EnableCount | null;
};

class StudentList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isCreateModalOpen: false,
      maxPage: 0,
      students: [],
      isLoading: false,
      isFilterModalOpen: false,
      enableCount: null
    };
  }

  componentDidMount() {
    this.getInit();
  }

  render() {
    return (
      <>
        <FilterStudentModal
          isOpen={this.state.isFilterModalOpen}
          onClose={this.onFilterClose.bind(this)}
          onSearch={this.searchStudents.bind(this)}
          query={this.getQuery()}
        />
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapBackground
          color={StudentBackground}
          history={this.props.history}
          profile={this.props.profile}
          selectedSidebar="student"
          sidebarColor={StudentSidebarBackground}
        >
          <FilterButton
            onClick={this.onFilterOpen.bind(this)}
            isTestResultDetail={false}
          >
            検索・フィルター
          </FilterButton>
          <TopRightArea>
            {this.props.profile.authority_set &&
              haveStudentCreate(this.props.profile.authority_set) && (
                <TopButton
                  onClick={this.onCreate.bind(this)}
                  color={StudentButtonColor}
                >
                  新規登録
                </TopButton>
              )}
            <PagingArea
              page={extractPage(this.getQuery())}
              maxPage={this.state.maxPage}
              onMostBack={this.onMostBackPage.bind(this)}
              onBack={this.onBackPage.bind(this)}
              onNext={this.onNextPage.bind(this)}
              onMostNest={this.onMostNextPage.bind(this)}
              currentNumber={this.state.enableCount?.enabled_user_num}
              maxNumber={this.state.enableCount?.max_enabled_user_num}
            />
          </TopRightArea>
          <StudentListContent
            history={this.props.history}
            profile={this.props.profile}
            students={this.state.students}
            onChangeSort={this.onChangeSort.bind(this)}
            sortBy={extractSortBy(this.getQuery())}
            order={extractOrder(this.getQuery())}
          />
          <UserCreateModal
            history={this.props.history}
            userType={UserType.student}
            isOpen={this.state.isCreateModalOpen}
            onClose={this.onCreateModalClose.bind(this)}
          />
        </WrapBackground>
      </>
    );
  }

  onFilterOpen() {
    this.setState({ isFilterModalOpen: true });
  }

  onFilterClose() {
    this.setState({ isFilterModalOpen: false });
  }

  onCreate() {
    this.setState({
      isCreateModalOpen: true
    });
  }

  onCreateModalClose() {
    this.setState({
      isCreateModalOpen: false
    });
    this.pushWithQuery(this.getQuery());
  }

  getInit() {
    this.setState({ isLoading: true });
    let query = "";
    query += `limit=${pagingLimit}`;
    query += `&${queryPageKey}=${extractPage(this.getQuery())}`;
    if (extractOrder(this.getQuery())) {
      query += `&${queryOrderKey}=${extractOrder(this.getQuery())}`;
    }
    if (extractSortBy(this.getQuery())) {
      query += `&${querySortByKey}=${extractSortBy(this.getQuery())}`;
    }
    if (extractDivition(this.getQuery()).length !== 0) {
      const divisionQuery = extractDivition(this.getQuery())
        .map(i => {
          return `${queryAffiliationDivisionKey}=${i}`;
        })
        .join("&");
      query += `&${divisionQuery}`;
    }
    if (extractFirstName(this.getQuery())) {
      query += `&${queryFirstNameKey}=${extractFirstName(this.getQuery())}`;
    }
    if (extractFamilyName(this.getQuery())) {
      query += `&${queryFamilyNameKey}=${extractFamilyName(this.getQuery())}`;
    }
    if (extractDivisionName(this.getQuery())) {
      query += `&${queryAffiliationDivisionNameKey}=${extractDivisionName(
        this.getQuery()
      )}`;
    }

    if (extractCoach(this.getQuery()).length !== 0) {
      const coachQuery = extractCoach(this.getQuery())
        .map(i => {
          return `${queryCoachKey}=${i}`;
        })
        .join("&");
      query += `&${coachQuery}`;
    }
    if (extractCoachFirstName(this.getQuery())) {
      query += `&${queryCoachFirstNameKey}=${extractCoachFirstName(
        this.getQuery()
      )}`;
    }
    if (extractCoachFamilyName(this.getQuery())) {
      query += `&${queryCoachFamilyNameKey}=${extractCoachFamilyName(
        this.getQuery()
      )}`;
    }

    if (extractTargetType(this.getQuery()).length !== 0) {
      const targetTypeQuery = extractTargetType(this.getQuery())
        .map(i => {
          return `${queryTargetTypeKey}=${i}`;
        })
        .join("&");
      query += `&${targetTypeQuery}`;
    }

    if (extractExternalEval(this.getQuery()).length !== 0) {
      const evalQuery = extractExternalEval(this.getQuery())
        .map(i => {
          if (extractExternalEvalSearchType(this.getQuery()) === "or") {
            return `${queryExternalEvalKey}=${i}`;
          } else if (extractExternalEvalSearchType(this.getQuery()) === "and") {
            return `${queryExternalEvalAndKey}=${i}`;
          }
          return "";
        })
        .join("&");
      query += `&${evalQuery}`;
    }

    if (extractAcademicLevel(this.getQuery()).length !== 0) {
      const academicLevelQuery = extractAcademicLevel(this.getQuery())
        .map(i => {
          return `${queryAcademicLevelKey}=${i}`;
        })
        .join("&");
      query += `&${academicLevelQuery}`;
    }

    Promise.all([
      get("/students/count?" + query),
      get("/students?" + query),
      get("/users/enabled_count")
    ])
      .then(responses => {
        this.setState({
          maxPage: Math.ceil(responses[0].result.count / pagingLimit),
          isLoading: false,
          students: responses[1].result,
          enableCount: responses[2].result
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onMostNextPage() {
    const page = extractPage(this.getQuery());
    if (page === this.state.maxPage) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(this.state.maxPage);
    this.pushWithQuery(query);
  }

  onNextPage() {
    const page = extractPage(this.getQuery());
    if (page === this.state.maxPage) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(page + 1);
    this.pushWithQuery(query);
  }

  onBackPage() {
    const page = extractPage(this.getQuery());
    if (page === 1) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(page - 1);
    this.pushWithQuery(query);
  }

  onMostBackPage() {
    const page = extractPage(this.getQuery());
    if (page === 1) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(1);
    this.pushWithQuery(query);
  }

  pushWithQuery(query: { [name: string]: string | string[] }) {
    this.props.history.push(
      this.props.location.pathname + "?" + querystring.stringify(query)
    );
  }

  getQuery(): { [name: string]: string | string[] } {
    const query = querystring.parse(this.props.location.search.slice(1));
    return query as { [name: string]: string | string[] };
  }

  onChangeSort(sortBy: SortBy, order: Order) {
    const query = this.getQuery();
    query[querySortByKey] = sortBy;
    query[queryOrderKey] = order;
    this.pushWithQuery(query);
  }

  searchStudents(query: string) {
    let queryDict: { [name: string]: string | string[] } = {};
    if (extractSortBy(this.getQuery())) {
      queryDict[querySortByKey] = extractSortBy(this.getQuery());
    }
    if (extractOrder(this.getQuery())) {
      queryDict[queryOrderKey] = extractOrder(this.getQuery());
    }
    queryDict[queryPageKey] = "1";
    queryDict = Object.assign(queryDict, querystring.parse(query));
    this.pushWithQuery(queryDict);
  }
}

export default StudentList;
