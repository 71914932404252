import React from "react";
import {
  ErrorBackground,
  ErrorContainer,
  StatusCode,
  Message,
  SubMessage,
  MessageContainer,
  ErrorIconStyle
} from "./styles/ErrorStyle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Error500 extends React.Component {
  render() {
    return (
      <ErrorBackground>
        <FontAwesomeIcon icon="exclamation-triangle" style={ErrorIconStyle} />
        <ErrorContainer>
          <MessageContainer>
            <StatusCode>500</StatusCode>
            <Message>Internal Server Error</Message>
            <SubMessage>サーバー内部エラー</SubMessage>
          </MessageContainer>
        </ErrorContainer>
      </ErrorBackground>
    );
  }
}

export default Error500;
