import styled from "styled-components";

export const Container = styled.div``;

export const RadarChartContainer = styled.div`
  & > .recharts-wrapper {
    width: 100% !important;
    height: auto !important;
    > svg {
      width: 100%;
      height: auto;
    }
  }
`;
