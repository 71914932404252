import styled from "styled-components";

type Props = {
  type: string;
  index: number;
};

const LoginInput = styled.input.attrs(props => ({
  type: props.type
}))<Props>`
  font-size: 30px;
  font-weight: 600;
  background-color: white;
  border: 4px black solid;
  width: 80%;
  height: 70px;
  text-align: center;
  margin-top: ${props => (props.index === 0 ? "65px" : "40px")};
  color: black;
  box-shadow: 0 0 8px gray;
`;

export default LoginInput;
