import React from "react";
import Modal from "react-modal";
import { CreateButton, DeleteButton } from "../common/Colors";
import LoadingOverlay from "../common/LoadingOverlay";
import {
  ModalStyle,
  Title,
  Border,
  Section,
  InputHalfArea,
  InputHalf,
  CheckArea,
  CheckAreaRow,
  CheckContent,
  CheckLabel,
  InputCheckbox,
  MarginTopInput,
  ButtonArea,
  Button
} from "./styles/FilterCoachModalStyle";
import {
  CloseButton,
  CloseButtonImage
} from "../modal/styles/UserEditModalStyle";
import { AffiliationDivision } from "../../model/entities/AffiliationDivision";
import { AuthoritySet } from "../../model/entities/AuthoritySet";
import { get } from "../../model/api/Request";
import { divideArray } from "../../utils/array";
import { getDivisionsRecursively } from "../../utils/division";
import {
  queryFamilyNameKey,
  queryFirstNameKey,
  queryAffiliationDivisionKey,
  queryAffiliationDivisionNameKey,
  queryAuthoritySetKey,
  queryIsValidKey,
  queryIsNotValidKey
} from "../../utils/Constant";

type Props = {
  isOpen: boolean;
  query: { [name: string]: string | string[] };
  onClose: () => void;
  onSearch: (query: string) => void;
  haveAuthoritySetRead: boolean;
};

type State = {
  isLoading: boolean;
  affiliationDivisions: AffiliationDivision[];
  authoritySets: AuthoritySet[];
  familyName: string;
  firstName: string;
  selectedDivisionIds: number[];
  divisionName: string;
  selectedAuthoritySetIds: number[];
  isValid: boolean;
  isNotValid: boolean;
};

class FilterCoachModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const familyNameQ = props.query[queryFamilyNameKey];
    const familyName = typeof familyNameQ === "string" ? familyNameQ : "";

    const firstNameQ = props.query[queryFirstNameKey];
    const firstName = typeof firstNameQ === "string" ? firstNameQ : "";

    let affiliationDivisions: number[] = [];
    if (typeof props.query[queryAffiliationDivisionKey] === "string") {
      affiliationDivisions = [+props.query[queryAffiliationDivisionKey]];
    } else if (props.query[queryAffiliationDivisionKey] instanceof Array) {
      affiliationDivisions = (props.query[
        queryAffiliationDivisionKey
      ] as string[]).map(id => +id);
    }

    const divisionQ = props.query[queryAffiliationDivisionNameKey];
    const division = typeof divisionQ === "string" ? divisionQ : "";

    let authoritySet: number[] = [];
    if (typeof props.query[queryAuthoritySetKey] === "string") {
      authoritySet = [+props.query[queryAuthoritySetKey]];
    } else if (props.query[queryAuthoritySetKey] instanceof Array) {
      authoritySet = (props.query[queryAuthoritySetKey] as string[]).map(
        id => +id
      );
    }

    const isValidQ = props.query[queryIsValidKey];
    const isValid = typeof isValidQ === "string" && isValidQ === "true";

    const isNotValidQ = props.query[queryIsNotValidKey];
    const isNotValid =
      typeof isNotValidQ === "string" && isNotValidQ === "true";

    this.state = {
      isLoading: false,
      affiliationDivisions: [],
      authoritySets: [],
      familyName: familyName,
      firstName: firstName,
      selectedDivisionIds: affiliationDivisions,
      divisionName: division,
      selectedAuthoritySetIds: authoritySet,
      isValid: isValid,
      isNotValid: isNotValid
    };
  }

  componentDidMount() {
    const getRequests = [get("/affiliation-divisions")];
    if (this.props.haveAuthoritySetRead) {
      getRequests.push(get("/authority-sets"));
    }
    Promise.all(getRequests).then(responses => {
      const divisions = getDivisionsRecursively(responses[0].result);
      const authoritySets = this.props.haveAuthoritySetRead
        ? responses[1].result
        : [];
      this.setState({
        affiliationDivisions: divisions,
        authoritySets: authoritySets
      });
    });
  }

  render() {
    const divisions = divideArray(this.state.affiliationDivisions, 2).map(
      (divisions: AffiliationDivision[]) => {
        return (
          <CheckAreaRow key={divisions[0].id}>
            {divisions.map(division => {
              return (
                <CheckContent key={division.id}>
                  <InputCheckbox
                    type="checkbox"
                    checked={
                      this.state.selectedDivisionIds.find(
                        id => id === division.id
                      ) !== undefined
                    }
                    onChange={this.onDivisionChecked.bind(this)}
                  />
                  <CheckLabel>{division.name}</CheckLabel>
                </CheckContent>
              );
            })}
          </CheckAreaRow>
        );
      }
    );
    const authoritySets = divideArray(this.state.authoritySets, 2).map(
      (authoritySets: AuthoritySet[]) => {
        return (
          <CheckAreaRow key={authoritySets[0].id}>
            {authoritySets.map(authoritySet => {
              return (
                <CheckContent key={authoritySet.id}>
                  <InputCheckbox
                    type="checkbox"
                    checked={
                      this.state.selectedAuthoritySetIds.find(
                        id => id === authoritySet.id
                      ) !== undefined
                    }
                    onChange={this.onAuthoritySetChecked.bind(this)}
                  />
                  <CheckLabel>{authoritySet.name}</CheckLabel>
                </CheckContent>
              );
            })}
          </CheckAreaRow>
        );
      }
    );
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
          <Title>検索・フィルター</Title>
          <Border />
          <Section>
            <Title>氏名</Title>
            <InputHalfArea>
              <InputHalf
                placeholder="姓を入力してください"
                value={this.state.familyName}
                onChange={this.onChangeLastName.bind(this)}
              />
              <InputHalf
                placeholder="名を入力してください"
                value={this.state.firstName}
                onChange={this.onChangeFirstName.bind(this)}
              />
            </InputHalfArea>
          </Section>
          <Border />
          <Section>
            <Title>所属</Title>
            <CheckArea>
              {divisions}
              <MarginTopInput
                disabled={this.state.selectedDivisionIds.length !== 0}
                placeholder="入力してください"
                value={this.state.divisionName}
                onChange={this.onChangeDivisionName.bind(this)}
              />
            </CheckArea>
          </Section>
          <Border />
          {/* 管理者閲覧権限あり、かつauthority-set閲覧権限がない場合の対応 */}
          {this.state.authoritySets.length !== 0 && (
            <>
              <Section>
                <Title>権限レベル</Title>
                <CheckArea>{authoritySets}</CheckArea>
              </Section>
              <Border />
            </>
          )}
          <Section>
            <Title>有効 / 無効</Title>
            <CheckArea>
              <CheckAreaRow>
                <CheckContent>
                  <InputCheckbox
                    type="checkbox"
                    checked={this.state.isValid}
                    onChange={this.onValidCheck.bind(this)}
                  ></InputCheckbox>
                  <CheckLabel>有効</CheckLabel>
                </CheckContent>
                <CheckContent>
                  <InputCheckbox
                    type="checkbox"
                    checked={this.state.isNotValid}
                    onChange={this.onNotValidCheck.bind(this)}
                  ></InputCheckbox>
                  <CheckLabel>無効</CheckLabel>
                </CheckContent>
              </CheckAreaRow>
            </CheckArea>
          </Section>
          <Border />
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onSearch.bind(this)}
            >
              検索
            </Button>
            <Button background={DeleteButton} onClick={this.onClear.bind(this)}>
              値をクリア
            </Button>
          </ButtonArea>
        </Modal>
        <LoadingOverlay isLoading={this.state.isLoading} />
      </>
    );
  }

  onChangeLastName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ familyName: e.target.value });
  }

  onChangeFirstName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ firstName: e.target.value });
  }

  onDivisionChecked(e: React.ChangeEvent<HTMLInputElement>) {
    const selectDivision = this.state.affiliationDivisions.filter(division => {
      return division.name === e.target.parentElement?.children[1].innerHTML;
    })[0];
    let selectedDivisionIds = this.state.selectedDivisionIds;
    if (e.target.checked) {
      selectedDivisionIds.push(selectDivision.id);
    } else {
      selectedDivisionIds = selectedDivisionIds.filter(
        id => id !== selectDivision.id
      );
    }
    this.setState({ selectedDivisionIds: selectedDivisionIds });
  }

  onChangeDivisionName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ divisionName: e.target.value });
  }

  onAuthoritySetChecked(e: React.ChangeEvent<HTMLInputElement>) {
    const selectAuthoritySet = this.state.authoritySets.filter(authoritySet => {
      return (
        authoritySet.name === e.target.parentElement?.children[1].innerHTML
      );
    })[0];
    let selectedAuthoritySetIds = this.state.selectedAuthoritySetIds;
    if (e.target.checked) {
      selectedAuthoritySetIds.push(selectAuthoritySet.id);
    } else {
      selectedAuthoritySetIds = selectedAuthoritySetIds.filter(
        id => id !== selectAuthoritySet.id
      );
    }
    this.setState({ selectedAuthoritySetIds: selectedAuthoritySetIds });
  }

  onValidCheck(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ isValid: e.target.checked });
  }
  onNotValidCheck(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ isNotValid: e.target.checked });
  }

  onClear() {
    this.setState({
      familyName: "",
      firstName: "",
      selectedDivisionIds: [],
      divisionName: "",
      selectedAuthoritySetIds: [],
      isValid: false,
      isNotValid: false
    });
  }

  onSearch() {
    let query: string[] = [];
    if (this.state.familyName !== "") {
      query.push(`${queryFamilyNameKey}=${this.state.familyName}`);
    }
    if (this.state.firstName !== "") {
      query.push(`${queryFirstNameKey}=${this.state.firstName}`);
    }
    const divisionIds = this.state.selectedDivisionIds
      .map(id => {
        return `${queryAffiliationDivisionKey}=${id}`;
      })
      .join("&");
    if (divisionIds !== "") {
      query.push(divisionIds);
    } else if (this.state.divisionName !== "") {
      query.push(
        `${queryAffiliationDivisionNameKey}=${this.state.divisionName}`
      );
    }
    const authoritySetIds = this.state.selectedAuthoritySetIds
      .map(id => {
        return `${queryAuthoritySetKey}=${id}`;
      })
      .join("&");
    if (authoritySetIds !== "") {
      query.push(authoritySetIds);
    }
    if (this.state.isValid) {
      query.push(`${queryIsValidKey}=true`);
    }
    if (this.state.isNotValid) {
      query.push(`${queryIsNotValidKey}=true`);
    }
    this.props.onSearch(query.join("&"));
  }

  onClose() {
    this.props.onClose();
  }
}

export default FilterCoachModal;
