import { Question } from "../../../../model/entities/Question";
import { TestInformation } from "../../../../model/entities/TestInformation";
import React from "react";
import TrainingQuestionListRow from "./TrainingQuestionListRow";
import TrainingTestContent from "./TrainingTestContent";
import { match } from "react-router-dom";
import * as H from "history";
import { ListContentTable } from "../../../common/styles/ListContentStyle";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  Th,
  TagElement,
  TagTable,
  TagTh,
  TagTd
} from "./styles/TrainingQuestionListContentStyle";

type State = {};

type Props = {
  match: match<{ id: string }>;
  history: H.History;
  testInformation: TestInformation | null;
};

class TrainingQuestionListContent extends React.Component<Props, State> {
  render() {
    const listRows = this.props.testInformation?.questions.map(
      (question: Question) => (
        <TrainingQuestionListRow
          key={question.question_number}
          question={question}
          history={this.props.history}
        />
      )
    );
    return (
      <>
        <TrainingTestContent testInformation={this.props.testInformation} />
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <Th>NO.</Th>
                <Th>問題</Th>
                <Th>正解</Th>
                <Th>平均正答率</Th>
                <Th>平均解答時間</Th>
                <Th>分野</Th>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement>
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
        <TagElement>
          <TagTable>
            <thead>
              <tr>
                <TagTh>分野</TagTh>
                <TagTh>A</TagTh>
                <TagTh>B</TagTh>
                <TagTh>C</TagTh>
                <TagTh>D</TagTh>
                <TagTh>E</TagTh>
                <TagTh>F</TagTh>
                <TagTh>G</TagTh>
                <TagTh>H</TagTh>
                <TagTh>I</TagTh>
                <TagTh>J</TagTh>
                <TagTh>K</TagTh>
                <TagTh>L</TagTh>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TagTd>分野名</TagTd>
                <TagTd>文型</TagTd>
                <TagTd>態</TagTd>
                <TagTd>時制</TagTd>
                <TagTd>助動詞</TagTd>
                <TagTd>不定詞</TagTd>
                <TagTd>動名詞</TagTd>
                <TagTd>分詞</TagTd>
                <TagTd>関係詞</TagTd>
                <TagTd>比較</TagTd>
                <TagTd>仮定法</TagTd>
                <TagTd>接続詞</TagTd>
                <TagTd>その他</TagTd>
              </tr>
            </tbody>
          </TagTable>
        </TagElement>
      </>
    );
  }
}

export default TrainingQuestionListContent;
