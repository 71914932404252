import * as React from "react";
import { Container, RadarChartContainer, RadarChartNoData } from "./styled";
import { Element } from "../../types";
import { Flex } from "../../../common/Flex";
import { Spacer } from "../../../common/Spacer";
import { RecordElement } from "../RecordElement";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend
} from "recharts";
import { Section } from "../Section";

function createRadarData(items: Element[]) {
  const data = items
    .map(item => ({
      ...item,
      // TODO: average
      average: 3.5,
      name: item.name_ja
    }))
    // importanceがnullの場合はデータなしとみなす
    .filter(item => item.importance !== null);
  // 順番を恣意的に変更
  if (data.length === 6) {
    return [data[5], data[3], data[1], data[2], data[0], data[4]];
  } else if (data.length === 4) {
    return [data[3], data[1], data[2], data[0]];
  } else {
    return [data[0], data[1]];
  }
}

type Props = {
  items: Element[];
};

const RecordElements: React.FunctionComponent<Props> = ({ items }) => {
  items = items.sort((lhs, rhs) => (lhs.order < rhs.order ? -1 : 1));
  return (
    <Container>
      {items[5] && <RecordElement index={5} item={items[5]} />}
      <Spacer pt={1.5} />
      {items[4] && items[3] && (
        <Flex display="flex">
          <Flex flex={1}>
            <RecordElement index={4} item={items[4]} />
          </Flex>
          <Spacer pl={1.5} />
          <Flex flex={1}>
            <RecordElement index={3} item={items[3]} />
          </Flex>
        </Flex>
      )}
      <Spacer pt={1.5} />
      <Section title="6 ELEMENTS RADAR" titleAlign="center">
        {createRadarData(items).length < 3 ? (
          <RadarChartNoData>No Data</RadarChartNoData>
        ) : (
          <RadarChartContainer>
            <RadarChart
              cx="50%"
              cy="50%"
              width={400}
              height={400}
              outerRadius={120}
              data={createRadarData(items)}
            >
              <PolarGrid />
              <PolarAngleAxis dataKey="name" />
              <PolarRadiusAxis angle={90} orientation="left" domain={[0, 5]} />
              <Radar
                name="あなた"
                dataKey="value"
                stroke="#e5643b"
                strokeWidth={4}
                fill="none"
              />
              <Radar
                name="平均"
                dataKey="average"
                stroke="#6ae3d1"
                strokeWidth={4}
                strokeDasharray="4"
                fill="none"
              />
              <Radar
                name="目標"
                dataKey="target"
                stroke="#efb056"
                strokeWidth={4}
                strokeDasharray="4"
                fill="none"
              />
              <Legend verticalAlign="bottom" align="right" />
            </RadarChart>
          </RadarChartContainer>
        )}
      </Section>
      <Spacer pt={1.5} />
      {items[0] && items[1] && (
        <Flex display="flex">
          <Flex flex={1}>
            <RecordElement index={0} item={items[0]} />
          </Flex>
          <Spacer pl={1.5} />
          <Flex flex={1}>
            <RecordElement index={1} item={items[1]} />
          </Flex>
        </Flex>
      )}
      <Spacer pt={1.5} />
      {items[2] && <RecordElement index={2} item={items[2]} />}
    </Container>
  );
};

export { RecordElements };
