import styled from "styled-components";
import {
  SelectedSidebarButtonBackground,
  NotSelectedSidebarButtonBackground,
  SelectedSidebarButton,
  NotSelectedSidebarButton,
  NotSelectedSidebarButtonBorder
} from "./../Colors";

type Props = { isSelected: boolean; length: number };
export const Element = styled.button<Props>`
  width: 90%;
  margin-top: 32px;
  height: 80px;
  background: ${props =>
    props.isSelected
      ? SelectedSidebarButtonBackground
      : NotSelectedSidebarButtonBackground};
  color: ${props =>
    props.isSelected ? SelectedSidebarButton : NotSelectedSidebarButton};
  border-style: solid;
  border-width: 6px;
  border-color: ${props =>
    props.isSelected
      ? SelectedSidebarButtonBackground
      : NotSelectedSidebarButtonBorder};
  border-radius: 16px;
  font-size: ${props => (props.length === 1 ? "30px" : "23px")};
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;
