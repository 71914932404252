import React from "react";
import Modal from "react-modal";
import { CreateButton, DeleteButton } from "../../common/Colors";
import {
  ModalStyle,
  Title,
  Border,
  Section,
  InputHalfArea,
  InputHalf,
  ButtonArea,
  Button
} from "../../coachList/styles/FilterCoachModalStyle";
import {
  CloseButton,
  CloseButtonImage
} from "../../modal/styles/UserEditModalStyle";
import {
  queryFromAverageScore,
  queryToAverageScore,
  queryFromParticipantNumber,
  queryToParticipantNumber,
  queryFromDoDateKey,
  queryToDoDateKey
} from "../../../utils/Constant";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSearch: (query: string) => void;
  query: { [name: string]: string | string[] };
};

type State = {
  fromAverageScore: string;
  toAverageScore: string;
  fromParticipantNumber: string;
  toParticipantNumber: string;
  fromDate: string;
  toDate: string;
};

class FilterTestResultModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const fromAverageScoreQ = props.query[queryFromAverageScore];
    const fromAverageScore =
      typeof fromAverageScoreQ === "string" ? fromAverageScoreQ : "";

    const toAverageScoreQ = props.query[queryToAverageScore];
    const toAverageScore =
      typeof toAverageScoreQ === "string" ? toAverageScoreQ : "";

    const fromParticipantNumberQ = props.query[queryFromParticipantNumber];
    const fromParticipantNumber =
      typeof fromParticipantNumberQ === "string" ? fromParticipantNumberQ : "";

    const toParticipantNumberQ = props.query[queryToParticipantNumber];
    const toParticipantNumber =
      typeof toParticipantNumberQ === "string" ? toParticipantNumberQ : "";

    const fromDateQ = props.query[queryFromDoDateKey];
    const fromDate = typeof fromDateQ === "string" ? fromDateQ : "";

    const toDateQ = props.query[queryToDoDateKey];
    const toDate = typeof toDateQ === "string" ? toDateQ : "";

    this.state = {
      fromAverageScore: fromAverageScore,
      toAverageScore: toAverageScore,
      fromParticipantNumber: fromParticipantNumber,
      toParticipantNumber: toParticipantNumber,
      fromDate: fromDate,
      toDate: toDate
    };
  }

  render() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
          <Title>検索・フィルター</Title>
          <Border />
          <Section>
            <Title>平均点</Title>
            <InputHalfArea>
              <InputHalf
                type="number"
                onChange={this.onChangeFromAverageScore.bind(this)}
                value={this.state.fromAverageScore}
              />
              ~
              <InputHalf
                type="number"
                onChange={this.onChangeToAverageScore.bind(this)}
                value={this.state.toAverageScore}
              />
            </InputHalfArea>
          </Section>
          <Border />
          <Section>
            <Title>受験者数</Title>
            <InputHalfArea>
              <InputHalf
                type="number"
                onChange={this.onChangeFromParticipantNumber.bind(this)}
                value={this.state.fromParticipantNumber}
              />
              ~
              <InputHalf
                type="number"
                onChange={this.onChangeToParticipantNumber.bind(this)}
                value={this.state.toParticipantNumber}
              />
            </InputHalfArea>
          </Section>
          <Border />
          <Section>
            <Title>受験日</Title>
            <InputHalfArea>
              <InputHalf
                type="date"
                onChange={this.onChangeFromDate.bind(this)}
                value={this.state.fromDate}
              />
              ~
              <InputHalf
                type="date"
                onChange={this.onChangeToDate.bind(this)}
                value={this.state.toDate}
              />
            </InputHalfArea>
          </Section>
          <Border />
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onSearch.bind(this)}
            >
              検索
            </Button>
            <Button background={DeleteButton} onClick={this.onClear.bind(this)}>
              値をクリア
            </Button>
          </ButtonArea>
        </Modal>
      </>
    );
  }

  onChangeFromAverageScore(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ fromAverageScore: e.target.value });
  }

  onChangeToAverageScore(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toAverageScore: e.target.value });
  }

  onChangeFromParticipantNumber(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ fromParticipantNumber: e.target.value });
  }

  onChangeToParticipantNumber(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toParticipantNumber: e.target.value });
  }

  onChangeFromDate(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ fromDate: e.target.value });
  }

  onChangeToDate(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toDate: e.target.value });
  }

  onClear() {
    this.setState({
      fromAverageScore: "",
      toAverageScore: "",
      fromParticipantNumber: "",
      toParticipantNumber: "",
      fromDate: "",
      toDate: ""
    });
  }

  onSearch() {
    let query: string[] = [];

    if (this.state.fromAverageScore !== "") {
      query.push("from_average_score=" + this.state.fromAverageScore);
    }

    if (this.state.toAverageScore !== "") {
      query.push("to_average_score=" + this.state.toAverageScore);
    }

    if (this.state.fromParticipantNumber !== "") {
      query.push("from_participant_number=" + this.state.fromParticipantNumber);
    }

    if (this.state.toParticipantNumber !== "") {
      query.push("to_participant_number=" + this.state.toParticipantNumber);
    }

    if (this.state.fromDate !== "") {
      query.push("from_do_date=" + this.state.fromDate);
    }

    if (this.state.toDate !== "") {
      query.push("to_do_date=" + this.state.toDate);
    }
    this.props.onSearch(query.join("&"));
  }

  onClose() {
    this.props.onClose();
  }
}

export default FilterTestResultModal;
