import React from "react";
import { WrapBackground } from "../common/WrapBackground";
import { get } from "../../model/api/Request";
import { CoachBackground, CoachSidebarBackground } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import AuthorityListContent from "./AuthorityListContent";
import { commonErrorHandle } from "../../utils/errorHandle";
import { AuthoritySet } from "../../model/entities/AuthoritySet";
import LoadingOverlay from "../common/LoadingOverlay";
import { Profile } from "../../utils/profile";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  isLoading: boolean;
  authoritySets: AuthoritySet[];
};

class AuthorityList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      authoritySets: []
    };
  }

  componentDidMount() {
    this.getAuthoritySets();
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapBackground
          color={CoachBackground}
          history={this.props.history}
          profile={this.props.profile}
          selectedSidebar="authority"
          sidebarColor={CoachSidebarBackground}
        >
          <AuthorityListContent
            history={this.props.history}
            authoritySets={this.state.authoritySets}
            profile={this.props.profile}
          />
        </WrapBackground>
      </>
    );
  }

  getAuthoritySets() {
    this.setState({ isLoading: true });
    get("/authority-sets")
      .then(json => {
        this.setState({ isLoading: false });
        const authoritySets: AuthoritySet[] = json.result;
        this.setState({
          authoritySets: authoritySets
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default AuthorityList;
