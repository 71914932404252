import { AffiliationDivision } from "./../model/entities/AffiliationDivision";

export function getDivisionsRecursively(
  rootDivision: AffiliationDivision,
  parentDivision: AffiliationDivision | null = null,
  divisions: AffiliationDivision[] = []
) {
  const division: AffiliationDivision = {
    id: rootDivision.id,
    name: rootDivision.name,
    child_affiliation_divisions: []
  };
  divisions.push(division);
  if (!rootDivision.child_affiliation_divisions) {
    return divisions;
  }
  rootDivision.child_affiliation_divisions.forEach(division => {
    getDivisionsRecursively(division, rootDivision, divisions);
  });
  return divisions;
}
