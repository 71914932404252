import React from "react";
import { ListRowTd } from "../../common/styles/ListRowStyle";
import { Tr, RowEditButton, RowPullDownEditButton } from "./styles/styles";
import StudentExternalEvalEditModal from "../../modal/studentExternalEval/StudentExternalEvalEditModal";
import { ExternalEval, EvalLevel } from "../../../model/entities/ExternalEval";
import * as H from "history";
import styled from "styled-components";
import { User } from "../../../model/entities/User";
import { haveStudentEdit } from "../../../utils/checkAuthority";

type Props = {
  history: H.History;
  external_eval: ExternalEval;
  completeEdit: () => void;
  user: User;
  profile: User;
};

type State = {
  isShowDetail: boolean;
  selectLevel: EvalLevel | null;
};

export const MinTr = styled(Tr)`
  transform: translateX(2.5%) scaleX(0.95);
  height: 48px;
`;

export const MinTd = styled(ListRowTd)<{ index: number; max: number }>`
  transform: translateX(${props => (props.max - props.index) * -5}%);
`;

class ExternalEvaluationContainerRow extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isShowDetail: false,
      selectLevel: null
    };
  }

  render() {
    const highestLevelResult = this.getHighestEvalResult(
      this.props.external_eval.levels
    );
    const levels = this.props.external_eval.levels.map(level => {
      return (
        <MinTr key={level.id}>
          <MinTd index={0} max={5}>
            {this.props.external_eval.name}
          </MinTd>
          <MinTd index={1} max={5}>
            {level.name}
          </MinTd>
          <MinTd index={2} max={5}>
            {level.academic_level.name}
          </MinTd>
          <MinTd index={3} max={5}>
            {level.result.got_date || "-"}
          </MinTd>
          <MinTd index={4} max={5}>
            {haveStudentEdit(this.props.profile.authority_set!) && (
              <RowPullDownEditButton
                onClick={this.onEvalEditModalOpen.bind(this)}
              >
                編集
              </RowPullDownEditButton>
            )}
          </MinTd>
        </MinTr>
      );
    });
    return (
      <>
        <Tr>
          <ListRowTd>{this.props.external_eval.name}</ListRowTd>
          <ListRowTd> {highestLevelResult?.name || "-"}</ListRowTd>
          <ListRowTd>
            {highestLevelResult?.academic_level.name || "-"}
          </ListRowTd>
          <ListRowTd>{highestLevelResult?.result?.got_date || "-"}</ListRowTd>
          <ListRowTd>
            <RowEditButton onClick={this.onShowDetail.bind(this)}>
              {this.state.isShowDetail ? "閉じる" : "開く"}
            </RowEditButton>
          </ListRowTd>
        </Tr>
        {this.state.isShowDetail && levels}
        {this.state.selectLevel != null && (
          <StudentExternalEvalEditModal
            history={this.props.history}
            externalEval={this.props.external_eval}
            level={this.state.selectLevel}
            isOpen={true}
            onClose={this.onEvalEditModalClose.bind(this)}
            user={this.props.user}
          ></StudentExternalEvalEditModal>
        )}
      </>
    );
  }

  onEvalEditModalClose() {
    this.props.completeEdit();
    this.setState({
      selectLevel: null
    });
  }

  onEvalEditModalOpen(e) {
    const levelName =
      e.target.parentElement.parentElement.children[1].innerHTML;
    const selectLevel = this.props.external_eval.levels.filter(
      level => level.name === levelName
    )[0];
    this.setState({ selectLevel: selectLevel });
  }

  onShowDetail() {
    this.setState({ isShowDetail: !this.state.isShowDetail });
  }

  getHighestEvalResult(levels: EvalLevel[]): EvalLevel | undefined {
    const highestLevel = levels.filter(level => {
      return Object.keys(level.result).length;
    });
    return highestLevel[highestLevel.length - 1];
  }
}

export default ExternalEvaluationContainerRow;
