import { Question } from "../../../../model/entities/Question";
import React from "react";
import { ListRowTr } from "../../../common/styles/ListRowStyle";
import * as H from "history";
import { Td } from "./styles/TrainingQuestionListContentStyle";

type Props = {
  question: Question;
  history: H.History;
};

class TrainingQuestionListRow extends React.Component<Props, {}> {
  render() {
    const question = this.props.question;
    const choices = question.choices.join("/");
    const tags = question.tags.join(",");
    return (
      <ListRowTr>
        <Td>{question.question_number}</Td>
        <Td>
          {question.passages.join("\n")}
          <br />
          {choices}
        </Td>
        <Td>{question.answer}</Td>
        <Td>
          {question.average_correct_rate_per !== null
            ? (Math.round(question.average_correct_rate_per * 10) / 10).toFixed(
                1
              ) + "%"
            : "-"}
        </Td>
        <Td>
          {question.average_time_ms !== null
            ? Math.floor((question.average_time_ms ?? 0) / 1000 / 60) +
              "分" +
              Math.floor(((question.average_time_ms ?? 0) / 1000) % 60) +
              "秒"
            : "-"}
        </Td>
        <Td>{tags}</Td>
      </ListRowTr>
    );
  }
}

export default TrainingQuestionListRow;
