import React from "react";
import Modal from "react-modal";
import { match } from "react-router-dom";
import StudentEditModalListRow from "./StudentEditModalListRow";
import {
  ModalStyle,
  TitleLabel,
  ButtonArea,
  Button,
  CloseButton,
  CloseButtonImage,
  ListContentElement,
  ListContentTable,
  ListContentTh,
  ListContentThElement,
  ListContentThTable
} from "./styles/StudentEditModalStyle";
import { User } from "../../model/entities/User";
import { put } from "../../model/api/Request";
import { CreateButton } from "../common/Colors";
import LoadingOverlay from "../common/LoadingOverlay";
import * as H from "history";
import { commonErrorHandle } from "../../utils/errorHandle";

type Props = {
  coach: User;
  students: User[];
  match: match<{ id: string }>;
  history: H.History;
  isOpen: boolean;
  onClose: () => void;
};

type State = {
  selectedStudentIds: number[];
  isLoading: boolean;
};

class StudentEditModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedStudentIds: props.coach.coach?.students.map(
        (student: User) => student.id
      ),
      isLoading: false
    };
  }

  render() {
    return this.editModal();
  }

  editModal() {
    const listRows = this.props.students.map((student: User) => {
      const isChecked =
        this.state.selectedStudentIds.findIndex(
          (id: number) => student.id === id
        ) !== -1;
      return (
        <StudentEditModalListRow
          key={student.id}
          student={student}
          isChecked={isChecked}
          onCheckboxChanged={this.onCheckboxChanged.bind(this)}
        />
      );
    });
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <TitleLabel>担当生徒の編集</TitleLabel>
          <ListContentThElement>
            <ListContentThTable>
              <thead>
                <tr>
                  <ListContentTh>所属</ListContentTh>
                  <ListContentTh>氏名</ListContentTh>
                  <ListContentTh>現在の担当コーチ</ListContentTh>
                  <ListContentTh>
                    {this.props.coach.family_name_ja +
                      this.props.coach.first_name_ja}
                    の担当
                  </ListContentTh>
                </tr>
              </thead>
            </ListContentThTable>
          </ListContentThElement>
          <ListContentElement>
            <ListContentTable>
              <tbody>{listRows}</tbody>
            </ListContentTable>
          </ListContentElement>
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onUpdate.bind(this)}
            >
              更新
            </Button>
          </ButtonArea>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
        </Modal>
        <LoadingOverlay isLoading={this.state.isLoading} />
      </>
    );
  }

  onCheckboxChanged(isCheck: boolean, studnet: User) {
    let ids = this.state.selectedStudentIds;
    if (isCheck) {
      ids.push(studnet.id);
    } else {
      const index = ids.findIndex(id => id === studnet.id);
      if (index > -1) {
        ids.splice(index, 1);
      }
    }
    this.setState({ selectedStudentIds: ids });
  }

  onUpdate() {
    if (
      this.state.selectedStudentIds.length !== 0 ||
      (this.state.selectedStudentIds.length === 0 &&
        this.getDefaultStudentsNum() !== 0)
    ) {
      this.setState({ isLoading: true });
      const params: { [index: string]: any } = {
        coach: {
          student_user_ids: this.state.selectedStudentIds
        }
      };
      put("/coaches/" + this.props.coach.id, params)
        .then(res => {
          this.setState({ isLoading: false });
          this.props.onClose();
        })
        .catch(error => {
          commonErrorHandle(error, this.props.history);
        });
    } else {
      this.props.onClose();
    }
  }

  getDefaultStudentsNum() {
    return this.props.coach.coach?.students.length;
  }

  onClose() {
    this.props.onClose();
  }
}

export default StudentEditModal;
