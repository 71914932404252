import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { WrapBackground } from "../common/WrapBackground";
import { HomeArea, HomeAreaColumn } from "./styles/HomeArea";
import { HomeBackground } from "../common/Colors";
import { HomeButton } from "./HomeButton";
import { Profile } from "../../utils/profile";
import { DemoButtonaArea, DemoButton } from "./styles/StudentHomeStyle";
import { put, post } from "../../model/api/Request";

type Props = RouteComponentProps<{}> & Profile;

type State = {};

class ClientHome extends React.Component<Props, State> {
  render() {
    const isStaging = process.env.REACT_APP_ENV === "staging";
    const isDemo = this.props.profile.affiliation_client.name === "DEMO";
    const isPrefixDemo =
      this.props.profile.affiliation_client.name.indexOf("DEMO_") === 0;
    return (
      <WrapBackground
        history={this.props.history}
        profile={this.props.profile}
        color={HomeBackground}
      >
        {/* stg環境のみ、デモ用としてのボタンを表示 */}
        {/* https://gitlab.com/tfa-lab/frontend/-/merge_requests/303#note_472371309 */}
        {isStaging && isDemo && (
          <DemoButtonaArea>
            <DemoButton onClick={this.onClickReset.bind(this)}>
              デモ初期状態に戻す
            </DemoButton>
          </DemoButtonaArea>
        )}
        {isStaging && !isDemo && isPrefixDemo && (
          <DemoButtonaArea>
            <DemoButton onClick={this.onClickReset.bind(this)}>
              デモ初期状態に戻す
            </DemoButton>
            <DemoButton onClick={this.onClickMoveNextSet.bind(this)}>
              <div style={{ fontSize: "10px" }}>AIレコメンドアプリを</div>
              次のセットへ進む
            </DemoButton>
          </DemoButtonaArea>
        )}
        <HomeArea>
          <HomeAreaColumn>
            <div style={{ cursor: "pointer" }} onClick={this.onTraining}>
              <HomeButton path="/assets/TrainingApp.png" />
            </div>
            <div style={{ cursor: "pointer" }} onClick={this.onReccomend}>
              <HomeButton path="/assets/ReccomentApp.png" />
            </div>
          </HomeAreaColumn>
          <HomeAreaColumn>
            <Link to="/student/training_test_results">
              <HomeButton path="/assets/TrainingTestResult.png" />
            </Link>
            <Link to="/student/practical_test_results">
              <HomeButton path="/assets/PracticalTestResult.png" />
            </Link>
          </HomeAreaColumn>
        </HomeArea>
      </WrapBackground>
    );
  }

  onClickReset() {
    const isDemo = this.props.profile.affiliation_client.name === "DEMO";
    const path = isDemo ? "/student/reset_1" : "/student/reset_3";
    put(path, {})
      .then(() => {
        alert("初期化が完了しました。");
      })
      .catch(() => {
        alert("許可されていない操作です。");
      });
  }

  onClickMoveNextSet() {
    post("/student/add_new_recommend_app_schedule", {})
      .then(() => {
        alert("AIレコメンドアプリを次の問題セットへ進めました。");
      })
      .catch(() => {
        alert("許可されていない操作です。");
      });
  }

  onTraining() {
    window.open(process.env.REACT_APP_TRAINING_URL);
  }

  onReccomend() {
    window.open(process.env.REACT_APP_RECOMMEND_URL);
  }
}

export default ClientHome;
