import * as React from "react";
import { Badge } from "./styled";
import { Section } from "../Section";
import { Element } from "../../types";
import { Text } from "../../../common/Text";
import { Flex } from "../../../common/Flex";
import { Spacer } from "../../../common/Spacer";

type Props = {
  item: Element;
  index: number;
};

function romeNumber(num: number): string {
  switch (num) {
    case 1:
      return "Ⅰ";
    case 2:
      return "Ⅱ";
    case 3:
      return "Ⅲ";
    case 4:
      return "Ⅳ";
    case 5:
      return "Ⅴ";
    case 6:
      return "Ⅵ";
  }
  return "";
}

const RecordElement: React.FunctionComponent<Props> = ({ item, index }) => {
  return (
    <Section
      title={romeNumber(index + 1) + item.name_ja + " " + item.name_en}
      titleAlign="center"
    >
      <Flex display="flex" justifyContent="space-around">
        <div>
          <Text weight="bold" size="12px">
            スコア/目標
          </Text>
          <Spacer pt={1} />
          <Flex display="flex" justifyContent="center" alignItems="flex-end">
            <Text
              weight="bold"
              size="30px"
              color="#efb056"
              lineHeight="1"
              component="span"
            >
              {item.value?.toFixed(1) ?? "-"}
            </Text>
            <Spacer pl={0.5} />
            <Text size="18px" component="span">
              /
            </Text>
            <Text weight="bold" size="18px" lineHeight="1" component="span">
              {item.value == null ? "-" : item.target?.toFixed(1) ?? "-"}
            </Text>
          </Flex>
        </div>
        <Flex display="flex" flexDirection="column" alignItems="center">
          <Text weight="bold" size="10px">
            重要度指数
          </Text>
          <Spacer pt={0.5} />
          <Badge>
            <Text weight="bold" size="22px" color="#efb056">
              {item.importance ?? "-"}
            </Text>
          </Badge>
        </Flex>
      </Flex>
    </Section>
  );
};

export { RecordElement };
