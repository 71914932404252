import React from "react";
import Modal from "react-modal";
import {
  ModalStyle,
  CloseButton,
  CloseButtonImage,
  SelectElementArea,
  SelectElementDiv,
  SelectElement
} from "../modal/styles/UserEditModalStyle";
import * as H from "history";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  history: H.History;
};

class SelectEditModal extends React.Component<Props, {}> {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <SelectElementArea scrollable={false}>
          <SelectElementDiv onClick={this.onEditLoginId.bind(this)}>
            <SelectElement isSelected={false}>
              ログインID / メールアドレスを編集
            </SelectElement>
          </SelectElementDiv>
          <SelectElementDiv onClick={this.onEditPassword.bind(this)}>
            <SelectElement isSelected={false}>パスワードを編集</SelectElement>
          </SelectElementDiv>
        </SelectElementArea>
        <CloseButton onClick={this.props.onClose}>
          <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
        </CloseButton>
      </Modal>
    );
  }

  onEditLoginId() {
    this.props.history.push("/account/edit");
  }

  onEditPassword() {
    this.props.history.push("/password/edit");
  }
}

export default SelectEditModal;
