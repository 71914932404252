import styled from "styled-components";

export const HomeArea = styled.div`
  background: rgba(238, 238, 238, 1);
  border-radius: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const HomeAreaColumn = styled.div`
  display: flex;
  justify-content: center;
`;
