import React from "react";
import { User } from "../../model/entities/User";
import { match } from "react-router-dom";
import StudentListRow from "./StudentListRow";
import {
  ListContentTable,
  ListContentTh
} from "../common/styles/ListContentStyle";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement
} from "./styles/CoachDetailContentStyle";
import * as H from "history";

type State = {};

type Props = {
  match: match<{ id: string }>;
  user: User | null;
  history: H.History;
  profile: User | null;
};

class StudentListContent extends React.Component<Props, State> {
  render() {
    const listRows = this.props.user?.coach?.students?.map((student: User) => (
      <StudentListRow
        key={student.id}
        match={this.props.match}
        student={student}
        history={this.props.history}
      />
    ));
    return (
      <>
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh enableClick={false}>所属</ListContentTh>
                <ListContentTh enableClick={false}>氏名</ListContentTh>
                <ListContentTh enableClick={false}>目標</ListContentTh>
                <ListContentTh enableClick={false}>取得資格</ListContentTh>
                <ListContentTh enableClick={false}>レベル</ListContentTh>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement>
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }
}

export default StudentListContent;
