import { User } from "../../model/entities/User";
import React from "react";
import {
  ListRowTr,
  ListRowTrIdBorder,
  ListRowTd,
  HilighedListRowTr
} from "../common/styles/ListRowStyle";
import Switch from "rc-switch";
import styled from "styled-components";
import "rc-switch/assets/index.css";
import * as H from "history";
import { put } from "./../../model/api/Request";
import { commonErrorHandle } from "../../utils/errorHandle";
import { CustomError } from "../../model/api/CustomError";

// Switchの文字が被るので、独自Styleを適用
import "./styles/CoachListRow.css";
import { haveUserValidityEdit } from "../../utils/checkAuthority";
import { Link } from "react-router-dom";

type Props = {
  user: User;
  history: H.History;
  profile: User | null;
};

type State = {
  isValid: boolean;
};

const CustomSwitch = styled(Switch)`
  transform: scale(2, 2);
  width: 50px;
`;

class CoachListRow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isValid: props.user.is_valid === 1
    };
  }
  render() {
    if (this.props.profile?.id === this.props.user.id) {
      return <HilighedListRowTr>{this.rowContent()}</HilighedListRowTr>;
    }
    return <ListRowTr>{this.rowContent()}</ListRowTr>;
  }

  rowContent() {
    const user = this.props.user;
    return (
      <>
        <ListRowTd>{user.affiliation_division.name}</ListRowTd>
        <ListRowTd>
          <Link to={"/client/coaches/" + this.props.user.id}>
            <ListRowTrIdBorder>
              {user.family_name_ja + " " + user.first_name_ja}
            </ListRowTrIdBorder>
          </Link>
        </ListRowTd>
        <ListRowTd>{user.authority_set?.name}</ListRowTd>
        {this.props.profile?.authority_set &&
          haveUserValidityEdit(this.props.profile.authority_set) && (
            <ListRowTd>
              <CustomSwitch
                checked={this.state.isValid}
                onChange={this.onSwitchChange.bind(this)}
                checkedChildren="On"
                unCheckedChildren="Off"
                loadingIcon={null}
              />
            </ListRowTd>
          )}
      </>
    );
  }

  onSwitchChange(value: boolean) {
    this.setState({ isValid: value });
    put("/coaches/" + this.props.user.id + "/validity", {
      is_valid: value
    }).catch(error => {
      this.setState({ isValid: !value });
      if (error instanceof CustomError) {
        const customError = error as CustomError;
        if (customError.code === "EnabledUserNumLimitExceededError") {
          alert("有効にできるユーザーの最大数を超えています。");
          return;
        }
      }
      commonErrorHandle(error, this.props.history);
    });
  }
}

export default CoachListRow;
