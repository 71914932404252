import React from "react";
import { WrapBackground } from "../../../common/WrapBackground";
import { get } from "../../../../model/api/Request";
import TestResultDetailListContent from "./TestResultDetailListContent";
import {
  TestResultBackground,
  TestResultSidebarBackground
} from "../../../common/Colors";
import PagingArea from "../../../common/PagingArea";
import { RouteComponentProps } from "react-router-dom";
import { commonErrorHandle } from "../../../../utils/errorHandle";
import { TestResult } from "../../../../model/entities/TestResult";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { Profile } from "../../../../utils/profile";
import FilterTestResultDetailModal from "./FilterTestResultDetail";
import {
  BottomRightArea,
  FilterButton
} from "../../../common/styles/TopButton";
import {
  extractPage,
  extractSortBy,
  extractOrder,
  extractDivition,
  extractDivisionName,
  extractStudentFirstName,
  extractStudentFamilyName,
  extractCoachFirstName,
  extractCoachFamilyName,
  extractToScore,
  extractFromScore,
  extractFromRank,
  extractToRank,
  extractToDeviationValue,
  extractFromDeviationValue,
  extractFromSpentTimeMs,
  extractToSpentTimeMs,
  extractFromDatetime,
  extractToDatetime
} from "../../../../utils/query";
import {
  pagingLimit,
  queryPageKey,
  querySortByKey,
  queryOrderKey,
  SortBy,
  Order,
  queryAffiliationDivisionKey,
  queryAffiliationDivisionNameKey,
  queryStudentFirstNameKey,
  queryStudentFamilyNameKey,
  queryCoachFirstNameKey,
  queryCoachFamilyNameKey,
  queryFromScoreKey,
  queryToScoreKey,
  queryFromRankKey,
  queryToRankKey,
  queryToDeviationValueKey,
  queryFromDeviationValueKey,
  queryToSpentTimeMsKey,
  queryFromSpentTimeMsKey,
  queryFromDoDateTimeKey,
  queryToDoDateTimeKey
} from "../../../../utils/Constant";
import querystring from "querystring";

type Props = RouteComponentProps<{
  test_id: string;
  test_num: string;
  academic_level_id: string;
}> &
  Profile;

type State = {
  maxPage: number;
  isLoading: boolean;
  testResults: TestResult[];
  testContent: TestResult | null;
  isFilterModalOpen: boolean;
};

class TestResultDetailList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      maxPage: 0,
      isLoading: false,
      testResults: [],
      testContent: null,
      isFilterModalOpen: false
    };
  }

  componentDidMount() {
    this.getInit();
  }

  render() {
    return (
      <>
        <FilterTestResultDetailModal
          isOpen={this.state.isFilterModalOpen}
          onClose={this.onFilterClose.bind(this)}
          onSearch={this.searchTestResults.bind(this)}
          query={this.getQuery()}
        />
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapBackground
          color={TestResultBackground}
          history={this.props.history}
          profile={this.props.profile}
          selectedSidebar="practical_test_result"
          sidebarColor={TestResultSidebarBackground}
        >
          <FilterButton
            onClick={this.onFilterOpen.bind(this)}
            isTestResultDetail={true}
          >
            検索・フィルター
          </FilterButton>
          <BottomRightArea>
            <PagingArea
              page={extractPage(this.getQuery())}
              maxPage={this.state.maxPage}
              onMostBack={this.onMostBackPage.bind(this)}
              onBack={this.onBackPage.bind(this)}
              onNext={this.onNextPage.bind(this)}
              onMostNest={this.onMostNextPage.bind(this)}
            />
          </BottomRightArea>
          <TestResultDetailListContent
            history={this.props.history}
            testResults={this.state.testResults}
            testContent={this.state.testContent}
            onChangeSort={this.onChangeSort.bind(this)}
            sortBy={extractSortBy(this.getQuery())}
            order={extractOrder(this.getQuery())}
          />
        </WrapBackground>
      </>
    );
  }

  onFilterOpen() {
    this.setState({ isFilterModalOpen: true });
  }

  onFilterClose() {
    this.setState({ isFilterModalOpen: false });
  }

  getInit() {
    this.setState({ isLoading: true });
    let query = "";
    query += `limit=${pagingLimit}`;
    query += `&${queryPageKey}=${extractPage(this.getQuery())}`;
    if (extractOrder(this.getQuery())) {
      query += `&${queryOrderKey}=${extractOrder(this.getQuery())}`;
    }
    if (extractSortBy(this.getQuery())) {
      query += `&${querySortByKey}=${extractSortBy(this.getQuery())}`;
    }
    if (extractDivition(this.getQuery()).length !== 0) {
      const divisionQuery = extractDivition(this.getQuery())
        .map(i => {
          return `${queryAffiliationDivisionKey}=${i}`;
        })
        .join("&");
      query += `&${divisionQuery}`;
    }
    if (extractDivisionName(this.getQuery())) {
      query += `&${queryAffiliationDivisionNameKey}=${extractDivisionName(
        this.getQuery()
      )}`;
    }

    if (extractStudentFirstName(this.getQuery())) {
      query += `&${queryStudentFirstNameKey}=${extractStudentFirstName(
        this.getQuery()
      )}`;
    }
    if (extractStudentFamilyName(this.getQuery())) {
      query += `&${queryStudentFamilyNameKey}=${extractStudentFamilyName(
        this.getQuery()
      )}`;
    }
    if (extractCoachFirstName(this.getQuery())) {
      query += `&${queryCoachFirstNameKey}=${extractCoachFirstName(
        this.getQuery()
      )}`;
    }
    if (extractCoachFamilyName(this.getQuery())) {
      query += `&${queryCoachFamilyNameKey}=${extractCoachFamilyName(
        this.getQuery()
      )}`;
    }
    if (extractFromScore(this.getQuery())) {
      query += `&${queryFromScoreKey}=${extractFromScore(this.getQuery())}`;
    }
    if (extractToScore(this.getQuery())) {
      query += `&${queryToScoreKey}=${extractToScore(this.getQuery())}`;
    }
    if (extractFromRank(this.getQuery())) {
      query += `&${queryFromRankKey}=${extractFromRank(this.getQuery())}`;
    }
    if (extractToRank(this.getQuery())) {
      query += `&${queryToRankKey}=${extractToRank(this.getQuery())}`;
    }
    if (extractFromDeviationValue(this.getQuery())) {
      query += `&${queryFromDeviationValueKey}=${extractFromDeviationValue(
        this.getQuery()
      )}`;
    }
    if (extractToDeviationValue(this.getQuery())) {
      query += `&${queryToDeviationValueKey}=${extractToDeviationValue(
        this.getQuery()
      )}`;
    }
    if (extractFromSpentTimeMs(this.getQuery())) {
      query += `&${queryFromSpentTimeMsKey}=${extractFromSpentTimeMs(
        this.getQuery()
      )}`;
    }
    if (extractToSpentTimeMs(this.getQuery())) {
      query += `&${queryToSpentTimeMsKey}=${extractToSpentTimeMs(
        this.getQuery()
      )}`;
    }
    if (extractFromDatetime(this.getQuery())) {
      query += `&${queryFromDoDateTimeKey}=${extractFromDatetime(
        this.getQuery()
      )}`;
    }
    if (extractToDatetime(this.getQuery())) {
      query += `&${queryToDoDateTimeKey}=${extractToDatetime(this.getQuery())}`;
    }
    const testId = this.props.match.params.test_id;
    const testNumber = this.props.match.params.test_num;
    const academicLevelId = this.props.match.params.academic_level_id;
    Promise.all([
      get(
        "/practical-tests/" +
          testId +
          "/" +
          testNumber +
          "/" +
          academicLevelId +
          "/results/count?" +
          query
      ),
      get(
        "/practical-tests/" +
          testId +
          "/" +
          testNumber +
          "/" +
          academicLevelId +
          "/results?" +
          query
      )
    ])
      .then(responses => {
        this.setState({
          maxPage: Math.ceil(responses[0].result.count / pagingLimit),
          isLoading: false,
          testResults: responses[1].result.results,
          testContent: responses[1].result
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onMostNextPage() {
    const page = extractPage(this.getQuery());
    if (page === this.state.maxPage) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(this.state.maxPage);
    this.pushWithQuery(query);
  }

  onNextPage() {
    const page = extractPage(this.getQuery());
    if (page === this.state.maxPage) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(page + 1);
    this.pushWithQuery(query);
  }

  onBackPage() {
    const page = extractPage(this.getQuery());
    if (page === 1) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(page - 1);
    this.pushWithQuery(query);
  }

  onMostBackPage() {
    const page = extractPage(this.getQuery());
    if (page === 1) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(1);
    this.pushWithQuery(query);
  }

  pushWithQuery(query: { [name: string]: string | string[] }) {
    this.props.history.push(
      this.props.location.pathname + "?" + querystring.stringify(query)
    );
  }

  onChangeSort(sortBy: SortBy, order: Order) {
    const query = this.getQuery();
    query[querySortByKey] = sortBy;
    query[queryOrderKey] = order;
    this.pushWithQuery(query);
  }

  searchTestResults(query: string) {
    let queryDict: { [name: string]: string | string[] } = {};
    if (extractSortBy(this.getQuery())) {
      queryDict[querySortByKey] = extractSortBy(this.getQuery());
    }
    if (extractOrder(this.getQuery())) {
      queryDict[queryOrderKey] = extractOrder(this.getQuery());
    }
    queryDict[queryPageKey] = "1";
    queryDict = Object.assign(queryDict, querystring.parse(query));
    this.pushWithQuery(queryDict);
  }

  getQuery(): { [name: string]: string | string[] } {
    const query = querystring.parse(this.props.location.search.slice(1));
    return query as { [name: string]: string | string[] };
  }
}

export default TestResultDetailList;
