import React from "react";
import Modal from "react-modal";
import { CreateButton, DeleteButton } from "../../common/Colors";
import {
  ModalStyle,
  Title,
  Border,
  Section,
  Input,
  ButtonArea,
  Button,
  CheckAreaRow,
  CheckContent,
  InputCheckbox,
  CheckLabel,
  CheckArea
} from "../../coachList/styles/FilterCoachModalStyle";
import {
  CloseButton,
  CloseButtonImage
} from "../../modal/styles/UserEditModalStyle";
import {
  queryGrammarFieldKey,
  queryTestNumberKey,
  queryLevelKey
} from "../../../utils/Constant";
import { GrammarField } from "../../../model/entities/Test";
import { get } from "../../../model/api/Request";
import { divideArray } from "../../../utils/array";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSearch: (query: string) => void;
  query: { [name: string]: string | string[] };
};

type State = {
  grammarFields: GrammarField[];
  selectedGrammarFieldIds: number[];
  level: string;
  testNumber: string;
};

class FilterTestResultModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    let grammarFieldIds: number[] = [];
    if (typeof props.query[queryGrammarFieldKey] === "string") {
      grammarFieldIds = [+props.query[queryGrammarFieldKey]];
    } else if (props.query[queryGrammarFieldKey] instanceof Array) {
      grammarFieldIds = (props.query[queryGrammarFieldKey] as string[]).map(
        id => +id
      );
    }

    const levelQ = props.query[queryLevelKey];
    const level = typeof levelQ === "string" ? levelQ : "";

    const testNumberQ = props.query[queryTestNumberKey];
    const testNumber = typeof testNumberQ === "string" ? testNumberQ : "";

    this.state = {
      grammarFields: [],
      selectedGrammarFieldIds: grammarFieldIds,
      level: level,
      testNumber: testNumber
    };
  }

  componentDidMount() {
    get("/grammar-fields").then(res => {
      this.setState({ grammarFields: res.result });
    });
  }

  render() {
    const grammarFields = divideArray(this.state.grammarFields, 2).map(
      (grammarFields: GrammarField[]) => {
        return (
          <CheckAreaRow key={grammarFields[0].id}>
            {grammarFields.map(grammarField => {
              return (
                <CheckContent key={grammarField.id}>
                  <InputCheckbox
                    type="checkbox"
                    checked={
                      this.state.selectedGrammarFieldIds.find(
                        id => id === grammarField.id
                      ) !== undefined
                    }
                    onChange={this.onGrammarFieldChecked.bind(this)}
                  />
                  <CheckLabel>{grammarField.name_ja}</CheckLabel>
                </CheckContent>
              );
            })}
          </CheckAreaRow>
        );
      }
    );
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
          <Title>検索・フィルター</Title>
          <Border />
          <Section>
            <Title>分野</Title>
            <CheckArea>{grammarFields}</CheckArea>
          </Section>
          <Border />
          <Section>
            <Title>レベル</Title>
            <Input
              value={this.state.level}
              onChange={this.onChangeLevel.bind(this)}
            />
          </Section>
          <Border />
          <Section>
            <Title>テスト番号</Title>
            <Input
              type="number"
              value={this.state.testNumber}
              onChange={this.onChangeTestNumber.bind(this)}
            />
          </Section>
          <Border />
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onSearch.bind(this)}
            >
              検索
            </Button>
            <Button background={DeleteButton} onClick={this.onClear.bind(this)}>
              値をクリア
            </Button>
          </ButtonArea>
        </Modal>
      </>
    );
  }

  onGrammarFieldChecked(e: React.ChangeEvent<HTMLInputElement>) {
    const selectGrammarField = this.state.grammarFields.filter(grammarField => {
      return (
        grammarField.name_ja === e.target.parentElement?.children[1].innerHTML
      );
    })[0];
    let selectedGrammarFieldIds = this.state.selectedGrammarFieldIds;
    if (e.target.checked) {
      selectedGrammarFieldIds.push(selectGrammarField.id);
    } else {
      selectedGrammarFieldIds = selectedGrammarFieldIds.filter(
        id => id !== selectGrammarField.id
      );
    }
    this.setState({ selectedGrammarFieldIds: selectedGrammarFieldIds });
  }

  onChangeLevel(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ level: e.target.value });
  }

  onChangeTestNumber(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ testNumber: e.target.value });
  }

  onClear() {
    this.setState({
      level: "",
      testNumber: ""
    });
  }

  onSearch() {
    let query: string[] = [];

    const grammarFieldIds = this.state.selectedGrammarFieldIds
      .map(id => {
        return `${queryGrammarFieldKey}=${id}`;
      })
      .join("&");
    if (grammarFieldIds !== "") {
      query.push(grammarFieldIds);
    }

    if (this.state.level !== "") {
      query.push("level=" + this.state.level);
    }

    if (this.state.testNumber !== "") {
      query.push("test_number=" + this.state.testNumber);
    }
    this.props.onSearch(query.join("&"));
  }

  onClose() {
    this.props.onClose();
  }
}

export default FilterTestResultModal;
