import styled from "styled-components";

export const ListRowTr = styled.tr`
  background: white;
  width: 100%;
  height: 64px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
`;

export const HilighedListRowTr = styled(ListRowTr)`
  background: rgba(211, 211, 211, 1);
`;

export const ListRowTd = styled.td`
  word-break: break-all;
  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;

export const ListRowTrIdBorder = styled.button`
  border: 1px solid black;
  width: 70%;
  font-size: 16px;
  border-radius: 6px;
  &:focus {
    outline: 0;
  }
  &:active {
    color: black;
  }
  background: transparent;
  cursor: pointer;
`;
