import styled from "styled-components";
import { CoachButtonColor } from "../../common/Colors";

export const EditButton = styled.button`
  position: absolute;
  left: 170px;
  bottom: 50px;
  width: 140px;
  background: ${CoachButtonColor};
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  &:focus {
    outline: 0;
  }
`;

export const StudentEditButton = styled.button`
  background: ${CoachButtonColor};
  border: none;
  color: white;
  margin-left: 16px;
  font-size: 20px;
  border-radius: 8px;
  width: 172px;
  height: 38px;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const StudentEditButtonArea = styled.div`
  float: right;
  margin-top: 38px;
  margin-right: 37px;
  display: flex;
`;
