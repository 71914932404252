import React from "react";
import { WrapBackground } from "../../common/WrapBackground";
import { get } from "../../../model/api/Request";
import StudentTrainingTestResultListContent from "./StudentTrainingTestResultListContent";
import {
  TestResultBackground,
  TestResultSidebarBackground
} from "../../common/Colors";
import PagingArea from "../../common/PagingArea";
import { RouteComponentProps } from "react-router-dom";
import { commonErrorHandle } from "../../../utils/errorHandle";
import { pagingLimit } from "../../../utils/Constant";
import { Test } from "../../../model/entities/Test";
import LoadingOverlay from "../../common/LoadingOverlay";
import { Profile } from "../../../utils/profile";
import { TopRightArea } from "../../common/styles/TopButton";

export type SortBy =
  | ""
  | "datetime"
  | "affiliation_division"
  | "name"
  | "test_name"
  | "score";

export type Order = "" | "desc";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  page: number;
  maxPage: number;
  isLoading: boolean;
  tests: Test[];
  isFilterModalOpen: boolean;
  query: string;
  sortBy: SortBy;
  order: Order;
};

class StudentTrainingTestResultList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      maxPage: 0,
      isLoading: false,
      tests: [],
      isFilterModalOpen: false,
      query: "",
      sortBy: "",
      order: ""
    };
  }

  componentDidMount() {
    this.getInit();
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapBackground
          color={TestResultBackground}
          history={this.props.history}
          profile={this.props.profile}
          selectedSidebar="training_test_result"
          sidebarColor={TestResultSidebarBackground}
        >
          <TopRightArea>
            <PagingArea
              page={this.state.page}
              maxPage={this.state.maxPage}
              onMostBack={this.onMostBackPage.bind(this)}
              onBack={this.onBackPage.bind(this)}
              onNext={this.onNextPage.bind(this)}
              onMostNest={this.onMostNextPage.bind(this)}
            />
          </TopRightArea>
          <StudentTrainingTestResultListContent
            history={this.props.history}
            tests={this.state.tests}
            onChangeSort={this.onChangeSort.bind(this)}
            sortBy={this.state.sortBy}
            order={this.state.order}
          />
        </WrapBackground>
      </>
    );
  }

  getInit() {
    this.setState({ isLoading: true });
    Promise.all([
      get("/student/training-tests/count"),
      get("/student/training-tests?limit=" + pagingLimit + "&page=1")
    ])
      .then(responses => {
        this.setState({
          maxPage: Math.ceil(responses[0].result.count / pagingLimit),
          isLoading: false,
          tests: responses[1].result
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onFilterOpen() {
    this.setState({ isFilterModalOpen: true });
  }

  onFilterClose() {
    this.setState({ isFilterModalOpen: false });
  }

  onMostNextPage() {
    if (this.state.page === this.state.maxPage) {
      return;
    }
    this.scrollTopIfNeed();
    this.getTests(this.state.maxPage);
  }

  onNextPage() {
    if (this.state.page === this.state.maxPage) {
      return;
    }
    this.scrollTopIfNeed();
    this.getTests(this.state.page + 1);
  }

  onBackPage() {
    if (this.state.page === 1) {
      return;
    }
    this.scrollTopIfNeed();
    this.getTests(this.state.page - 1);
  }

  onMostBackPage() {
    if (this.state.page === 1) {
      return;
    }
    this.scrollTopIfNeed();
    this.getTests(1);
  }

  scrollTopIfNeed() {
    const scrollListElement = document.getElementsByClassName(
      "scrollListElement"
    )[0];
    if (scrollListElement) {
      scrollListElement.scrollTop = 0;
    }
  }

  onChangeSort(sortBy: SortBy, order: Order) {
    this.setState({ isLoading: true, sortBy: sortBy, order: order });
    let url = "/student/training-tests?limit=" + pagingLimit + "&page=1";
    if (this.state.query !== "") {
      url = url + "&" + this.state.query;
    }
    if (sortBy !== "") {
      url = url + "&sort_by=" + sortBy;
    }
    if (order !== "") {
      url = url + "&order=" + order;
    }
    get(url)
      .then(json => {
        this.setState({
          tests: json.result,
          page: 1,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  getTests(page: number) {
    this.setState({ isLoading: true });
    let url = "/student/training-tests?limit=" + pagingLimit + "&page=" + page;
    if (this.state.query !== "") {
      url = url + "&" + this.state.query;
    }
    if (this.state.sortBy !== "") {
      url = url + "&sort_by=" + this.state.sortBy;
    }
    if (this.state.order !== "") {
      url = url + "&order=" + this.state.order;
    }
    get(url)
      .then(json => {
        this.setState({
          tests: json.result,
          page: page,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default StudentTrainingTestResultList;
