import {
  queryPageKey,
  querySortByKey,
  queryOrderKey,
  queryAffiliationDivisionKey,
  queryAffiliationDivisionNameKey,
  Order,
  orderList,
  sortByList,
  SortBy,
  queryFirstNameKey,
  queryFamilyNameKey,
  queryAuthoritySetKey,
  queryIsValidKey,
  queryIsNotValidKey,
  queryCoachKey,
  queryCoachFirstNameKey,
  queryCoachFamilyNameKey,
  queryTargetTypeKey,
  queryExternalEvalKey,
  queryAcademicLevelKey,
  queryExternalEvalSearchTypeKey,
  queryFromDoDateKey,
  queryToDoDateKey,
  queryToAverageScore,
  queryFromAverageScore,
  queryFromParticipantNumber,
  queryToParticipantNumber,
  queryGrammarFieldKey,
  queryStudentFamilyNameKey,
  queryStudentFirstNameKey,
  queryFromScoreKey,
  queryToScoreKey,
  queryToRankKey,
  queryFromRankKey,
  queryFromDeviationValueKey,
  queryToDeviationValueKey,
  queryFromSpentTimeMsKey,
  queryToSpentTimeMsKey,
  queryFromDoDateTimeKey,
  queryToDoDateTimeKey,
  queryLevelKey,
  queryTestNumberKey
} from "./Constant";

export function extractPage(query: {
  [name: string]: string | string[];
}): number {
  if (query[queryPageKey] && typeof query[queryPageKey] === "string") {
    return parseInt(String(query[queryPageKey]))
      ? parseInt(String(query[queryPageKey]))
      : 1;
  } else {
    return 1;
  }
}

export function extractOrder(query: {
  [name: string]: string | string[];
}): Order {
  const order = query[queryOrderKey];
  if (typeof order !== "string" || !orderList.includes(order)) {
    return "";
  }
  return order;
}

export function extractSortBy(query: {
  [name: string]: string | string[];
}): SortBy {
  const sortBy = query[querySortByKey];
  if (typeof sortBy !== "string" || !sortByList.includes(sortBy)) {
    return "";
  }
  return sortBy;
}

export function extractDivition(query: {
  [name: string]: string | string[];
}): String[] {
  return extractStringArrayFromKey(query, queryAffiliationDivisionKey);
}

export function extractCoach(query: {
  [name: string]: string | string[];
}): String[] {
  return extractStringArrayFromKey(query, queryCoachKey);
}

export function extractTargetType(query: {
  [name: string]: string | string[];
}): string[] {
  return extractStringArrayFromKey(query, queryTargetTypeKey);
}

export function extractExternalEval(query: {
  [name: string]: string | string[];
}): string[] {
  return extractStringArrayFromKey(query, queryExternalEvalKey);
}

export function extractExternalEvalSearchType(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryExternalEvalSearchTypeKey);
}

export function extractAcademicLevel(query: {
  [name: string]: string | string[];
}): string[] {
  return extractStringArrayFromKey(query, queryAcademicLevelKey);
}

export function extractCoachFirstName(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryCoachFirstNameKey);
}

export function extractCoachFamilyName(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryCoachFamilyNameKey);
}

export function extractAuthoritySet(query: {
  [name: string]: string | string[];
}): string[] {
  return extractStringArrayFromKey(query, queryAuthoritySetKey);
}

export function extractGrammarField(query: {
  [name: string]: string | string[];
}): string[] {
  return extractStringArrayFromKey(query, queryGrammarFieldKey);
}

export function extractFirstName(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFirstNameKey);
}

export function extractFamilyName(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFamilyNameKey);
}

export function extractStudentFirstName(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryStudentFirstNameKey);
}

export function extractStudentFamilyName(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryStudentFamilyNameKey);
}

export function extractDivisionName(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryAffiliationDivisionNameKey);
}

export function extractFromAverageScore(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFromAverageScore);
}

export function extractToAverageScore(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryToAverageScore);
}

export function extractFromParticipantNumber(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFromParticipantNumber);
}

export function extractToParticipantNumber(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryToParticipantNumber);
}

export function extractFromDate(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFromDoDateKey);
}

export function extractToDate(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryToDoDateKey);
}

export function extractFromScore(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFromScoreKey);
}

export function extractToScore(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryToScoreKey);
}

export function extractFromRank(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFromRankKey);
}

export function extractToRank(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryToRankKey);
}

export function extractFromDeviationValue(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFromDeviationValueKey);
}

export function extractToDeviationValue(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryToDeviationValueKey);
}

export function extractFromSpentTimeMs(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFromSpentTimeMsKey);
}

export function extractToSpentTimeMs(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryToSpentTimeMsKey);
}

export function extractFromDatetime(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryFromDoDateTimeKey);
}

export function extractToDatetime(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryToDoDateTimeKey);
}

export function extractLevel(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryLevelKey);
}

export function extractTestNumber(query: {
  [name: string]: string | string[];
}): string {
  return extractStringFromKey(query, queryTestNumberKey);
}

export function extractIsValid(query: {
  [name: string]: string | string[];
}): string {
  const isValid = query[queryIsValidKey];
  const isNotValid = query[queryIsNotValidKey];

  if (typeof isValid === "string" && isValid === "true") {
    if (typeof isNotValid !== "string" || isNotValid !== "true") {
      return "true";
    }
  }

  if (typeof isNotValid === "string" && isNotValid === "true") {
    if (typeof isValid !== "string" || isValid !== "true") {
      return "false";
    }
  }
  return "";
}

function extractStringFromKey(
  query: {
    [name: string]: string | string[];
  },
  key: string
): string {
  const value = query[key];
  if (value && typeof value === "string") {
    return value;
  }
  return "";
}

function extractStringArrayFromKey(
  query: {
    [name: string]: string | string[];
  },
  key: string
): string[] {
  const value = query[key];
  if (!value) {
    return [];
  }
  if (typeof value === "string") {
    return [value];
  }
  return value;
}
