// 背景関連
export const HomeBackground: string = "rgba(211,211,211,1)";
export const CoachBackground: string = "rgba(211,211,211,1)";
export const StudentBackground: string = "rgba(211,211,211,1)";
export const TestResultBackground: string = "rgba(211,211,211,1)";

// sidebar関連
export const SidebarBorder: string = "rgba(112, 112, 112, 1)";
export const CoachSidebarBackground: string =
  "linear-gradient(to right,#562389,#036EB8)";
export const StudentSidebarBackground: string =
  "linear-gradient(to right,#17AF9F,#A6CC31)";
export const TestResultSidebarBackground: string =
  "linear-gradient(to right,#E73962,#F18C21)";

export const SelectedSidebarButton: string = "rgba(89,87,87,1)";
export const NotSelectedSidebarButton: string = "white";
export const SelectedSidebarButtonBackground: string = "rgba(247,248,248,1)";
export const NotSelectedSidebarButtonBackground: string = "transparent";
export const NotSelectedSidebarButtonBorder: string = "white";

// Button系
export const BlackButton: string = "rgba(89,87,87,1)";
export const CreateButton: string = "linear-gradient(to right,#2382C5,#4CB8EA)";
export const DeleteButton: string = "linear-gradient(to right,#E62486,#EB6337)";
export const FilterButtonColor: string = "rgba(89,87,87,1)";
export const CoachButtonColor: string =
  "linear-gradient(to right,#036EB8,#562389)";
export const StudentButtonColor: string =
  "linear-gradient(to right,#A6CC31,#17AF9F)";

//ポップアップ枠
export const PopupFrame: string = "solid 6px rgba(220,221,221,1)";

//ログイン画面関連
export const LoginAreaBackground: string = "rgba(238, 238, 238, 1)";
export const LoginButtonColor: string = "rgba(117, 169, 255, 1)";

// その他
export const SubStringColor: string = "rgba(112,112,112,1)";
export const PagingBorder: string = "rgba(221, 221, 221, 1)";

export const ContentBackground: string = "rgba(239, 239, 239, 1)";

export const VocabularyColor: string = "#72bff7";
export const GrammerColor: string = "#6ae3d1";
export const StructureColor: string = "#a3f76c";
export const LogicColor: string = "#81d551";
export const KnowledgeColor: string = "#f19437";
export const SpeedColor: string = "#ea6958";
