import * as React from "react";
import {
  Container,
  Header,
  TopContainer,
  SectionContainer,
  SectionColumn,
  HeaderContainer,
  HeaderIcon,
  TopLeft,
  TopRight
} from "./styled";
import dayjs from "dayjs";
import { Color } from "../../styles";
import { Text } from "../common/Text";
import { Spacer } from "../common/Spacer";
import { Flex } from "../common/Flex";
import { get } from "../../model/api/Request";
import { RecordResponse } from "./types";
import { StatusIndicator } from "./internal/StatusIndicator";
import { RecordPossibility } from "./internal/RecordPossibility";
import { RecordElements } from "./internal/RecordElements";
import { Target } from "./internal/Target";
import { StudyTime } from "./internal/StudyTime";
import { TestResult } from "./internal/TestResult";
import { GrammarPriority } from "./internal/GrammarPriority";
import { EnglishExamLevel } from "./internal/EnglishExamLevel";
import { RouteComponentProps } from "react-router-dom";
import { UserProfile } from "./internal/UserProfile";
import TFALabMark from "../../assets/images/tfa_lab_mark.png";
import { TFAElements } from "./internal/TFAElements";
import { StudyElement } from "./internal/StudyElement";
import LoadingOverlay from "../common/LoadingOverlay";

type Props = RouteComponentProps<{ id: string }> & {
  record: RecordResponse | null;
};

const MonthlyReport: React.FunctionComponent<Props> = ({ match, record }) => {
  const [report, setReport] = React.useState<RecordResponse | null>(null);

  React.useEffect(() => {
    if (record) {
      setReport(record);
    } else {
      get(`/records/${match.params.id}`).then(json => {
        setReport(json.result);
      });
    }
  }, [match, record]);

  if (report == null || (record && report.id !== record.id)) {
    return <LoadingOverlay isLoading={true} />;
  }
  return (
    <Container>
      <HeaderContainer>
        <HeaderIcon>
          <img src={TFALabMark} alt="" />
        </HeaderIcon>
        <Header>
          <Text color={Color.WHITE} weight="bold" size="18px">
            Monthly REPORT
          </Text>
          <Text color={Color.WHITE} weight="bold" size="18px">
            {dayjs(report.date.current.name).format("YYYY/MM/DD")}
          </Text>
        </Header>
      </HeaderContainer>
      <TopContainer>
        <TopLeft>
          <Flex display="flex">
            <Spacer pr={2}>
              <UserProfile
                student={report.student}
                coach={report.coach}
                externalEval={report.external_eval}
              />
            </Spacer>
            <EnglishExamLevel
              level={parseInt(
                report.academic_level.current.name.replace(/[^0-9]/g, "")
              )}
              currentExp={report.academic_level.current.current_exp.toFixed(0)}
              remainingExp={report.academic_level.current.remaining_exp.toFixed(
                0
              )}
            />
          </Flex>
        </TopLeft>
        <TopRight>
          <SectionColumn width="55%">
            <TFAElements elements={report.elements} />
          </SectionColumn>
          <SectionColumn width="45%">
            <Target
              title="次の目標 Next Target"
              titleBackground="#d96864"
              item={report.target.next}
            />
            <Spacer pt={1.5} />
            <Target
              title="最終目標 The Target"
              titleBackground="#c09d73"
              item={report.target.final}
            />
          </SectionColumn>
        </TopRight>
      </TopContainer>
      <SectionContainer>
        <SectionColumn width="25%">
          <TestResult
            recommendTest={report.recommend_test}
            practicalTest={report.practical_test}
          />
          {report.status_indicators.map((item, i) => (
            <React.Fragment key={i}>
              <Spacer pt={1.5} />
              <StatusIndicator item={item} index={i} />
            </React.Fragment>
          ))}
        </SectionColumn>
        <SectionColumn width="25%">
          <GrammarPriority
            grammarField={report.grammar_field}
            grammarElement={report.elements[1]} // index=1は文法のElement
            date={report.date}
          />
        </SectionColumn>
        <SectionColumn width="25%">
          <RecordElements items={report.elements} />
        </SectionColumn>
        <SectionColumn width="25%">
          <RecordPossibility item={report.record_possibility} />
          <Spacer pt={1.5} />
          <StudyTime studyTime={report.study_time} elements={report.elements} />
          <Spacer pt={1.5} />
          <StudyElement studyTimeZones={report.study_time_zone} />
        </SectionColumn>
      </SectionContainer>
    </Container>
  );
};

export { MonthlyReport };
