import React from "react";
import WrapLoginArea from "./styles/WrapLoginArea";
import LoginArea from "./styles/LoginArea";
import LoginInput from "./styles/LoginInput";
import LoginButton from "./styles/LoginButton";
import Logo from "./styles/Logo";
import { LoginButtonColor } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { put } from "../../model/api/Request";
import LoadingOverlay from "../common/LoadingOverlay";
import { commonErrorHandle } from "../../utils/errorHandle";
import { Profile } from "../../utils/profile";
import { isEmail } from "../../utils/validation";
import ProfileArea from "../common/ProfileArea";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  isLoading: boolean;
};

class ChangeEmail extends React.Component<Props, State> {
  email: string = "";

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapLoginArea>
          <Logo />
          <LoginArea color="white">
            <LoginInput
              type="text"
              placeholder="メールアドレス"
              index={0}
              onChange={this.onChangeEmail.bind(this)}
            />
            <LoginButton
              color={LoginButtonColor}
              onClick={this.onChangeButton.bind(this)}
            >
              変更
            </LoginButton>
          </LoginArea>
        </WrapLoginArea>
        <ProfileArea
          history={this.props.history}
          profile={this.props.profile}
        />
      </>
    );
  }

  onChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    this.email = e.target.value;
  }

  onChangeButton() {
    if (!isEmail(this.email)) {
      alert("メールアドレスの形式が不正です。");
      return;
    }
    this.setState({ isLoading: true });
    const params: { [index: string]: string } = {};
    params["email"] = this.email;
    put("/profile", { user_attributes: params })
      .then(res => {
        alert("メールアドレス認証コードを送りました。");
        this.props.history.push("/verify");
      })

      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default ChangeEmail;
