import styled from "styled-components";
import React from "react";

const LogoStyle = styled.img`
  height: 176px;
`;

class Logo extends React.Component {
  render() {
    return <LogoStyle src="/assets/logo_AI.png" alt="logoAI" />;
  }
}

export default Logo;
