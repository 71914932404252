import { CustomError } from "./CustomError";
import { Auth } from "aws-amplify";

const baseUrl = process.env.REACT_APP_API_URL;

const defaultHeader = {
  "content-type": "application/json"
};

const maxRefreshRetry = 1;

export function get(
  path: string,
  additionalHeaders: { [index: string]: string } = {}
) {
  return request("GET", path, null, additionalHeaders);
}

export function put(
  path: string,
  body: { [index: string]: any },
  additionalHeaders: { [index: string]: string } = {}
) {
  return request("PUT", path, body, additionalHeaders);
}

export function post(
  path: string,
  body: { [index: string]: any },
  additionalHeaders: { [index: string]: string } = {}
) {
  return request("POST", path, body, additionalHeaders);
}

export function deleteRequest(
  path: string,
  additionalHeaders: { [index: string]: string } = {}
) {
  return request("DELETE", path, null, additionalHeaders);
}

function request(
  method: string,
  path: string,
  body: { [index: string]: any } | null,
  additionalHeaders: { [index: string]: string },
  refreshRetryCount: number = 0
): Promise<any> {
  const headers: { [index: string]: string } = Object.assign(
    defaultHeader,
    additionalHeaders
  );
  if (getToken() != null) {
    headers["Authorization"] = getToken()!;
  }
  return fetch(baseUrl + path, {
    method: method,
    headers: headers,
    body: body == null ? null : JSON.stringify(body)
  }).then(res => {
    return res.json().then(response => {
      // tokenがexpiredされたエラー
      if (
        response.result &&
        response.result.errorCode === "TokenExpiredError"
      ) {
        return refreshAndRetry(
          method,
          path,
          body,
          additionalHeaders,
          refreshRetryCount
        );
      }

      // 一般的なエラー
      if (!res.ok) {
        throw new CustomError(
          "Invalid Status Code",
          res.status,
          response.result.errorCode
        );
      }
      return response;
    });
  });
}

function refreshAndRetry(
  method: string,
  path: string,
  body: { [index: string]: any } | null,
  additionalHeaders: { [index: string]: string },
  refreshRetryCount: number
): Promise<any> {
  if (maxRefreshRetry <= refreshRetryCount) {
    return Promise.reject(new Error("Failed Refresh"));
  }
  return Auth.currentAuthenticatedUser().then(user => {
    user.refreshSession(user.signInUserSession.getRefreshToken(), () => {
      Auth.currentSession().then(session => {
        saveToken(session.getIdToken().getJwtToken());
        refreshRetryCount++;
        put("/token", {
          access_token: session.getAccessToken().getJwtToken(),
          id_token: session.getIdToken().getJwtToken()
        }).then(() => {
          return request(
            method,
            path,
            body,
            additionalHeaders,
            refreshRetryCount
          );
        });
      });
    });
  });
}

function saveToken(token: string) {
  localStorage.setItem("token", token);
}

function getToken(): string | null {
  return localStorage.getItem("token");
}
