import React from "react";
import WrapLoginArea from "./styles/WrapLoginArea";
import LoginArea from "./styles/LoginArea";
import LoginInput from "./styles/LoginInput";
import LoginButton from "./styles/LoginButton";
import Logo from "./styles/Logo";
import { LoginButtonColor } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { post } from "./../../model/api/Request";
import LoadingOverlay from "../common/LoadingOverlay";
import { commonErrorHandle } from "../../utils/errorHandle";
import queryString from "query-string";
import { isValidPassword } from "../../utils/validation";

type Props = RouteComponentProps<{ id: string }>;

type State = {
  isLoading: boolean;
  loginId: string;
  verifyCode: string;
  password: string;
};

class PasswordVerify extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      loginId: queryString.parse(this.props.location.search).id,
      verifyCode: "",
      password: ""
    };
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapLoginArea>
          <Logo />
          <LoginArea color="white">
            <LoginInput
              type="text"
              placeholder="ID"
              value={this.state.loginId}
              index={0}
              onChange={this.onChangeId.bind(this)}
            />
            <LoginInput
              type="text"
              placeholder="認証コード"
              index={1}
              onChange={this.onChangeVerifyCode.bind(this)}
            />
            <LoginInput
              type="password"
              placeholder="新しいパスワード"
              index={2}
              onChange={this.onChangePassword.bind(this)}
            />
            <LoginButton
              color={LoginButtonColor}
              onClick={this.onLoginButton.bind(this)}
            >
              パスワードを初期化
            </LoginButton>
          </LoginArea>
        </WrapLoginArea>
      </>
    );
  }

  onChangeId(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ loginId: e.target.value });
  }

  onChangeVerifyCode(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ verifyCode: e.target.value });
  }

  onChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ password: e.target.value });
  }

  onLoginButton() {
    const errorMessages: string[] = [];

    if (this.state.loginId === "") {
      errorMessages.push("・ ログインIDを入力してください");
    }

    if (this.state.verifyCode === "") {
      errorMessages.push("・ 認証コードを入力してください");
    }

    if (!isValidPassword(this.state.password)) {
      errorMessages.push(
        "・ パスワードは大文字・小文字・数字・記号を1つ以上含んだ8文字以上を設定してください"
      );
    }

    if (errorMessages.length !== 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    this.setState({ isLoading: true });
    post("/profile/password/verify", {
      code: this.state.verifyCode,
      login_id: this.state.loginId,
      password: this.state.password
    })
      .then(res => {
        this.setState({ isLoading: false });
        alert("パスワードの変更が完了しました。再度ログインしてください。");
        this.props.history.push("/");
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default PasswordVerify;
