import styled from "styled-components";

type ContainerProps = {
  color?: string;
  align?: string;
  size?: string;
  weight?: string;
  lineHeight?: string;
  marginTop?: string;
  height?: string;
  width?: string;
};
export const Container = styled.p<ContainerProps>`
  line-height: ${({ lineHeight }) => lineHeight};
  color: ${({ color }) => color};
  text-align: ${({ align }) => align};
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  word-break: break-all;
  margin-top: ${({ marginTop }) => marginTop};
  height: ${({height}) => height};
  width: ${({width}) => width};
`;
