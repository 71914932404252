import React from "react";
import { User } from "../../../model/entities/User";
import Modal from "react-modal";
import {
  ModalStyle,
  CloseButton,
  CloseButtonImage,
  TitleLabel,
  Input,
  InputCheckbox,
  InputCheckboxLabel,
  Button,
  ButtonArea
} from "../styles/UserEditModalStyle";
import { TargetType } from "../../../model/entities/TargetType";
import { get, put } from "../../../model/api/Request";
import dayjs from "dayjs";
import { AcademicLevel } from "../../../model/entities/AcademicLevel";
import { selectAcademocLevelModal } from "./StudentTargetComponentModal";
import SelectTargetTypeModal from "./SelectTargetTypeModal";
import { Target } from "../../../model/entities/Target";
import * as H from "history";
import { commonErrorHandle } from "../../../utils/errorHandle";
import { CreateButton } from "../../common/Colors";
import LoadingOverlay from "../../common/LoadingOverlay";

type Props = {
  history: H.History;
  user: User;
  target: Target;
  isOpen: boolean;
  onClose: () => void;
};

type State = {
  targetTypes: TargetType[];
  academiLevels: AcademicLevel[];
  isSelectTargetMode: boolean;
  isSelectAcademicLevelMode: boolean;
  selectedTargetType: TargetType;
  targetName: string;
  targetNameElement: string; //targetTypeの名前を連結さすための状態
  selectedAcademicLevel: AcademicLevel | null;
  toDate: string;
  comment: string;
  isTargetCleared: boolean;
  isLoading: boolean;
};

class StudentTargetEditModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      targetTypes: [],
      academiLevels: [],
      isSelectTargetMode: false,
      isSelectAcademicLevelMode: false,
      selectedTargetType: props.target.target_type,
      targetName: props.target.name,
      targetNameElement: "",
      selectedAcademicLevel: props.target.academic_level,
      toDate: props.target.do_date,
      comment: props.target.result.comment ?? "",
      isTargetCleared: props.target.result.is_cleared ?? false,
      isLoading: false
    };
  }

  render() {
    if (this.state.isSelectTargetMode) {
      return (
        <SelectTargetTypeModal
          targetTypes={this.state.targetTypes}
          onSelectTargetType={this.onSelectTargetTypeElement.bind(this)}
          addTargetElement={this.addTargetElement.bind(this)}
          onClose={this.onClose.bind(this)}
          isOpen={this.props.isOpen}
          onBack={this.onBack.bind(this)}
        />
      );
    } else if (this.state.isSelectAcademicLevelMode) {
      return selectAcademocLevelModal(
        this.state.academiLevels,
        this.onSelectAcademicLevelElement.bind(this),
        this.onClose.bind(this),
        this.props.isOpen,
        this.onBack.bind(this)
      );
    }
    return this.editModal();
  }

  editModal() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <TitleLabel>目標の編集</TitleLabel>
          <Input
            readOnly={true}
            placeholder="目標の種類"
            value={this.state.selectedTargetType.name || ""}
            onClick={this.onSelectTargetType.bind(this)}
          />
          <Input
            placeholder="目標名"
            disabled={this.state.selectedTargetType?.academic_level != null}
            value={this.state.targetName}
            onChange={this.onChangeTargetName.bind(this)}
          />
          <Input
            readOnly={true}
            disabled={this.state.selectedTargetType?.academic_level != null}
            placeholder="レベル"
            value={this.state.selectedAcademicLevel?.name || ""}
            onClick={this.onSelectAcademicLevel.bind(this)}
          />
          <Input
            type="date"
            min={dayjs().format("YYYY-MM-DD")}
            placeholder="実施日"
            value={this.state.toDate}
            onChange={this.onChangeToDate.bind(this)}
          />
          <InputCheckbox
            type="checkbox"
            checked={this.state.isTargetCleared}
            onChange={this.onChangeIsCleared.bind(this)}
          />
          <InputCheckboxLabel>達成の有無</InputCheckboxLabel>
          <Input
            placeholder="コメント"
            value={this.state.comment}
            onChange={this.onChangeComment.bind(this)}
          />
          <ButtonArea>
            <Button background={CreateButton} onClick={this.onEdit.bind(this)}>
              保存
            </Button>
          </ButtonArea>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
        </Modal>
        <LoadingOverlay isLoading={this.state.isLoading} />
      </>
    );
  }

  onEdit() {
    const errorMessages: string[] = [];
    if (this.state.targetName === "") {
      errorMessages.push("・ 目標名を入力してください");
    }
    if (this.state.selectedAcademicLevel == null) {
      errorMessages.push("・ レベルを入力してください");
    }
    if (this.state.toDate === "") {
      errorMessages.push("・ 実施日を入力してください");
    }
    if (
      this.state.toDate !== "" &&
      this.state.toDate.replace(/-/g, "") <
        dayjs().format("YYYY-MM-DD").replace(/-/g, "")
    ) {
      errorMessages.push("・ 実施日は未来の日付にしてください");
    }
    if (errorMessages.length !== 0) {
      alert(errorMessages.join("\n"));
      return;
    }
    const body: { [index: string]: any } = {
      name: this.state.targetName,
      target_type_id: this.state.selectedTargetType.id,
      academic_level_id: this.state.selectedAcademicLevel?.id,
      do_date: dayjs(Date.parse(this.state.toDate)).toISOString(),
      result: {
        is_cleared: this.state.isTargetCleared ? true : false,
        comment: this.state.comment
      }
    };

    this.setState({ isLoading: true });
    put("/targets/" + this.props.target.id, body)
      .then(res => {
        this.onClose();
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onChangeIsCleared(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ isTargetCleared: e.target.checked });
  }

  onChangeComment(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ comment: e.target.value });
  }

  onChangeToDate(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toDate: e.target.value });
  }

  onChangeTargetName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ targetName: e.target.value });
  }

  onSelectAcademicLevelElement(e: React.MouseEvent<HTMLDivElement>) {
    const academiLevel = this.state.academiLevels.filter(academiLevel => {
      return academiLevel.name === e.currentTarget.innerText;
    })[0];
    this.setState({
      selectedAcademicLevel: academiLevel,
      isSelectAcademicLevelMode: false
    });
  }

  onSelectAcademicLevel() {
    if (this.state.academiLevels.length !== 0) {
      this.setState({ isSelectAcademicLevelMode: true });
      return;
    }
    this.setState({ isLoading: true });
    get("/academic-levels")
      .then(res => {
        const academiLevels: AcademicLevel[] = res.result;
        this.setState({
          academiLevels: academiLevels,
          isSelectAcademicLevelMode: true,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onSelectTargetTypeElement(targetType: TargetType) {
    if (targetType.academic_level != null) {
      this.setState({
        selectedAcademicLevel: targetType.academic_level,
        selectedTargetType: targetType,
        targetName:
          this.state.targetNameElement === ""
            ? targetType.name
            : this.state.targetNameElement + " " + targetType.name,
        isSelectTargetMode: false
      });
    }
    //その他のとき
    else {
      this.setState({
        selectedTargetType: targetType,
        targetName: "",
        selectedAcademicLevel: null,
        isSelectTargetMode: false
      });
    }
  }

  addTargetElement(targetType: TargetType) {
    this.setState({
      targetNameElement:
        this.state.targetNameElement === ""
          ? targetType.name
          : this.state.targetNameElement + " " + targetType.name
    });
  }

  onSelectTargetType() {
    if (this.state.targetTypes.length !== 0) {
      this.setState({
        targetNameElement: "",
        isSelectTargetMode: true
      });
      return;
    }
    this.setState({ isLoading: true });
    get("/target-types")
      .then(res => {
        const targetTypes: TargetType[] = res.result;
        this.setState({
          targetTypes: targetTypes,
          isSelectTargetMode: true,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  toBackTargetList() {
    this.clear();
  }

  clear() {
    this.setState({
      targetNameElement: "",
      isSelectTargetMode: false,
      isSelectAcademicLevelMode: false
    });
  }

  onBack() {
    this.setState({
      isSelectTargetMode: false,
      isSelectAcademicLevelMode: false
    });
  }

  onClose() {
    this.setState({ isLoading: false });
    this.clear();
    this.props.onClose();
  }
}

export default StudentTargetEditModal;
