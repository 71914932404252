import React from "react";
import Modal from "react-modal";
import {
  ModalStyle,
  ButtonArea,
  Button,
  SelectElementArea,
  SelectElementDiv,
  SelectElement,
  TitleLabel
} from "../styles/UserEditModalStyle";
import { BlackButton } from "../../common/Colors";
import { TargetType } from "../../../model/entities/TargetType";

type Props = {
  targetTypes: TargetType[];
  onSelectTargetType: (targetType: TargetType) => void;
  addTargetElement: (targetType: TargetType) => void;
  onClose: () => void;
  isOpen: boolean;
  onBack: () => void;
};

type State = {
  childTargetType: TargetType[];
};

class SelectTargetTypeModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      childTargetType: []
    };
  }
  render() {
    // 子TargetTypeが選択されたとき
    if (this.state.childTargetType.length !== 0) {
      return (
        <SelectTargetTypeModal
          targetTypes={this.state.childTargetType}
          onSelectTargetType={this.props.onSelectTargetType}
          addTargetElement={this.props.addTargetElement}
          onClose={this.props.onClose}
          isOpen={this.props.isOpen}
          onBack={this.props.onBack}
        />
      );
    }

    const targetTypeElements = this.props.targetTypes.map(type => {
      return (
        <SelectElementDiv
          onClick={this.onSelectTargetType.bind(this)}
          key={type.id}
        >
          <SelectElement isSelected={false}>{type.name}</SelectElement>
        </SelectElementDiv>
      );
    });
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <TitleLabel>目標の種類</TitleLabel>
        <SelectElementArea scrollable={true}>
          {targetTypeElements}
        </SelectElementArea>
        <ButtonArea>
          <Button background={BlackButton} onClick={this.props.onBack}>
            戻る
          </Button>
        </ButtonArea>
      </Modal>
    );
  }

  onSelectTargetType(e: React.MouseEvent<HTMLDivElement>) {
    const targetType: TargetType = this.props.targetTypes.filter(targetType => {
      return targetType.name === e.currentTarget.innerText;
    })[0];

    // 子のtarget typeがない場合
    if (
      !targetType.child_target_types ||
      targetType.child_target_types.length === 0
    ) {
      this.props.onSelectTargetType(targetType);
      return;
    }

    // 子のtarget typeが存在するときは再帰的に選択

    this.setState({
      childTargetType: targetType.child_target_types
    });
    this.props.addTargetElement(targetType);
  }
}

export default SelectTargetTypeModal;
