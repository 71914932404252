import React from "react";
import Modal from "react-modal";
import {
  ModalStyle,
  ButtonArea,
  Button,
  SelectElementArea,
  SelectElementDiv,
  SelectElement,
  TitleLabel
} from "../styles/UserEditModalStyle";
import { BlackButton } from "../../common/Colors";
import { ExternalEval } from "../../../model/entities/ExternalEval";
import { Level } from "../../../model/entities/Level";

export function selectExternalEvalTypeModal(
  externalEvalTypes: ExternalEval[],
  onSelectExternalEvalType: (e: React.MouseEvent<HTMLDivElement>) => void,
  isOpen: boolean,
  onBack: () => void
) {
  const externalEvalTypeElements = externalEvalTypes.map(type => {
    return (
      <SelectElementDiv onClick={onSelectExternalEvalType} key={type.id}>
        <SelectElement isSelected={false}>{type.name}</SelectElement>
      </SelectElementDiv>
    );
  });
  return (
    <Modal isOpen={isOpen} style={ModalStyle}>
      <TitleLabel>ジャンルの選択</TitleLabel>
      <SelectElementArea scrollable={true}>
        {externalEvalTypeElements}
      </SelectElementArea>
      <ButtonArea>
        <Button background={BlackButton} onClick={onBack}>
          戻る
        </Button>
      </ButtonArea>
    </Modal>
  );
}

export function selectExternalEvalLevelModal(
  externalEvalLevels: Level[],
  onSelectExternalEvalLevel: (e: React.MouseEvent<HTMLDivElement>) => void,
  isOpen: boolean,
  onBack: () => void
) {
  const externalEvalLevelElements = externalEvalLevels.map(level => {
    return (
      <SelectElementDiv onClick={onSelectExternalEvalLevel} key={level.id}>
        <SelectElement isSelected={false}>{level.name}</SelectElement>
      </SelectElementDiv>
    );
  });
  return (
    <Modal isOpen={isOpen} style={ModalStyle}>
      <TitleLabel>スコアの選択</TitleLabel>
      <SelectElementArea scrollable={true}>
        {externalEvalLevelElements}
      </SelectElementArea>
      <ButtonArea>
        <Button background={BlackButton} onClick={onBack}>
          戻る
        </Button>
      </ButtonArea>
    </Modal>
  );
}
