import Modal from "react-modal";
import React from "react";
import styled from "styled-components";
import "./styles/LoadingOverlayStyle.css";

type Props = {
  isLoading: boolean;
};

const ModalStyle = {
  overlay: {
    zIndex: 100
  },
  content: {
    top: "50%",
    left: "50%",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    border: "none",
    background: "rgba(0, 0, 0, 0)"
  }
};

const LoadingLabel = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: black;
`;

class LoadingOverlay extends React.Component<Props, {}> {
  render() {
    return (
      <Modal isOpen={this.props.isLoading} style={ModalStyle}>
        <div className="loadingOverlayElement" />
        <LoadingLabel>Loading...</LoadingLabel>
      </Modal>
    );
  }
}

export default LoadingOverlay;
