import React from "react";
import styled from "styled-components";
import { ContentBackground } from "../../../../common/Colors";
import { TestResult } from "../../../../../model/entities/TestResult";
import { QuestionResult } from "../../../../../model/entities/Test";
const Table = styled.table`
  table-layout: fixed;
  width: 100%;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px;
`;
const Th = styled.th`
  position: sticky;
  top: 0;
  border-bottom: 2px solid black;
  background: ${ContentBackground};
  &:last-child {
    font-size: 12px;
  }
`;

const Td = styled.td`
  border-top: solid 1px #ddd;
  padding: 2px;
`;

type Props = {
  test: TestResult;
  selectQuestion: (question: QuestionResult) => void;
};

class RightQuestionTable extends React.Component<Props, {}> {
  render() {
    /* todo:平均正答率を表示 */
    const questions = this.props.test.result.questions.map(
      (question, index) => {
        let second = String(Math.floor(question.spent_time_ms / 1000));
        let mSecond = String(
          Math.floor(
            (question.spent_time_ms / 1000 -
              Math.floor(question.spent_time_ms / 1000)) *
              100
          )
        );
        if (mSecond.length === 1) {
          mSecond = "0" + mSecond;
        }
        return (
          <tr key={index} onClick={this.selectQuestion.bind(this)}>
            <Td>{question.question_number}</Td>
            <Td>{question.is_correct ? "○" : "×"}</Td>
            <Td>{question.is_confident ? "あり" : "なし"}</Td>
            <Td>{second + "秒" + mSecond}</Td>
          </tr>
        );
      }
    );

    return (
      <Table>
        <thead>
          <tr>
            <Th>問題番号</Th>
            <Th>正誤</Th>
            <Th>確信</Th>
            <Th>時間</Th>
          </tr>
        </thead>
        <tbody>{questions}</tbody>
      </Table>
    );
  }

  selectQuestion(e) {
    const questionNumber: number = parseInt(
      e.target.parentElement.children[0].innerHTML
    );
    const question = this.props.test.result.questions.filter(
      question => question.question_number === questionNumber
    )[0];
    this.props.selectQuestion(question);
  }
}

export default RightQuestionTable;
