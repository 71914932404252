import React from "react";
import { WrapBackground } from "../../../common/WrapBackground";
import TrainingQuestionListContent from "./TrainingQuestionListContent";
import {
  TestResultBackground,
  TestResultSidebarBackground
} from "../../../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { get } from "../../../../model/api/Request";
import { commonErrorHandle } from "../../../../utils/errorHandle";
import { TestInformation } from "../../../../model/entities/TestInformation";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { Profile } from "../../../../utils/profile";

type Props = RouteComponentProps<{ id: string }> & Profile;

type State = {
  isLoading: boolean;
  testInformation: TestInformation | null;
};

class TrainingQuestionList extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      testInformation: null
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    const testId = this.props.match.params.id;
    Promise.all([get("/training-tests/" + testId)])
      .then(responses => {
        this.setState({
          isLoading: false,
          testInformation: responses[0].result
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapBackground
          color={TestResultBackground}
          history={this.props.history}
          profile={this.props.profile}
          selectedSidebar="training_test_result"
          sidebarColor={TestResultSidebarBackground}
        >
          <TrainingQuestionListContent
            match={this.props.match}
            history={this.props.history}
            testInformation={this.state.testInformation}
          />
        </WrapBackground>
      </>
    );
  }
}

export default TrainingQuestionList;
