import React from "react";
import styled from "styled-components";
import { TestResult } from "../../../../../model/entities/TestResult";

const Table = styled.table`
  margin-top: 102px;
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  table-layout: fixed;
`;

const Tr = styled.tr<{ isTopBold?: boolean }>`
  border-top: ${props =>
    props.isTopBold ? "2px solid black" : "1px solid #aaa"};
  :first-child {
    border-top: 1px solid black;
  }
  :last-child {
    border-bottom: 1px solid black;
  }
`;

const Td = styled.td`
  padding: 2px 0;
  font-size: 11px;
  :first-child {
    border-left: 1px solid black;
    border-right: 1px solid black;
  }
  :last-child {
    border-right: 1px solid black;
  }
`;

type Props = {
  test: TestResult;
};

class TagTable extends React.Component<Props, {}> {
  render() {
    const tagsElement = (
      <Tr>
        <Td>分野</Td>
        {this.props.test.result.grammar_fields.map(tag => (
          <Td key={tag.alphabet}>{tag.alphabet}</Td>
        ))}
      </Tr>
    );
    const tagTitlesElement = (
      <Tr>
        <Td>分野名</Td>
        {this.props.test.result.grammar_fields.map(tag => (
          <Td key={tag.alphabet}>{tag.name_ja}</Td>
        ))}
      </Tr>
    );
    const questionElement = (
      <Tr isTopBold={true}>
        <Td>出題数</Td>
        {this.props.test.result.grammar_fields.map(tag => (
          <Td key={tag.alphabet}>{tag.question_count}</Td>
        ))}
      </Tr>
    );
    const correctElement = (
      <Tr>
        <Td>正解数</Td>
        {this.props.test.result.grammar_fields.map(tag => (
          <Td key={tag.alphabet}>{tag.correct_count}</Td>
        ))}
      </Tr>
    );
    return (
      <Table>
        <tbody>
          {tagsElement}
          {tagTitlesElement}
          {questionElement}
          {correctElement}
        </tbody>
      </Table>
    );
  }
}

export default TagTable;
