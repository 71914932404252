import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { get, put } from "./../../model/api/Request";
import LoadingOverlay from "../common/LoadingOverlay";
import { User } from "../../model/entities/User";
import Amplify, { Auth, Hub } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";

type Props = RouteComponentProps<{}>;

type State = {};

class Login extends React.Component<Props, State> {
  // 初回ログイン時はaccess_tokenをAPIに送ってから画面遷移させるためのフラグ
  isSignIn: boolean = false;

  componentDidMount() {
    Amplify.configure({
      Auth: {
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
        authenticationFlowType: "USER_PASSWORD_AUTH"
      }
    });
    const oauth = {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      redirectSignIn: window.location.protocol + "//" + window.location.host,
      redirectSignOut: window.location.protocol + "//" + window.location.host,
      responseType: "code"
    };
    Auth.configure({ oauth });

    Hub.listen("auth", ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          this.isSignIn = true;
          Auth.currentSession().then(session => {
            this.getProfileAndPush(session);
          });
          break;
        case "signIn_failure":
          alert(
            "別のユーザーでログインする場合は、予めログアウトしてください。"
          );
      }
    });
    // cognitoからコールバックされた時、結果取得に少しラグがあるから0.5s遅延させる
    setTimeout(() => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          if (this.isSignIn) {
            return;
          }
          // 複数端末でログインされることを考慮して、access_tokenは取得したらapiへputする
          this.getProfileAndPush(user.signInUserSession);
        })
        .catch(e => {
          Auth.federatedSignIn();
        });
    }, 500);
  }

  getProfileAndPush(session: CognitoUserSession) {
    put("/token", {
      access_token: session.getAccessToken().getJwtToken(),
      id_token: session.getIdToken().getJwtToken()
    })
      .then(_ => {
        localStorage.setItem("token", session.getIdToken().getJwtToken());
        get("/profile")
          .then(res => {
            const profile: User = res.result;
            if (profile.user_type.name === "student") {
              this.props.history.push("/student");
            } else if (profile.user_type.name === "coach") {
              this.props.history.push("/client");
            }
          })
          .catch(_ => {
            Auth.signOut();
          });
      })
      .catch(_ => {
        Auth.signOut();
      });
  }

  render() {
    return <LoadingOverlay isLoading={true} />;
  }
}

export default Login;
