import React from "react";
import Modal from "react-modal";
import {
  ModalStyle,
  TitleLabel,
  BodyLabel,
  Input,
  InputNameElement,
  ButtonArea,
  Button,
  DisablableButton,
  CloseButton,
  CloseButtonImage,
  InputNameArea,
  SelectElementArea,
  SelectElementDiv,
  SelectElement
} from "./styles/UserEditModalStyle";
import { get, post } from "../../model/api/Request";
import { selectAcademocLevelModal } from "./studnetTarget/StudentTargetComponentModal";
import { AffiliationDivision } from "../../model/entities/AffiliationDivision";
import { AcademicLevel } from "../../model/entities/AcademicLevel";
import { AuthoritySet } from "../../model/entities/AuthoritySet";
import { AffiliationRole } from "../../model/entities/AffiliationRole";
import { User } from "../../model/entities/User";
import { BlackButton, CreateButton } from "../common/Colors";
import LoadingOverlay from "../common/LoadingOverlay";
import { isValidPassword, isKatakana } from "../../utils/validation";
import { commonErrorHandle } from "../../utils/errorHandle";
import * as H from "history";
import { CustomError } from "../../model/api/CustomError";

type Props = {
  history: H.History;
  userType: UserType;
  isOpen: boolean;
  onClose: () => void;
};

type State = {
  isLoading: boolean;
  tmpPassword: string;
  tmpPasswordConfirm: string;
  firstName: string;
  familyName: string;
  firstNameFurigana: string;
  familyNameFurigana: string;
  academicLevels: AcademicLevel[];
  selectedAcademicLevel: AcademicLevel | null;
  isSelectAcademicLevelMode: boolean;
  isSelectDivisionMode: boolean;
  selectableDivisions: AffiliationDivision[];
  selectedDivision: AffiliationDivision | null;
  isSelectAuthoritySetMode: boolean;
  selectableAuthoritySets: AuthoritySet[];
  selectedAuthoritySet: AuthoritySet | null;
  isSelectRoleMode: boolean;
  selectableRoles: AffiliationRole[];
  selectedRole: AffiliationRole | null;
  loginId: string | null;
  coaches: User[];
  selectedCoach: User | null;
  isSelectCoachMode: boolean;
};

export enum UserType {
  student = "students",
  coach = "coaches"
}

class UserCreateModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      tmpPassword: "",
      tmpPasswordConfirm: "",
      firstName: "",
      familyName: "",
      firstNameFurigana: "",
      familyNameFurigana: "",
      academicLevels: [],
      selectedAcademicLevel: null,
      isSelectAcademicLevelMode: false,
      isSelectDivisionMode: false,
      selectableDivisions: [],
      selectedDivision: null,
      selectableAuthoritySets: [],
      isSelectAuthoritySetMode: false,
      selectedAuthoritySet: null,
      selectableRoles: [],
      isSelectRoleMode: false,
      selectedRole: null,
      loginId: null,
      coaches: [],
      selectedCoach: null,
      isSelectCoachMode: false
    };
  }

  render() {
    if (this.state.isSelectCoachMode) {
      return this.selectCoachModal();
    }
    if (this.state.isSelectAcademicLevelMode) {
      return selectAcademocLevelModal(
        this.state.academicLevels,
        this.onSelectAcademicLevelElement.bind(this),
        this.onClose.bind(this),
        this.props.isOpen,
        this.onBack.bind(this)
      );
    }
    if (this.state.isSelectDivisionMode) {
      return this.getSelectDivisionModal();
    }
    if (this.state.isSelectAuthoritySetMode) {
      return this.getSelectAuthoritySetModal();
    }
    if (this.state.isSelectRoleMode) {
      return this.getSelectRoleModal();
    }
    if (this.state.loginId != null) {
      return this.getShowLoginIdModal();
    }
    return this.getInputModal();
  }

  getInputModal() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <TitleLabel>新規登録</TitleLabel>
          <Input
            type="password"
            placeholder="仮のパスワード"
            value={this.state.tmpPassword}
            onChange={this.onChangeTmpPassword.bind(this)}
          />
          <Input
            type="password"
            placeholder="仮のパスワード(確認)"
            value={this.state.tmpPasswordConfirm}
            onChange={this.onChangeTmpPasswordConfirm.bind(this)}
          />
          <InputNameArea>
            <InputNameElement
              placeholder="姓"
              value={this.state.familyName}
              onChange={this.onChangeFamilyName.bind(this)}
            />
            <InputNameElement
              placeholder="名"
              value={this.state.firstName}
              onChange={this.onChangeFirstName.bind(this)}
            />
          </InputNameArea>
          <InputNameArea>
            <InputNameElement
              placeholder="姓(フリガナ)"
              value={this.state.familyNameFurigana}
              onChange={this.onChangeFamilyNameFurigana.bind(this)}
            />
            <InputNameElement
              placeholder="名(フリガナ)"
              value={this.state.firstNameFurigana}
              onChange={this.onChangeFirstNameFurigana.bind(this)}
            />
          </InputNameArea>
          <Input
            readOnly={true}
            placeholder="所属"
            value={this.state.selectedDivision?.name}
            onClick={this.onAffiliationClick.bind(this)}
          />
          {this.props.userType === UserType.student && (
            <Input
              readOnly={true}
              placeholder="担当コーチ"
              value={
                this.state.selectedCoach !== null
                  ? this.state.selectedCoach?.family_name_ja +
                    this.state.selectedCoach?.first_name_ja
                  : ""
              }
              onClick={this.onCoachClick.bind(this)}
            />
          )}
          {this.props.userType === UserType.student && (
            <Input
              readOnly={true}
              placeholder="レベル"
              value={this.state.selectedAcademicLevel?.name || ""}
              onClick={this.onSelectAcademicLevel.bind(this)}
            />
          )}
          {this.props.userType === UserType.coach && (
            <Input
              readOnly={true}
              placeholder="権限"
              value={this.state.selectedAuthoritySet?.name}
              onClick={this.onAuthorityClick.bind(this)}
            />
          )}
          {this.props.userType === UserType.student && (
            <Input
              readOnly={true}
              placeholder="役割"
              value={this.state.selectedRole?.name}
              onClick={this.onRoleClick.bind(this)}
            />
          )}
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onCreate.bind(this)}
            >
              登録
            </Button>
          </ButtonArea>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
        </Modal>
        <LoadingOverlay isLoading={this.state.isLoading} />
      </>
    );
  }

  selectCoachModal() {
    const coaches = this.state.coaches.map(coach => {
      return (
        <SelectElementDiv
          onClick={this.onClickCoachElement.bind(this)}
          key={coach.id}
        >
          <SelectElement isSelected={false}>
            {coach.family_name_ja + coach.first_name_ja}
          </SelectElement>
        </SelectElementDiv>
      );
    });
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <TitleLabel>担当コーチ選択</TitleLabel>
        <SelectElementArea scrollable={true}>{coaches}</SelectElementArea>
        <ButtonArea>
          <Button
            background={BlackButton}
            onClick={this.onCoachBack.bind(this)}
          >
            戻る
          </Button>
        </ButtonArea>
      </Modal>
    );
  }

  getSelectDivisionModal() {
    const divisions = this.state.selectableDivisions.map(division => {
      return (
        <SelectElementDiv
          onClick={this.onClickDivisionElement.bind(this)}
          key={division.id}
        >
          <SelectElement
            isSelected={division.id === this.state.selectedDivision?.id}
          >
            {division.name}
          </SelectElement>
        </SelectElementDiv>
      );
    });
    const enableDecideButton = this.state.selectedDivision != null;
    const enableNextButton =
      this.state.selectedDivision != null &&
      this.state.selectedDivision.child_affiliation_divisions != null &&
      this.state.selectedDivision.child_affiliation_divisions.length !== 0;
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <TitleLabel>所属の選択</TitleLabel>
        <SelectElementArea scrollable={true}>{divisions}</SelectElementArea>
        <ButtonArea>
          <Button
            background={BlackButton}
            onClick={this.onAffiliationDivisionBack.bind(this)}
          >
            戻る
          </Button>
          <DisablableButton
            background={CreateButton}
            isActive={enableDecideButton}
            onClick={this.onSetAffiliationDivision.bind(this)}
            disabled={!enableDecideButton}
          >
            決定して終了
          </DisablableButton>
          <DisablableButton
            background={CreateButton}
            isActive={enableNextButton}
            disabled={!enableNextButton}
            onClick={this.onNextAffiliationDivision.bind(this)}
          >
            決定して次へ
          </DisablableButton>
        </ButtonArea>
      </Modal>
    );
  }

  getSelectAuthoritySetModal() {
    const authoritySets = this.state.selectableAuthoritySets.map(authority => {
      return (
        <SelectElementDiv
          onClick={this.onClickAuthorityElement.bind(this)}
          key={authority.id}
        >
          <SelectElement isSelected={false}>{authority.name}</SelectElement>
        </SelectElementDiv>
      );
    });
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <TitleLabel>権限の選択</TitleLabel>
        <SelectElementArea scrollable={true}>{authoritySets}</SelectElementArea>
        <ButtonArea>
          <Button
            background={BlackButton}
            onClick={this.onAuthorityBack.bind(this)}
          >
            戻る
          </Button>
        </ButtonArea>
      </Modal>
    );
  }

  getSelectRoleModal() {
    const roles = this.state.selectableRoles.map(role => {
      return (
        <SelectElementDiv
          onClick={this.onClickRoleElement.bind(this)}
          key={role.id}
        >
          <SelectElement isSelected={false}>{role.name}</SelectElement>
        </SelectElementDiv>
      );
    });
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <TitleLabel>役割の選択</TitleLabel>
        <SelectElementArea scrollable={true}>{roles}</SelectElementArea>
        <ButtonArea>
          <Button background={BlackButton} onClick={this.onRoleBack.bind(this)}>
            戻る
          </Button>
        </ButtonArea>
      </Modal>
    );
  }

  getShowLoginIdModal() {
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <TitleLabel>作成されたユーザー名</TitleLabel>
        <BodyLabel>
          {this.state.familyName + " " + this.state.firstName}
        </BodyLabel>
        <BodyLabel>
          {this.state.familyNameFurigana + " " + this.state.firstNameFurigana}
        </BodyLabel>
        <TitleLabel>作成されたユーザーのLoginID</TitleLabel>
        <BodyLabel>{this.state.loginId}</BodyLabel>
        <CloseButton onClick={this.onClose.bind(this)}>
          <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
        </CloseButton>
      </Modal>
    );
  }

  onSelectAcademicLevelElement(e: React.MouseEvent<HTMLDivElement>) {
    const academiLevel = this.state.academicLevels.filter(academiLevel => {
      return academiLevel.name === e.currentTarget.innerText;
    })[0];
    this.setState({
      selectedAcademicLevel: academiLevel,
      isSelectAcademicLevelMode: false
    });
  }

  onSelectAcademicLevel() {
    if (this.state.academicLevels.length !== 0) {
      this.setState({ isSelectAcademicLevelMode: true });
      return;
    }
    this.setState({ isLoading: true });
    get("/academic-levels")
      .then(res => {
        const academicLevels: AcademicLevel[] = res.result;
        this.setState({
          academicLevels: academicLevels,
          isSelectAcademicLevelMode: true,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onCreate() {
    const errorMessages: string[] = [];
    if (!isValidPassword(this.state.tmpPassword)) {
      errorMessages.push(
        "・ パスワードは大文字・小文字・数字・記号を1つ以上含んだ8文字以上を設定してください"
      );
    }
    if (this.state.tmpPassword !== this.state.tmpPasswordConfirm) {
      errorMessages.push(
        "・ 確認用のパスワードは仮パスワードと同じものを入力してください"
      );
    }
    if (this.state.familyName === "" || this.state.firstName === "") {
      errorMessages.push("・ 氏名をを入力してください");
    }
    if (
      this.state.familyNameFurigana === "" ||
      this.state.firstNameFurigana === ""
    ) {
      errorMessages.push("・ フリガナを入力してください");
    } else if (
      !isKatakana(this.state.familyNameFurigana) ||
      !isKatakana(this.state.firstNameFurigana)
    ) {
      errorMessages.push("・ フリガナはカタカナで入力してください");
    }

    if (
      this.props.userType === UserType.student &&
      this.state.selectedAcademicLevel == null
    ) {
      errorMessages.push("・ レベルを入力してください");
    }

    if (this.state.selectedDivision == null) {
      errorMessages.push("・ 所属を入力してください");
    }

    if (
      this.props.userType === UserType.student &&
      this.state.selectedRole == null
    ) {
      errorMessages.push("・ 役割を入力してください");
    }

    if (
      this.props.userType === UserType.coach &&
      this.state.selectedAuthoritySet == null
    ) {
      errorMessages.push("・ 権限を入力してください");
    }

    if (errorMessages.length !== 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    this.setState({ isLoading: true });
    const params: { [index: string]: any } = {
      temporary_password: this.state.tmpPassword,
      user: {
        first_name_ja: this.state.firstName,
        family_name_ja: this.state.familyName,
        first_name_furigana: this.state.firstNameFurigana,
        family_name_furigana: this.state.familyNameFurigana,
        affiliation_division_id: this.state.selectedDivision!.id
      }
    };
    if (this.props.userType === UserType.student) {
      params["student"] = {
        coach_user_id: this.state.selectedCoach?.id ?? null,
        academic_level_id: this.state.selectedAcademicLevel?.id ?? null,
        comment: ""
      };
      params["user"]["affiliation_role_id"] = this.state.selectedRole!.id;
    } else if (this.props.userType === UserType.coach) {
      params["user"]["authority_set_id"] = this.state.selectedAuthoritySet!.id;
    }
    post("/" + this.props.userType, params)
      .then(res => {
        this.setState({
          selectedCoach: null,
          selectedAcademicLevel: null,
          selectedDivision: null,
          selectedAuthoritySet: null,
          selectedRole: null,
          loginId: res.result.login_id,
          isLoading: false
        });
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (error instanceof CustomError) {
          const customError = error as CustomError;
          if (customError.code === "EnabledUserNumLimitExceededError") {
            alert("登録できるユーザーの最大数を超えています。");
            return;
          }
        }
        commonErrorHandle(error, this.props.history);
      });
  }

  onCoachBack() {
    this.setState({
      isSelectCoachMode: false
    });
  }

  onCoachClick() {
    this.setState({ isLoading: true });
    get("/coaches")
      .then(res => {
        const coaches: User[] = res.result;
        this.setState({
          coaches: coaches,
          isSelectCoachMode: true,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onAffiliationDivisionBack() {
    this.setState({
      selectedDivision: null,
      isSelectDivisionMode: false
    });
  }

  onAffiliationClick() {
    this.setState({ isLoading: true });
    get("/affiliation-divisions")
      .then(res => {
        this.setState({ isLoading: false });
        const division: AffiliationDivision = res.result;
        this.setState({
          selectableDivisions: [division],
          selectedDivision: null,
          isSelectDivisionMode: true
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onAuthorityBack() {
    this.setState({
      selectedAuthoritySet: null,
      isSelectAuthoritySetMode: false
    });
  }

  onAuthorityClick() {
    this.setState({ isLoading: true });
    get("/authority-sets")
      .then(res => {
        this.setState({ isLoading: false });
        const authorities: AuthoritySet[] = res.result;
        this.setState({
          selectableAuthoritySets: authorities,
          selectedAuthoritySet: null,
          isSelectAuthoritySetMode: true
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onRoleBack() {
    this.setState({
      selectedRole: null,
      isSelectRoleMode: false
    });
  }

  onRoleClick() {
    this.setState({ isLoading: true });
    get("/affiliation-roles")
      .then(res => {
        this.setState({ isLoading: false });
        const roles: AffiliationRole[] = res.result;
        this.setState({
          selectableRoles: roles,
          selectedRole: null,
          isSelectRoleMode: true
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onClickCoachElement(e: React.MouseEvent<HTMLDivElement>) {
    const coach = this.state.coaches.filter(coach => {
      return (
        coach.family_name_ja + coach.first_name_ja === e.currentTarget.innerText
      );
    })[0];
    this.setState({ selectedCoach: coach, isSelectCoachMode: false });
  }

  onClickDivisionElement(e: React.MouseEvent<HTMLDivElement>) {
    const division = this.state.selectableDivisions.filter(division => {
      return division.name === e.currentTarget.innerText;
    })[0];
    this.setState({ selectedDivision: division });
  }

  onClickAuthorityElement(e: React.MouseEvent<HTMLDivElement>) {
    const authority = this.state.selectableAuthoritySets.filter(authority => {
      return authority.name === e.currentTarget.innerText;
    })[0];
    this.setState({
      selectedAuthoritySet: authority,
      isSelectAuthoritySetMode: false
    });
  }

  onClickRoleElement(e: React.MouseEvent<HTMLDivElement>) {
    const role = this.state.selectableRoles.filter(role => {
      return role.name === e.currentTarget.innerText;
    })[0];
    this.setState({
      selectedRole: role,
      isSelectRoleMode: false
    });
  }

  onNextAffiliationDivision() {
    this.setState({
      selectableDivisions:
        this.state.selectedDivision?.child_affiliation_divisions || [],
      selectedDivision: null
    });
  }

  onSetAffiliationDivision() {
    this.setState({
      isSelectDivisionMode: false
    });
  }

  onChangeTmpPassword(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ tmpPassword: e.target.value });
  }

  onChangeTmpPasswordConfirm(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ tmpPasswordConfirm: e.target.value });
  }

  onChangeFirstName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ firstName: e.target.value });
  }

  onChangeFamilyName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ familyName: e.target.value });
  }

  onChangeFirstNameFurigana(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ firstNameFurigana: e.target.value });
  }

  onChangeFamilyNameFurigana(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ familyNameFurigana: e.target.value });
  }

  onBack() {
    this.setState({
      isSelectAcademicLevelMode: false
    });
  }

  onClose() {
    this.setState({
      selectedCoach: null,
      isSelectCoachMode: false,
      selectedAcademicLevel: null,
      isSelectAcademicLevelMode: false,
      selectedDivision: null,
      isSelectDivisionMode: false,
      selectedAuthoritySet: null,
      isSelectAuthoritySetMode: false,
      selectedRole: null,
      isSelectRoleMode: false,
      loginId: null,
      tmpPassword: "",
      tmpPasswordConfirm: "",
      firstName: "",
      familyName: "",
      firstNameFurigana: "",
      familyNameFurigana: ""
    });
    this.props.onClose();
  }
}

export default UserCreateModal;
