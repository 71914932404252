import { Question } from "../../../../model/entities/Question";
import React from "react";
import { ListRowTr } from "../../../common/styles/ListRowStyle";
import * as H from "history";
import { Td } from "./styles/StudentTrainingQuestionListContentStyle";

type Props = {
  question: Question;
  additionalInformaition: Question;
  history: H.History;
};

class StudentTrainingQuestionListRow extends React.Component<Props, {}> {
  render() {
    const question = this.props.question;
    const additionalInformaition = this.props.additionalInformaition;
    const choices = question.choices.join("/");
    const tags = question.tags.join(",");
    return (
      <ListRowTr>
        <Td>{question.question_number}</Td>
        <Td>
          {question.passages.join("\n")}
          <br />
          {choices}
        </Td>
        <Td>
          {additionalInformaition.answered_choice}
          <br />({question.answer})
        </Td>
        <Td>
          {question.average_correct_rate_per !== null
            ? (Math.round(question.average_correct_rate_per * 10) / 10).toFixed(
                1
              ) + "%"
            : "-"}
        </Td>
        <Td>
          {additionalInformaition.spent_time_ms !== null
            ? Math.floor(
                (additionalInformaition.spent_time_ms ?? 0) / 1000 / 60
              ) +
              "分" +
              Math.floor(
                ((additionalInformaition.spent_time_ms ?? 0) / 1000) % 60
              ) +
              "秒"
            : "-"}
          <br />(
          {question.average_time_ms !== null
            ? Math.floor((question.average_time_ms ?? 0) / 1000 / 60) +
              "分" +
              Math.floor(((question.average_time_ms ?? 0) / 1000) % 60) +
              "秒"
            : "-"}
          )
        </Td>
        <Td>{tags}</Td>
      </ListRowTr>
    );
  }
}

export default StudentTrainingQuestionListRow;
