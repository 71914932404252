import styled from "styled-components";
import { Space } from "../../styles";

export const Container = styled.div`
  width: 1680px;
  background: white;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Header = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Space}px ${Space}px ${Space}px ${Space * 2}px;
  margin-left: -8px;
  height: 30px;
  background-color: #357fc2;
`;

export const HeaderIcon = styled.div`
  z-index: 1;
  width: 70px;
  padding: ${Space}px 0 ${Space}px ${Space}px;
  img {
    display: block;
    width: 100%;
  }
`;

export const TopContainer = styled.div`
  display: flex;
  padding: ${Space * 1}px ${Space * 1.5}px 0 ${Space * 7}px;
`;

export const TopLeft = styled.div`
  width: calc(50% + ${Space * 1.5}px - (${Space * 7}px / 2));
  padding-right: ${Space * 1.5}px;
`;

export const TopRight = styled.div`
  display: flex;
  width: calc(50% + (${Space * 7}px / 2));
  padding-left: ${Space * 1.5}px;
`;

export const SectionContainer = styled.div`
  display: flex;
  padding: ${Space * 1.5}px;
`;

export const SectionColumn = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  & + & {
    padding-left: ${Space * 1.5}px;
  }
`;
