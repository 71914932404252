import { TestResult } from "../../../../model/entities/TestResult";
import React from "react";
import styled from "styled-components";
import { ListContentTable } from "../../../common/styles/ListContentStyle";
import { ListRowTd } from "../../../common/styles/ListRowStyle";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";
import dayjs from "dayjs";

type Props = {
  testContent: TestResult | null;
};

const ListContentElement = styled.div`
  position: absolute;
  left: 703px;
  right: 18px;
  height: 124px;
  padding: 0px 16px 16px 16px;
  overflow: hidden;
`;

class TestContent extends React.Component<Props, {}> {
  render() {
    const testContent = this.props.testContent;
    // Todo: フィルターで検索結果が0のとき、テスト情報が存在しなくなるのでどうするか考える
    if (!testContent) {
      return <></>;
    }
    const Th = styled.th`
      background: rgba(112, 112, 112, 1);
      color: white;
      font-size: 18px;
      border: solid 5px rgba(239, 239, 239, 1);
      border-collapse: separate;
      &:nth-child(4) {
        font-size: 16px;
      }
    `;
    const Tr = styled.tr`
      background: white;
      width: 100%;
      height: 64px;
      filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
    `;
    return (
      <ListContentElement>
        <ListContentTable>
          <thead>
            <tr>
              <Th>平均点</Th>
              <Th>受験者数</Th>
              <Th>問題数</Th>
              <Th>平均解答時間</Th>
              <Th>受験日</Th>
              <Th>問題一覧</Th>
            </tr>
          </thead>
          <tbody>
            <Tr>
              <ListRowTd>
                {testContent?.average_score !== null && testContent !== null
                  ? (Math.round(testContent.average_score * 10) / 10).toFixed(1)
                  : "-"}
              </ListRowTd>
              <ListRowTd>
                {testContent?.participant_number !== null
                  ? testContent?.participant_number + "名"
                  : "-"}
              </ListRowTd>
              <ListRowTd>
                {testContent?.question_count !== null
                  ? testContent?.question_count + "問"
                  : "-"}
              </ListRowTd>
              <ListRowTd>
                {testContent?.average_time_ms !== null
                  ? Math.floor(
                      (testContent?.average_time_ms ?? 0) / 1000 / 60
                    ) +
                    "分" +
                    Math.floor(
                      ((testContent?.average_time_ms ?? 0) / 1000) % 60
                    ) +
                    "秒"
                  : "-"}
              </ListRowTd>
              <ListRowTd>
                {dayjs(testContent?.from_date).format("YYYY/MM/DD")}
                <br />～{dayjs(testContent?.to_date).format("YYYY/MM/DD")}
              </ListRowTd>
              <ListRowTd>
                <Link
                  to={
                    "/client/practical_test_results/questions/" +
                    testContent?.id +
                    "/" +
                    testContent?.test_number +
                    "/" +
                    testContent?.academic_level.id
                  }
                >
                  <FontAwesomeIcon icon="info-circle" style={DetailIconStyle} />
                </Link>
              </ListRowTd>
            </Tr>
          </tbody>
        </ListContentTable>
      </ListContentElement>
    );
  }
}

const DetailIconStyle: CSSProperties = {
  color: "rgba(112, 112, 112, 1)",
  fontSize: "48px"
};

export default TestContent;
