import React from "react";
import { WrapBackground } from "../common/WrapBackground";
import { CoachBackground } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import CoachDetailContent from "./CoachDetailContent";
import {
  EditButton,
  StudentEditButton,
  StudentEditButtonArea
} from "./styles/CoachDetailStyle";
import { TopRightArea } from "../common/styles/TopButton";
import { haveCoachEdit } from "../../utils/checkAuthority";
import { Profile } from "../../utils/profile";

type Props = RouteComponentProps<{ id: string }> & Profile;

type State = {};

class CoachDetail extends React.Component<Props, State> {
  render() {
    return (
      <WrapBackground
        color={CoachBackground}
        history={this.props.history}
        profile={this.props.profile}
      >
        {haveCoachEdit(this.props.profile.authority_set!) && (
          <StudentEditButtonArea>
            <StudentEditButton
              onClick={this.onStudentEdit.bind(this)}
              color={CoachBackground}
            >
              担当生徒の編集
            </StudentEditButton>
          </StudentEditButtonArea>
        )}
        <CoachDetailContent
          match={this.props.match}
          history={this.props.history}
          ref={CoachDetailContent.name}
          profile={this.props.profile}
        />
        <TopRightArea>
          {this.props.profile.authority_set &&
            haveCoachEdit(this.props.profile.authority_set) && (
              <EditButton onClick={this.onEdit.bind(this)}>編集</EditButton>
            )}
        </TopRightArea>
      </WrapBackground>
    );
  }
  onEdit() {
    const coachDetailContent: CoachDetailContent = this.refs[
      CoachDetailContent.name
    ] as CoachDetailContent;
    coachDetailContent.onEdit();
  }

  onStudentEdit() {
    const coachDetailContent: CoachDetailContent = this.refs[
      CoachDetailContent.name
    ] as CoachDetailContent;
    coachDetailContent.onStudentEdit();
  }
}

export default CoachDetail;
