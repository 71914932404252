import React from "react";
import { WrapBackground } from "../../../common/WrapBackground";
import {
  TestResultBackground,
  TestResultSidebarBackground
} from "../../../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import StudentTestResultDetailContent from "./StudentTestResultDetailContent";
import { Profile } from "../../../../utils/profile";

type Props = RouteComponentProps<{ id: string }> & Profile;

type State = {};

class StudentTestResultDetail extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { profile: null };
  }

  render() {
    return (
      <WrapBackground
        color={TestResultBackground}
        history={this.props.history}
        profile={this.props.profile}
        selectedSidebar="practical_test_result"
        sidebarColor={TestResultSidebarBackground}
      >
        <StudentTestResultDetailContent
          match={this.props.match}
          history={this.props.history}
        />
      </WrapBackground>
    );
  }
}

export default StudentTestResultDetail;
