import React from "react";
import Modal from "react-modal";
import { CreateButton, DeleteButton } from "../../../common/Colors";
import {
  ModalStyle,
  Title,
  Border,
  Section,
  InputHalfArea,
  InputHalf,
  ButtonArea,
  Button,
  CheckAreaRow,
  CheckContent,
  InputCheckbox,
  CheckLabel,
  MarginTopInput,
  CheckArea
} from "../../../coachList/styles/FilterCoachModalStyle";
import {
  CloseButton,
  CloseButtonImage
} from "../../../modal/styles/UserEditModalStyle";
import { AffiliationDivision } from "../../../../model/entities/AffiliationDivision";
import { getDivisionsRecursively } from "../../../../utils/division";
import { get } from "../../../../model/api/Request";
import { divideArray } from "../../../../utils/array";
import {
  queryAffiliationDivisionKey,
  queryAffiliationDivisionNameKey,
  queryStudentFamilyNameKey,
  queryStudentFirstNameKey,
  queryCoachFamilyNameKey,
  queryCoachFirstNameKey,
  queryFromScoreKey,
  queryToScoreKey,
  queryFromRankKey,
  queryToRankKey,
  queryFromDeviationValueKey,
  queryToDeviationValueKey,
  queryFromSpentTimeMsKey,
  queryToSpentTimeMsKey,
  queryFromDoDateTimeKey,
  queryToDoDateTimeKey
} from "../../../../utils/Constant";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSearch: (query: string) => void;
  query: { [name: string]: string | string[] };
};

type State = {
  affiliationDivisions: AffiliationDivision[];
  selectedDivisionIds: number[];
  affiliationDivisionName: string;
  studentFamilyName: string;
  studentFirstName: string;
  coachFamilyName: string;
  coachFirstName: string;
  fromScore: string;
  toScore: string;
  fromRank: string;
  toRank: string;
  fromDeviationValue: string;
  toDeviationValue: string;
  fromSpentTimeMs: string;
  toSpentTimeMs: string;
  fromDoDatetime: string;
  toDoDatetime: string;
};

class FilterTestResultModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    let affiliationDivisions: number[] = [];
    if (typeof props.query[queryAffiliationDivisionKey] === "string") {
      affiliationDivisions = [+props.query[queryAffiliationDivisionKey]];
    } else if (props.query[queryAffiliationDivisionKey] instanceof Array) {
      affiliationDivisions = (props.query[
        queryAffiliationDivisionKey
      ] as string[]).map(id => +id);
    }

    const affiliationDivisionNameQ =
      props.query[queryAffiliationDivisionNameKey];
    const affiliationDivisionName =
      typeof affiliationDivisionNameQ === "string"
        ? affiliationDivisionNameQ
        : "";

    const studentFamilyNameQ = props.query[queryStudentFamilyNameKey];
    const studentFamilyName =
      typeof studentFamilyNameQ === "string" ? studentFamilyNameQ : "";

    const studentFirstNameQ = props.query[queryStudentFirstNameKey];
    const studentFirstName =
      typeof studentFirstNameQ === "string" ? studentFirstNameQ : "";

    const coachFamilyNameQ = props.query[queryCoachFamilyNameKey];
    const coachFamilyName =
      typeof coachFamilyNameQ === "string" ? coachFamilyNameQ : "";

    const coachFirstNameQ = props.query[queryCoachFirstNameKey];
    const coachFirstName =
      typeof coachFirstNameQ === "string" ? coachFirstNameQ : "";

    const fromScoreQ = props.query[queryFromScoreKey];
    const fromScore = typeof fromScoreQ === "string" ? fromScoreQ : "";

    const toScoreQ = props.query[queryToScoreKey];
    const toScore = typeof toScoreQ === "string" ? toScoreQ : "";

    const fromRankQ = props.query[queryFromRankKey];
    const fromRank = typeof fromRankQ === "string" ? fromRankQ : "";

    const toRankQ = props.query[queryToRankKey];
    const toRank = typeof toRankQ === "string" ? toRankQ : "";

    const fromDeviationValueQ = props.query[queryFromDeviationValueKey];
    const fromDeviationValue =
      typeof fromDeviationValueQ === "string" ? fromDeviationValueQ : "";

    const toDeviationValueQ = props.query[queryToDeviationValueKey];
    const toDeviationValue =
      typeof toDeviationValueQ === "string" ? toDeviationValueQ : "";

    const fromSpentTimeMsQ = props.query[queryFromSpentTimeMsKey];
    const fromSpentTimeMs =
      typeof fromSpentTimeMsQ === "string" ? fromSpentTimeMsQ : "";

    const toSpentTimeMsQ = props.query[queryToSpentTimeMsKey];
    const toSpentTimeMs =
      typeof toSpentTimeMsQ === "string" ? toSpentTimeMsQ : "";

    const fromDoDatetimeQ = props.query[queryFromDoDateTimeKey];
    const fromDoDatetime =
      typeof fromDoDatetimeQ === "string" ? fromDoDatetimeQ : "";

    const toDoDatetimeQ = props.query[queryToDoDateTimeKey];
    const toDoDatetime = typeof toDoDatetimeQ === "string" ? toDoDatetimeQ : "";

    this.state = {
      affiliationDivisions: [],
      selectedDivisionIds: affiliationDivisions,
      affiliationDivisionName: affiliationDivisionName,
      studentFamilyName: studentFamilyName,
      studentFirstName: studentFirstName,
      coachFamilyName: coachFamilyName,
      coachFirstName: coachFirstName,
      fromScore: fromScore,
      toScore: toScore,
      fromRank: fromRank,
      toRank: toRank,
      fromDeviationValue: fromDeviationValue,
      toDeviationValue: toDeviationValue,
      fromSpentTimeMs: fromSpentTimeMs,
      toSpentTimeMs: toSpentTimeMs,
      fromDoDatetime: fromDoDatetime,
      toDoDatetime: toDoDatetime
    };
  }

  componentDidMount() {
    Promise.all([get("/affiliation-divisions")]).then(responses => {
      const divisions = getDivisionsRecursively(responses[0].result);
      this.setState({
        affiliationDivisions: divisions
      });
    });
  }

  render() {
    const divisions = divideArray(this.state.affiliationDivisions, 2).map(
      (divisions: AffiliationDivision[]) => {
        return (
          <CheckAreaRow key={divisions[0].id}>
            {divisions.map(division => {
              return (
                <CheckContent key={division.id}>
                  <InputCheckbox
                    type="checkbox"
                    checked={
                      this.state.selectedDivisionIds.find(
                        id => id === division.id
                      ) !== undefined
                    }
                    onChange={this.onDivisionChecked.bind(this)}
                  />
                  <CheckLabel>{division.name}</CheckLabel>
                </CheckContent>
              );
            })}
          </CheckAreaRow>
        );
      }
    );
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
          <Title>検索・フィルター</Title>
          <Border />
          <Section>
            <Title>所属</Title>
            <CheckArea>
              {divisions}
              <MarginTopInput
                disabled={this.state.selectedDivisionIds.length !== 0}
                placeholder="入力してください"
                value={this.state.affiliationDivisionName}
                onChange={this.onChangeAffiliationDivitionName.bind(this)}
              />
            </CheckArea>
          </Section>
          <Border />
          <Section>
            <Title>生徒氏名</Title>
            <InputHalfArea>
              <InputHalf
                placeholder="姓を入力してください"
                onChange={this.onChangeStudentFamilyName.bind(this)}
                value={this.state.studentFamilyName}
              />

              <InputHalf
                placeholder="名を入力してください"
                onChange={this.onChangeStudentFirsttName.bind(this)}
                value={this.state.studentFirstName}
              />
            </InputHalfArea>
          </Section>
          <Border />
          <Section>
            <Title>担当コーチ</Title>
            <InputHalfArea>
              <InputHalf
                placeholder="姓を入力してください"
                onChange={this.onChangeCoachFamilyName.bind(this)}
                value={this.state.coachFamilyName}
              />

              <InputHalf
                placeholder="名を入力してください"
                onChange={this.onChangeCoachFirsttName.bind(this)}
                value={this.state.coachFirstName}
              />
            </InputHalfArea>
          </Section>
          <Border />
          <Section>
            <Title>得点</Title>
            <InputHalfArea>
              <InputHalf
                type="number"
                onChange={this.onChangeFromScore.bind(this)}
                value={this.state.fromScore}
              />
              ~
              <InputHalf
                type="number"
                onChange={this.onChangeToScore.bind(this)}
                value={this.state.toScore}
              />
            </InputHalfArea>
          </Section>
          <Border />
          {/* ref https://gitlab.com/tfa-lab/frontend/-/merge_requests/264 */}
          {/* <Section>
            <Title>順位</Title>
            <InputHalfArea>
              <InputHalf
                type="number"
                onChange={this.onChangeFromRank.bind(this)}
                value={this.state.fromRank}
              />
              ~
              <InputHalf
                type="number"
                onChange={this.onChangeToRank.bind(this)}
                value={this.state.toRank}
              />
            </InputHalfArea>
          </Section>
          <Border />
          <Section>
            <Title>偏差値</Title>
            <InputHalfArea>
              <InputHalf
                type="number"
                onChange={this.onChangeFromDeviationValue.bind(this)}
                value={this.state.fromDeviationValue}
              />
              ~
              <InputHalf
                type="number"
                onChange={this.onChangeToDeviationValue.bind(this)}
                value={this.state.toDeviationValue}
              />
            </InputHalfArea>
          </Section>
          <Border />
          <Section>
            <Title>解答時間</Title>
            <InputHalfArea>
              <InputHalf
                type="number"
                onChange={this.onChangeFromSpentTimeMs.bind(this)}
                value={this.state.fromSpentTimeMs}
              />
              ~
              <InputHalf
                type="number"
                onChange={this.onChangeToSpentTimeMs.bind(this)}
                value={this.state.toSpentTimeMs}
              />
            </InputHalfArea>
          </Section>
          <Border />

          <Section>
            <Title>受験日</Title>
            <InputHalfArea>
              <InputHalf
                type="date"
                onChange={this.onChangeFromDoDatetime.bind(this)}
                value={this.state.fromDoDatetime}
              />
              ~
              <InputHalf
                type="date"
                onChange={this.onChangeToDoDatetime.bind(this)}
                value={this.state.toDoDatetime}
              />
            </InputHalfArea>
          </Section>
          <Border /> */}
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onSearch.bind(this)}
            >
              検索
            </Button>
            <Button background={DeleteButton} onClick={this.onClear.bind(this)}>
              値をクリア
            </Button>
          </ButtonArea>
        </Modal>
      </>
    );
  }

  onDivisionChecked(e: React.ChangeEvent<HTMLInputElement>) {
    const selectDivision = this.state.affiliationDivisions.filter(division => {
      return division.name === e.target.parentElement?.children[1].innerHTML;
    })[0];
    let selectedDivisions = this.state.selectedDivisionIds;
    if (e.target.checked) {
      selectedDivisions.push(selectDivision.id);
    } else {
      selectedDivisions = selectedDivisions.filter(
        id => id !== selectDivision.id
      );
    }
    this.setState({ selectedDivisionIds: selectedDivisions });
  }

  onChangeAffiliationDivitionName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ affiliationDivisionName: e.target.value });
  }

  onChangeStudentFamilyName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ studentFamilyName: e.target.value });
  }

  onChangeStudentFirsttName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ studentFirstName: e.target.value });
  }

  onChangeCoachFamilyName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ coachFamilyName: e.target.value });
  }

  onChangeCoachFirsttName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ coachFirstName: e.target.value });
  }

  onChangeFromScore(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ fromScore: e.target.value });
  }

  onChangeToScore(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toScore: e.target.value });
  }

  onChangeFromRank(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ fromRank: e.target.value });
  }

  onChangeToRank(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toRank: e.target.value });
  }

  onChangeFromDeviationValue(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ fromDeviationValue: e.target.value });
  }

  onChangeToDeviationValue(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toDeviationValue: e.target.value });
  }

  onChangeFromSpentTimeMs(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ fromSpentTimeMs: e.target.value });
  }

  onChangeToSpentTimeMs(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toSpentTimeMs: e.target.value });
  }

  onChangeFromDoDatetime(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ fromDoDatetime: e.target.value });
  }

  onChangeToDoDatetime(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toDoDatetime: e.target.value });
  }

  onClear() {
    this.setState({
      affiliationDivisionName: "",
      studentFamilyName: "",
      studentFirstName: "",
      coachFamilyName: "",
      coachFirstName: "",
      fromScore: "",
      toScore: "",
      fromRank: "",
      toRank: "",
      fromDeviationValue: "",
      toDeviationValue: "",
      fromSpentTimeMs: "",
      toSpentTimeMs: "",
      fromDoDatetime: "",
      toDoDatetime: ""
    });
  }

  onSearch() {
    let query: string[] = [];

    const divisions = this.state.selectedDivisionIds
      .map(id => {
        return `${queryAffiliationDivisionKey}=${id}`;
      })
      .join("&");
    if (divisions !== "") {
      query.push(divisions);
    } else if (this.state.affiliationDivisionName !== "") {
      query.push(
        `${queryAffiliationDivisionNameKey}=${this.state.affiliationDivisionName}`
      );
    }
    if (this.state.studentFamilyName !== "") {
      query.push("student_family_name=" + this.state.studentFamilyName);
    }
    if (this.state.studentFirstName !== "") {
      query.push("student_first_name=" + this.state.studentFirstName);
    }
    if (this.state.coachFamilyName !== "") {
      query.push("coach_family_name=" + this.state.coachFamilyName);
    }
    if (this.state.coachFirstName !== "") {
      query.push("coach_first_name=" + this.state.coachFirstName);
    }
    if (this.state.fromScore !== "") {
      query.push("from_score=" + this.state.fromScore);
    }
    if (this.state.toScore !== "") {
      query.push("to_score=" + this.state.toScore);
    }
    if (this.state.fromRank !== "") {
      query.push("from_rank=" + this.state.fromRank);
    }
    if (this.state.toRank !== "") {
      query.push("to_rank=" + this.state.toRank);
    }
    if (this.state.fromDeviationValue !== "") {
      query.push("from_deviation_value=" + this.state.fromDeviationValue);
    }
    if (this.state.toDeviationValue !== "") {
      query.push("to_deviation_value=" + this.state.toDeviationValue);
    }
    if (this.state.fromSpentTimeMs !== "") {
      query.push("from_spent_time_ms=" + this.state.fromSpentTimeMs);
    }
    if (this.state.toSpentTimeMs !== "") {
      query.push("to_spent_time_ms=" + this.state.toSpentTimeMs);
    }
    if (this.state.fromDoDatetime !== "") {
      query.push("from_do_datetime=" + this.state.fromDoDatetime);
    }
    if (this.state.toDoDatetime !== "") {
      query.push("to_do_datetime=" + this.state.toDoDatetime);
    }
    this.props.onSearch(query.join("&"));
  }

  onClose() {
    this.props.onClose();
  }
}

export default FilterTestResultModal;
