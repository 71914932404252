import React from "react";
import WrapLoginArea from "../login/styles/WrapLoginArea";
import LoginArea from "../login/styles/LoginArea";
import LoginInput from "../login/styles/LoginInput";
import LoginButton from "../login/styles/LoginButton";
import Logo from "../login/styles/Logo";
import { LoginButtonColor } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { put } from "../../model/api/Request";
import LoadingOverlay from "../common/LoadingOverlay";
import { commonErrorHandle } from "../../utils/errorHandle";
import { CustomError } from "../../model/api/CustomError";
import {
  isEmail,
  isExistSpace,
  isExistFullWidthCharacter
} from "../../utils/validation";
import { Profile } from "../../utils/profile";
import ProfileArea from "../common/ProfileArea";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  isLoading: boolean;
  loginId: string;
  email: string;
};

class AccountEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      loginId: props.profile.login_id,
      email: props.profile.email
    };
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapLoginArea>
          <Logo />
          <LoginArea color="white">
            <LoginInput
              type="text"
              placeholder="ID"
              index={0}
              value={this.state.loginId}
              onChange={this.onChangeId.bind(this)}
            />
            <LoginInput
              type="text"
              placeholder="Email"
              index={1}
              value={this.state.email}
              onChange={this.onChangeEmail.bind(this)}
            />
            <LoginButton
              color={LoginButtonColor}
              onClick={this.onEditButton.bind(this)}
            >
              変更
            </LoginButton>
          </LoginArea>
        </WrapLoginArea>
        <ProfileArea
          history={this.props.history}
          profile={this.props.profile}
        />
      </>
    );
  }

  onChangeId(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ loginId: e.target.value });
  }

  onChangeEmail(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ email: e.target.value });
  }

  onEditButton() {
    const params: { [index: string]: string } = {};
    if (this.state.email !== this.props.profile?.email) {
      params["email"] = this.state.email;
    }
    if (this.state.loginId !== this.props.profile?.login_id) {
      params["login_id"] = this.state.loginId;
    }
    if (!isEmail(this.state.email)) {
      alert("メールアドレスの形式が不正です。");
      return;
    }
    if (JSON.stringify(params) === "{}") {
      alert("値を変更してください。");
      return;
    }
    if (this.state.loginId === "") {
      alert("ログインIDを入力してください。");
      return;
    }
    if (isExistSpace(this.state.loginId)) {
      alert("ログインIDにスペースを含むことはできません。");
      return;
    }
    if (isExistFullWidthCharacter(this.state.loginId)) {
      alert("ログインIDに全角文字を使用することはできません。");
      return;
    }
    this.setState({ isLoading: true });
    put("/profile", { user_attributes: params })
      .then(res => {
        this.setState({ isLoading: false });
        alert(
          "変更が完了しました。メールアドレスの変更を行なった場合は認証後に変更が完了となります"
        );
        this.props.history.goBack();
      })
      .catch(error => {
        if (error instanceof CustomError) {
          const customError = error as CustomError;
          if (customError.code === "UserAlreadyExistsError") {
            this.setState({ isLoading: false });
            alert("既に登録されているログインIDです");
            return;
          }
        }
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default AccountEdit;
