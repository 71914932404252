import React from "react";
import { get } from "../../model/api/Request";
import { match, Link } from "react-router-dom";
import { User } from "../../model/entities/User";
import {
  Title,
  Body,
  BodyName,
  LevelTitle,
  Level,
  BackButton,
  StudentListTitle
} from "./styles/CoachDetailContentStyle";
import StudentListContent from "./StudentListContent";
import StudentEditModal from "./StudentEditModal";
import * as H from "history";
import UserEditModal, { UserType } from "../modal/UserEditModal";
import LoadingOverlay from "../common/LoadingOverlay";
import { commonErrorHandle } from "../../utils/errorHandle";
import { haveCoachtDelete } from "../../utils/checkAuthority";

type Props = {
  match: match<{ id: string }>;
  history: H.History;
  profile: User;
};

type State = {
  coach: User | null;
  students: User[];
  isEditModalOpen: boolean;
  isStudentEditModalOpen: boolean;
  isLoading: boolean;
};

class CoachDetailContent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      coach: null,
      students: [],
      isEditModalOpen: false,
      isStudentEditModalOpen: false,
      isLoading: false
    };
  }

  componentDidMount() {
    this.getUser();
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <Title>管理者詳細</Title>
        <Body isTitle={true} index={0}>
          ID
        </Body>
        <Body isTitle={false} index={0}>
          {this.state.coach?.login_id}
        </Body>
        <Body isTitle={true} index={1}>
          所属
        </Body>
        <Body isTitle={false} index={1}>
          {this.state.coach?.affiliation_division.name}
        </Body>
        <Body isTitle={true} index={3}>
          氏名
        </Body>
        <BodyName isTitle={false} index={3}>
          {(this.state.coach?.family_name_ja || "") +
            " " +
            (this.state.coach?.first_name_ja || "")}
        </BodyName>
        <LevelTitle>権限レベル</LevelTitle>
        <Level>{this.state.coach?.authority_set?.name}</Level>
        <StudentListTitle>担当生徒一覧</StudentListTitle>
        <StudentListContent
          match={this.props.match}
          user={this.state.coach}
          history={this.props.history}
          profile={this.props.profile}
        />
        <Link to="/client/coaches">
          <BackButton>管理者一覧へ</BackButton>
        </Link>
        {this.state.coach !== null && (
          <UserEditModal
            history={this.props.history}
            user={this.state.coach}
            userType={UserType.coach}
            isOpen={this.state.isEditModalOpen}
            onClose={this.onEditModalClose.bind(this)}
            canDelete={
              (this.props.profile.authority_set &&
                haveCoachtDelete(this.props.profile.authority_set)) ??
              false
            }
          ></UserEditModal>
        )}
        {this.state.coach !== null && this.state.isStudentEditModalOpen && (
          <StudentEditModal
            coach={this.state.coach}
            students={this.state.students}
            match={this.props.match}
            history={this.props.history}
            isOpen={this.state.isStudentEditModalOpen}
            onClose={this.onStudentEditModalClose.bind(this)}
          ></StudentEditModal>
        )}
      </>
    );
  }
  onEdit() {
    this.setState({ isEditModalOpen: true });
  }

  onStudentEdit() {
    this.setState({ isStudentEditModalOpen: true });
  }

  onEditModalClose() {
    this.getUser();
    this.setState({ isEditModalOpen: false });
  }

  onStudentEditModalClose() {
    this.getUser();
    this.setState({ isStudentEditModalOpen: false });
  }

  getUser() {
    this.setState({ isLoading: true });
    const id = this.props.match.params.id;
    get("/coaches/" + id)
      .then(res => {
        const coach: User = res.result;
        // cf https://gitlab.com/tfa-lab/backend/-/wikis/%E3%82%BD%E3%83%BC%E3%83%88%E3%83%BB%E6%A4%9C%E7%B4%A2%E3%83%BB%E3%83%95%E3%82%A3%E3%83%AB%E3%82%BF%E6%A9%9F%E8%83%BD
        get(
          "/students?affiliation_division_id=" + coach.affiliation_division.id
        ).then(res => {
          this.setState({
            isLoading: false,
            coach: coach,
            students: res.result
          });
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default CoachDetailContent;
