import React from "react";
import Modal from "react-modal";
import {
  ModalStyle,
  TitleLabel,
  Input,
  InputNameElement,
  ButtonArea,
  Button,
  CloseButton,
  CloseButtonImage,
  InputNameArea,
  SelectElementDiv,
  SelectElement,
  SelectElementArea,
  DisablableButton
} from "./styles/UserEditModalStyle";
import { User } from "../../model/entities/User";
import { get, put, deleteRequest } from "../../model/api/Request";
import { AffiliationDivision } from "../../model/entities/AffiliationDivision";
import { AuthoritySet } from "../../model/entities/AuthoritySet";
import { AffiliationRole } from "../../model/entities/AffiliationRole";
import { BlackButton, CreateButton, DeleteButton } from "../common/Colors";
import LoadingOverlay from "../common/LoadingOverlay";
import { isKatakana } from "../../utils/validation";
import * as H from "history";
import { commonErrorHandle } from "../../utils/errorHandle";

type Props = {
  history: H.History;
  user: User;
  userType: UserType;
  isOpen: boolean;
  onClose: () => void;
  canDelete: boolean;
};

type State = {
  isLoading: boolean;
  firstName: string;
  familyName: string;
  firstNameFurigana: string;
  familyNameFurigana: string;
  isSelectDivisionMode: boolean;
  selectableDivisions: AffiliationDivision[];
  selectedDivision: AffiliationDivision | null;
  isSelectAuthoritySetMode: boolean;
  selectableAuthoritySets: AuthoritySet[];
  selectedAuthoritySet: AuthoritySet | null;
  isSelectRoleMode: boolean;
  selectableRoles: AffiliationRole[];
  selectedRole: AffiliationRole | null;
};

export enum UserType {
  student = "students",
  coach = "coaches"
}

class UserEditModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      firstName: this.props.user.first_name_ja,
      familyName: this.props.user.family_name_ja,
      firstNameFurigana: this.props.user.first_name_furigana,
      familyNameFurigana: this.props.user.family_name_furigana,
      isSelectDivisionMode: false,
      selectableDivisions: [],
      selectedDivision: this.props.user.affiliation_division,
      selectableAuthoritySets: [],
      isSelectAuthoritySetMode: false,
      selectedAuthoritySet: this.props.user.authority_set,
      selectableRoles: [],
      isSelectRoleMode: false,
      selectedRole: this.props.user.affiliation_role
    };
  }

  render() {
    if (this.state.isSelectDivisionMode) {
      return this.getSelectDivisionModal();
    }
    if (this.state.isSelectAuthoritySetMode) {
      return this.getSelectAuthoritySetModal();
    }
    if (this.state.isSelectRoleMode) {
      return this.getSelectRoleModal();
    }
    return this.getInputModal();
  }

  getInputModal() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <TitleLabel>編集</TitleLabel>
          <InputNameArea>
            <InputNameElement
              placeholder="姓"
              value={this.state.familyName}
              onChange={this.onChangeFamilyName.bind(this)}
            />
            <InputNameElement
              placeholder="名"
              value={this.state.firstName}
              onChange={this.onChangeFirstNameJa.bind(this)}
            />
          </InputNameArea>
          <InputNameArea>
            <InputNameElement
              placeholder="姓(フリガナ)"
              value={this.state.familyNameFurigana}
              onChange={this.onChangeFamilyNameEn.bind(this)}
            />
            <InputNameElement
              placeholder="名(フリガナ)"
              value={this.state.firstNameFurigana}
              onChange={this.onChangeFirstNameFurigana.bind(this)}
            />
          </InputNameArea>
          <Input
            readOnly={true}
            placeholder="所属"
            value={this.state.selectedDivision?.name}
            onClick={this.onAffiliationClick.bind(this)}
          />
          {this.props.userType === UserType.coach && (
            <Input
              readOnly={true}
              placeholder="権限"
              value={this.state.selectedAuthoritySet?.name}
              onClick={this.onAuthorityClick.bind(this)}
            />
          )}
          {this.props.userType === UserType.student && (
            <Input
              readOnly={true}
              placeholder="役割"
              value={this.state.selectedRole?.name}
              onClick={this.onRoleClick.bind(this)}
            />
          )}
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onUpdate.bind(this)}
            >
              更新
            </Button>
            {this.props.canDelete && (
              <Button
                background={DeleteButton}
                onClick={this.onDelete.bind(this)}
              >
                削除
              </Button>
            )}
          </ButtonArea>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
        </Modal>
        <LoadingOverlay isLoading={this.state.isLoading} />
      </>
    );
  }

  getSelectDivisionModal() {
    const enableNextButton =
      this.state.selectedDivision != null &&
      this.state.selectedDivision.child_affiliation_divisions != null &&
      this.state.selectedDivision.child_affiliation_divisions.length !== 0;
    const divisions = this.state.selectableDivisions.map(division => {
      return (
        <SelectElementDiv
          onClick={this.onClickDivisionElement.bind(this)}
          key={division.id}
        >
          <SelectElement
            isSelected={division.id === this.state.selectedDivision?.id}
          >
            {division.name}
          </SelectElement>
        </SelectElementDiv>
      );
    });
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <TitleLabel>所属の選択</TitleLabel>
        <SelectElementArea scrollable={true}>{divisions}</SelectElementArea>
        <ButtonArea>
          <Button
            background={BlackButton}
            onClick={this.onAffiliationDivisionBack.bind(this)}
          >
            戻る
          </Button>
          <DisablableButton
            background={CreateButton}
            isActive={this.state.selectedDivision != null}
            onClick={this.onSetAffiliationDivision.bind(this)}
            disabled={this.state.selectedDivision == null}
          >
            決定して終了
          </DisablableButton>
          <DisablableButton
            background={CreateButton}
            isActive={enableNextButton}
            disabled={!enableNextButton}
            onClick={this.onNextAffiliationDivision.bind(this)}
          >
            決定して次へ
          </DisablableButton>
        </ButtonArea>
      </Modal>
    );
  }

  getSelectAuthoritySetModal() {
    const authoritySets = this.state.selectableAuthoritySets.map(authority => {
      return (
        <SelectElementDiv
          onClick={this.onClickAuthorityElement.bind(this)}
          key={authority.id}
        >
          <SelectElement isSelected={false}>{authority.name}</SelectElement>
        </SelectElementDiv>
      );
    });
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <TitleLabel>権限の選択</TitleLabel>
        <SelectElementArea scrollable={true}>{authoritySets}</SelectElementArea>
        <ButtonArea>
          <Button
            background={BlackButton}
            onClick={this.onAuthorityBack.bind(this)}
          >
            戻る
          </Button>
        </ButtonArea>
      </Modal>
    );
  }

  getSelectRoleModal() {
    const roles = this.state.selectableRoles.map(role => {
      return (
        <SelectElementDiv
          onClick={this.onClickRoleElement.bind(this)}
          key={role.id}
        >
          <SelectElement isSelected={false}>{role.name}</SelectElement>
        </SelectElementDiv>
      );
    });
    return (
      <Modal isOpen={this.props.isOpen} style={ModalStyle}>
        <TitleLabel>役割の選択</TitleLabel>
        <SelectElementArea scrollable={true}>{roles}</SelectElementArea>
        <ButtonArea>
          <Button background={BlackButton} onClick={this.onRoleBack.bind(this)}>
            戻る
          </Button>
        </ButtonArea>
      </Modal>
    );
  }

  onUpdate() {
    const errorMessages: string[] = [];
    if (this.state.familyName === "" || this.state.firstName === "") {
      errorMessages.push("・ 氏名をを入力してください");
    }
    if (
      this.state.familyNameFurigana === "" ||
      this.state.firstNameFurigana === ""
    ) {
      errorMessages.push("・ フリガナを入力してください");
    } else if (
      !isKatakana(this.state.familyNameFurigana) ||
      !isKatakana(this.state.firstNameFurigana)
    ) {
      errorMessages.push("・ フリガナはカタカナで入力してください");
    }

    if (errorMessages.length !== 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    const params: { [index: string]: any } = {
      user: {
        first_name_ja: this.state.firstName,
        family_name_ja: this.state.familyName,
        first_name_furigana: this.state.firstNameFurigana,
        family_name_furigana: this.state.familyNameFurigana,
        affiliation_division_id: this.state.selectedDivision!.id
      }
    };
    if (this.props.userType === UserType.coach) {
      params["user"]["authority_set_id"] = this.state.selectedAuthoritySet!.id;
    }
    if (this.props.userType === UserType.student) {
      params["user"]["affiliation_role_id"] = this.state.selectedRole!.id;
    }
    this.setState({ isLoading: true });
    put("/" + this.props.userType + "/" + this.props.user.id, params)
      .then(res => {
        this.setState({ isLoading: false });
        this.props.onClose();
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onDelete() {
    const result = window.confirm(
      "本当に削除してもよろしいでしょうか？この作業は復元できません"
    );
    if (result) {
      this.setState({ isLoading: true });
      deleteRequest("/" + this.props.userType + "/" + this.props.user.id)
        .then(res => {
          this.setState({ isLoading: false });
          this.props.history.push("/client/" + this.props.userType);
        })
        .catch(error => {
          commonErrorHandle(error, this.props.history);
        });
    }
  }

  onAffiliationDivisionBack() {
    this.setState({
      selectedDivision: this.props.user.affiliation_division,
      isSelectDivisionMode: false
    });
  }

  onAffiliationClick() {
    this.setState({ isLoading: true });
    get("/affiliation-divisions")
      .then(res => {
        this.setState({ isLoading: false });
        const division: AffiliationDivision = res.result;
        this.setState({
          selectableDivisions: [division],
          selectedDivision: null,
          isSelectDivisionMode: true
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onAuthorityBack() {
    this.setState({
      selectedAuthoritySet: this.props.user.authority_set,
      isSelectAuthoritySetMode: false
    });
  }

  onAuthorityClick() {
    this.setState({ isLoading: true });
    get("/authority-sets")
      .then(res => {
        this.setState({ isLoading: false });
        const authorities: AuthoritySet[] = res.result;
        this.setState({
          selectableAuthoritySets: authorities,
          selectedAuthoritySet: null,
          isSelectAuthoritySetMode: true
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onRoleBack() {
    this.setState({
      selectedRole: this.props.user.affiliation_role,
      isSelectRoleMode: false
    });
  }

  onRoleClick() {
    this.setState({ isLoading: true });
    get("/affiliation-roles")
      .then(res => {
        this.setState({ isLoading: false });
        const roles: AffiliationRole[] = res.result;
        this.setState({
          selectableRoles: roles,
          selectedRole: null,
          isSelectRoleMode: true
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onClickDivisionElement(e: React.MouseEvent<HTMLDivElement>) {
    const division = this.state.selectableDivisions.filter(division => {
      return division.name === e.currentTarget.innerText;
    })[0];
    this.setState({ selectedDivision: division });
  }

  onClickAuthorityElement(e: React.MouseEvent<HTMLDivElement>) {
    const authority = this.state.selectableAuthoritySets.filter(authority => {
      return authority.name === e.currentTarget.innerText;
    })[0];
    this.setState({
      selectedAuthoritySet: authority,
      isSelectAuthoritySetMode: false
    });
  }

  onClickRoleElement(e: React.MouseEvent<HTMLDivElement>) {
    const role = this.state.selectableRoles.filter(role => {
      return role.name === e.currentTarget.innerText;
    })[0];
    this.setState({
      selectedRole: role,
      isSelectRoleMode: false
    });
  }

  onNextAffiliationDivision() {
    this.setState({
      selectableDivisions:
        this.state.selectedDivision?.child_affiliation_divisions || [],
      selectedDivision: null
    });
  }

  onSetAffiliationDivision() {
    this.setState({
      isSelectDivisionMode: false
    });
  }

  onChangeFirstNameJa(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ firstName: e.target.value });
  }

  onChangeFamilyName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ familyName: e.target.value });
  }

  onChangeFirstNameFurigana(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ firstNameFurigana: e.target.value });
  }

  onChangeFamilyNameEn(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ familyNameFurigana: e.target.value });
  }

  onClose() {
    this.setState({
      selectedDivision: this.props.user.affiliation_division,
      isSelectDivisionMode: false,
      selectedAuthoritySet: this.props.user.authority_set,
      isSelectAuthoritySetMode: false,
      selectedRole: this.props.user.affiliation_role,
      isSelectRoleMode: false
    });
    this.props.onClose();
  }
}

export default UserEditModal;
