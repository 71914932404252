import React from "react";
import { ListContentTable } from "../../common/styles/ListContentStyle";
import { Th, EditButton, ListContentElement } from "./styles/styles";
import { User } from "../../../model/entities/User";
import ExternalEvaluationContainerRow from "./ExternalEvaluationContainerRow";
import * as H from "history";
import { ExternalEval } from "../../../model/entities/ExternalEval";
import { get } from "../../../model/api/Request";
import CreateModal from "../../modal/studentExternalEval/StudentExternalEvalCreateModal";
import { haveStudentEdit } from "../../../utils/checkAuthority";

type Props = {
  user: User;
  history: H.History;
  profile: User;
};

type State = {
  externalEvals: ExternalEval[];
  isCreateModalOpen: boolean;
};

class ExternalEvaluationContainer extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      externalEvals: [],
      isCreateModalOpen: false
    };
  }

  componentDidMount() {
    this.getExternalEvals();
  }

  render() {
    const externalEvaluations = this.state.externalEvals.map(
      (external_eval: ExternalEval) => (
        <ExternalEvaluationContainerRow
          key={external_eval.id}
          user={this.props.user}
          external_eval={external_eval}
          history={this.props.history}
          completeEdit={this.getExternalEvals.bind(this)}
          profile={this.props.profile}
        />
      )
    );
    return (
      <>
        <EditButton
          top="46%"
          onClick={this.onCreateModalOpen.bind(this)}
          disabled={!haveStudentEdit(this.props.profile.authority_set!)}
        >
          取得資格の登録
        </EditButton>
        <ListContentElement top="52%" bottom="12%">
          <ListContentTable>
            <thead>
              <tr>
                <Th>ジャンル</Th>
                <Th>スコア</Th>
                <Th>対応レベル</Th>
                <Th>取得日</Th>
                <Th>編集</Th>
              </tr>
            </thead>
            <tbody>{externalEvaluations}</tbody>
          </ListContentTable>
        </ListContentElement>
        <CreateModal
          history={this.props.history}
          user={this.props.user}
          isOpen={this.state.isCreateModalOpen}
          onClose={this.onCreateModalClose.bind(this)}
        />
      </>
    );
  }

  onCreateModalClose() {
    this.getExternalEvals();
    this.setState({
      isCreateModalOpen: false
    });
  }

  onCreateModalOpen() {
    this.setState({
      isCreateModalOpen: true
    });
  }

  getExternalEvals() {
    get("/students/" + this.props.user.id + "/external-eval-results").then(
      res => {
        const externalEvals: ExternalEval[] = res.result;
        this.setState({
          externalEvals: externalEvals
        });
      }
    );
  }
}

export default ExternalEvaluationContainer;
