import React from "react";
import { User } from "../../../model/entities/User";
import Modal from "react-modal";
import {
  ModalStyle,
  CloseButton,
  CloseButtonImage,
  TitleLabel,
  Input,
  Button,
  ButtonArea
} from "../styles/UserEditModalStyle";
import { TargetType } from "../../../model/entities/TargetType";
import { get, post } from "../../../model/api/Request";
import dayjs from "dayjs";
import { AcademicLevel } from "../../../model/entities/AcademicLevel";
import { selectAcademocLevelModal } from "./StudentTargetComponentModal";
import SelectTargetTypeModal from "./SelectTargetTypeModal";
import { commonErrorHandle } from "../../../utils/errorHandle";
import * as H from "history";
import { CreateButton } from "../../common/Colors";
import LoadingOverlay from "../../common/LoadingOverlay";

type Props = {
  history: H.History;
  user: User;
  isOpen: boolean;
  onClose: () => void;
};

type State = {
  targetTypes: TargetType[];
  academicLevels: AcademicLevel[];
  isSelectTargetMode: boolean;
  isSelectAcademicLevelMode: boolean;
  selectedTargetType: TargetType | null;
  targetName: string;
  targetNameElement: string; //targetTypeの名前を連結さすための状態
  selectedAcademicLevel: AcademicLevel | null;
  toDate: string;
  isLoading: boolean;
};

class StudentTargetCreateModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      targetTypes: [],
      academicLevels: [],
      isSelectTargetMode: false,
      isSelectAcademicLevelMode: false,
      selectedTargetType: null,
      targetName: "",
      targetNameElement: "",
      selectedAcademicLevel: null,
      toDate: "",
      isLoading: false
    };
  }

  render() {
    if (this.state.isSelectTargetMode) {
      return (
        <SelectTargetTypeModal
          targetTypes={this.state.targetTypes}
          onSelectTargetType={this.onSelectTargetTypeElement.bind(this)}
          addTargetElement={this.addTargetElement.bind(this)}
          onClose={this.onClose.bind(this)}
          isOpen={this.props.isOpen}
          onBack={this.onBack.bind(this)}
        />
      );
    } else if (this.state.isSelectAcademicLevelMode) {
      return selectAcademocLevelModal(
        this.state.academicLevels,
        this.onSelectAcademicLevelElement.bind(this),
        this.onClose.bind(this),
        this.props.isOpen,
        this.onBack.bind(this)
      );
    }
    return this.makeModal();
  }

  makeModal() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <TitleLabel>目標の登録</TitleLabel>
          <Input
            readOnly={true}
            placeholder="目標の種類"
            value={this.state.selectedTargetType?.name || ""}
            onClick={this.onSelectTargetType.bind(this)}
          />
          <Input
            placeholder="目標名"
            disabled={this.state.selectedTargetType?.academic_level != null}
            value={this.state.targetName}
            onChange={this.onChangeTargetName.bind(this)}
          />
          <Input
            readOnly={true}
            disabled={this.state.selectedTargetType?.academic_level != null}
            placeholder="レベル"
            value={this.state.selectedAcademicLevel?.name || ""}
            onClick={this.onSelectAcademicLevel.bind(this)}
          />
          <Input
            type="date"
            min={dayjs().format("YYYY-MM-DD")}
            placeholder="実施日"
            value={this.state.toDate}
            onChange={this.onChangeToDate.bind(this)}
          />
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onCreate.bind(this)}
            >
              登録
            </Button>
          </ButtonArea>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
        </Modal>
        <LoadingOverlay isLoading={this.state.isLoading} />
      </>
    );
  }

  onCreate() {
    const errorMessages: string[] = [];
    if (this.state.selectedTargetType == null) {
      errorMessages.push("・ 目標の種類を入力してください");
    }
    if (this.state.targetName === "") {
      errorMessages.push("・ 目標名を入力してください");
    }
    if (this.state.selectedAcademicLevel == null) {
      errorMessages.push("・ レベルを入力してください");
    }
    if (this.state.toDate === "") {
      errorMessages.push("・ 実施日を入力してください");
    }
    if (
      this.state.toDate !== "" &&
      this.state.toDate.replace(/-/g, "") <
        dayjs().format("YYYY-MM-DD").replace(/-/g, "")
    ) {
      errorMessages.push("・ 実施日は未来の日付にしてください");
    }
    if (errorMessages.length !== 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    const body: { [index: string]: any } = {
      name: this.state.targetName,
      target_type_id: this.state.selectedTargetType!.id,
      academic_level_id: this.state.selectedAcademicLevel!.id,
      do_date: dayjs(Date.parse(this.state.toDate)).toISOString(),
      comment: ""
    };
    this.setState({ isLoading: true });
    post("/students/" + this.props.user.id + "/targets", body)
      .then(res => {
        this.onClose();
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onChangeToDate(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toDate: e.target.value });
  }

  onChangeTargetName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ targetName: e.target.value });
  }

  onSelectAcademicLevelElement(e: React.MouseEvent<HTMLDivElement>) {
    const academiLevel = this.state.academicLevels.filter(academiLevel => {
      return academiLevel.name === e.currentTarget.innerText;
    })[0];
    this.setState({
      selectedAcademicLevel: academiLevel,
      isSelectAcademicLevelMode: false
    });
  }

  onSelectAcademicLevel() {
    if (this.state.academicLevels.length !== 0) {
      this.setState({ isSelectAcademicLevelMode: true });
      return;
    }
    this.setState({ isLoading: true });
    get("/academic-levels")
      .then(res => {
        const academicLevels: AcademicLevel[] = res.result;
        this.setState({
          academicLevels: academicLevels,
          isSelectAcademicLevelMode: true,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onSelectTargetTypeElement(targetType: TargetType) {
    if (targetType.academic_level != null) {
      this.setState({
        selectedAcademicLevel: targetType.academic_level,
        selectedTargetType: targetType,
        targetName:
          this.state.targetNameElement === ""
            ? targetType.name
            : this.state.targetNameElement + " " + targetType.name,
        isSelectTargetMode: false
      });
    }
    //その他のとき
    else {
      this.setState({
        selectedTargetType: targetType,
        targetName: "",
        selectedAcademicLevel: null,
        isSelectTargetMode: false
      });
    }
  }

  addTargetElement(targetType: TargetType) {
    this.setState({
      targetNameElement:
        this.state.targetNameElement === ""
          ? targetType.name
          : this.state.targetNameElement + " " + targetType.name
    });
  }

  onSelectTargetType() {
    if (this.state.targetTypes.length !== 0) {
      this.setState({
        targetNameElement: "",
        isSelectTargetMode: true
      });
      return;
    }
    this.setState({ isLoading: true });
    get("/target-types")
      .then(res => {
        const targetTypes: TargetType[] = res.result;
        this.setState({
          targetTypes: targetTypes,
          isSelectTargetMode: true,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  clear() {
    this.setState({
      isSelectTargetMode: false,
      isSelectAcademicLevelMode: false,
      selectedTargetType: null,
      targetName: "",
      targetNameElement: "",
      selectedAcademicLevel: null,
      toDate: ""
    });
  }

  onBack() {
    this.setState({
      isSelectTargetMode: false,
      isSelectAcademicLevelMode: false
    });
  }

  onClose() {
    this.setState({ isLoading: false });
    this.clear();
    this.props.onClose();
  }
}

export default StudentTargetCreateModal;
