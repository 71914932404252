import styled from "styled-components";

const WrapLoginArea = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  pointer-events: none;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default WrapLoginArea;
