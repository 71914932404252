import styled from "styled-components";
import { SubStringColor, PagingBorder } from "./../Colors";

export const Element = styled.div``;

export const InequalitySign = styled.button`
  color: ${SubStringColor};
  background: white;
  margin: 1px;
  padding: 2px 6px;
  font-size: 12px;
  border: 1px solid ${PagingBorder};
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;
export const Page = styled.span`
  background: white;
  margin: 1px;
  padding: 6px 14px;
  font-size: 10px;
  height: 32px;
  border: 1px solid ${PagingBorder};
`;
export const MaxPage = styled.span`
  padding: 4px 12px;
  font-size: 10px;
`;

export const NumberElement = styled.span`
  margin-right: 8px;
`;
