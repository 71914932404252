import styled from "styled-components";
import { Space } from "../../../../styles";

export const Level = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: ${Space * 0.5}px ${Space * 2}px;
  background-color: #f2f2f2;
  border-radius: 12px;
  border: 3px solid #d96864;
  white-space: nowrap;
`;
