import React from "react";
import {
  Container,
  LeftContainer,
  RightContainer,
  LeftContainerTop,
  TestResultIcon,
  LeftContainerTitle,
  LeftContainerBorder,
  ChartContainer,
  UserInformationContainer,
  RightScoreContainer,
  RightQuestionContainer,
  RightQuestionTableContainer,
  RightQuestionContentContainer
} from "./styles/TestResultDetailContentStyle";
import TestContent from "./TestContent";
import Chart from "./inner/Chart";
import TagTable from "./inner/TagTable";
import RightQuestionTable from "./inner/RightQuestionTable";
import RightQuestionContent from "./inner/RightQuestionContent";
import { TestResult } from "../../../../model/entities/TestResult";
import { QuestionResult } from "../../../../model/entities/Test";
import { User } from "../../../../model/entities/User";
import { get } from "../../../../model/api/Request";
import { match } from "react-router-dom";
import * as H from "history";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { commonErrorHandle } from "../../../../utils/errorHandle";

type Props = {
  match: match<{ id: string }>;
  history: H.History;
};

type State = {
  test: TestResult | null;
  user: User | null;
  selectQuestion: QuestionResult | null;
};

class StudentTestResultDetailContent extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      test: null,
      user: null,
      selectQuestion: null
    };
  }

  componentDidMount() {
    const testId = this.props.match.params.id;
    get("/student/practical-test-results/" + testId)
      .then(res => {
        const test: TestResult = res.result;
        const user: User = res.result.result.student;
        this.setState({
          test: test,
          user: user
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  render() {
    if (!this.state.test) {
      return (
        <>
          <LoadingOverlay isLoading={true} />
        </>
      );
    }
    return (
      <>
        <Container>
          <LeftContainer>
            <LeftContainerTop>
              <TestResultIcon src="/assets/TestResultIcon.png" />
              <LeftContainerTitle>{this.state.test?.name}</LeftContainerTitle>
            </LeftContainerTop>
            <LeftContainerBorder />
            <ChartContainer>
              {this.state.test !== null && <Chart test={this.state.test} />}
            </ChartContainer>
          </LeftContainer>
          <RightContainer>
            <UserInformationContainer>
              氏名:
              <span>
                {this.state.user!.family_name_ja +
                  " " +
                  this.state.user!.first_name_ja}
              </span>
              <br />
              担当コーチ:
              {this.state.user?.student?.coach !== null ? (
                <span>
                  {this.state.user?.student!.coach!.family_name_ja +
                    " " +
                    this.state.user?.student!.coach!.first_name_ja}
                </span>
              ) : (
                <span>-</span>
              )}
            </UserInformationContainer>
            <RightScoreContainer>
              得点：{this.state.test.result.score}
              <br />
              解答時間：
              {Math.floor(
                (this.state.test.result.spent_time_ms ?? 0) / 1000 / 60
              )}
              分
              {Math.floor(
                ((this.state.test.result.spent_time_ms ?? 0) / 1000) % 60
              )}
              秒
            </RightScoreContainer>
            {this.state.test !== null && <TagTable test={this.state.test} />}
            <RightQuestionContainer>
              <RightQuestionTableContainer>
                {this.state.test !== null && (
                  <RightQuestionTable
                    test={this.state.test}
                    selectQuestion={this.selectQuestion.bind(this)}
                  />
                )}
              </RightQuestionTableContainer>
              <RightQuestionContentContainer>
                <RightQuestionContent question={this.state.selectQuestion} />
              </RightQuestionContentContainer>
            </RightQuestionContainer>
          </RightContainer>
        </Container>
        <TestContent test={this.state.test} />
      </>
    );
  }

  selectQuestion(question: QuestionResult) {
    this.setState({ selectQuestion: question });
  }
}

export default StudentTestResultDetailContent;
