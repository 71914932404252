import styled from "styled-components";

export const DemoButtonaArea = styled.div`
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  top: -72px;
}
`;

export const DemoButton = styled.button`
  background: red;
  color: white;
  border-radius: 10px;
  height: 44px;
  width: 160px;
  border: none;
  font-size: 16px;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
  margin: 0 8px;
`;
