import { TestResult } from "../../../../model/entities/TestResult";
import React from "react";
import {
  ListRowTr,
  ListRowTrIdBorder,
  ListRowTd
} from "../../../common/styles/ListRowStyle";
import * as H from "history";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

type Props = {
  testResult: TestResult;
  history: H.History;
};

class TrainingTestResultDetailListRow extends React.Component<Props, {}> {
  render() {
    const test = this.props.testResult;
    const user = this.props.testResult.student;
    return (
      <ListRowTr>
        <ListRowTd>{user?.affiliation_division.name}</ListRowTd>
        {user?.student?.coach !== null ? (
          <ListRowTd>
            <Link to={"/client/coaches/" + user?.student?.coach?.id}>
              <ListRowTrIdBorder>
                {user?.student!.coach!.family_name_ja +
                  " " +
                  user?.student!.coach!.first_name_ja}
              </ListRowTrIdBorder>
            </Link>
          </ListRowTd>
        ) : (
          <ListRowTd>-</ListRowTd>
        )}
        <ListRowTd>
          <Link to={"/client/students/" + user?.id}>
            <ListRowTrIdBorder>
              {user?.family_name_ja + " " + user?.first_name_ja}
            </ListRowTrIdBorder>
          </Link>
        </ListRowTd>
        <ListRowTd>{test.score}</ListRowTd>
        <ListRowTd>{test.rank !== null ? test.rank + "位" : "-"}</ListRowTd>
        <ListRowTd>
          {test.deviation_value !== null
            ? (Math.round(test.deviation_value * 10) / 10).toFixed(1)
            : "-"}
        </ListRowTd>
        <ListRowTd>
          {Math.floor((test.spent_time_ms ?? 0) / 1000 / 60)}分
          {Math.floor(((test.spent_time_ms ?? 0) / 1000) % 60)}秒
        </ListRowTd>
        <ListRowTd>
          {dayjs(Date.parse(test.do_datetime)).format("YYYY/MM/DD")}
        </ListRowTd>
      </ListRowTr>
    );
  }
}

export default TrainingTestResultDetailListRow;
