import React from "react";
import { Element } from "./styles/SidebarButtonStyle";
import * as H from "history";
import { Link } from "react-router-dom";

type Props = {
  titles: string[];
  isSelected: boolean;
  history: H.History;
  link: string | null;
  onClick: (() => void) | null;
};

type DefaultProps = {
  link: string | null;
  onClick: (() => void) | null;
};

class SidebarButton extends React.Component<Props, {}> {
  public static defaultProps: Partial<DefaultProps> = {
    link: null,
    onClick: null
  };

  render() {
    if (this.props.link) {
      return (
        <Link to={this.props.link}>
          <Element
            isSelected={this.props.isSelected}
            length={this.props.titles.length}
          >
            {this.props.titles.reduce((accumulator, current, index) => {
              if (index === 0) {
                return <>{current}</>;
              }
              return (
                <>
                  {accumulator}
                  <br />
                  {current}
                </>
              );
            }, <></>)}
          </Element>
        </Link>
      );
    }
    return (
      <Element
        isSelected={this.props.isSelected}
        length={this.props.titles.length}
        onClick={this.onClick.bind(this)}
      >
        {this.props.titles}
      </Element>
    );
  }

  onClick() {
    if (this.props.onClick) {
      this.props.onClick();
    }
  }
}

export default SidebarButton;
