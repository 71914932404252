import * as React from "react";
import { Container, Element, Badge } from "./styled";
import { Text } from "../../../../../common/Text";
import { StudyTimeZoneContent } from "../../../../types";
import { Color } from "../../../../../../styles";
import {
  VocabularyColor,
  GrammerColor,
  StructureColor,
  LogicColor,
  KnowledgeColor,
  SpeedColor
} from "../../../../../common/Colors";

const ColorMap = {
  処理スピード: {
    text: Color.WHITE,
    background: SpeedColor
  },
  背景知識: {
    text: Color.GRAY_DARK,
    background: KnowledgeColor
  },
  論理力: {
    text: Color.WHITE,
    background: LogicColor
  },
  構造把握力: {
    text: Color.GRAY_DARK,
    background: StructureColor
  },
  "文法・語法力": {
    text: Color.WHITE,
    background: GrammerColor
  },
  語彙力: {
    text: Color.WHITE,
    background: VocabularyColor
  }
};

type Props = {
  item: StudyTimeZoneContent;
};

const Column: React.FunctionComponent<Props> = ({ item }) => {
  return (
    <Container>
      {item.elements.map((element, i) => (
        <Element
          key={`${element.name_ja}-${i}`}
          background={ColorMap[element.name_ja].background}
        >
          <Text
            size="8px"
            weight="bold"
            align="center"
            color={ColorMap[element.name_ja].text}
            lineHeight="1"
          >
            {element.name_ja}
          </Text>
          <Text
            size="12px"
            weight="bold"
            align="right"
            color={ColorMap[element.name_ja].text}
          >
            {item.time_h}h
          </Text>
        </Element>
      ))}
      {item.elements.length > 1 && <Badge>OR</Badge>}
    </Container>
  );
};

export { Column };
