import React from "react";
import { WrapBackground } from "../../common/WrapBackground";
import { StudentBackground, BlackButton } from "../../common/Colors";
import {
  Body,
  BodyName,
  TargetTitle,
  TargetBody,
  TargetBodySpan,
  Level,
  ButtonArea,
  Button
} from "../styles/StudentDetailContentStyle";
import styled from "styled-components";
import { get } from "../../../model/api/Request";
import { User } from "../../../model/entities/User";
import dayjs from "dayjs";
import LoadingOverlay from "../../common/LoadingOverlay";
import { commonErrorHandle } from "../../../utils/errorHandle";
import TargetContainer from "./TargetContainer";
import ExternalEvaluationContainer from "./ExternalEvaluationContainer";
import { Profile } from "../../../utils/profile";
import { RouteComponentProps, Link } from "react-router-dom";

type Props = RouteComponentProps<{ id: string }> & Profile;

type State = {
  user: User | null;
  isLoading: boolean;
};

class StudentTargetAndExternalEvaluation extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({
      isLoading: true
    });
    const id = this.props.match.params.id;
    get("/students/" + id)
      .then(json => {
        this.setState({
          user: json.result,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  render() {
    const Title = styled.span`
      font-size: 30px;
      font-weight: bold;
      color: white;
      background: rgba(89, 87, 87, 1);
      position: absolute;
      left: 16px;
      top: 8px;
      padding: 8px 25px;
    `;
    const coach =
      this.state.user !== null &&
      Object.keys(this.state.user.student!).length !== 0 &&
      this.state.user.student!.coach! !== null
        ? this.state.user.student!.coach!.family_name_ja +
          " " +
          this.state.user.student!.coach!.first_name_ja
        : "-";
    const nextTarget =
      (this.state.user?.student?.targets.length ?? 0) >= 1
        ? this.state.user?.student?.targets[0]
        : null;
    const lastTarget =
      (this.state.user?.student?.targets.length ?? 0) >= 2
        ? this.state.user?.student?.targets[
            this.state.user?.student?.targets.length - 1
          ]
        : null;
    let startDate = "";
    let endDate = "";
    if (this.state.user != null) {
      startDate = dayjs(Date.parse(this.state.user.created_at)).format(
        "YYYY/MM/DD"
      );
      endDate = dayjs(
        Date.parse(this.state.user.affiliation_client.valid_date)
      ).format("YYYY/MM/DD");
    }
    return (
      <WrapBackground
        color={StudentBackground}
        history={this.props.history}
        profile={this.props.profile}
      >
        <LoadingOverlay isLoading={this.state.isLoading} />
        <Title>目標・取得資格詳細</Title>
        <Body isTitle={true} index={0}>
          所属
        </Body>
        <Body isTitle={false} index={0}>
          {this.state.user?.affiliation_division.name}
        </Body>
        <Body isTitle={true} index={2}>
          学年
        </Body>
        <Body isTitle={false} index={2}>
          {this.state.user?.affiliation_role?.name}
        </Body>
        <Body isTitle={true} index={4}>
          生徒ID
        </Body>
        <Body isTitle={false} index={4}>
          {this.state.user?.login_id}
        </Body>
        <Body isTitle={true} index={7}>
          生徒氏名
        </Body>
        <BodyName isTitle={false} index={7}>
          {(this.state.user?.family_name_ja || "") +
            " " +
            (this.state.user?.first_name_ja || "")}
        </BodyName>
        <Body isTitle={true} index={10}>
          担当コーチ
        </Body>
        <Body isTitle={false} index={10}>
          {coach}
        </Body>
        <Body isTitle={true} index={12}>
          利用期間
        </Body>
        <Body isTitle={false} index={12}>
          {startDate}~
          <br />
          {endDate}
        </Body>
        <TargetTitle isLastTarget={true}>最終目標</TargetTitle>
        <TargetBody isLastTarget={true}>
          <TargetBodySpan>{lastTarget?.name}</TargetBodySpan>
        </TargetBody>
        <TargetTitle isLastTarget={false}>次の目標</TargetTitle>
        <TargetBody isLastTarget={false}>
          <TargetBodySpan>{nextTarget?.name}</TargetBodySpan>
        </TargetBody>
        <Level>Lv.{this.state.user?.student?.academic_level.name}</Level>
        {this.state.user !== null && (
          <TargetContainer
            user={this.state.user}
            history={this.props.history}
            profile={this.props.profile}
          />
        )}
        {this.state.user !== null && (
          <ExternalEvaluationContainer
            user={this.state.user}
            history={this.props.history}
            profile={this.props.profile}
          />
        )}
        <ButtonArea>
          <Link to={"/client/students/" + this.state.user?.id}>
            <Button background={BlackButton}>ﾌﾟﾛﾌｨｰﾙ詳細へ</Button>
          </Link>
        </ButtonArea>
      </WrapBackground>
    );
  }
}

export default StudentTargetAndExternalEvaluation;
