import styled from "styled-components";

type Props = {
  color: string;
};

const LoginButton = styled.button<Props>`
  font-size: 30px;
  font-weight: bold;
  background: ${props => props.color};
  border: none;
  width: 80%;
  height: 70px;
  text-align: center;
  margin-top: 40px;
  color: white;
  box-shadow: 0 0 8px gray;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export default LoginButton;
