import styled from "styled-components";

export const Title = styled.span`
  font-size: 30px;
  font-weight: bold;
  color: white;
  background: rgba(89, 87, 87, 1);
  position: absolute;
  left: 16px;
  top: 8px;
  padding: 8px 40px;
`;

export const Body = styled.span<{ isTitle: boolean; index: number }>`
  position: absolute;
  top: ${props => 82 + props.index * 15 + "px"};
  left: ${props => (props.isTitle ? "16px" : "116px")};
  color: rgba(112, 112, 112, 1);
  font-weight: bold;
  font-size: 16px;
`;

export const BodyName = styled(Body)`
  top: 184px;
  font-size: 22px;
`;

export const TargetTitle = styled.span<{ isLastTarget: boolean }>`
  position: absolute;
  top: ${props => 318 + (props.isLastTarget ? 0 : 96) + "px"};
  left: 16px;
  text-align: center;
  color: white;
  width: 318px;
  height: 34px;
  border: solid 1px black;
  background: ${props =>
    props.isLastTarget ? "rgba(255, 83, 83, 1)" : "rgba(184, 139, 90, 1)"};
  padding-top: 4px;
`;

export const TargetBody = styled.span<{ isLastTarget: boolean }>`
  position: absolute;
  top: ${props => 350 + (props.isLastTarget ? 0 : 96) + "px"};
  left: 16px;
  text-align: center;
  width: 318px;
  height: 56px;
  border: solid 1px black;
  background: white;
`;

export const TargetBodySpan = styled.span`
  margin: 10px;
  display: block;
  font-size: 20px;
`;

export const Level = styled.span`
  position: absolute;
  top: 120px;
  left: 244px;
  border-radius: 8px;
  padding: 6px 12px;
  border: solid 4px rgba(212, 113, 113, 1);
  color: rgba(212, 113, 113, 1);
  font-size: 20px;
`;

export const ExternalEvaluateTitle = styled.span`
  position: absolute;
  top: 504px;
  left: 55px;
  color: rgba(112, 112, 112, 1);
  font-weight: bold;
  font-size: 16px;
`;

export const ExternalEvaluateArea = styled.div`
  position: absolute;
  top: 532px;
  left: 16px;
  bottom: 30px;
  width: 599px;
  height: 220px;
  background: white;
  border: solid 1px black;
`;

export const ExternalEvaluateHeaderFlex = styled.div`
  margin-right: 18px;
  display: flex;
  justify-content: space-evenly;
`;

export const ExternalEvaluateHeader = styled.span`
  margin-top: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 132px;
  background: rgba(112, 112, 112, 1);
  color: white;
  text-align: center;
`;

export const ExternalEvaluateContent = styled.div`
  display: flex;
  border: solid 1px rgba(112, 112, 112, 1);
  border-radius: 12px;
  margin-top: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 16px;
  height: 66px;
`;

export const ExternalEvaluateContentElement = styled.div`
  position: absolute;
  overflow-y: scroll;
  top: 40px;
  bottom: 3px;
  width: 597px;
`;

export const ExternalEvaluateContentValue = styled.p`
  width: 142px;
  margin: 2px 10px 6px 2px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TestResultArea = styled.div`
  position: absolute;
  top: 8px;
  left: 345px;
  width: 270px;
  height: 500px;
  background: white;
  border: solid 1px black;
`;

export const TestResultHeaderFlex = styled.div`
  margin-right: 18px;
  display: flex;
  justify-content: space-evenly;
`;

export const TestResultHeader = styled.span`
  margin-top: 16px;
  padding-top: 2px;
  padding-bottom: 2px;
  width: 114px;
  background: rgba(112, 112, 112, 1);
  color: white;
  text-align: center;
`;
export const TestResultContent = styled.div`
  display: flex;
  border: solid 1px rgba(112, 112, 112, 1);
  border-radius: 12px;
  margin-top: 16px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 16px;
  height: 66px;
  color: black;
`;

export const TestResultContentElement = styled.div`
  position: absolute;
  overflow-y: scroll;
  top: 40px;
  bottom: 3px;
  width: 267px;
`;

export const TestResultContentValue = styled.p`
  width: 136px;
  margin: 2px 2px 6px 2px;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;

export const ButtonArea = styled.div`
  position: absolute;
  right: 20px;
  bottom: 12px;
  display: flex;
`;

export const Button = styled.button<{ background: string }>`
  background: ${props => props.background};
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 20px;
  width: 150px;
  color: white;
  font-size: 18px;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
  border: none;
`;

export const MinifyMonthlyReport = styled.div``;
