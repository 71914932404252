import * as React from "react";
import { Container } from "./styled";
import { Text } from "../../../common/Text";
import { Flex } from "../../../common/Flex";
import { Spacer } from "../../../common/Spacer";
import { Color } from "../../../../styles";
import { Student, Coach, ExternalEval } from "../../types";

type Props = {
  student: Student;
  coach: Coach;
  externalEval: ExternalEval;
};

const externalEvalColors = [
  "#52abde",
  "#9ac454",
  "#dcdf4e",
  "#f1b452",
  "#eb983c",
  "#e16334",
  "#db352e"
];

const UserProfile: React.FunctionComponent<Props> = ({
  student,
  coach,
  externalEval
}) => {
  return (
    <Container>
      <div>
        <Text color={Color.GRAY_DARK} weight="bold" size="12px">
          所属：{student.affiliation_division}
        </Text>
        <Spacer pt={0.5} />
        <Text color={Color.GRAY_DARK} weight="bold" size="12px">
          学年：{student.affiliation_role}
        </Text>
        <Spacer pt={0.5} />
        <Text color={Color.GRAY_DARK} weight="bold" size="12px">
          生徒ID：{student.login_id}
        </Text>
        <Flex display="flex" alignItems="center">
          <Text color={Color.GRAY_DARK} weight="bold" size="12px">
            生徒氏名：
          </Text>
          <div>
            <Text weight="bold" size="18px" color={Color.GRAY_DARK}>
              {`${student.family_name_ja}　${student.first_name_ja}`}
            </Text>
            <Text weight="bold" size="10px" color={Color.GRAY_DARK}>
              {`${student.family_name_furigana} ${student.first_name_furigana}`}
            </Text>
          </div>
        </Flex>
        <Spacer pt={0.5} />
        {/* 現状、英検以外は対応しない */}
        {externalEval.name === "英検" && (
          <>
            <Text color={Color.GRAY_DARK} weight="bold" size="12px">
              英検取得状況
            </Text>
            <Spacer pt={0.5} />
            <Flex display="flex" justifyContent="space-between">
              {externalEval.external_eval_levels.map((level, i) => (
                <React.Fragment key={i}>
                  <div
                    style={{
                      backgroundColor: level.is_have
                        ? externalEvalColors[i]
                        : "#C0C0C0",
                      height: "40px",
                      width: "27px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px"
                    }}
                  >
                    {/* 準2級,準1級のときはハードコードでpをつける */}
                    {i === 3 || i === 5 ? (
                      <>
                        <Text
                          color="white"
                          weight="bold"
                          size="16px"
                          marginTop="-24px"
                        >
                          p
                        </Text>
                        <Text color="white" weight="bold" size="22px">
                          {level.name.replace(/[^0-9]/g, "")}
                        </Text>
                      </>
                    ) : (
                      <Text color="white" weight="bold" size="26px">
                        {level.name.replace(/[^0-9]/g, "")}
                      </Text>
                    )}
                  </div>
                  <Spacer pl={0.5} />
                </React.Fragment>
              ))}
            </Flex>
          </>
        )}
        <Spacer pt={1.5} />
        <Text color={Color.GRAY_DARK} weight="bold" size="12px">
          担当コーチ：{coach.family_name_ja}コーチ
        </Text>
        <Spacer pt={0.5} />
        <Text color={Color.GRAY_DARK} weight="bold" size="12px">
          コーチID：{coach.login_id}
        </Text>
      </div>
    </Container>
  );
};

export { UserProfile };
