export const pagingLimit: number = 10;

export const queryPageKey: string = "page";
export const querySortByKey: string = "sort_by";
export const queryOrderKey: string = "order";

export const queryFamilyNameKey: string = "family_name";
export const queryFirstNameKey: string = "first_name";
export const queryStudentFamilyNameKey: string = "student_family_name";
export const queryStudentFirstNameKey: string = "student_first_name";
export const queryAffiliationDivisionKey: string = "affiliation_division";
export const queryAffiliationDivisionNameKey: string =
  "affiliation_division_name";
export const queryAuthoritySetKey: string = "authority_set";
export const queryIsValidKey: string = "is_valid";
export const queryIsNotValidKey: string = "is_not_valid";
export const queryCoachKey: string = "coach";
export const queryCoachFamilyNameKey: string = "coach_family_name";
export const queryCoachFirstNameKey: string = "coach_first_name";
export const queryTargetTypeKey: string = "target_type";
export const queryExternalEvalAndKey: string = "external_eval_and";
export const queryExternalEvalKey: string = "external_eval";
export const queryExternalEvalSearchTypeKey: string =
  "external_eval_search_type";
export const queryAcademicLevelKey: string = "academic_level";
export const queryFromAverageScore: string = "from_average_score";
export const queryToAverageScore: string = "to_average_score";
export const queryFromScoreKey: string = "from_score";
export const queryToScoreKey: string = "to_score";
export const queryFromParticipantNumber: string = "from_participant_number";
export const queryToParticipantNumber: string = "to_participant_number";
export const queryFromDoDateKey: string = "from_do_date";
export const queryToDoDateKey: string = "to_do_date";
export const queryFromRankKey: string = "from_rank";
export const queryToRankKey: string = "to_rank";
export const queryFromDeviationValueKey: string = "from_deviation_value";
export const queryToDeviationValueKey: string = "to_deviation_value";
export const queryFromSpentTimeMsKey: string = "from_spent_time_ms";
export const queryToSpentTimeMsKey: string = "to_spent_time_ms";
export const queryFromDoDateTimeKey: string = "from_do_datetime";
export const queryToDoDateTimeKey: string = "to_do_datetime";
export const queryGrammarFieldKey: string = "grammar_field";
export const queryLevelKey: string = "level";
export const queryTestNumberKey: string = "test_number";

export const sortByList = [
  "",
  "affiliation_division",
  "name",
  "authority_set",
  "is_valid",
  "academic_level",
  "test_number",
  "average_score",
  "participant_number",
  "do_datetime",
  "to_date",
  "coach",
  "student",
  "score",
  "rank",
  "deviation_value",
  "spent_time_ms",
  "datetime",
  "grammar_field",
  "level"
];
export type SortBy = typeof sortByList[number];

export const orderList = ["", "desc"];
export type Order = typeof orderList[number];
