import React from "react";
import { WrapBackground } from "../../../common/WrapBackground";
import { get } from "../../../../model/api/Request";
import StudentTrainingTestResultDetailListContent from "./StudentTrainingTestResultDetailListContent";
import {
  TestResultBackground,
  TestResultSidebarBackground
} from "../../../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { commonErrorHandle } from "../../../../utils/errorHandle";
import {
  TestResult,
  StudentResult
} from "../../../../model/entities/TestResult";
import LoadingOverlay from "../../../common/LoadingOverlay";
import { Profile } from "../../../../utils/profile";

type Props = RouteComponentProps<{ id: string }> & Profile;

type State = {
  isLoading: boolean;
  testResults: StudentResult[];
  testContent: TestResult | null;
  isFilterModalOpen: boolean;
};

class StudentTrainingTestResultDetailList extends React.Component<
  Props,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      testResults: [],
      testContent: null,
      isFilterModalOpen: false
    };
  }

  componentDidMount() {
    this.getInit();
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapBackground
          color={TestResultBackground}
          history={this.props.history}
          profile={this.props.profile}
          selectedSidebar="training_test_result"
          sidebarColor={TestResultSidebarBackground}
        >
          <StudentTrainingTestResultDetailListContent
            history={this.props.history}
            testResults={this.state.testResults}
            testContent={this.state.testContent}
          />
        </WrapBackground>
      </>
    );
  }

  getInit() {
    this.setState({ isLoading: true });
    const testId = this.props.match.params.id;
    Promise.all([get("/student/training-tests/" + testId + "/results")])
      .then(responses => {
        this.setState({
          isLoading: false,
          testResults: responses[0].result.results,
          testContent: responses[0].result
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  scrollTopIfNeed() {
    const scrollListElement = document.getElementsByClassName(
      "scrollListElement"
    )[0];
    if (scrollListElement) {
      scrollListElement.scrollTop = 0;
    }
  }
}

export default StudentTrainingTestResultDetailList;
