import React from "react";
import { User } from "../../../model/entities/User";
import Modal from "react-modal";
import {
  ModalStyle,
  CloseButton,
  CloseButtonImage,
  TitleLabel,
  Input,
  Button,
  ButtonArea
} from "../styles/UserEditModalStyle";
import { get, post } from "../../../model/api/Request";
import dayjs from "dayjs";
import { ExternalEval } from "../../../model/entities/ExternalEval";
import { Level } from "../../../model/entities/Level";
import {
  selectExternalEvalTypeModal,
  selectExternalEvalLevelModal
} from "./StudentExternalEvalComponentModal";
import { commonErrorHandle } from "../../../utils/errorHandle";
import * as H from "history";
import { CreateButton } from "../../common/Colors";
import LoadingOverlay from "../../common/LoadingOverlay";

type Props = {
  history: H.History;
  user: User;
  isOpen: boolean;
  onClose: () => void;
};

type State = {
  externalEvalTypes: ExternalEval[];
  isSelectExternalEvalTypeMode: boolean;
  selectedExternalEvalType: ExternalEval | null;
  externalEvalLevels: Level[];
  isSelectExternalEvalLevelMode: boolean;
  selectedExternalEvalLevel: Level | null;
  externalEvalResult: string;
  toDate: string;
  comment: string;
  isLoading: boolean;
};

class StudentExternalEvalCreateModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      externalEvalTypes: [],
      isSelectExternalEvalTypeMode: false,
      selectedExternalEvalType: null,
      externalEvalLevels: [],
      isSelectExternalEvalLevelMode: false,
      selectedExternalEvalLevel: null,
      externalEvalResult: "",
      toDate: "",
      comment: "",
      isLoading: false
    };
  }

  render() {
    if (this.state.isSelectExternalEvalTypeMode) {
      return selectExternalEvalTypeModal(
        this.state.externalEvalTypes,
        this.onSelectExternalEvalTypeElement.bind(this),
        this.props.isOpen,
        this.onBack.bind(this)
      );
    } else if (this.state.isSelectExternalEvalLevelMode) {
      return selectExternalEvalLevelModal(
        this.state.externalEvalLevels,
        this.onSelectExternalEvalLevelElement.bind(this),
        this.props.isOpen,
        this.onBack.bind(this)
      );
    }
    return this.makeModal();
  }

  makeModal() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <TitleLabel>取得資格の登録</TitleLabel>
          <Input
            readOnly={true}
            placeholder="ジャンル"
            value={this.state.selectedExternalEvalType?.name || ""}
            onClick={this.onSelectExternalEvalType.bind(this)}
          />
          {this.state.selectedExternalEvalType !== null && (
            <Input
              readOnly={true}
              placeholder="スコア"
              value={this.state.selectedExternalEvalLevel?.name || ""}
              onClick={this.onSelectExternalEvalLevel.bind(this)}
            />
          )}
          <Input
            placeholder="スコア詳細"
            value={this.state.externalEvalResult}
            onChange={this.onChangeExternalEvalResult.bind(this)}
          />
          <Input
            type="date"
            max={dayjs().format("YYYY-MM-DD")}
            placeholder="取得日"
            value={this.state.toDate}
            onChange={this.onChangeToDate.bind(this)}
          />
          <Input
            placeholder="コメント"
            value={this.state.comment}
            onChange={this.onChangeComment.bind(this)}
          />
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onCreate.bind(this)}
            >
              登録
            </Button>
          </ButtonArea>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
        </Modal>
        <LoadingOverlay isLoading={this.state.isLoading} />
      </>
    );
  }

  onCreate() {
    const errorMessages: string[] = [];
    if (this.state.selectedExternalEvalType == null) {
      errorMessages.push("・ ジャンルを入力してください");
    }
    if (this.state.selectedExternalEvalLevel == null) {
      errorMessages.push("・ スコアを入力してください");
    }
    if (this.state.externalEvalResult === "") {
      errorMessages.push("・ スコア詳細を入力してください");
    }
    if (this.state.toDate === "") {
      errorMessages.push("・ 取得日を入力してください");
    }
    if (
      this.state.toDate.replace(/-/g, "") >
      dayjs().format("YYYY-MM-DD").replace(/-/g, "")
    ) {
      errorMessages.push("・ 取得日は過去の日付にしてください");
    }
    if (errorMessages.length !== 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    const body: { [index: string]: any } = {
      external_eval_level_id: this.state.selectedExternalEvalLevel!.id,
      value: this.state.externalEvalResult,
      comment: this.state.comment,
      got_date: dayjs(Date.parse(this.state.toDate)).toISOString()
    };
    this.setState({ isLoading: true });
    post("/students/" + this.props.user.id + "/external-eval-results", body)
      .then(res => {
        this.onClose();
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onChangeExternalEvalResult(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ externalEvalResult: e.target.value });
  }

  onChangeToDate(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toDate: e.target.value });
  }

  onChangeComment(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ comment: e.target.value });
  }

  onSelectExternalEvalTypeElement(e: React.MouseEvent<HTMLDivElement>) {
    const externalEvalType = this.state.externalEvalTypes.filter(
      externalEvalType => {
        return externalEvalType.name === e.currentTarget.innerText;
      }
    )[0];
    this.setState({
      selectedExternalEvalLevel: null,
      selectedExternalEvalType: externalEvalType,
      isSelectExternalEvalTypeMode: false
    });
  }

  onSelectExternalEvalType() {
    if (this.state.externalEvalTypes.length !== 0) {
      this.setState({ isSelectExternalEvalTypeMode: true });
      return;
    }
    this.setState({ isLoading: true });
    get("/external-evals")
      .then(res => {
        const externalEvalTypes: ExternalEval[] = res.result;
        this.setState({
          externalEvalTypes: externalEvalTypes,
          isSelectExternalEvalTypeMode: true,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onSelectExternalEvalLevelElement(e: React.MouseEvent<HTMLDivElement>) {
    const externalEvalLevel = this.state.externalEvalLevels.filter(
      externalEvalLevel => {
        return externalEvalLevel.name === e.currentTarget.innerText;
      }
    )[0];
    this.setState({
      selectedExternalEvalLevel: externalEvalLevel,
      isSelectExternalEvalLevelMode: false
    });
  }

  onSelectExternalEvalLevel() {
    this.setState({ isLoading: true });
    get(
      "/external-evals/" +
        this.state.selectedExternalEvalType?.id +
        "/external-eval-levels"
    )
      .then(res => {
        const externalEvalLevels: Level[] = res.result.levels;
        this.setState({
          externalEvalLevels: externalEvalLevels,
          isSelectExternalEvalLevelMode: true,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  clear() {
    this.setState({
      isSelectExternalEvalTypeMode: false,
      selectedExternalEvalType: null,
      isSelectExternalEvalLevelMode: false,
      selectedExternalEvalLevel: null,
      externalEvalResult: "",
      toDate: "",
      comment: "",
      isLoading: false
    });
  }

  onBack() {
    this.setState({
      isSelectExternalEvalTypeMode: false,
      isSelectExternalEvalLevelMode: false
    });
  }

  onClose() {
    this.clear();
    this.props.onClose();
  }
}

export default StudentExternalEvalCreateModal;
