import styled from "styled-components";
import { BlackButton } from "../../common/Colors";

export const Title = styled.span`
  font-size: 30px;
  font-weight: bold;
  color: white;
  background: rgba(89, 87, 87, 1);
  position: absolute;
  left: 16px;
  top: 8px;
  padding: 8px 40px;
`;

export const Body = styled.span<{ isTitle: boolean; index: number }>`
  position: absolute;
  top: ${props => 88 + props.index * 32 + "px"};
  left: ${props => (props.isTitle ? "16px" : "116px")};
  color: rgba(112, 112, 112, 1);
  font-weight: bold;
  font-size: 16px;
`;

export const BodyName = styled(Body)`
  top: 176px;
  font-size: 24px;
`;

export const LevelTitle = styled.span`
  position: absolute;
  top: 250px;
  left: 20px;
  font-size: 13px;
  color: #c7463a;
`;

export const Level = styled.span`
  position: absolute;
  top: 276px;
  left: 20px;
  border-radius: 8px;
  padding: 6px 12px;
  border: solid 4px #c7463a;
  color: #c7463a;
  font-size: 20px;
`;

export const BackButton = styled.button`
  position: absolute;
  left: 20px;
  bottom: 50px;
  width: 140px;
  background: ${BlackButton};
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  &:focus {
    outline: 0;
  }
`;

export const StudentListTitle = styled.span`
  position: absolute;
  top: 55px;
  left: 370px;
  color: rgba(112, 112, 112, 1);
  font-weight: bold;
  font-size: 16px;
`;

export const ListContentThElement = styled.div`
  position: absolute;
  left: 344px;
  top: 88px;
  padding: 0px 16px 16px 16px;
`;

export const ListContentThTable = styled.table`
  width: 98.5%;
  table-layout: fixed;
  text-align: center;
  border-spacing: 0;
`;

export const ListContentElement = styled.div`
  position: absolute;
  left: 344px;
  top: 115px;
  bottom: 16px;
  overflow-y: scroll;
  padding: 0px 16px 16px 16px;
`;
