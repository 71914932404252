import React from "react";
import AuthorityListRow from "./AuthorityListRow";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  ListContentTable,
  ListContentTh
} from "../common/styles/ListContentStyle";
import * as H from "history";
import { AuthoritySet } from "../../model/entities/AuthoritySet";
import { User } from "../../model/entities/User";

type Props = {
  history: H.History;
  authoritySets: AuthoritySet[];
  profile: User;
};

type State = {};

class AuthorityListContent extends React.Component<Props, State> {
  render() {
    const listRows = this.props.authoritySets.map(authoritySet => (
      <AuthorityListRow
        key={authoritySet.id}
        authoritySet={authoritySet}
        history={this.props.history}
        profile={this.props.profile}
      />
    ));
    return (
      <>
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh enableClick={false}>権限レベル</ListContentTh>
                <ListContentTh enableClick={false}>役職</ListContentTh>
                <ListContentTh enableClick={false}>閲覧</ListContentTh>
                <ListContentTh enableClick={false}>新規作成</ListContentTh>
                <ListContentTh enableClick={false}>編集</ListContentTh>
                <ListContentTh enableClick={false}>削除</ListContentTh>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement>
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }
}

export default AuthorityListContent;
