import React from "react";
import Modal from "react-modal";
import {
  ModalStyle,
  CloseButton,
  CloseButtonImage,
  TitleLabel,
  Input,
  Button,
  ButtonArea
} from "../styles/UserEditModalStyle";
import { get, put, post } from "../../../model/api/Request";
import dayjs from "dayjs";
import { ExternalEval, EvalLevel } from "../../../model/entities/ExternalEval";
import { selectExternalEvalLevelModal } from "./StudentExternalEvalComponentModal";
import { commonErrorHandle } from "../../../utils/errorHandle";
import * as H from "history";
import { CreateButton } from "../../common/Colors";
import LoadingOverlay from "../../common/LoadingOverlay";
import { User } from "../../../model/entities/User";

type Props = {
  history: H.History;
  externalEval: ExternalEval;
  level: EvalLevel;
  isOpen: boolean;
  onClose: () => void;
  user: User;
};

type State = {
  externalEvalLevels: EvalLevel[];
  isSelectExternalEvalLevelMode: boolean;
  selectedExternalEvalLevel: EvalLevel | null;
  externalEvalResult: string;
  toDate: string;
  comment: string;
  isLoading: boolean;
};

class StudentExternalEvalEditModal extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      externalEvalLevels: [],
      isSelectExternalEvalLevelMode: false,
      selectedExternalEvalLevel: this.props.level,
      externalEvalResult: this.props.level.result.value,
      toDate: this.props.level.result.got_date,
      comment: this.props.level.result.comment,
      isLoading: false
    };
  }

  render() {
    if (this.state.isSelectExternalEvalLevelMode) {
      return selectExternalEvalLevelModal(
        this.state.externalEvalLevels,
        this.onSelectExternalEvalLevelElement.bind(this),
        this.props.isOpen,
        this.onBack.bind(this)
      );
    }
    return this.editModal();
  }

  editModal() {
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <TitleLabel>{this.props.externalEval.name}の編集</TitleLabel>
          <Input
            readOnly={true}
            placeholder="スコア"
            value={this.state.selectedExternalEvalLevel?.name || ""}
            onClick={this.onSelectExternalEvalLevel.bind(this)}
          />
          <Input
            placeholder="スコア詳細"
            value={this.state.externalEvalResult}
            onChange={this.onChangeExternalEvalResult.bind(this)}
          />
          <Input
            type="date"
            max={dayjs().format("YYYY-MM-DD")}
            placeholder="取得日"
            value={this.state.toDate}
            onChange={this.onChangeToDate.bind(this)}
          />
          <Input
            placeholder="コメント"
            value={this.state.comment}
            onChange={this.onChangeComment.bind(this)}
          />
          <ButtonArea>
            <Button background={CreateButton} onClick={this.onEdit.bind(this)}>
              保存
            </Button>
          </ButtonArea>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
        </Modal>
        <LoadingOverlay isLoading={this.state.isLoading} />
      </>
    );
  }

  onEdit() {
    const errorMessages: string[] = [];
    if (
      this.state.externalEvalResult === undefined ||
      this.state.externalEvalResult === ""
    ) {
      errorMessages.push("・ スコア詳細を入力してください");
    }
    if (this.state.toDate === undefined || this.state.toDate === "") {
      errorMessages.push("・ 取得日を入力してください");
    }
    if (
      this.state.toDate.replace(/-/g, "") >
      dayjs().format("YYYY-MM-DD").replace(/-/g, "")
    ) {
      errorMessages.push("・ 取得日は過去の日付にしてください");
    }
    if (errorMessages.length !== 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    const body: { [index: string]: any } = {
      external_eval_level_id: this.state.selectedExternalEvalLevel?.id,
      value: this.state.externalEvalResult,
      comment: this.state.comment ?? "",
      got_date: dayjs(Date.parse(this.state.toDate)).toISOString()
    };
    this.setState({ isLoading: true });
    let request: Promise<any>;
    if (this.props.level.result.id) {
      request = put(
        "/external-eval-results/" + this.props.level.result.id,
        body
      );
    } else {
      request = post(
        "/students/" + this.props.user.id + "/external-eval-results",
        body
      );
    }
    request
      .then(res => {
        this.onClose();
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onChangeExternalEvalResult(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ externalEvalResult: e.target.value });
  }

  onChangeToDate(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ toDate: e.target.value });
  }

  onChangeComment(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ comment: e.target.value });
  }

  onSelectExternalEvalLevelElement(e: React.MouseEvent<HTMLDivElement>) {
    const externalEvalLevel = this.state.externalEvalLevels.filter(
      externalEvalLevel => {
        return externalEvalLevel.name === e.currentTarget.innerText;
      }
    )[0];
    this.setState({
      selectedExternalEvalLevel: externalEvalLevel,
      isSelectExternalEvalLevelMode: false
    });
  }

  onSelectExternalEvalLevel() {
    if (this.state.externalEvalLevels.length !== 0) {
      this.setState({ isSelectExternalEvalLevelMode: true });
      return;
    }
    this.setState({ isLoading: true });
    get(
      "/external-evals/" + this.props.externalEval.id + "/external-eval-levels"
    )
      .then(res => {
        const externalEvalLevels: EvalLevel[] = res.result.levels;
        this.setState({
          externalEvalLevels: externalEvalLevels,
          isSelectExternalEvalLevelMode: true,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  clear() {
    this.setState({
      isSelectExternalEvalLevelMode: false,
      selectedExternalEvalLevel: null
    });
  }

  onBack() {
    this.setState({
      isSelectExternalEvalLevelMode: false
    });
  }

  onClose() {
    this.clear();
    this.props.onClose();
  }
}

export default StudentExternalEvalEditModal;
