import * as React from "react";
import dayjs from "dayjs";
import { Level } from "./styled";
import { Text } from "../../../common/Text";
import { Color } from "../../../../styles";
import { TargetElement } from "../../types";
import { Section } from "../Section";
import { Flex } from "../../../common/Flex";
import { Spacer } from "../../../common/Spacer";

type Props = {
  title: string;
  titleBackground?: string;
  item: TargetElement | null;
};

const Target: React.FunctionComponent<Props> = ({
  title,
  item,
  titleBackground
}) => {
  return (
    <Section title={title} titleAlign="center" background={titleBackground}>
      <Flex display="flex" alignItems="center" justifyContent="flex-start">
        <div>
          <Text size="10px">目標</Text>
          <Spacer pt={0.5} />
          <Level>
            <Text size="10px" weight="bold" color="#d96864">
              {item ? item.academic_level : ""}
            </Text>
          </Level>
        </div>
        <Spacer pl={2} />
        <div style={{ width: "185px" }}>
          <Text size="12px" weight="bold" color={Color.GRAY_DARK}>
            {item ? item.name : "-"}
          </Text>
        </div>
        <Spacer pl={1} />
        <Flex
          display="flex"
          flexDirection="column"
          alignItems="center"
          style={{ marginRight: "8px" }}
        >
          <Text size="10px">実施日</Text>
          <Spacer pt={1} />
          <Text size="10px" color={Color.GRAY_DARK} weight="bold">
            {item ? dayjs().add(item.remaining_days, "day").format("YYYY") : ""}
          </Text>
          <Text size="14px" color={Color.GRAY_DARK} weight="bold">
            {item ? dayjs().add(item.remaining_days, "day").format("M/D") : ""}
          </Text>
        </Flex>
        <Flex display="flex" flexDirection="column" alignItems="center">
          <Text size="10px">残り日数</Text>
          <Spacer pt={1.5} />
          <Text size="20px" weight="bold" color="#d96864">
            {item ? item.remaining_days : "-"}
          </Text>
        </Flex>
      </Flex>
    </Section>
  );
};

export { Target };
