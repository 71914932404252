import { TestResult } from "../../../model/entities/TestResult";
import React from "react";
import StudentTestResultListRow from "./StudentTestResultListRow";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  ListContentTable,
  ListContentTh
} from "../../common/styles/ListContentStyle";
import * as H from "history";

type State = {};

type Props = {
  history: H.History;
  testResults: TestResult[];
};

class StudentTestResultListContent extends React.Component<Props, State> {
  render() {
    const listRows = this.props.testResults.map((testResult: TestResult) => (
      <StudentTestResultListRow
        key={testResult.id}
        testResult={testResult}
        history={this.props.history}
      />
    ));

    return (
      <>
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh enableClick={true}>レベル</ListContentTh>
                <ListContentTh enableClick={true}>テスト番号</ListContentTh>
                <ListContentTh enableClick={true}>得点</ListContentTh>
                <ListContentTh enableClick={true}>平均点</ListContentTh>
                <ListContentTh enableClick={true}>偏差値</ListContentTh>
                <ListContentTh enableClick={false}>受験日</ListContentTh>
                <ListContentTh enableClick={false}>結果詳細</ListContentTh>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement>
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }
}

export default StudentTestResultListContent;
