import * as React from "react";
import { Badge, Triangle } from "./styled";
import { Section } from "../Section";
import { RecordPossibility as RecordPossibilityType } from "../../types";
import { Color } from "../../../../styles";
import { Text } from "../../../common/Text";
import { Flex } from "../../../common/Flex";
import { Spacer } from "../../../common/Spacer";

type Props = {
  item: RecordPossibilityType;
};

const RecordPossibility: React.FunctionComponent<Props> = ({ item }) => {
  return (
    <Section title="次の目標 達成可能性" titleAlign="center">
      <Spacer pt={1} />
      <Flex display="flex" justifyContent="center">
        <Text size="12px">現在の達成可能性</Text>
        <Spacer pl={2} />
        <Badge>
          <Text weight="bold" color={Color.WHITE} size="20px">
            {item.target_reachability}
          </Text>
        </Badge>
        <Spacer pl={2} />
        <Text size="12px">
          学習目標達成時の
          <br />
          来月の達成可能性
        </Text>
      </Flex>
      <Flex display="flex" justifyContent="center" alignItems="center">
        <Text weight="bold" size="50px" color="#efb056">
          {item.current_percentage}
          <Text weight="bold" size="40px" color="#efb056" component="span">
            %
          </Text>
        </Text>
        <Spacer pl={2} />
        <Triangle />
        <Spacer pl={2} />
        <Text weight="bold" size="30px" color="#e2623a">
          {item.next_percentage}
          <Text weight="bold" size="20px" color="#e2623a" component="span">
            %
          </Text>
        </Text>
      </Flex>
    </Section>
  );
};

export { RecordPossibility };
