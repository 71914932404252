import { Test } from "../../../model/entities/Test";
import React from "react";
import TrainingTestResultListRow from "./TrainingTestResultListRow";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  ListContentTable,
  ListContentTh,
  SortArrow
} from "../../common/styles/ListContentStyle";
import * as H from "history";
import { SortBy, Order } from "../../../utils/Constant";

type State = {};

type Props = {
  history: H.History;
  tests: Test[];
  onChangeSort: (sortBy: SortBy, order: Order) => void;
  sortBy: SortBy;
  order: Order;
};

class TrainingTestResultListContent extends React.Component<Props, State> {
  render() {
    const listRows = this.props.tests.map((test: Test) => (
      <TrainingTestResultListRow
        key={test.id}
        test={test}
        history={this.props.history}
      />
    ));
    return (
      <>
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh
                  onClick={this.onClickGrammarFieldTh.bind(this)}
                  enableClick={true}
                >
                  分野
                  <SortArrow>
                    {this.props.sortBy === "grammar_field"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickLevelTh.bind(this)}
                  enableClick={true}
                >
                  レベル
                  {this.props.sortBy === "level"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickTestNumberTh.bind(this)}
                  enableClick={true}
                >
                  テスト番号
                  {this.props.sortBy === "test_number"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickAverageScoreTh.bind(this)}
                  enableClick={true}
                >
                  平均点
                  {this.props.sortBy === "average_score"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickParticipantNumberTh.bind(this)}
                  enableClick={true}
                >
                  受験者数
                  {this.props.sortBy === "participant_number"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickDoDatetimeTh.bind(this)}
                  enableClick={true}
                >
                  受験日
                  {this.props.sortBy === "do_datetime"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh enableClick={false}>問題一覧</ListContentTh>
                <ListContentTh enableClick={false}>結果詳細</ListContentTh>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement>
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }

  onClickGrammarFieldTh() {
    let order: Order = "";
    if (this.props.sortBy === "grammar_field" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("grammar_field", order);
  }

  onClickLevelTh() {
    let order: Order = "";
    if (this.props.sortBy === "level" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("level", order);
  }

  onClickTestNumberTh() {
    let order: Order = "";
    if (this.props.sortBy === "test_number" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("test_number", order);
  }

  onClickAverageScoreTh() {
    let order: Order = "";
    if (this.props.sortBy === "average_score" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("average_score", order);
  }

  onClickParticipantNumberTh() {
    let order: Order = "";
    if (this.props.sortBy === "participant_number" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("participant_number", order);
  }

  onClickDoDatetimeTh() {
    let order: Order = "";
    if (this.props.sortBy === "do_datetime" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("do_datetime", order);
  }
}

export default TrainingTestResultListContent;
