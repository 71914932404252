import * as React from "react";
import {
  Container,
  SeparatorBox,
  SeparatorContent,
  ColumnsContainer
} from "./styled";
import { Section } from "../Section";
import { StudyTimeZones, StudyTimeZoneContent } from "../../types";
import { Column } from "./internal/Column";
import { Flex } from "../../../common/Flex";
import { Color } from "../../../../styles";
import { Text } from "../../../common/Text";

type Props = {
  studyTimeZones: StudyTimeZones;
};

function getTimeTest(contents: StudyTimeZoneContent[]): number {
  return contents.reduce<number>((acc, cur) => {
    return acc + cur.time_h;
  }, 0);
}

const StudyElement: React.FunctionComponent<Props> = ({ studyTimeZones }) => {
  return (
    <Container>
      <Section title="●推奨時間帯と学習エレメント">
        <Flex display="flex">
          <SeparatorBox>
            <Text
              size="12px"
              lineHeight="1"
              weight="bold"
              align="center"
              color={Color.GRAY_DARK}
            >
              {studyTimeZones.morning.name}
            </Text>
            <SeparatorContent>
              <Text size="24px" weight="bold" align="center" color="#F15A25">
                {getTimeTest(studyTimeZones.morning.contents)}h
              </Text>
              <ColumnsContainer>
                {studyTimeZones.morning.contents.map((column, i) => (
                  <Column key={`morning-${i}`} item={column} />
                ))}
              </ColumnsContainer>
            </SeparatorContent>
          </SeparatorBox>
          <SeparatorBox>
            <Text
              size="12px"
              lineHeight="1"
              weight="bold"
              align="center"
              color={Color.GRAY_DARK}
            >
              {studyTimeZones.afternoon.name}
            </Text>
            <SeparatorContent>
              <Text size="24px" weight="bold" align="center" color="#F15A25">
                {getTimeTest(studyTimeZones.afternoon.contents)}h
              </Text>
              <ColumnsContainer>
                {studyTimeZones.afternoon.contents.map((column, i) => (
                  <Column key={`noon-${i}`} item={column} />
                ))}
              </ColumnsContainer>
            </SeparatorContent>
          </SeparatorBox>
          <SeparatorBox>
            <Text
              size="12px"
              lineHeight="1"
              weight="bold"
              align="center"
              color={Color.GRAY_DARK}
            >
              {studyTimeZones.night.name}
            </Text>
            <SeparatorContent>
              <Text size="24px" weight="bold" align="center" color="#F15A25">
                {getTimeTest(studyTimeZones.night.contents)}h
              </Text>
              <ColumnsContainer>
                {studyTimeZones.night.contents.map((column, i) => (
                  <Column key={`evening-${i}`} item={column} />
                ))}
              </ColumnsContainer>
            </SeparatorContent>
          </SeparatorBox>
        </Flex>
      </Section>
    </Container>
  );
};

export { StudyElement };
