import React from "react";
import { User } from "../../model/entities/User";
import { match, Link } from "react-router-dom";
import {
  ListRowTr,
  ListRowTrIdBorder,
  ListRowTd
} from "../common/styles/ListRowStyle";
import * as H from "history";

type Props = {
  match: match<{ id: string }>;
  student: User;
  history: H.History;
};

class StudentListRow extends React.Component<Props, {}> {
  render() {
    const student = this.props.student;
    const target =
      (student.student?.targets.length || 0) === 0
        ? "-"
        : student.student?.targets[0].name;
    const externalEval = student.student?.external_eval.level
      ? student.student?.external_eval.name +
        " " +
        student.student?.external_eval.level.name
      : "-";

    return (
      <ListRowTr>
        <ListRowTd>{student.affiliation_division.name}</ListRowTd>
        <ListRowTd>
          <Link to={"/client/students/" + this.props.student.id}>
            <ListRowTrIdBorder>
              {student.family_name_ja + " " + student.first_name_ja}
            </ListRowTrIdBorder>
          </Link>
        </ListRowTd>
        <ListRowTd>{target}</ListRowTd>
        <ListRowTd>{externalEval}</ListRowTd>
        <ListRowTd>{student.student?.academic_level.name}</ListRowTd>
      </ListRowTr>
    );
  }
}

export default StudentListRow;
