import styled from "styled-components";

export const ListContentThElement = styled.div`
  position: absolute;
  left: 344px;
  top: 50px;
  padding: 0px 16px 16px 16px;
  /* データが入ってるtableはscrollbarがあり、それに合わせるため */
  margin-right: 16px;
`;

export const ListContentThTable = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: 0;
`;

export const ListContentElement = styled.div`
  position: absolute;
  left: 344px;
  top: 77px;
  bottom: 16px;
  overflow-y: scroll;
  padding: 0px 16px 16px 16px;
`;

export const ListContentTable = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px 8px;
`;

export const ListContentTh = styled.th<{ enableClick: boolean }>`
  background: rgba(112, 112, 112, 1);
  color: white;
  font-size: 18px;
  border-left: solid 5px rgba(239, 239, 239, 1);
  border-right: solid 5px rgba(239, 239, 239, 1);
  border-collapse: separate;
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: ${props => (props.enableClick ? "pointer" : "auto")};
`;

export const ThMultipleLines = styled.th<{ enableClick: boolean }>`
  background: rgba(112, 112, 112, 1);
  color: white;
  font-size: 10px;
  border-left: solid 5px rgba(239, 239, 239, 1);
  border-right: solid 5px rgba(239, 239, 239, 1);
  border-collapse: separate;
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: pointer;
  cursor: ${props => (props.enableClick ? "pointer" : "auto")};
`;

export const SortArrow = styled.span`
  font-size: 14px;
  float: right;
  margin-top: 3px;
  margin-right: 4px;
`;
