import * as React from "react";
import { Container, ChartContainer } from "./styled";
import { GrammarFields, Element, DateType } from "../../types";
import { Text } from "../../../common/Text";
import { BarChart, Bar, LabelList } from "recharts";
import { Section } from "../Section";
import { Flex } from "../../../common/Flex";
import { Spacer } from "../../../common/Spacer";
import { Color } from "../../../../styles";

type Props = {
  grammarField: GrammarFields;
  grammarElement: Element;
  date: DateType;
};

function generateEmptyData(period: string) {
  return {
    score: 0,
    displayScore: 0,
    name: "",
    period: period,
    alphabet: ""
  };
}

function createChartData(grammarField: GrammarFields) {
  // valueが1~9のときは10と表示させる。
  return {
    data: [
      grammarField.before_last
        .sort((lhs, rhs) => (lhs.order > rhs.order ? -1 : 1))
        .map(field => {
          return {
            score: field.value,
            displayScore: (field.value ?? 0) < 10 ? 10 : field.value!,
            name: field.name_ja,
            period: "two_month_ago",
            alphabet: field.alphabet
          };
        })
        // 2ヶ月前のgrammarFieldが存在しない場合の対応。領域を確保するために空のデータを入れる。色の順番を守るために12個入れる。
        .concat(
          grammarField.before_last.length !== 0
            ? []
            : grammarField.current.map(_ => generateEmptyData("two_month_ago"))
        )
        .concat(
          grammarField.last
            .sort((lhs, rhs) => (lhs.order > rhs.order ? -1 : 1))
            .map(field => {
              return {
                score: field.value,
                displayScore: (field.value ?? 0) < 10 ? 10 : field.value!,
                name: field.name_ja,
                period: "last_manth",
                alphabet: field.alphabet
              };
            })
        )
        .concat(
          grammarField.last.length !== 0
            ? []
            : grammarField.current.map(_ => generateEmptyData("last_manth"))
        )
        .concat(
          grammarField.current
            .sort((lhs, rhs) => (lhs.order > rhs.order ? -1 : 1))
            .map(field => {
              return {
                score: field.value,
                displayScore: (field.value ?? 0) < 10 ? 10 : field.value!,
                name: field.name_ja,
                period: "current",
                alphabet: field.alphabet
              };
            })
        )
    ]
  };
}

const activeColors = [
  "#FF9201",
  "#F7BB02",
  "#5FD838",
  "#1FB100",
  "#08A89F",
  "#0576BA",
  "#03A1FF",
  "#57BFFE",
  "#EF5EA8",
  "#FE8BC5",
  "#FE958C",
  "#FE634B"
];

const disableColors = [
  "#e4e4e4",
  "#dfdfdf",
  "#d9d9d9",
  "#d4d4d4",
  "#cecece",
  "#c9c9c9",
  "#c5c5c5",
  "#bfbfbf",
  "#b9b9b9",
  "#b4b4b4",
  "#afafaf",
  "#aaaaaa"
];

function getTopScore(grammarField: GrammarFields) {
  const sortedData = grammarField.current.sort((a, b) => {
    if ((a.value ?? 0) > (b.value ?? 0)) return -1;
    if ((a.value ?? 0) < (b.value ?? 0)) return 1;
    return 0;
  });
  return sortedData.slice(0, 4);
}

function getColor(score: number) {
  if (score >= 80) {
    return "#FE634B";
  }
  if (score >= 50) {
    return "#eaac59";
  }
  if (score >= 31) {
    return Color.GRAY_DARK;
  }
  return Color.GRAY_LIGHT;
}

const GrammarPriority: React.FunctionComponent<Props> = ({
  grammarField,
  grammarElement,
  date
}) => {
  const chartData = createChartData(grammarField);
  const top4 = getTopScore(grammarField);

  function renderCustomizedLabel({ x, y, width }: any) {
    const cx = x + width / 2;
    const cy = y - 10;
    return (
      <text x={cx} y={cy} textAnchor="middle" fontSize="30px" fontWeight="bold">
        <tspan fill="#FE634B">{grammarElement.importance}</tspan>
      </text>
    );
  }

  let copyDisableColors = disableColors;
  return (
    <Container>
      <Section title="英文法 復習 自習レコメンド" titleAlign="center">
        <Spacer pt={1} />
        <Text size="12px" align="center">
          優先度ランキング&amp;文法・語法 重要度(1~100)
        </Text>
        <Flex display="flex" alignItems="center" justifyContent="center">
          <Spacer pr={3} pb={1} pt={1}>
            <Flex display="flex" alignItems="center">
              <Text size="12px">①</Text>
              <Spacer pr={1} />
              <Text
                size="14px"
                weight="bold"
                color={getColor(top4[0].value ?? 0)}
              >{`${top4[0].alphabet} ${top4[0].name_ja}`}</Text>
              <Spacer pr={1} />
              <Text
                size="14px"
                weight="bold"
                color={getColor(top4[0].value ?? 0)}
              >
                {top4[0].value}
              </Text>
            </Flex>
            <Spacer pb={0.5} />
            <Flex display="flex" alignItems="center">
              <Text size="12px">②</Text>
              <Spacer pr={1} />
              <Text
                size="14px"
                weight="bold"
                color={getColor(top4[1].value ?? 0)}
              >{`${top4[1].alphabet} ${top4[1].name_ja}`}</Text>
              <Spacer pr={1} />
              <Text
                size="14px"
                weight="bold"
                color={getColor(top4[1].value ?? 0)}
              >
                {top4[1].value}
              </Text>
            </Flex>
          </Spacer>
          <div>
            <Flex display="flex" alignItems="center">
              <Text size="12px">③</Text>
              <Spacer pr={1} />
              <Text
                size="14px"
                weight="bold"
                color={getColor(top4[2].value ?? 0)}
              >{`${top4[2].alphabet} ${top4[2].name_ja}`}</Text>
              <Spacer pr={1} />
              <Text
                size="14px"
                weight="bold"
                color={getColor(top4[2].value ?? 0)}
              >
                {top4[2].value}
              </Text>
            </Flex>
            <Spacer pb={0.5} />
            <Flex display="flex" alignItems="center">
              <Text size="12px">④</Text>
              <Spacer pr={1} />
              <Text
                size="14px"
                weight="bold"
                color={getColor(top4[3].value ?? 0)}
              >{`${top4[3].alphabet} ${top4[3].name_ja}`}</Text>
              <Spacer pr={1} />
              <Text
                size="14px"
                weight="bold"
                color={getColor(top4[3].value ?? 0)}
              >
                {top4[3].value}
              </Text>
            </Flex>
          </div>
        </Flex>
        <Spacer pt={4} />
        <Text size="12px" align="center">
          復習優先度（Ⅱ重要度指数）の推移
        </Text>
        <ChartContainer>
          <BarChart
            width={500}
            height={1005}
            data={chartData.data}
            margin={{
              top: 180,
              right: 0,
              left: 0,
              bottom: 0
            }}
            barGap={40}
          >
            {chartData.data[0].map((data, i) => {
              if (i % grammarField.current.length === 0) {
                // deep copy
                copyDisableColors = JSON.parse(JSON.stringify(disableColors));
              }
              return (
                <Bar
                  key={i}
                  dataKey={data => data[i].displayScore}
                  stackId={data.period}
                  fill={
                    data.score == null
                      ? copyDisableColors.pop()
                      : activeColors[i % activeColors.length]
                  }
                  label={
                    i === grammarField.current.length * 3 - 1
                      ? (renderCustomizedLabel as any)
                      : undefined
                  }
                >
                  <LabelList
                    className="custom-name-label"
                    dataKey={data =>
                      data[i].period === "current"
                        ? data[i].alphabet + " " + data[i].name
                        : data[i].alphabet
                    }
                  />
                  <LabelList
                    className="custom-score-label"
                    position="right"
                    dataKey={data =>
                      data[i].period === "current"
                        ? data[i].score === null
                          ? "-"
                          : data[i].displayScore
                        : undefined
                    }
                  />
                </Bar>
              );
            })}
          </BarChart>
        </ChartContainer>
        <div style={{ margin: "0 44px" }}>
          <Flex display="flex" justifyContent="space-between">
            <Text width="74px">{date.before_last.name}</Text>
            <Text width="74px">{date.last.name}</Text>
            <Text width="74px">{date.current.name}</Text>
          </Flex>
        </div>
      </Section>
    </Container>
  );
};

export { GrammarPriority };
