import { User } from "../../model/entities/User";
import React from "react";
import CoachListRow from "./CoachListRow";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  ListContentTable,
  ListContentTh,
  SortArrow
} from "../common/styles/ListContentStyle";
import * as H from "history";
import { haveUserValidityEdit } from "../../utils/checkAuthority";
import { Order, SortBy } from "../../utils/Constant";

type Props = {
  history: H.History;
  profile: User | null;
  coaches: User[];
  onChangeSort: (sortBy: SortBy, order: Order) => void;
  sortBy: SortBy;
  order: Order;
};

type State = {};

class CoachListContent extends React.Component<Props, State> {
  render() {
    const listRows = this.props.coaches.map((user: User) => (
      <CoachListRow
        key={user.id}
        user={user}
        history={this.props.history}
        profile={this.props.profile}
      />
    ));
    return (
      <>
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh
                  onClick={this.onClickDivisionTh.bind(this)}
                  enableClick={true}
                >
                  所属
                  <SortArrow>
                    {this.props.sortBy === "affiliation_division"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickNameTh.bind(this)}
                  enableClick={true}
                >
                  氏名
                  <SortArrow>
                    {this.props.sortBy === "name"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickAuthoritySetTh.bind(this)}
                  enableClick={true}
                >
                  権限レベル
                  <SortArrow>
                    {this.props.sortBy === "authority_set"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                {this.props.profile?.authority_set &&
                  haveUserValidityEdit(this.props.profile.authority_set) && (
                    <ListContentTh
                      onClick={this.onClickIsValidTh.bind(this)}
                      enableClick={true}
                    >
                      有効・無効
                      <SortArrow>
                        {this.props.sortBy === "is_valid"
                          ? this.props.order === ""
                            ? "▲"
                            : "▼"
                          : "△"}
                      </SortArrow>
                    </ListContentTh>
                  )}
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement>
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }
  onClickDivisionTh() {
    let order: Order = "";
    if (
      this.props.sortBy === "affiliation_division" &&
      this.props.order === ""
    ) {
      order = "desc";
    }
    this.props.onChangeSort("affiliation_division", order);
  }
  onClickNameTh() {
    let order: Order = "";
    if (this.props.sortBy === "name" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("name", order);
  }
  onClickAuthoritySetTh() {
    let order: Order = "";
    if (this.props.sortBy === "authority_set" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("authority_set", order);
  }
  onClickIsValidTh() {
    let order: Order = "";
    if (this.props.sortBy === "is_valid" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("is_valid", order);
  }
}

export default CoachListContent;
