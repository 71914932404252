import * as React from "react";
import { Container, LeftContainer } from "./styled";
import { Text } from "../../../common/Text";
import { Section } from "../Section";
import { ScoreCircle } from "../ScoreCircle";
import { RecommendTest, PracticalTest } from "../../types";
import { Flex } from "../../../common/Flex";
import { Spacer } from "../../../common/Spacer";
import { Color } from "../../../../styles";

type Props = {
  recommendTest: RecommendTest | null;
  practicalTest: PracticalTest | null;
};

const TestResult: React.FunctionComponent<Props> = ({
  recommendTest,
  practicalTest
}) => {
  return (
    <Container>
      <Section title="テスト結果" titleAlign="center">
        <Flex display="flex" justifyContent="center">
          <LeftContainer>
            <Text align="center" weight="bold">
              AI レコメンドテスト
            </Text>
            <Flex display="flex">
              <Spacer pt={2}>
                <ScoreCircle score={recommendTest ? recommendTest.score : 0} />
              </Spacer>
              <Spacer pl={2} />
              <div>
                <Text size="10px" component="span">
                  苦手①　
                </Text>
                <Text
                  size="12px"
                  component="span"
                  color="#3577bb"
                  weight="bold"
                >
                  {recommendTest
                    ? recommendTest.week_grammar_fields.first.name_ja
                    : "-"}
                </Text>
                <Text size="12px" align="center">
                  正答率
                </Text>
                <Text size="12px" align="center" weight="bold">
                  {recommendTest
                    ? recommendTest.week_grammar_fields.first.correct_percentage
                    : "-"}
                  %
                </Text>
                <Spacer pt={0.5} />
                <Text size="10px" component="span">
                  苦手②　
                </Text>
                <Text
                  size="12px"
                  component="span"
                  color="#3577bb"
                  weight="bold"
                >
                  {recommendTest
                    ? recommendTest.week_grammar_fields.second.name_ja
                    : "-"}
                </Text>
                <Text size="12px" align="center">
                  正答率
                </Text>
                <Text size="12px" align="center" weight="bold">
                  {recommendTest
                    ? recommendTest.week_grammar_fields.second
                        .correct_percentage
                    : "-"}
                  %
                </Text>
              </div>
            </Flex>
          </LeftContainer>
          <Spacer pl={2} />
          <div>
            <Text align="center" weight="bold">
              実用英語力テスト
            </Text>
            <Flex display="flex">
              <Spacer pt={2}>
                <ScoreCircle score={practicalTest ? practicalTest.score : 0} />
              </Spacer>
              <Spacer pl={2} />
              <div>
                <Text size="10px" align="center">
                  順位
                </Text>
                <Text size="12px" align="center">
                  <Text size="14px" component="span" weight="bold">
                    {practicalTest ? practicalTest.rank : "-"}
                  </Text>
                  /{practicalTest ? practicalTest.participant_number : "-"}
                </Text>
                <Spacer pt={0.5} />
                <Text size="10px" align="center">
                  偏差値
                </Text>
                <Text size="14px" align="center" weight="bold">
                  {practicalTest ? practicalTest.deviation : "-"}
                </Text>
                <Spacer pt={0.5} />
                <Text size="12px" align="center">
                  Avg.TIME/目標
                </Text>
                <Text
                  size="12px"
                  align="center"
                  weight="bold"
                  color={Color.GRAY_DARK}
                >
                  <Text
                    size="14px"
                    align="center"
                    color="#d84236"
                    weight="bold"
                    component="span"
                  >
                    {practicalTest
                      ? Math.floor(practicalTest.average_time_ms / 1000 / 60)
                      : "-"}
                    m
                    {practicalTest
                      ? Math.floor((practicalTest.average_time_ms / 1000) % 60)
                      : "-"}
                    s
                  </Text>
                  /
                  {practicalTest
                    ? Math.floor(practicalTest.target_time_ms / 1000 / 60)
                    : "-"}
                  m
                  {practicalTest
                    ? Math.floor((practicalTest.target_time_ms / 1000) % 60)
                    : "-"}
                  s
                </Text>
              </div>
            </Flex>
          </div>
        </Flex>
      </Section>
    </Container>
  );
};

export { TestResult };
