import React from "react";
import {
  ListRowTr,
  ListRowTrIdBorder,
  ListRowTd,
  HilighedListRowTr
} from "../common/styles/ListRowStyle";
import { AuthoritySet } from "../../model/entities/AuthoritySet";
import "rc-switch/assets/index.css";
import * as H from "history";
import styled from "styled-components";
import { User } from "../../model/entities/User";

type Props = {
  authoritySet: AuthoritySet;
  history: H.History;
  profile: User;
};

const Ul = styled.ul`
  padding-left: 30px;
`;

const Li = styled.li`
  margin: 2px auto;
  text-align: left;
`;

class AuthorityListRow extends React.Component<Props, {}> {
  render() {
    if (this.props.profile.authority_set?.id === this.props.authoritySet.id) {
      return <HilighedListRowTr>{this.rowContent()}</HilighedListRowTr>;
    }
    return <ListRowTr>{this.rowContent()}</ListRowTr>;
  }

  rowContent() {
    const authoritySet = this.props.authoritySet;
    const read = authoritySet.authorities
      .filter(authority => {
        return authority.authority_type.name === "read";
      })
      .map(authority => {
        return <Li key={authority.id}>{authority.name}</Li>;
      });
    const create = authoritySet.authorities
      .filter(authority => {
        return authority.authority_type.name === "create";
      })
      .map(authority => {
        return <Li key={authority.id}>{authority.name}</Li>;
      });
    const edit = authoritySet.authorities
      .filter(authority => {
        return authority.authority_type.name === "edit";
      })
      .map(authority => {
        return <Li key={authority.id}>{authority.name}</Li>;
      });
    let deleteAuthority = authoritySet.authorities
      .filter(authority => {
        return authority.authority_type.name === "delete";
      })
      .map(authority => {
        return <Li key={authority.id}>{authority.name}</Li>;
      });
    return (
      <>
        <ListRowTd>{authoritySet.name}</ListRowTd>
        <ListRowTd>
          <ListRowTrIdBorder>{authoritySet.name}</ListRowTrIdBorder>
        </ListRowTd>
        <ListRowTd>
          <Ul>{read.length === 0 ? "-" : read}</Ul>
        </ListRowTd>
        <ListRowTd>
          <Ul>{create.length === 0 ? "-" : create}</Ul>
        </ListRowTd>
        <ListRowTd>
          <Ul>{edit.length === 0 ? "-" : edit}</Ul>
        </ListRowTd>
        <ListRowTd>
          <Ul>{deleteAuthority.length === 0 ? "-" : deleteAuthority}</Ul>
        </ListRowTd>
      </>
    );
  }
}

export default AuthorityListRow;
