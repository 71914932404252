import styled from "styled-components";
import { CSSProperties } from "react";

export const ErrorIconStyle: CSSProperties = {
  fontSize: "600px",
  position: "absolute",
  zIndex: -1,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  color: "rgba(212, 138, 180, 0.5)"
};

export const ErrorBackground = styled.div`
  width: 100%;
  height: 100%;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(255, 255, 255, 0.4);
`;

export const MessageContainer = styled.div`
  width: 100%;
  height: 70%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const StatusCode = styled.p`
  font-size: 100px;
  color: rgba(212, 138, 180, 1);
`;

export const Message = styled.p`
  font-size: 70px;
  color: rgba(212, 138, 180, 1);
`;

export const SubMessage = styled.p`
  font-size: 32px;
  color: rgba(212, 138, 180, 1);
`;
