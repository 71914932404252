import React from "react";
import { QuestionResult } from "../../../../../model/entities/Test";

type Props = {
  question: QuestionResult | null;
};

class RightQuestionContent extends React.Component<Props, {}> {
  render() {
    if (!this.props.question) {
      return <p>表をクリックすると、出題された問題を確認できます。</p>;
    }
    return (
      <>
        <p>第{this.props.question.question_number}問</p>
        <p>{this.props.question.direction}</p>
        <p>{this.props.question.passages.join("\n")}</p>
        <p>{this.props.question.choices.join(" / ")}</p>
        <p>あなたの解答：{this.props.question.answered_choice}</p>
        <p>正解：{this.props.question.answer}</p>
      </>
    );
  }
}

export default RightQuestionContent;
