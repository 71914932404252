import { TestResult } from "../../../../model/entities/TestResult";
import React from "react";
import TestResultDetailListRow from "./TestResultDetailListRow";
import TestContent from "./TestContent";
import { SortArrow } from "../../../common/styles/ListContentStyle";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  ListContentTable,
  ListContentTh,
  TestName
} from "./styles/TestResultDetailListContentStyles";
import * as H from "history";
import { SortBy, Order } from "../../../../utils/Constant";

type State = {};

type Props = {
  history: H.History;
  testResults: TestResult[];
  testContent: TestResult | null;
  onChangeSort: (sortBy: SortBy, order: Order) => void;
  sortBy: SortBy;
  order: Order;
};

class TestResultDetailListContent extends React.Component<Props, State> {
  render() {
    const listRows = this.props.testResults.map(
      (testResult: TestResult, i: number) => (
        <TestResultDetailListRow
          key={i}
          testResult={testResult}
          history={this.props.history}
        />
      )
    );
    return (
      <>
        <TestName>{this.props.testContent?.name}</TestName>
        <TestContent testContent={this.props.testContent} />
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh
                  onClick={this.onClickDivisionTh.bind(this)}
                  enableClick={true}
                >
                  所属
                  <SortArrow>
                    {this.props.sortBy === "affiliation_division"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickCoachTh.bind(this)}
                  enableClick={true}
                >
                  担当コーチ
                  {this.props.sortBy === "coach"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickStudentTh.bind(this)}
                  enableClick={true}
                >
                  生徒氏名
                  {this.props.sortBy === "student"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickScoreTh.bind(this)}
                  enableClick={true}
                >
                  得点
                  {this.props.sortBy === "score"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickRankTh.bind(this)}
                  enableClick={true}
                >
                  順位
                  {this.props.sortBy === "rank"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickDeviationValueTh.bind(this)}
                  enableClick={true}
                >
                  偏差値
                  {this.props.sortBy === "deviation_value"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickSpentTimeTh.bind(this)}
                  enableClick={true}
                >
                  解答時間
                  {this.props.sortBy === "spent_time_ms"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickDateTh.bind(this)}
                  enableClick={true}
                >
                  受験日
                  {this.props.sortBy === "datetime"
                    ? this.props.order === ""
                      ? "▲"
                      : "▼"
                    : "△"}
                </ListContentTh>
                <ListContentTh enableClick={false}>個人結果詳細</ListContentTh>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement>
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }
  onClickDivisionTh() {
    let order: Order = "";
    if (
      this.props.sortBy === "affiliation_division" &&
      this.props.order === ""
    ) {
      order = "desc";
    }
    this.props.onChangeSort("affiliation_division", order);
  }
  onClickCoachTh() {
    let order: Order = "";
    if (this.props.sortBy === "coach" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("coach", order);
  }
  onClickStudentTh() {
    let order: Order = "";
    if (this.props.sortBy === "student" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("student", order);
  }
  onClickScoreTh() {
    let order: Order = "";
    if (this.props.sortBy === "score" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("score", order);
  }
  onClickRankTh() {
    let order: Order = "";
    if (this.props.sortBy === "rank" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("rank", order);
  }
  onClickDeviationValueTh() {
    let order: Order = "";
    if (this.props.sortBy === "deviation_value" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("deviation_value", order);
  }
  onClickSpentTimeTh() {
    let order: Order = "";
    if (this.props.sortBy === "spent_time_ms" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("spent_time_ms", order);
  }
  onClickDateTh() {
    let order: Order = "";
    if (this.props.sortBy === "datetime" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("datetime", order);
  }
}

export default TestResultDetailListContent;
