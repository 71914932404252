import { User } from "../../model/entities/User";
import React from "react";
import {
  ListRowTr,
  ListRowTrIdBorder,
  ListRowTd
} from "../common/styles/ListRowStyle";
import * as H from "history";
import dayjs from "dayjs";
import { Link } from "react-router-dom";

type Props = {
  user: User;
  history: H.History;
};

class StudentListRow extends React.Component<Props, {}> {
  render() {
    const user = this.props.user;
    const startDate = dayjs(Date.parse(user.created_at)).format("YYYY/MM/DD");
    const endDate = dayjs(
      Date.parse(user.affiliation_client.valid_date)
    ).format("YYYY/MM/DD");
    const target =
      (user.student?.targets.length || 0) === 0
        ? "-"
        : user.student?.targets[0].name;
    const externalEval = user.student?.external_eval.level
      ? user.student?.external_eval.name +
        " " +
        user.student?.external_eval.level.name
      : "-";
    return (
      <ListRowTr>
        <ListRowTd>{user.affiliation_division.name}</ListRowTd>
        <ListRowTd>
          <Link to={"/client/students/" + this.props.user.id}>
            <ListRowTrIdBorder>
              {user.family_name_ja + " " + user.first_name_ja}
            </ListRowTrIdBorder>
          </Link>
        </ListRowTd>
        <ListRowTd>
          {user.student?.coach !== null ? (
            <Link to={"/client/coaches/" + this.props.user.student?.coach?.id}>
              <ListRowTrIdBorder>
                {user.student!.coach!.family_name_ja +
                  " " +
                  user.student!.coach!.first_name_ja}
              </ListRowTrIdBorder>
            </Link>
          ) : (
            "-"
          )}
        </ListRowTd>
        <ListRowTd>{target}</ListRowTd>
        <ListRowTd>{externalEval}</ListRowTd>
        <ListRowTd>{user.student?.academic_level.name}</ListRowTd>
        <ListRowTd>
          {startDate}
          <br />~{endDate}
        </ListRowTd>
      </ListRowTr>
    );
  }
}

export default StudentListRow;
