import styled from "styled-components";
import { LoginAreaBackground } from "../../common/Colors";

const LoginArea = styled.div<{ color: string }>`
  background: ${LoginAreaBackground};
  width: 500px;
  margin-top: 16px;
  border-style: solid;
  border-width: 1px;
  text-align: center;
  box-shadow: 0 0 8px gray;
  padding-bottom: 30px;
  display: inline-block;
  pointer-events: auto;
`;

export default LoginArea;
