import styled from "styled-components";

export const Container = styled.div``;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 2px solid #efb056;
  border-radius: 5px;
`;
