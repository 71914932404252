import React from "react";
import { WrapBackground } from "../../common/WrapBackground";
import { get } from "../../../model/api/Request";
import TestResultListContent from "./TestResultListContent";
import {
  TestResultBackground,
  TestResultSidebarBackground
} from "../../common/Colors";
import PagingArea from "../../common/PagingArea";
import { RouteComponentProps } from "react-router-dom";
import { commonErrorHandle } from "../../../utils/errorHandle";
import { Test } from "../../../model/entities/Test";
import LoadingOverlay from "../../common/LoadingOverlay";
import { Profile } from "../../../utils/profile";
import { TopRightArea, FilterButton } from "../../common/styles/TopButton";
import FilterTestResultModal from "./FilterTestResultModal";
import {
  extractPage,
  extractSortBy,
  extractOrder,
  extractFromDate,
  extractToDate,
  extractFromAverageScore,
  extractToAverageScore,
  extractFromParticipantNumber,
  extractToParticipantNumber
} from "../../../utils/query";
import {
  pagingLimit,
  queryPageKey,
  querySortByKey,
  queryOrderKey,
  SortBy,
  Order,
  queryFromDoDateKey,
  queryToDoDateKey,
  queryFromAverageScore,
  queryToAverageScore,
  queryFromParticipantNumber,
  queryToParticipantNumber
} from "../../../utils/Constant";
import querystring from "querystring";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  maxPage: number;
  isLoading: boolean;
  tests: Test[];
  isFilterModalOpen: boolean;
};

class TestResultList extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      maxPage: 0,
      isLoading: false,
      tests: [],
      isFilterModalOpen: false
    };
  }

  componentDidMount() {
    this.getInit();
  }

  render() {
    return (
      <>
        <FilterTestResultModal
          isOpen={this.state.isFilterModalOpen}
          onClose={this.onFilterClose.bind(this)}
          onSearch={this.searchTestResults.bind(this)}
          query={this.getQuery()}
        />
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapBackground
          color={TestResultBackground}
          history={this.props.history}
          profile={this.props.profile}
          selectedSidebar="practical_test_result"
          sidebarColor={TestResultSidebarBackground}
        >
          <FilterButton
            onClick={this.onFilterOpen.bind(this)}
            isTestResultDetail={false}
          >
            検索・フィルター
          </FilterButton>
          <TopRightArea>
            <PagingArea
              page={extractPage(this.getQuery())}
              maxPage={this.state.maxPage}
              onMostBack={this.onMostBackPage.bind(this)}
              onBack={this.onBackPage.bind(this)}
              onNext={this.onNextPage.bind(this)}
              onMostNest={this.onMostNextPage.bind(this)}
            />
          </TopRightArea>
          <TestResultListContent
            history={this.props.history}
            tests={this.state.tests}
            onChangeSort={this.onChangeSort.bind(this)}
            sortBy={extractSortBy(this.getQuery())}
            order={extractOrder(this.getQuery())}
          />
        </WrapBackground>
      </>
    );
  }

  onFilterOpen() {
    this.setState({ isFilterModalOpen: true });
  }

  onFilterClose() {
    this.setState({ isFilterModalOpen: false });
  }

  getInit() {
    this.setState({ isLoading: true });
    let query = "";
    query += `limit=${pagingLimit}`;
    query += `&${queryPageKey}=${extractPage(this.getQuery())}`;
    if (extractOrder(this.getQuery())) {
      query += `&${queryOrderKey}=${extractOrder(this.getQuery())}`;
    }
    if (extractSortBy(this.getQuery())) {
      query += `&${querySortByKey}=${extractSortBy(this.getQuery())}`;
    }
    if (extractFromAverageScore(this.getQuery())) {
      query += `&${queryFromAverageScore}=${extractFromAverageScore(
        this.getQuery()
      )}`;
    }
    if (extractToAverageScore(this.getQuery())) {
      query += `&${queryToAverageScore}=${extractToAverageScore(
        this.getQuery()
      )}`;
    }
    if (extractFromParticipantNumber(this.getQuery())) {
      query += `&${queryFromParticipantNumber}=${extractFromParticipantNumber(
        this.getQuery()
      )}`;
    }
    if (extractToParticipantNumber(this.getQuery())) {
      query += `&${queryToParticipantNumber}=${extractToParticipantNumber(
        this.getQuery()
      )}`;
    }
    if (extractFromDate(this.getQuery())) {
      query += `&${queryFromDoDateKey}=${extractFromDate(this.getQuery())}`;
    }
    if (extractToDate(this.getQuery())) {
      query += `&${queryToDoDateKey}=${extractToDate(this.getQuery())}`;
    }

    Promise.all([
      get("/practical-tests/count?" + query),
      get("/practical-tests?" + query)
    ])
      .then(responses => {
        this.setState({
          maxPage: Math.ceil(responses[0].result.count / pagingLimit),
          isLoading: false,
          tests: responses[1].result
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onMostNextPage() {
    const page = extractPage(this.getQuery());
    if (page === this.state.maxPage) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(this.state.maxPage);
    this.pushWithQuery(query);
  }

  onNextPage() {
    const page = extractPage(this.getQuery());
    if (page === this.state.maxPage) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(page + 1);
    this.pushWithQuery(query);
  }

  onBackPage() {
    const page = extractPage(this.getQuery());
    if (page === 1) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(page - 1);
    this.pushWithQuery(query);
  }

  onMostBackPage() {
    const page = extractPage(this.getQuery());
    if (page === 1) {
      return;
    }
    const query = this.getQuery();
    query[queryPageKey] = String(1);
    this.pushWithQuery(query);
  }

  pushWithQuery(query: { [name: string]: string | string[] }) {
    this.props.history.push(
      this.props.location.pathname + "?" + querystring.stringify(query)
    );
  }

  onChangeSort(sortBy: SortBy, order: Order) {
    const query = this.getQuery();
    query[querySortByKey] = sortBy;
    query[queryOrderKey] = order;
    this.pushWithQuery(query);
  }

  searchTestResults(query: string) {
    let queryDict: { [name: string]: string | string[] } = {};
    if (extractSortBy(this.getQuery())) {
      queryDict[querySortByKey] = extractSortBy(this.getQuery());
    }
    if (extractOrder(this.getQuery())) {
      queryDict[queryOrderKey] = extractOrder(this.getQuery());
    }
    queryDict[queryPageKey] = "1";
    queryDict = Object.assign(queryDict, querystring.parse(query));
    this.pushWithQuery(queryDict);
  }

  getQuery(): { [name: string]: string | string[] } {
    const query = querystring.parse(this.props.location.search.slice(1));
    return query as { [name: string]: string | string[] };
  }
}

export default TestResultList;
