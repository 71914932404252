import * as React from "react";
import {
  Container,
  CurrentStatus,
  StatusLabels,
  CirclesContainer,
  Circle
} from "./styled";
import { Text } from "../../../common/Text";
import { Color } from "../../../../styles";
import { Section } from "../Section";
import { StatusIndicator as StatusIndicatorType } from "../../types";

type Props = {
  index: number;
  item: StatusIndicatorType;
};

const statusIndicatorKeys = ["A", "B", "C", "D", "E", "F"];

function getTitle(item: StatusIndicatorType, index: number) {
  return `${statusIndicatorKeys[index]} ${item.name_ja} ${item.name_en}`;
}

const statusLabels = ["弱い", "平均", "強い"];

const StatusIndicator: React.FunctionComponent<Props> = ({ index, item }) => {
  return (
    <Section title={getTitle(item, index)}>
      <Container>
        <StatusLabels>
          {statusLabels.map(label => (
            <Text key={label} color={Color.GRAY_DARK} weight="bold" size="10px">
              {label}
            </Text>
          ))}
        </StatusLabels>
        <CirclesContainer>
          {Array(9)
            .fill(0)
            .map((_, i) => (
              <Circle key={i} />
            ))}
          <CurrentStatus value={item.value}>
            <Text weight="bold" color={Color.WHITE} size="12px">
              {item.value.toFixed(1)}
            </Text>
          </CurrentStatus>
        </CirclesContainer>
      </Container>
    </Section>
  );
};

export { StatusIndicator };
