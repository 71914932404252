import { AcademicLevel } from "../../../model/entities/AcademicLevel";
import React from "react";
import Modal from "react-modal";
import {
  ModalStyle,
  ButtonArea,
  Button,
  SelectElementArea,
  SelectElementDiv,
  SelectElement,
  TitleLabel
} from "../styles/UserEditModalStyle";
import { BlackButton } from "../../common/Colors";

export function selectAcademocLevelModal(
  academicLevels: AcademicLevel[],
  onSelectAcademicLevel: (e: React.MouseEvent<HTMLDivElement>) => void,
  onClose: () => void,
  isOpen: boolean,
  onBack: () => void
) {
  const academicLevelElements = academicLevels.map(level => {
    return (
      <SelectElementDiv onClick={onSelectAcademicLevel} key={level.id}>
        <SelectElement isSelected={false}>{level.name}</SelectElement>
      </SelectElementDiv>
    );
  });
  return (
    <Modal isOpen={isOpen} style={ModalStyle}>
      <TitleLabel>レベル選択</TitleLabel>
      <SelectElementArea scrollable={true}>
        {academicLevelElements}
      </SelectElementArea>
      <ButtonArea>
        <Button background={BlackButton} onClick={onBack}>
          戻る
        </Button>
      </ButtonArea>
    </Modal>
  );
}
