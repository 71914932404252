import styled from "styled-components";

export const ListContentThElement = styled.div`
  position: absolute;
  left: 344px;
  top: 132px;
  padding: 0px 16px 16px 16px;
  /* データが入ってるtableはscrollbarがあり、それに合わせるため */
  margin-right: 16px;
`;

export const ListContentThTable = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: 0;
`;

export const ListContentElement = styled.div`
  position: absolute;
  left: 344px;
  top: 160px;
  bottom: 50px;
  overflow-y: scroll;
  padding: 0px 16px 16px 16px;
`;

export const ListContentTable = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px 8px;
`;

export const ListContentTh = styled.th<{ enableClick: boolean }>`
  background: rgba(112, 112, 112, 1);
  color: white;
  font-size: 18px;
  border-left: solid 5px rgba(239, 239, 239, 1);
  border-right: solid 5px rgba(239, 239, 239, 1);
  border-collapse: separate;
  position: sticky;
  top: 0;
  z-index: 1;
  cursor: ${props => (props.enableClick ? "pointer" : "auto")};
  &:last-child {
    font-size: 16px;
  }
`;

export const TestName = styled.span`
  font-size: 20px;
  font-weight: bold;
  color: black;
  position: absolute;
  border: solid 2px black;
  text-align: center;
  width: 330px;
  height: 60px;
  top: 18px;
  left: 366px;
  padding-top: 11px;
`;
