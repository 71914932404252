import { AuthoritySet } from "../model/entities/AuthoritySet";

export function haveStudentRead(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "read" &&
        authority.authority_target.name === "student"
    ).length > 0
  );
}

export function haveStudentCreate(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "create" &&
        authority.authority_target.name === "student"
    ).length > 0
  );
}

export function haveStudentEdit(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "edit" &&
        authority.authority_target.name === "student"
    ).length > 0
  );
}

export function haveStudentDelete(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "delete" &&
        authority.authority_target.name === "student"
    ).length > 0
  );
}

export function haveCoachRead(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "read" &&
        authority.authority_target.name === "coach"
    ).length > 0
  );
}

export function haveCoachCreate(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "create" &&
        authority.authority_target.name === "coach"
    ).length > 0
  );
}

export function haveCoachEdit(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "edit" &&
        authority.authority_target.name === "coach"
    ).length > 0
  );
}

export function haveCoachtDelete(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "delete" &&
        authority.authority_target.name === "coach"
    ).length > 0
  );
}

export function haveTestResultRead(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "read" &&
        authority.authority_target.name === "test_result"
    ).length > 0
  );
}

export function haveAuthoritySetRead(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "read" &&
        authority.authority_target.name === "authority_set"
    ).length > 0
  );
}

export function haveAuthoritySetCreate(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "create" &&
        authority.authority_target.name === "authority_set"
    ).length > 0
  );
}

export function haveUserValidityEdit(authoritySet: AuthoritySet) {
  return (
    authoritySet.authorities.filter(
      authority =>
        authority.authority_type.name === "edit" &&
        authority.authority_target.name === "user_validity"
    ).length > 0
  );
}
