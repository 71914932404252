import styled from "styled-components";
import { ContentBackground } from "./Colors";

const WrapContent = styled.div`
  background: ${ContentBackground};
  position: absolute;
  top: 112px;
  right: 16px;
  bottom: 8px;
  left: 16px;
  border-radius: 32px;
  min-width: 1200px;
  min-height: 600px;
`;

export default WrapContent;
