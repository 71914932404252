import styled from "styled-components";
import { PopupFrame } from "../../common/Colors";

export const ModalStyle = {
  overlay: {
    zIndex: 99
  },
  content: {
    top: "50%",
    left: "50%",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "600px",
    maxHeight: "800px",
    border: PopupFrame,
    borderRadius: "48px"
  }
};

export const Title = styled.span`
  font-size: 20px;
  color: rgba(112, 112, 112, 1);
`;

export const Border = styled.div`
  height: 1px;
  width: 100%;
  background: rgba(224, 224, 224, 1);
  margin: 20px 0;
`;

export const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Input = styled.input`
  padding: 12px 8px;
  font-size: 18px;
  border: solid 1px rgba(112, 112, 112, 1);
  border-radius: 8px;
  width: 400px;
  background: ${props => (props.disabled ? "rgba(232, 232, 232, 1)" : "white")};
  &:focus {
    outline: 0;
  }
`;

export const InputHalfArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
`;

export const InputHalf = styled(Input)`
  width: 197px;
`;

export const MarginTopInput = styled(Input)`
  margin-top: 16px;
`;

export const CheckArea = styled.div`
  width: 400px;
`;

export const CheckAreaRow = styled.div`
  display: flex;
`;

export const CheckContent = styled.span`
  width: 200px;
  display: flex;
  align-items: center;
`;

export const CheckLabel = styled.span`
  word-break: break-all;
`;

export const InputCheckbox = styled.input`
  margin-right: 8px;
  cursor: pointer;

  :before {
    content: "";
    margin-top: 2px;
    margin-left: -2px;
    width: 18px;
    height: 18px;
    display: block;
    border: 2px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  :after {
    float: left;
    margin-top: -15px;
    margin-left: 2px;
    font-size: 1.3em;
    line-height: 0.8;
    color: #09ad7e;
    font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
  }

  :not(:checked):after {
    content: "";
  }
  :checked:after {
    content: "\\2713\\0020";
  }
  :hover:before {
    border: 2px solid #4778d9;
  }

  :focus {
    outline: 0;
  }
`;

export const ButtonArea = styled.div`
  text-align: center;
  margin: 12px;
`;

export const Button = styled.button<{ background: string }>`
  margin-right: 12px;
  margin-left: 12px;
  color: white;
  background: ${props => props.background};
  border: none;
  font-size: 18px;
  border-radius: 12px;
  padding: 12px 0;
  width: 120px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;
