import styled from "styled-components";
import { PopupFrame } from "../../common/Colors";

export const ModalStyle = {
  overlay: {
    zIndex: 99
  },
  content: {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "1200px",
    height: "650px",
    border: PopupFrame,
    borderRadius: "48px"
  }
};

export const TitleLabel = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: rgba(112, 112, 112, 1);
  margin-left: 16px;
`;

export const InputCheckbox = styled.input`
  cursor: pointer;

  :before {
    content: "";
    margin-top: -4px;
    margin-left: -2px;
    width: 18px;
    height: 18px;
    display: block;
    border: 2px solid #ccc;
    background: #fff;
    border-radius: 4px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }

  :after {
    float: left;
    margin-top: -15px;
    margin-left: 2px;
    font-size: 1.3em;
    line-height: 0.8;
    color: #09ad7e;
    font-family: "Lucida Sans Unicode", "Arial Unicode MS", Arial;
  }

  :not(:checked):after {
    content: "";
  }
  :checked:after {
    content: "\\2713\\0020";
  }
  :hover:before {
    border: 2px solid #4778d9;
  }

  :focus {
    outline: 0;
  }
`;

export const InputCheckboxLabel = styled.span`
  font-size: 18px;
`;

export const ButtonArea = styled.div`
  text-align: center;
  margin-top: 510px;
`;

export const Button = styled.button<{
  background: string;
}>`
  margin-right: 12px;
  margin-left: 12px;
  color: white;
  background: ${props => props.background};
  border: none;
  font-size: 18px;
  border-radius: 12px;
  padding: 12px 48px;
  filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 16px;
  right: 16px;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0);
  border: none;
  padding: 0;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const CloseButtonImage = styled.img`
  width: 32px;
  height: 32px;
`;

export const ListContentElement = styled.div`
  position: absolute;
  left: 7px;
  top: 82px;
  bottom: 90px;
  overflow-y: scroll;
  padding: 0px 16px 16px 16px;
`;

export const ListContentTable = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0px 8px;
`;

export const ListContentTh = styled.th`
  background: rgba(112, 112, 112, 1);
  color: white;
  font-size: 18px;
  border-left: solid 5px white;
  border-right: solid 5px white;
  border-collapse: separate;
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const ListContentThElement = styled.div`
  position: absolute;
  left: 7px;
  top: 54px;
  padding: 0px 16px 16px 16px;
`;

export const ListContentThTable = styled.table`
  width: 98.5%;
  table-layout: fixed;
  text-align: center;
  border-spacing: 0;
`;
