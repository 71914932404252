import styled from "styled-components";

export const Container = styled.div``;

export const RadarChartContainer = styled.div`
  margin: -10% 0;
  & > .recharts-wrapper {
    width: 100% !important;
    height: auto !important;
    > svg {
      width: 100%;
      height: auto;
    }
  }
  .recharts-legend-wrapper {
    bottom: 10% !important;
  }
`;

export const RadarChartNoData = styled.div`
  height: 350px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
