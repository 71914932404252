import React from "react";
import { ListContentTable } from "../../common/styles/ListContentStyle";
import { User } from "../../../model/entities/User";
import { Th, EditButton, ListContentElement } from "./styles/styles";
import { get } from "../../../model/api/Request";
import { Target } from "../../../model/entities/Target";
import TargetContainerRow from "./TargetContainerRow";
import StudentTargetCreateModal from "../../modal/studnetTarget/StudentTargetCreateModal";
import * as H from "history";
import { haveStudentEdit } from "../../../utils/checkAuthority";

type Props = {
  user: User;
  history: H.History;
  profile: User;
};

type State = {
  targets: Target[];
  isCreateModalOpen: boolean;
  selectTarget: Target | null;
};

class StudneTarget extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      targets: [],
      isCreateModalOpen: false,
      selectTarget: null
    };
  }

  componentDidMount() {
    this.getTargets();
  }
  render() {
    const targets = this.state.targets.map((target: Target) => (
      <TargetContainerRow
        key={target.id}
        target={target}
        user={this.props.user}
        history={this.props.history}
        profile={this.props.profile}
        completeEdit={this.getTargets.bind(this)}
      />
    ));
    return (
      <>
        <EditButton
          top="1%"
          onClick={this.onCreateModalOpen.bind(this)}
          disabled={!haveStudentEdit(this.props.profile.authority_set!)}
        >
          目標の登録
        </EditButton>
        <ListContentElement top="7%" bottom="57%">
          <ListContentTable>
            <thead>
              <tr>
                <Th>目標</Th>
                <Th>実施日</Th>
                <Th>結果</Th>
                {haveStudentEdit(this.props.profile.authority_set!) && (
                  <Th>編集</Th>
                )}
              </tr>
            </thead>
            <tbody>{targets}</tbody>
          </ListContentTable>
        </ListContentElement>
        <StudentTargetCreateModal
          history={this.props.history}
          user={this.props.user}
          isOpen={this.state.isCreateModalOpen}
          onClose={this.onCreateModalClose.bind(this)}
        />
      </>
    );
  }

  onCreateModalOpen() {
    this.setState({
      isCreateModalOpen: true
    });
  }

  onCreateModalClose() {
    this.getTargets();
    this.setState({
      isCreateModalOpen: false
    });
  }

  getTargets() {
    get("/students/" + this.props.user.id + "/targets").then(res => {
      const targets: Target[] = res.result;
      this.setState({ targets: targets });
    });
  }
}

export default StudneTarget;
