import { TestInformation } from "../../../../model/entities/TestInformation";
import React from "react";
import styled from "styled-components";
import { ListContentTable } from "../../../common/styles/ListContentStyle";
import { ListRowTd } from "../../../common/styles/ListRowStyle";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";
import dayjs from "dayjs";

type Props = {
  testInformation: TestInformation | null;
};

const ListContentElement = styled.div`
  position: absolute;
  left: 344px;
  height: 124px;
  padding: 0px 16px 16px 16px;
  overflow: hidden;
`;

class TrainingTestContent extends React.Component<Props, {}> {
  render() {
    const testInformation = this.props.testInformation;
    const Th = styled.th`
      width: 110px;
      height: 40px;
      background: rgba(112, 112, 112, 1);
      color: white;
      font-size: 16px;
      border: solid 5px rgba(239, 239, 239, 1);
      border-collapse: separate;
      &:first-child {
        width: 200px;
      }
    `;
    const Tr = styled.tr`
      background: white;
      width: 100%;
      height: 64px;
      filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
    `;
    return (
      <ListContentElement>
        <ListContentTable>
          <thead>
            <tr>
              <Th>テスト名</Th>
              <Th>平均点</Th>
              <Th>受験者数</Th>
              <Th>問題数</Th>
              <Th>平均解答時間</Th>
              <Th>受験日</Th>
              <Th>結果詳細</Th>
            </tr>
          </thead>
          <tbody>
            <Tr>
              <ListRowTd>{testInformation?.name}</ListRowTd>
              <ListRowTd>
                {testInformation?.average_score !== null &&
                testInformation !== null
                  ? (
                      Math.round(testInformation.average_score * 10) / 10
                    ).toFixed(1)
                  : "-"}
              </ListRowTd>
              <ListRowTd>
                {testInformation?.participant_number !== null
                  ? testInformation?.participant_number + "名"
                  : "-"}
              </ListRowTd>
              <ListRowTd>{testInformation?.question_count}問</ListRowTd>
              <ListRowTd>
                {testInformation?.average_time_ms !== null
                  ? Math.floor(
                      (testInformation?.average_time_ms ?? 0) / 1000 / 60
                    ) +
                    "分" +
                    Math.floor(
                      ((testInformation?.average_time_ms ?? 0) / 1000) % 60
                    ) +
                    "秒"
                  : "-"}
              </ListRowTd>
              <ListRowTd>
                {dayjs(testInformation?.from_date).format("YYYY/MM/DD")}
                <br />～{dayjs(testInformation?.to_date).format("YYYY/MM/DD")}
              </ListRowTd>
              <ListRowTd>
                <Link
                  to={"/client/training_test_results/" + testInformation?.id}
                >
                  <FontAwesomeIcon icon="info-circle" style={DetailIconStyle} />
                </Link>
              </ListRowTd>
            </Tr>
          </tbody>
        </ListContentTable>
      </ListContentElement>
    );
  }
}

const DetailIconStyle: CSSProperties = {
  color: "rgba(112, 112, 112, 1)",
  fontSize: "48px"
};

export default TrainingTestContent;
