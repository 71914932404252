import * as React from "react";
import {
  Container,
  ElementRowTop,
  RowHeight,
  ElementRow,
  AxisElement,
  BarElement,
  TargetArea,
  TargetAreaLabel,
  TargetPoint,
  TargetLine,
  TargetLegendArea,
  TargetPointLegend
} from "./styled";
import { Element } from "../../types";
import { Flex } from "../../../common/Flex";
import { Text } from "../../../common/Text";
import { Spacer } from "../../../common/Spacer";

type Props = {
  elements: Element[];
};

const ElementColors = [
  "#3074b6",
  "#3277b8",
  "#5eb576",
  "#5eb47a",
  "#eb9d3f",
  "#db3d35"
];

function romeNumber(num: number): string {
  switch (num) {
    case 1:
      return "Ⅰ";
    case 2:
      return "Ⅱ";
    case 3:
      return "Ⅲ";
    case 4:
      return "Ⅳ";
    case 5:
      return "Ⅴ";
    case 6:
      return "Ⅵ";
  }
  return "";
}

const TFAElements: React.FunctionComponent<Props> = ({ elements }) => {
  return (
    <Container>
      <ElementRowTop>6 ELEMENTS</ElementRowTop>
      <Spacer pt={0.5} />
      <Flex display="flex" flexDirection="column-reverse">
        {elements.map((element, index) => (
          <ElementRow key={index}>
            <Flex display="flex">
              <Flex
                display="flex"
                alignItems="center"
                height={RowHeight}
                width="88px"
              >
                <Text size="10px">
                  {romeNumber(index + 1) + " " + element.name_ja}
                </Text>
              </Flex>
              <Flex display="flex" alignItems="center">
                <BarElement
                  color={ElementColors[index]}
                  // 最大が320px
                  width={64 * (element.value ?? 0) + "px"}
                ></BarElement>
              </Flex>
            </Flex>
            {element.value != null && (
              <TargetPoint target={element.target ?? 0} />
            )}
            {element.value != null && (
              <TargetLine
                value={element.value ?? 0}
                target={element.target ?? 0}
              />
            )}
          </ElementRow>
        ))}
      </Flex>
      <Spacer pt={2} />
      <AxisElement>
        <Flex display="flex" justifyContent="space-between">
          <Text>0</Text>
          <Text>1</Text>
          <Text>2</Text>
          <Text>3</Text>
          <Text>4</Text>
          <Text>5</Text>
        </Flex>
      </AxisElement>
      <TargetArea />
      <TargetAreaLabel>
        <Text size="10px">目標ゾーン</Text>
      </TargetAreaLabel>
      <TargetLegendArea>
        <TargetPointLegend />
        <Spacer pr={0.5} />
        <Text size="10px">あなたの目標</Text>
      </TargetLegendArea>
    </Container>
  );
};

export { TFAElements };
