import React from "react";
import {
  Element,
  InequalitySign,
  Page,
  MaxPage,
  NumberElement
} from "./styles/PagingAreaStyle";

type Props = {
  page: number;
  maxPage: number;
  onMostBack: () => void;
  onBack: () => void;
  onNext: () => void;
  onMostNest: () => void;

  currentNumber: number | null;
  maxNumber: number | null;
};

type DefaultProps = {
  currentNumber: number | null;
  maxNumber: number | null;
};

class PagingArea extends React.Component<Props, {}> {
  public static defaultProps: Partial<DefaultProps> = {
    currentNumber: null,
    maxNumber: null
  };
  render() {
    return <Element id="pagingArea">{this.getElement()}</Element>;
  }

  getElement() {
    let numberElement = <></>;
    if (this.props.currentNumber !== null && this.props.maxNumber !== null) {
      // 登録者数の最大がない場合はIntの最大値が返ってくる。最大登録者数が100万以上の場合はないので、その場合は最大人数を表示しない
      const max =
        this.props.maxNumber > 1000000 ? "" : "/" + this.props.maxNumber;
      numberElement = (
        <NumberElement>
          現在{this.props.currentNumber}名{max}
        </NumberElement>
      );
    }
    return (
      <>
        {numberElement}
        <InequalitySign onClick={this.props.onMostBack}>≪</InequalitySign>
        <InequalitySign onClick={this.props.onBack}>&lt;</InequalitySign>
        <Page>{this.props.page}</Page>
        <MaxPage>/ {this.props.maxPage}</MaxPage>
        <InequalitySign onClick={this.props.onNext}>&gt;</InequalitySign>
        <InequalitySign onClick={this.props.onMostNest}>≫</InequalitySign>
      </>
    );
  }
}

export default PagingArea;
