import React from "react";
import WrapLoginArea from "./styles/WrapLoginArea";
import LoginArea from "./styles/LoginArea";
import LoginInput from "./styles/LoginInput";
import LoginButton from "./styles/LoginButton";
import Logo from "./styles/Logo";
import { LoginButtonColor } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { post } from "../../model/api/Request";
import querystring from "querystring";
import { CustomError } from "../../model/api/CustomError";
import LoadingOverlay from "../common/LoadingOverlay";
import { isValidPassword } from "../../utils/validation";
import { commonErrorHandle } from "../../utils/errorHandle";

type Props = RouteComponentProps<{}>;

type State = {
  isLoading: boolean;
};

class ActivatePassword extends React.Component<Props, State> {
  password: string = "";
  passwordConfirm: string = "";

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapLoginArea>
          <Logo />
          <LoginArea color="white">
            <LoginInput
              type="password"
              placeholder="新しいPassword"
              index={0}
              autoComplete="new-password"
              onChange={this.onChangePassword.bind(this)}
            />
            <LoginInput
              type="password"
              placeholder="新しいPassword(確認)"
              index={1}
              autoComplete="new-password"
              onChange={this.onChangePasswordConfirm.bind(this)}
            />
            <LoginButton
              color={LoginButtonColor}
              onClick={this.onActivateButton.bind(this)}
            >
              パスワード変更
            </LoginButton>
          </LoginArea>
        </WrapLoginArea>
      </>
    );
  }

  onChangePassword(e: React.ChangeEvent<HTMLInputElement>) {
    this.password = e.target.value;
  }

  onChangePasswordConfirm(e: React.ChangeEvent<HTMLInputElement>) {
    this.passwordConfirm = e.target.value;
  }

  onActivateButton() {
    const errorMessages: string[] = [];
    if (!isValidPassword(this.password)) {
      errorMessages.push(
        "・ パスワードは大文字・小文字・数字・記号を1つ以上含んだ8文字以上を設定してください"
      );
    }

    if (this.password !== this.passwordConfirm) {
      errorMessages.push(
        "・ 確認のパスワードは新パスワードと同じものを入力してください"
      );
    }

    if (errorMessages.length !== 0) {
      alert(errorMessages.join("\n"));
      return;
    }

    const queryString = this.props.location.search.slice(1);
    const query = querystring.parse(queryString);
    this.setState({ isLoading: true });
    post("/login/init", {
      challenge_name: "NEW_PASSWORD_REQUIRED",
      login_id: query["login_id"],
      session: query["session"],
      new_password: this.password
    })
      .then(response => {
        this.setState({ isLoading: false });
        this.props.history.push("/activate/info");
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (error instanceof CustomError) {
          const customError = error as CustomError;
          if (customError.code === "NotAuthorizedException") {
            alert("セッションの期限が切れました。再度ログインしてください。");
            this.props.history.push("/");
            return;
          }
          commonErrorHandle(error, this.props.history);
        }
      });
  }
}

export default ActivatePassword;
