import { TestResult } from "../../../model/entities/TestResult";
import React from "react";
import { ListRowTr, ListRowTd } from "../../common/styles/ListRowStyle";
import * as H from "history";
import dayjs from "dayjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "react";
import { Link } from "react-router-dom";

type Props = {
  testResult: TestResult;
  history: H.History;
};

class StudentTestResultListRow extends React.Component<Props, {}> {
  render() {
    const test = this.props.testResult;
    return (
      <ListRowTr>
        <ListRowTd>{test.academic_level.id}</ListRowTd>
        <ListRowTd>{test.test_number}</ListRowTd>
        <ListRowTd>{test.result.score}</ListRowTd>
        <ListRowTd>
          {test.average_score !== null
            ? (Math.round(test.average_score * 10) / 10).toFixed(1)
            : "-"}
        </ListRowTd>
        <ListRowTd>
          {test.result.deviation_value !== null
            ? (Math.round(test.result.deviation_value * 10) / 10).toFixed(1)
            : "-"}
        </ListRowTd>
        <ListRowTd>
          {dayjs(Date.parse(test.result.do_datetime)).format("YYYY/MM/DD")}
        </ListRowTd>
        <ListRowTd>
          <Link to={"/student/practical_test_results/" + test.result.id}>
            <FontAwesomeIcon icon="info-circle" style={DetailIconStyle} />
          </Link>
        </ListRowTd>
      </ListRowTr>
    );
  }
}

const DetailIconStyle: CSSProperties = {
  color: "rgba(112, 112, 112, 1)",
  fontSize: "48px"
};

export default StudentTestResultListRow;
