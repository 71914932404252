import * as React from "react";
import {
  Container,
  LevelContainer,
  LeveTextContainer,
  LevelBottomContainer,
  BorderBox
} from "./styled";
import LevelTable from "../../../../assets/images/level_table.png";
import { Text } from "../../../common/Text";
import { Spacer } from "../../..//common/Spacer";
import { Flex } from "../../../common/Flex";

type Props = {
  level: number;
  currentExp: string;
  remainingExp: string;
};

const EnglishExamLevel: React.FunctionComponent<Props> = ({
  level,
  currentExp,
  remainingExp
}) => {
  const right = -9.40857 * level + 94.7285;
  let height = 72;
  if (level < 4) {
    height = 23.5;
  } else if (level < 6) {
    height = 48;
  }
  return (
    <Container>
      <img src={LevelTable} alt="" />
      <LevelContainer>
        <LeveTextContainer>
          <Text weight="bold" size="40px" color="#d84236">
            LV.
          </Text>
          <Text
            weight="bold"
            lineHeight="1"
            size="80px"
            color="#d84236"
            marginTop="-7px"
          >
            {level}
          </Text>
        </LeveTextContainer>
      </LevelContainer>
      <LevelBottomContainer>
        <Text weight="bold" size="12px" color="#d84236">
          EXP.
        </Text>
        <Spacer pr={0.5} />
        <Text weight="bold" lineHeight="1" size="21px" color="#d84236">
          {currentExp}
        </Text>
        <Spacer pr={1.5} />
        <Flex>
          <Text weight="bold" size="10px" color="#aaa">
            次のレベル
          </Text>
          <Text weight="bold" size="10px" color="#aaa">
            まであと
          </Text>
        </Flex>
        <Spacer pr={0.5} />
        <Text weight="bold" lineHeight="1" size="21px" color="#d84236">
          {remainingExp}
        </Text>
      </LevelBottomContainer>
      <BorderBox height={`${height}%`} right={`${right}%`} />
    </Container>
  );
};

export { EnglishExamLevel };
