import React from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis
} from "recharts";
import { TestResult } from "../../../../../model/entities/TestResult";

type Props = {
  test: TestResult;
};

class Chart extends React.Component<Props, {}> {
  render() {
    return (
      <RadarChart
        cx="50%"
        cy="50%"
        outerRadius={120}
        width={350}
        height={350}
        data={this.chartData()}
      >
        <PolarGrid />
        <PolarAngleAxis dataKey="tag" />
        <PolarRadiusAxis angle={30} domain={[0, 100]} />
        <Radar
          name="平均"
          dataKey="avg"
          stroke="#ff7f50"
          strokeWidth={4}
          strokeDasharray="3 3"
          fillOpacity={0}
        />
        <Radar
          name="あなた"
          dataKey="user"
          stroke="#4169e1"
          strokeWidth={2.5}
          fillOpacity={0}
        />
      </RadarChart>
    );
  }

  chartData(): ReadonlyArray<any> {
    return this.props.test.result.grammar_fields.map(grammar_field => {
      return {
        tag: grammar_field.name_ja,
        user:
          (grammar_field.correct_count / grammar_field.question_count) * 100,
        avg: grammar_field.average_correct_rate_per
      };
    });
  }
}

export default Chart;
