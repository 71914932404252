import styled from "styled-components";

export const ListContentThElement = styled.div`
  position: absolute;
  left: 344px;
  top: 132px;
  bottom: 16px;
  padding: 0px 16px 16px 16px;
  margin-right: 16px;
`;

export const ListContentThTable = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: 0;
`;

export const ListContentElement = styled.div`
  position: absolute;
  left: 344px;
  top: 167px;
  bottom: 92px;
  overflow-y: scroll;
  padding: 0px 16px 16px 16px;
`;

export const Th = styled.th`
  width: 70px;
  height: 40px;
  background: rgba(112, 112, 112, 1);
  color: white;
  font-size: 16px;
  border: solid 5px rgba(239, 239, 239, 1);
  border-collapse: separate;
  &:first-child {
    width: 50px;
  }
  &:nth-child(2) {
    width: 348px;
  }
  &:nth-child(3) {
    width: 50px;
  }
  &:nth-child(5) {
    width: 80px;
  }
`;

export const Td = styled.td`
  width: 70px;
  height: 40px;
  word-break: break-all;
  &:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }
  &:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }
  &:first-child {
    width: 50px;
  }
  &:nth-child(2) {
    width: 348px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
    word-break: normal;
  }
  &:nth-child(3) {
    width: 50px;
  }
  &:nth-child(5) {
    width: 78px;
  }
`;

export const TagElement = styled.div`
  position: absolute;
  left: 344px;
  top: 91%;
  padding: 0px 16px 16px 16px;
  margin-right: 16px;
`;

export const TagTable = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-collapse: collapse;
`;

export const TagTh = styled.th`
  border-top: solid 2px black;
  border-bottom: solid 1px black;
  &:first-child {
    border-right: solid 2px black;
    border-left: solid 2px black;
  }
  &:last-child {
    border-right: solid 2px black;
  }
`;

export const TagTd = styled.td`
  border-top: solid 1px black;
  border-bottom: solid 2px black;
  &:first-child {
    border-right: solid 2px black;
    border-left: solid 2px black;
  }
  &:last-child {
    border-right: solid 2px black;
  }
`;
