import styled from "styled-components";

const SIZE = 70;

export const CircleContainer = styled.div`
  width: ${SIZE}px;
  height: ${SIZE}px;
  border-radius: 50%;
  background-color: #f2f2f2;
  position: relative;
  &.gt-50 {
    background-color: #d84236;
  }
`;
export const CircleBackground = styled.div`
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - ${SIZE / 2}px);
  top: calc(50% - ${SIZE / 2}px);
  width: ${SIZE}px;
  height: ${SIZE}px;
  clip: rect(0, ${SIZE}px, ${SIZE}px, ${SIZE / 2}px);
`;
export const Progress = styled.div`
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - ${SIZE / 2}px);
  top: calc(50% - ${SIZE / 2}px);
  width: ${SIZE}px;
  height: ${SIZE}px;
  clip: rect(0, ${SIZE}px, ${SIZE}px, ${SIZE / 2}px);
  .gt-50 & {
    clip: rect(0, ${SIZE / 2}px, ${SIZE}px, 0);
    & div {
      clip: rect(0, ${SIZE}px, ${SIZE}px, ${SIZE / 2}px);
      background: #f2f2f2;
    }
  }
`;

export const ProgressFill = styled.div<{ rotate: number }>`
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - ${SIZE / 2}px);
  top: calc(50% - ${SIZE / 2}px);
  width: ${SIZE}px;
  height: ${SIZE}px;
  clip: rect(0, ${SIZE / 2}px, ${SIZE}px, 0);
  background: #d84236;
  transform: ${({ rotate }) => `rotate(${rotate}deg);`};
`;

export const ChildrenContainer = styled.div`
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - ${SIZE / 1.25}px / 2);
  top: calc(50% - ${SIZE / 1.25}px / 2);
  width: ${SIZE / 1.25}px;
  height: ${SIZE / 1.25}px;
  background: #f2f2f2;
  text-align: center;
  display: table;
`;

export const Children = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;
