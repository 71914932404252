import { User } from "../../model/entities/User";
import React from "react";
import StudentListRow from "./StudentListRow";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  ListContentTable,
  ListContentTh,
  SortArrow
} from "../common/styles/ListContentStyle";
import * as H from "history";
import { SortBy, Order } from "../../utils/Constant";

type State = {};

type Props = {
  history: H.History;
  profile: User | null;
  students: User[];
  onChangeSort: (sortBy: SortBy, order: Order) => void;
  sortBy: SortBy;
  order: Order;
};

class StudentListContent extends React.Component<Props, State> {
  render() {
    const listRows = this.props.students.map((user: User) => (
      <StudentListRow key={user.id} user={user} history={this.props.history} />
    ));
    return (
      <>
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh
                  onClick={this.onClickDivisionTh.bind(this)}
                  enableClick={true}
                >
                  所属
                  <SortArrow>
                    {this.props.sortBy === "affiliation_division"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickNameTh.bind(this)}
                  enableClick={true}
                >
                  氏名
                  <SortArrow>
                    {this.props.sortBy === "name"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh enableClick={false}>担当コーチ</ListContentTh>
                <ListContentTh enableClick={false}>目標</ListContentTh>
                <ListContentTh enableClick={false}>取得資格</ListContentTh>
                <ListContentTh
                  onClick={this.onClickAcademicLevelTh.bind(this)}
                  enableClick={true}
                >
                  レベル
                  <SortArrow>
                    {this.props.sortBy === "academic_level"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh enableClick={false}>利用期間</ListContentTh>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement>
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }

  onClickDivisionTh() {
    let order: Order = "";
    if (
      this.props.sortBy === "affiliation_division" &&
      this.props.order === ""
    ) {
      order = "desc";
    }
    this.props.onChangeSort("affiliation_division", order);
  }
  onClickNameTh() {
    let order: Order = "";
    if (this.props.sortBy === "name" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("name", order);
  }
  onClickAcademicLevelTh() {
    let order: Order = "";
    if (this.props.sortBy === "academic_level" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("academic_level", order);
  }
}

export default StudentListContent;
