import React from "react";
import { WrapBackground } from "../common/WrapBackground";
import { StudentBackground } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import StudentDetailContent from "./StudentDetailContent";
import { Profile } from "../../utils/profile";

type Props = RouteComponentProps<{ id: string }> & Profile;

type State = {};

class StudentDetail extends React.Component<Props, State> {
  render() {
    return (
      <WrapBackground
        color={StudentBackground}
        history={this.props.history}
        profile={this.props.profile}
      >
        <StudentDetailContent
          match={this.props.match}
          history={this.props.history}
          location={this.props.location}
          profile={this.props.profile}
        />
      </WrapBackground>
    );
  }
}

export default StudentDetail;
