import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { WrapBackground } from "../common/WrapBackground";
import { HomeArea, HomeAreaColumn } from "./styles/HomeArea";
import { HomeButton } from "./HomeButton";
import { HomeBackground } from "../common/Colors";
import {
  haveCoachRead,
  haveStudentRead,
  haveTestResultRead
} from "../../utils/checkAuthority";
import { Profile } from "../../utils/profile";

type Props = RouteComponentProps<{}> & Profile;

type State = {};

class ClientHome extends React.Component<Props, State> {
  render() {
    return (
      <WrapBackground
        history={this.props.history}
        profile={this.props.profile}
        color={HomeBackground}
      >
        <HomeArea>
          <HomeAreaColumn>
            {this.props.profile.authority_set &&
              haveCoachRead(this.props.profile.authority_set) && (
                <Link to="/client/coaches">
                  <HomeButton path="/assets/CoachList.png" />
                </Link>
              )}
            {this.props.profile.authority_set &&
              haveStudentRead(this.props.profile.authority_set) && (
                <Link to="/client/students">
                  <HomeButton path="/assets/StudentList.png" />
                </Link>
              )}
          </HomeAreaColumn>
          <HomeAreaColumn>
            {this.props.profile.authority_set &&
              haveTestResultRead(this.props.profile.authority_set) && (
                <Link to="/client/practical_test_results">
                  <HomeButton path="/assets/PracticalTestResult.png" />
                </Link>
              )}
            {this.props.profile.authority_set &&
              haveTestResultRead(this.props.profile.authority_set) && (
                <Link to="/client/training_test_results">
                  <HomeButton path="/assets/TrainingTestResult.png" />
                </Link>
              )}
          </HomeAreaColumn>
        </HomeArea>
      </WrapBackground>
    );
  }
}

export default ClientHome;
