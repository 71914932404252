import styled from "styled-components";
import { Color } from "../../../../styles";

export const Container = styled.div``;

export const Badge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #efb056;
  border-radius: 5px;
`;

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-color: transparent transparent transparent ${Color.GRAY_LIGHT};
`;
