import { TestResult } from "../../../../model/entities/TestResult";
import React from "react";
import styled from "styled-components";
import { ListContentTable } from "../../../common/styles/ListContentStyle";
import { ListRowTd } from "../../../common/styles/ListRowStyle";
import dayjs from "dayjs";

type Props = {
  test: TestResult;
};

const ListContentElement = styled.div`
  position: absolute;
  left: 344px;
  bottom: 12px;
  height: 124px;
  padding: 0px 16px 16px 16px;
  overflow: hidden;
`;

class TestContent extends React.Component<Props, {}> {
  render() {
    const test = this.props.test;
    const Th = styled.th`
      background: rgba(112, 112, 112, 1);
      color: white;
      font-size: 18px;
      border: solid 5px rgba(239, 239, 239, 1);
      border-collapse: separate;
    `;
    const Tr = styled.tr`
      background: white;
      width: 100%;
      height: 64px;
      filter: drop-shadow(0 2px 2px rgba(0, 0, 0, 0.3));
    `;
    return (
      <ListContentElement>
        <ListContentTable>
          <thead>
            <tr>
              <Th>偏差値</Th>
              <Th>順位</Th>
              <Th>受験者数</Th>
              <Th>問題数</Th>
              <Th>平均点</Th>
              <Th>平均解答時間</Th>
              <Th>受験日</Th>
            </tr>
          </thead>
          <tbody>
            <Tr>
              <ListRowTd>
                {test.result.deviation_value !== null
                  ? (Math.round(test.result.deviation_value * 10) / 10).toFixed(
                      1
                    )
                  : "-"}
              </ListRowTd>
              <ListRowTd>
                {test.result.rank !== null ? test.result.rank : "-"}
              </ListRowTd>
              <ListRowTd>
                {test.participant_number !== null
                  ? test.participant_number + "名"
                  : "-"}
              </ListRowTd>
              <ListRowTd>{test.question_count}問</ListRowTd>
              <ListRowTd>
                {test.average_score !== null
                  ? (Math.round(test.average_score * 10) / 10).toFixed(1)
                  : "-"}
              </ListRowTd>
              <ListRowTd>
                {test.average_time_ms !== null
                  ? Math.floor((test.average_time_ms ?? 0) / 1000 / 60) +
                    "分" +
                    Math.floor(((test.average_time_ms ?? 0) / 1000) % 60) +
                    "秒"
                  : "-"}
              </ListRowTd>
              <ListRowTd>
                {dayjs(Date.parse(test.result.do_datetime)).format(
                  "YYYY/MM/DD"
                )}
              </ListRowTd>
            </Tr>
          </tbody>
        </ListContentTable>
      </ListContentElement>
    );
  }
}

export default TestContent;
