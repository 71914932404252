import React from "react";
import { WrapBackground } from "../../common/WrapBackground";
import { get } from "../../../model/api/Request";
import StudentTestResultListContent from "./StudentTestResultListContent";
import {
  TestResultBackground,
  TestResultSidebarBackground
} from "../../common/Colors";
import PagingArea from "../../common/PagingArea";
import { RouteComponentProps } from "react-router-dom";
import { commonErrorHandle } from "../../../utils/errorHandle";
import { pagingLimit } from "../../../utils/Constant";
import { TestResult } from "../../../model/entities/TestResult";
import LoadingOverlay from "../../common/LoadingOverlay";
import { Profile } from "../../../utils/profile";
import { TopRightArea } from "../../common/styles/TopButton";

type Props = RouteComponentProps<{}> & Profile;

type State = {
  page: number;
  maxPage: number;
  isLoading: boolean;
  testResults: TestResult[];
};

// 生徒ログインした場合のテスト一覧
class StudentTestResultList extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      maxPage: 0,
      isLoading: false,
      testResults: []
    };
  }

  componentDidMount() {
    this.getInit();
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapBackground
          color={TestResultBackground}
          history={this.props.history}
          profile={this.props.profile}
          selectedSidebar="practical_test_result"
          sidebarColor={TestResultSidebarBackground}
        >
          <TopRightArea>
            <PagingArea
              page={this.state.page}
              maxPage={this.state.maxPage}
              onMostBack={this.onMostBackPage.bind(this)}
              onBack={this.onBackPage.bind(this)}
              onNext={this.onNextPage.bind(this)}
              onMostNest={this.onMostNextPage.bind(this)}
            />
          </TopRightArea>
          <StudentTestResultListContent
            history={this.props.history}
            testResults={this.state.testResults}
          />
        </WrapBackground>
      </>
    );
  }

  getInit() {
    this.setState({ isLoading: true });
    Promise.all([
      get("/student/practical-tests/count"),
      get("/student/practical-tests?limit=" + pagingLimit + "&page=1")
    ])
      .then(responses => {
        this.setState({
          maxPage: Math.ceil(responses[0].result.count / pagingLimit),
          isLoading: false,
          testResults: responses[1].result
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }

  onMostNextPage() {
    if (this.state.page === this.state.maxPage) {
      return;
    }
    this.getTests(this.state.maxPage);
  }

  onNextPage() {
    if (this.state.page === this.state.maxPage) {
      return;
    }
    this.getTests(this.state.page + 1);
  }

  onBackPage() {
    if (this.state.page === 1) {
      return;
    }
    this.getTests(this.state.page - 1);
  }

  onMostBackPage() {
    if (this.state.page === 1) {
      return;
    }
    this.getTests(1);
  }

  getTests(page: number) {
    this.setState({
      isLoading: true
    });
    get("/student/practical-tests?limit=" + pagingLimit + "&page=" + page)
      .then(json => {
        this.setState({
          testResults: json.result,
          page: page,
          isLoading: false
        });
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default StudentTestResultList;
