import React from "react";
import { HomeButtonStyle } from "./styles/HomeButtonStyle";

type Props = {
  path: string;
};

export class HomeButton extends React.Component<Props, {}> {
  render() {
    return <HomeButtonStyle src={this.props.path} alt="logo" />;
  }
}
