import * as React from "react";

import { library } from "@fortawesome/fontawesome-svg-core"; //fontawesomeのコアファイル
import { fas } from "@fortawesome/free-solid-svg-icons"; //fontawesomeのsolidアイコンのインポート

//Route関連
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

// コンテナ読み込み
import AuthRoute from "./AuthRoute";
import StudentList from "./containers/studentList/StudentList";
import Login from "./containers/login/Login";
import ActivatePassword from "./containers/login/ActivatePassword";
import ActivateInfo from "./containers/login/ActivateInfo";
import Verify from "./containers/login/Verify";
import ChangeEmail from "./containers/login/ChangeEmail";
import AccountEdit from "./containers/accountEdit/AccountEdit";
import PasswordEdit from "./containers/accountEdit/PasswordEdit";
import ForgetPassword from "./containers/login/ForgetPassword";
import PasswordVefify from "./containers/login/PasswordVefify";
import ClientHome from "./containers/home/ClientHome";
import StudentHome from "./containers/home/StudentHome";
import StudentDetail from "./containers/student/StudentDetail";
import StudentTargetAndExternalEvaluation from "./containers/student/targetAndExternalEvaluation/StudentTargetAndExternalEvaluation";
import CoachList from "./containers/coachList/CoachList";
import CoachDetail from "./containers/coach/CoachDetail";
import TestResultList from "./containers/testResultList/practicalTest/TestResultList";
import TrainingTestResultList from "./containers/testResultList/trainingTest/TrainingTestResultList";
import TestResultDetailList from "./containers/testResultList/practicalTest/testResultDetail/TestResultDetailList";
import TrainingTestResultDetailList from "./containers/testResultList/trainingTest/trainingTestResultDetail/TrainingTestResultDetailList";
import StudentTrainingTestResultDetailList from "./containers/studentTestResultList/trainingTest/studentTrainingTestResultDetail/StudentTrainingTestResultDetailList";
import TestResultDetail from "./containers/testResultList/practicalTest/testResult/TestResultDetail";
import StudentTestResultList from "./containers/studentTestResultList/practicalTest/StudentTestResultList";
import StudentTrainingTestResultList from "./containers/studentTestResultList/trainingTest/StudentTrainingTestResultList";
import StudentTestResultDetail from "./containers/studentTestResultList/practicalTest/testResult/StudentTestResultDetail";
import QuestionList from "./containers/testResultList/practicalTest/questionList/QuestionList";
import TrainingQuestionList from "./containers/testResultList/trainingTest/trainingQuestionList/TrainingQuestionList";
import StudentTrainingQuestionList from "./containers/studentTestResultList/trainingTest/studentTrainingQuestionList/StudentTrainingQuestionList";
import AuthorityList from "./containers/authorityList/AuthorityList";
import MonthlyReport from "./containers/MonthlyReport";
import Error400 from "./containers/errors/Error400";
import Error404 from "./containers/errors/Error404";
import Error500 from "./containers/errors/Error500";

import { GlobalStyle } from "./styles/globalStyle";
import Modal from "react-modal";

library.add(fas);

Modal.setAppElement("#root");
export class App extends React.Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route
              exact
              path="/activate/password"
              component={ActivatePassword}
            />
            <AuthRoute exact path="/activate/info" component={ActivateInfo} />
            <AuthRoute exact path="/verify" component={Verify} />
            <AuthRoute exact path="/email/change" component={ChangeEmail} />
            <Route exact path="/forget/password" component={ForgetPassword} />
            <Route exact path="/password/verify" component={PasswordVefify} />
            <AuthRoute exact path="/account/edit" component={AccountEdit} />
            <AuthRoute exact path="/password/edit" component={PasswordEdit} />
            <AuthRoute exact path="/client" component={ClientHome} />
            <AuthRoute exact path="/client/students" component={StudentList} />
            <AuthRoute
              exact
              path="/client/students/:id"
              component={StudentDetail}
            />
            <AuthRoute
              exact
              path="/client/students/:id/targets"
              component={StudentTargetAndExternalEvaluation}
            />
            <AuthRoute
              exact
              path="/client/practical_test_results"
              component={TestResultList}
            />
            <AuthRoute
              exact
              path="/client/practical_test_results/:test_id/:test_num/:academic_level_id"
              component={TestResultDetailList}
            />
            <AuthRoute
              exact
              path="/client/practical_test_results/student/:id"
              component={TestResultDetail}
            />
            <AuthRoute
              exact
              path="/client/practical_test_results/questions/:test_id/:test_num/:academic_level_id"
              component={QuestionList}
            />
            <AuthRoute
              exact
              path="/client/training_test_results"
              component={TrainingTestResultList}
            />
            <AuthRoute
              exact
              path="/client/training_test_results/:id"
              component={TrainingTestResultDetailList}
            />
            <AuthRoute
              exact
              path="/client/training_test_results/questions/:id"
              component={TrainingQuestionList}
            />
            <AuthRoute exact path="/client/coaches" component={CoachList} />
            <AuthRoute
              exact
              path="/client/coaches/:id"
              component={CoachDetail}
            />
            <AuthRoute
              exact
              path="/client/authorities"
              component={AuthorityList}
            />
            <AuthRoute
              exact={true}
              path="/client/reports/:id"
              component={MonthlyReport}
            />
            <AuthRoute exact path="/student" component={StudentHome} />
            <AuthRoute
              exact
              path="/student/practical_test_results"
              component={StudentTestResultList}
            />
            <AuthRoute
              exact
              path="/student/practical_test_results/:id"
              component={StudentTestResultDetail}
            />
            <AuthRoute
              exact
              path="/student/training_test_results"
              component={StudentTrainingTestResultList}
            />
            <AuthRoute
              exact
              path="/student/training_test_results/:id"
              component={StudentTrainingTestResultDetailList}
            />
            <AuthRoute
              exact
              path="/student/training_test_results/questions/:id"
              component={StudentTrainingQuestionList}
            />
            <Route exact path="/400" component={Error400} />
            <Route exact path="/500" component={Error500} />
            <Route exact component={Error404} />
          </Switch>
        </Router>
      </>
    );
  }
}
