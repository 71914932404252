import { TargetType } from "./../model/entities/TargetType";

export function getTargetTypesRecursively(
  rootTargetType: TargetType,
  parentTargetType: TargetType | null = null,
  targetTypes: TargetType[] = []
) {
  const targetType: TargetType = {
    id: rootTargetType.id,
    name: (parentTargetType?.name ?? "") + " " + rootTargetType.name,
    academic_level: null,
    child_target_types: []
  };
  if (!rootTargetType.child_target_types) {
    targetTypes.push(targetType);
    return targetTypes;
  }
  rootTargetType.child_target_types.forEach(targetType => {
    getTargetTypesRecursively(targetType, rootTargetType, targetTypes);
  });
  return targetTypes;
}
