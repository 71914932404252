import React from "react";
import {
  Element,
  StringElement,
  Affiliation,
  Name,
  LogouButton
} from "./styles/ProfileAreaStyle";
import { get } from "./../../model/api/Request";
import * as H from "history";
import LoadingOverlay from "../common/LoadingOverlay";
import { User } from "../../model/entities/User";
import { Auth } from "aws-amplify";

type Props = {
  history: H.History;
  profile: User | null;
};

type State = {
  isLoading: boolean;
};

class ProfileArea extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <Element>
          <StringElement>
            <Affiliation>
              {this.props.profile?.affiliation_division.name}
            </Affiliation>
            <Name>
              {(this.props.profile?.family_name_ja || "") +
                " " +
                (this.props.profile?.first_name_ja || "")}
            </Name>
          </StringElement>
          <LogouButton onClick={this.onLogout.bind(this)}>
            ログアウト
          </LogouButton>
        </Element>
      </>
    );
  }
  onLogout() {
    this.setState({ isLoading: true });
    get("/logout")
      .then(res => {
        this.forceLogout();
      })
      .catch(error => {
        this.forceLogout();
      });
  }

  forceLogout() {
    localStorage.removeItem("token");
    Auth.signOut().then(() => {
      this.props.history.push("/");
    });
  }
}

export default ProfileArea;
