import React from "react";
import { ListRowTd } from "../../common/styles/ListRowStyle";
import { Target } from "../../../model/entities/Target";
import { User } from "../../../model/entities/User";
import { Tr, RowEditButton } from "./styles/styles";
import * as H from "history";
import StudentTargetEditModal from "../../modal/studnetTarget/StudentTargetEditModal";
import { haveStudentEdit } from "../../../utils/checkAuthority";

type Props = {
  target: Target;
  user: User;
  history: H.History;
  profile: User;
  completeEdit: () => void;
};

type State = {
  isEditModalOpen: boolean;
};

class TargetContainerRow extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      isEditModalOpen: false
    };
  }

  render() {
    const target = this.props.target;
    return (
      <>
        <Tr>
          <ListRowTd>{target.name}</ListRowTd>
          <ListRowTd>{target.do_date}</ListRowTd>
          <ListRowTd>{target.result.is_cleared ? "達成" : "未達成"}</ListRowTd>

          {haveStudentEdit(this.props.profile.authority_set!) && (
            <ListRowTd>
              <RowEditButton onClick={this.onEditModalOpen.bind(this)}>
                編集
              </RowEditButton>
            </ListRowTd>
          )}
        </Tr>
        {this.state.isEditModalOpen && (
          <StudentTargetEditModal
            history={this.props.history}
            user={this.props.user}
            target={this.props.target}
            isOpen={this.state.isEditModalOpen}
            onClose={this.onEditModalClose.bind(this)}
          />
        )}
      </>
    );
  }

  onEditModalOpen() {
    this.setState({ isEditModalOpen: true });
  }

  onEditModalClose() {
    this.props.completeEdit();
    this.setState({ isEditModalOpen: false });
  }
}

export default TargetContainerRow;
