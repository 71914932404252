import styled from "styled-components";
import { Space, Color } from "../../../../../../styles";

export const Container = styled.div`
  position: relative;
  display: flex;
  margin-top: ${Space}px;
`;

export const Element = styled.div<{ background: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 70px;
  padding: ${Space}px ${Space * 0.5}px;
  background-color: ${({ background }) => background};
  border-radius: 8px;
  & + & {
    margin-left: ${Space}px;
  }
`;

export const Badge = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: ${Color.GRAY_DARK};
  color: ${Color.WHITE};
  font-weight: bold;
  font-size: 10px;
`;
