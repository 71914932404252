import { Test } from "../../../model/entities/Test";
import React from "react";
import StudentTrainingTestResultListRow from "./StudentTrainingTestResultListRow";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  ListContentTable,
  ListContentTh
} from "../../common/styles/ListContentStyle";
import * as H from "history";
import { SortBy, Order } from "./StudentTrainingTestResultList";

type State = {};

type Props = {
  history: H.History;
  tests: Test[];
  onChangeSort: (sortBy: SortBy, order: Order) => void;
  sortBy: SortBy;
  order: Order;
};

class StudentTrainingTestResultListContent extends React.Component<
  Props,
  State
> {
  render() {
    const listRows = this.props.tests.map((test: Test) => (
      <StudentTrainingTestResultListRow
        key={test.id}
        test={test}
        history={this.props.history}
      />
    ));
    return (
      <>
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh enableClick={true}>分野</ListContentTh>
                <ListContentTh enableClick={true}>レベル</ListContentTh>
                <ListContentTh enableClick={true}>テスト番号</ListContentTh>
                <ListContentTh enableClick={true}>直近の得点</ListContentTh>
                <ListContentTh enableClick={true}>平均点</ListContentTh>
                <ListContentTh enableClick={false}>受験日</ListContentTh>
                <ListContentTh enableClick={false}>結果詳細</ListContentTh>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement className="scrollListElement">
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }
  onClickDateTh() {
    let order: Order = "";
    if (this.props.sortBy === "datetime" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("datetime", order);
  }
  onClickDivisionTh() {
    let order: Order = "";
    if (
      this.props.sortBy === "affiliation_division" &&
      this.props.order === ""
    ) {
      order = "desc";
    }
    this.props.onChangeSort("affiliation_division", order);
  }
  onClickNameTh() {
    let order: Order = "";
    if (this.props.sortBy === "name" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("name", order);
  }
  onClickTestNameTh() {
    let order: Order = "";
    if (this.props.sortBy === "test_name" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("test_name", order);
  }
  onClickScorelTh() {
    let order: Order = "";
    if (this.props.sortBy === "score" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("score", order);
  }
}

export default StudentTrainingTestResultListContent;
