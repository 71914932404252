import { Test } from "../../../model/entities/Test";
import React from "react";
import TestResultListRow from "./TestResultListRow";
import {
  ListContentThElement,
  ListContentThTable,
  ListContentElement,
  ListContentTable,
  ListContentTh,
  SortArrow
} from "../../common/styles/ListContentStyle";
import * as H from "history";
import { SortBy, Order } from "../../../utils/Constant";

type State = {};

type Props = {
  history: H.History;
  tests: Test[];
  onChangeSort: (sortBy: SortBy, order: Order) => void;
  sortBy: SortBy;
  order: Order;
};

class TestResultListContent extends React.Component<Props, State> {
  render() {
    const listRows = this.props.tests.map((test: Test, i: number) => {
      return (
        <TestResultListRow key={i} test={test} history={this.props.history} />
      );
    });
    return (
      <>
        <ListContentThElement>
          <ListContentThTable>
            <thead>
              <tr>
                <ListContentTh
                  onClick={this.onClickAcademicLevelTh.bind(this)}
                  enableClick={true}
                >
                  レベル
                  <SortArrow>
                    {this.props.sortBy === "academic_level"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickTestNumberTh.bind(this)}
                  enableClick={true}
                >
                  テスト番号
                  <SortArrow>
                    {this.props.sortBy === "test_number"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickAverageScoreTh.bind(this)}
                  enableClick={true}
                >
                  平均点
                  <SortArrow>
                    {this.props.sortBy === "average_score"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickParticipantNumberTh.bind(this)}
                  enableClick={true}
                >
                  受験者数
                  <SortArrow>
                    {this.props.sortBy === "participant_number"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh
                  onClick={this.onClickDoDatetimeTh.bind(this)}
                  enableClick={true}
                >
                  受験日
                  <SortArrow>
                    {this.props.sortBy === "to_date"
                      ? this.props.order === ""
                        ? "▲"
                        : "▼"
                      : "△"}
                  </SortArrow>
                </ListContentTh>
                <ListContentTh enableClick={false}>問題一覧</ListContentTh>
                <ListContentTh enableClick={false}>結果詳細</ListContentTh>
              </tr>
            </thead>
          </ListContentThTable>
        </ListContentThElement>
        <ListContentElement>
          <ListContentTable>
            <tbody>{listRows}</tbody>
          </ListContentTable>
        </ListContentElement>
      </>
    );
  }

  onClickAcademicLevelTh() {
    let order: Order = "";
    if (this.props.sortBy === "academic_level" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("academic_level", order);
  }

  onClickTestNumberTh() {
    let order: Order = "";
    if (this.props.sortBy === "test_number" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("test_number", order);
  }

  onClickAverageScoreTh() {
    let order: Order = "";
    if (this.props.sortBy === "average_score" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("average_score", order);
  }

  onClickParticipantNumberTh() {
    let order: Order = "";
    if (this.props.sortBy === "participant_number" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("participant_number", order);
  }

  onClickDoDatetimeTh() {
    let order: Order = "";
    if (this.props.sortBy === "to_date" && this.props.order === "") {
      order = "desc";
    }
    this.props.onChangeSort("to_date", order);
  }
}

export default TestResultListContent;
