import React from "react";
import { Redirect, Route, RouteComponentProps } from "react-router-dom";
import { User } from "./model/entities/User";
import { get } from "./model/api/Request";
import LoadingOverlay from "./containers/common/LoadingOverlay";

type Props = RouteComponentProps<{}>;

type State = {
  profile: User | null;
};

const WithProfile = ComposedComponent =>
  class extends React.Component<Props, State> {
    constructor(props) {
      super(props);

      this.state = {
        profile: null
      };
    }

    componentDidMount() {
      get("/profile")
        .then(res => {
          this.setState({ profile: res.result });
        })
        .catch(error => {
          this.props.history.push("/");
        });
    }
    render() {
      if (!this.state.profile) {
        return <LoadingOverlay isLoading={true} />;
      } else if (
        !this.state.profile.is_login_id_changed &&
        this.props.match.path !== "/activate/info"
      ) {
        // email & login_idが未変更
        return <Redirect to={"/activate/info"} />;
      } else if (
        this.state.profile.is_login_id_changed &&
        !this.state.profile.email_verified &&
        this.props.match.path !== "/verify" &&
        this.props.match.path !== "/email/change"
      ) {
        // emailが未認証
        return <Redirect to={"/verify"} />;
      }
      return <ComposedComponent {...this.props} profile={this.state.profile} />;
    }
  };

class AuthRoute extends Route {
  render() {
    if (localStorage.getItem("token") != null) {
      return (
        <Route
          path={this.props.path}
          component={WithProfile(this.props.component)}
        />
      );
    }
    return <Redirect to={"/"} />;
  }
}

export default AuthRoute;
