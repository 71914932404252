import styled from "styled-components";
import { FilterButtonColor } from "../../common/Colors";

type Props = { color: string };

export const TopButton = styled.button<Props>`
  background: ${props => props.color};
  border: none;
  color: white;
  font-size: 20px;
  border-radius: 8px;
  width: 122px;
  height: 32px;
  margin-right: 16px;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;

export const TopRightArea = styled.div`
  display: flex;
  align-items: center;
  float: right;
  clear: both;
  margin-right: 37px;
  margin-top: 10px;
  height: 32px;
`;

export const BottomRightArea = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 37px;
  bottom: 16px;
  height: 32px;
`;

export const FilterButton = styled.button<{ isTestResultDetail: boolean }>`
  position: absolute;
  top: ${props => (props.isTestResultDetail ? "90px" : "10px")};
  left: 360px;
  background: ${FilterButtonColor};
  border: none;
  color: white;
  margin-left: 5px;
  font-size: 20px;
  border-radius: 8px;
  width: 175px;
  height: 32px;
  &:focus {
    outline: 0;
  }
  cursor: pointer;
`;
