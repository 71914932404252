/**
 * arrayをn要素の配列に分割する
 * @param array  分割する配列
 * @param n      分割する個数
 */
export function divideArray(arr: Array<any>, n) {
  const arrayCopy = arr.map(element => element);
  const arrList: Array<any> = [];
  const idx = 0;
  while (idx < arrayCopy.length) {
    arrList.push(arrayCopy.splice(idx, idx + n));
  }
  return arrList;
}
