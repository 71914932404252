import styled from "styled-components";
import { Color, Space } from "../../../../styles";

export const Container = styled.div`
  position: relative;
  img {
    display: block;
    width: 100%;
  }
`;

export const SeparatorContent = styled.div`
  padding: 0 ${Space * 0.5}px;
`;

export const SeparatorBox = styled.div`
  width: 33.333%;
  & + & {
    ${SeparatorContent} {
      border-left: 2px dashed ${Color.GRAY_DARK};
    }
  }
`;

export const ColumnsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 180px;
`;
