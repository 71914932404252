import React from "react";
import WrapLoginArea from "./styles/WrapLoginArea";
import LoginArea from "./styles/LoginArea";
import LoginInput from "./styles/LoginInput";
import LoginButton from "./styles/LoginButton";
import Logo from "./styles/Logo";
import { LoginButtonColor } from "../common/Colors";
import { RouteComponentProps } from "react-router-dom";
import { post } from "./../../model/api/Request";
import LoadingOverlay from "../common/LoadingOverlay";
import { commonErrorHandle } from "../../utils/errorHandle";
import { isHanEisu } from "../../utils/validation";

type Props = RouteComponentProps<{}>;

type State = {
  isLoading: boolean;
};

class ForgetPassword extends React.Component<Props, State> {
  id: string = "";

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  render() {
    return (
      <>
        <LoadingOverlay isLoading={this.state.isLoading} />
        <WrapLoginArea>
          <Logo />
          <LoginArea color="white">
            <LoginInput
              type="text"
              placeholder="ID"
              index={0}
              onChange={this.onChangeId.bind(this)}
            />
            <LoginButton
              color={LoginButtonColor}
              onClick={this.onLoginButton.bind(this)}
            >
              パスワードを初期化
            </LoginButton>
          </LoginArea>
        </WrapLoginArea>
      </>
    );
  }

  onChangeId(e: React.ChangeEvent<HTMLInputElement>) {
    this.id = e.target.value;
  }

  onLoginButton() {
    if (this.id === "") {
      alert("IDを入力してください。");
      return;
    }
    if (!isHanEisu(this.id)) {
      alert("ログインIDは半角英数字で入力してください");
      return;
    }
    this.setState({ isLoading: true });
    post("/profile/password/init", {
      login_id: this.id
    })
      .then(res => {
        this.setState({ isLoading: false });
        alert(
          "登録されているメールアドレスに認証コードを送信しました。次の画面で入力してください"
        );
        this.props.history.push("/password/verify?id=" + this.id);
      })
      .catch(error => {
        commonErrorHandle(error, this.props.history);
      });
  }
}

export default ForgetPassword;
