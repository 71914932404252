import React from "react";
import Modal from "react-modal";
import { CreateButton, DeleteButton } from "../common/Colors";
import LoadingOverlay from "../common/LoadingOverlay";
import {
  ModalStyle,
  Title,
  Border,
  Section,
  InputHalfArea,
  InputHalf,
  CheckArea,
  CheckAreaRow,
  CheckContent,
  CheckLabel,
  InputCheckbox,
  MarginTopInput,
  ButtonArea,
  Button
} from "../coachList/styles/FilterCoachModalStyle";
import {
  CloseButton,
  CloseButtonImage
} from "../modal/styles/UserEditModalStyle";
import { AffiliationDivision } from "../../model/entities/AffiliationDivision";
import { get } from "../../model/api/Request";
import { divideArray } from "../../utils/array";
import { getDivisionsRecursively } from "../../utils/division";
import { getTargetTypesRecursively } from "../../utils/targetType";
import { TargetType } from "../../model/entities/TargetType";
import { ExternalEval } from "../../model/entities/ExternalEval";
import { AcademicLevel } from "../../model/entities/AcademicLevel";
import { User } from "../../model/entities/User";
import {
  queryFamilyNameKey,
  queryFirstNameKey,
  queryAffiliationDivisionKey,
  queryAffiliationDivisionNameKey,
  queryCoachKey,
  queryCoachFamilyNameKey,
  queryCoachFirstNameKey,
  queryTargetTypeKey,
  queryExternalEvalKey,
  queryAcademicLevelKey,
  queryExternalEvalSearchTypeKey
} from "../../utils/Constant";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSearch: (query: string) => void;
  query: { [name: string]: string | string[] };
};

type State = {
  isLoading: boolean;
  affiliationDivisions: AffiliationDivision[];
  coaches: User[];
  targetTypes: TargetType[];
  externalEvals: ExternalEval[];
  academicLevels: AcademicLevel[];
  familyName: string;
  firstName: string;
  selectedDivisionIds: number[];
  divisionName: string;
  selectedCoachIds: number[];
  coachFamilyName: string;
  coachFirstName: string;
  selectedTargetTypeIds: number[];
  selectedExternalEvalIds: number[];
  selectedCurrentLevelIds: number[];
  externalEvalSearchType: "and" | "or";
};

class FilterStudentModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const familyNameQ = props.query[queryFamilyNameKey];
    const familyName = typeof familyNameQ === "string" ? familyNameQ : "";

    const firstNameQ = props.query[queryFirstNameKey];
    const firstName = typeof firstNameQ === "string" ? firstNameQ : "";

    let affiliationDivisions: number[] = [];
    if (typeof props.query[queryAffiliationDivisionKey] === "string") {
      affiliationDivisions = [+props.query[queryAffiliationDivisionKey]];
    } else if (props.query[queryAffiliationDivisionKey] instanceof Array) {
      affiliationDivisions = (props.query[
        queryAffiliationDivisionKey
      ] as string[]).map(id => +id);
    }

    const divisionQ = props.query[queryAffiliationDivisionNameKey];
    const division = typeof divisionQ === "string" ? divisionQ : "";

    let coaches: number[] = [];
    if (typeof props.query[queryCoachKey] === "string") {
      coaches = [+props.query[queryCoachKey]];
    } else if (props.query[queryCoachKey] instanceof Array) {
      coaches = (props.query[queryCoachKey] as string[]).map(id => +id);
    }

    const coachFamilyNameQ = props.query[queryCoachFamilyNameKey];
    const coachFamilyName =
      typeof coachFamilyNameQ === "string" ? coachFamilyNameQ : "";

    const coachFirstNameQ = props.query[queryCoachFirstNameKey];
    const coachFirstName =
      typeof coachFirstNameQ === "string" ? coachFirstNameQ : "";

    let targetTypes: number[] = [];
    if (typeof props.query[queryTargetTypeKey] === "string") {
      targetTypes = [+props.query[queryTargetTypeKey]];
    } else if (props.query[queryTargetTypeKey] instanceof Array) {
      targetTypes = (props.query[queryTargetTypeKey] as string[]).map(
        id => +id
      );
    }

    let externalEval: number[] = [];
    if (typeof props.query[queryExternalEvalKey] === "string") {
      externalEval = [+props.query[queryExternalEvalKey]];
    } else if (props.query[queryExternalEvalKey] instanceof Array) {
      externalEval = (props.query[queryExternalEvalKey] as string[]).map(
        id => +id
      );
    }

    let academicLevels: number[] = [];
    if (typeof props.query[queryAcademicLevelKey] === "string") {
      academicLevels = [+props.query[queryAcademicLevelKey]];
    } else if (props.query[queryAcademicLevelKey] instanceof Array) {
      academicLevels = (props.query[queryAcademicLevelKey] as string[]).map(
        id => +id
      );
    }

    const externalEvalSearchTypeQ = props.query[queryExternalEvalSearchTypeKey];
    const externalEvalSearchType =
      typeof externalEvalSearchTypeQ === "string" &&
      externalEvalSearchTypeQ === "and"
        ? "and"
        : "or";

    this.state = {
      isLoading: false,
      affiliationDivisions: [],
      coaches: [],
      targetTypes: [],
      externalEvals: [],
      academicLevels: [],
      familyName: familyName,
      firstName: firstName,
      selectedDivisionIds: affiliationDivisions,
      divisionName: division,
      selectedCoachIds: coaches,
      coachFamilyName: coachFamilyName,
      coachFirstName: coachFirstName,
      selectedTargetTypeIds: targetTypes,
      selectedExternalEvalIds: externalEval,
      selectedCurrentLevelIds: academicLevels,
      externalEvalSearchType: externalEvalSearchType
    };
  }

  componentDidMount() {
    Promise.all([
      get("/affiliation-divisions"),
      get("/target-types"),
      get("/external-evals"),
      get("/academic-levels"),
      get("/coaches")
    ]).then(responses => {
      const divisions = getDivisionsRecursively(responses[0].result);
      const targetTypes = responses[1].result
        .map(targetType => {
          return getTargetTypesRecursively(targetType);
        })
        .reduce((acc, current) => {
          return acc.concat(current);
        });
      this.setState({
        affiliationDivisions: divisions,
        targetTypes: targetTypes,
        externalEvals: responses[2].result,
        academicLevels: responses[3].result,
        coaches: responses[4].result
      });
    });
  }

  render() {
    const divisions = divideArray(this.state.affiliationDivisions, 2).map(
      (divisions: AffiliationDivision[]) => {
        return (
          <CheckAreaRow key={divisions[0].id}>
            {divisions.map(division => {
              return (
                <CheckContent key={division.id}>
                  <InputCheckbox
                    type="checkbox"
                    checked={
                      this.state.selectedDivisionIds.find(
                        id => id === division.id
                      ) !== undefined
                    }
                    onChange={this.onDivisionChecked.bind(this)}
                  />
                  <CheckLabel>{division.name}</CheckLabel>
                </CheckContent>
              );
            })}
          </CheckAreaRow>
        );
      }
    );
    const coaches = divideArray(this.state.coaches, 2).map(
      (coaches: User[]) => {
        return (
          <CheckAreaRow key={coaches[0].id}>
            {coaches.map(coach => {
              return (
                <CheckContent key={coach.id}>
                  <InputCheckbox
                    type="checkbox"
                    checked={
                      this.state.selectedCoachIds.find(
                        id => id === coach.id
                      ) !== undefined
                    }
                    onChange={this.onCoachChecked.bind(this)}
                  />
                  <CheckLabel>
                    {coach.family_name_ja + " " + coach.first_name_ja}
                  </CheckLabel>
                </CheckContent>
              );
            })}
          </CheckAreaRow>
        );
      }
    );
    const targetTypes = divideArray(this.state.targetTypes, 2).map(
      (targetTypes: TargetType[]) => {
        return (
          <CheckAreaRow key={targetTypes[0].id}>
            {targetTypes.map(targetType => {
              return (
                <CheckContent key={targetType.id}>
                  <InputCheckbox
                    type="checkbox"
                    checked={
                      this.state.selectedTargetTypeIds.find(
                        id => id === targetType.id
                      ) !== undefined
                    }
                    onChange={this.onTargetTypeChecked.bind(this)}
                  />
                  <CheckLabel>{targetType.name}</CheckLabel>
                </CheckContent>
              );
            })}
          </CheckAreaRow>
        );
      }
    );
    const externalEvals = divideArray(this.state.externalEvals, 2).map(
      (externalEvals: ExternalEval[]) => {
        return (
          <CheckAreaRow key={externalEvals[0].id}>
            {externalEvals.map(externalEval => {
              return (
                <CheckContent key={externalEval.id}>
                  <InputCheckbox
                    type="checkbox"
                    checked={
                      this.state.selectedExternalEvalIds.find(
                        id => id === externalEval.id
                      ) !== undefined
                    }
                    onChange={this.onExternalEvalChecked.bind(this)}
                  />
                  <CheckLabel>{externalEval.name}</CheckLabel>
                </CheckContent>
              );
            })}
          </CheckAreaRow>
        );
      }
    );
    const currentLevels = divideArray(this.state.academicLevels, 2).map(
      (academicLevels: AcademicLevel[]) => {
        return (
          <CheckAreaRow key={academicLevels[0].id}>
            {academicLevels.map(academicLevel => {
              return (
                <CheckContent key={academicLevel.id}>
                  <InputCheckbox
                    type="checkbox"
                    checked={
                      this.state.selectedCurrentLevelIds.find(
                        id => id === academicLevel.id
                      ) !== undefined
                    }
                    onChange={this.onCurrentLevelChecked.bind(this)}
                  />
                  <CheckLabel>{academicLevel.name}</CheckLabel>
                </CheckContent>
              );
            })}
          </CheckAreaRow>
        );
      }
    );
    return (
      <>
        <Modal isOpen={this.props.isOpen} style={ModalStyle}>
          <CloseButton onClick={this.onClose.bind(this)}>
            <CloseButtonImage src="/assets/CloseButton.png" alt="CloseButton" />
          </CloseButton>
          <Title>検索・フィルター</Title>
          <Border />
          <Section>
            <Title>氏名</Title>
            <InputHalfArea>
              <InputHalf
                placeholder="姓を入力してください"
                value={this.state.familyName}
                onChange={this.onChangeLastName.bind(this)}
              />
              <InputHalf
                placeholder="名を入力してください"
                value={this.state.firstName}
                onChange={this.onChangeFirstName.bind(this)}
              />
            </InputHalfArea>
          </Section>
          <Border />
          <Section>
            <Title>所属</Title>
            <CheckArea>
              {divisions}
              <MarginTopInput
                disabled={this.state.selectedDivisionIds.length !== 0}
                placeholder="入力してください"
                value={this.state.divisionName}
                onChange={this.onChangeDivisionName.bind(this)}
              />
            </CheckArea>
          </Section>
          <Border />
          <Section>
            <Title>担当コーチ</Title>
            <CheckArea>
              {coaches}
              <InputHalfArea style={{ marginTop: "16px" }}>
                <InputHalf
                  disabled={this.state.selectedCoachIds.length !== 0}
                  placeholder="姓を入力してください"
                  value={this.state.coachFamilyName}
                  onChange={this.onChangeCoachFamilyName.bind(this)}
                />
                <InputHalf
                  disabled={this.state.selectedCoachIds.length !== 0}
                  placeholder="名を入力してください"
                  value={this.state.coachFirstName}
                  onChange={this.onChangeCoachFirstName.bind(this)}
                />
              </InputHalfArea>
            </CheckArea>
          </Section>
          <Border />
          <Section>
            <Title>目標タイプ</Title>
            <CheckArea>{targetTypes}</CheckArea>
          </Section>
          <Border />
          <Section>
            <Title>取得資格</Title>
            <CheckArea>
              {externalEvals}
              <div style={{ height: "16px" }}></div>
              <CheckAreaRow>
                <CheckContent>
                  <InputCheckbox
                    type="checkbox"
                    checked={this.state.externalEvalSearchType === "and"}
                    onChange={this.changeExternalEvalSearchTypeAnd.bind(this)}
                  ></InputCheckbox>
                  <CheckLabel>and検索をする</CheckLabel>
                </CheckContent>
              </CheckAreaRow>
            </CheckArea>
          </Section>
          <Border />
          <Section>
            <Title>現在のレベル</Title>
            <CheckArea>{currentLevels}</CheckArea>
          </Section>
          <Border />
          <ButtonArea>
            <Button
              background={CreateButton}
              onClick={this.onSearch.bind(this)}
            >
              検索
            </Button>
            <Button background={DeleteButton} onClick={this.onClear.bind(this)}>
              値をクリア
            </Button>
          </ButtonArea>
        </Modal>
        <LoadingOverlay isLoading={this.state.isLoading} />
      </>
    );
  }

  onChangeLastName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ familyName: e.target.value });
  }

  onChangeFirstName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ firstName: e.target.value });
  }

  onDivisionChecked(e: React.ChangeEvent<HTMLInputElement>) {
    const selectDivision = this.state.affiliationDivisions.filter(division => {
      return division.name === e.target.parentElement?.children[1].innerHTML;
    })[0];
    let selectedDivisions = this.state.selectedDivisionIds;
    if (e.target.checked) {
      selectedDivisions.push(selectDivision.id);
    } else {
      selectedDivisions = selectedDivisions.filter(
        id => id !== selectDivision.id
      );
    }
    this.setState({ selectedDivisionIds: selectedDivisions });
  }

  onChangeDivisionName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ divisionName: e.target.value });
  }

  onCoachChecked(e: React.ChangeEvent<HTMLInputElement>) {
    const selectCoach = this.state.coaches.filter(coach => {
      return (
        coach.family_name_ja + " " + coach.first_name_ja ===
        e.target.parentElement?.children[1].innerHTML
      );
    })[0];
    let selectedCoaches = this.state.selectedCoachIds;
    if (e.target.checked) {
      selectedCoaches.push(selectCoach.id);
    } else {
      selectedCoaches = selectedCoaches.filter(id => id !== selectCoach.id);
    }
    this.setState({ selectedCoachIds: selectedCoaches });
  }

  onChangeCoachFamilyName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ coachFamilyName: e.target.value });
  }

  onChangeCoachFirstName(e: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ coachFirstName: e.target.value });
  }

  onTargetTypeChecked(e: React.ChangeEvent<HTMLInputElement>) {
    const selectTargetType = this.state.targetTypes.filter(targetType => {
      return targetType.name === e.target.parentElement?.children[1].innerHTML;
    })[0];
    let selectedTargetTypes = this.state.selectedTargetTypeIds;
    if (e.target.checked) {
      selectedTargetTypes.push(selectTargetType.id);
    } else {
      selectedTargetTypes = selectedTargetTypes.filter(
        id => id !== selectTargetType.id
      );
    }
    this.setState({ selectedTargetTypeIds: selectedTargetTypes });
  }

  onExternalEvalChecked(e: React.ChangeEvent<HTMLInputElement>) {
    const selectExternalEval = this.state.externalEvals.filter(externalEval => {
      return (
        externalEval.name === e.target.parentElement?.children[1].innerHTML
      );
    })[0];
    let selectedExternalEvals = this.state.selectedExternalEvalIds;
    if (e.target.checked) {
      selectedExternalEvals.push(selectExternalEval.id);
    } else {
      selectedExternalEvals = selectedExternalEvals.filter(
        id => id !== selectExternalEval.id
      );
    }
    this.setState({ selectedExternalEvalIds: selectedExternalEvals });
  }

  changeExternalEvalSearchTypeAnd() {
    this.setState({
      externalEvalSearchType:
        this.state.externalEvalSearchType === "and" ? "or" : "and"
    });
  }

  onCurrentLevelChecked(e: React.ChangeEvent<HTMLInputElement>) {
    const selectAcademicLevel = this.state.academicLevels.filter(
      academicLevel => {
        return (
          academicLevel.name === e.target.parentElement?.children[1].innerHTML
        );
      }
    )[0];
    let selectedCurrentLevels = this.state.selectedCurrentLevelIds;
    if (e.target.checked) {
      selectedCurrentLevels.push(selectAcademicLevel.id);
    } else {
      selectedCurrentLevels = selectedCurrentLevels.filter(
        id => id !== selectAcademicLevel.id
      );
    }
    this.setState({ selectedCurrentLevelIds: selectedCurrentLevels });
  }

  onClear() {
    this.setState({
      familyName: "",
      firstName: "",
      selectedDivisionIds: [],
      divisionName: "",
      selectedCoachIds: [],
      coachFamilyName: "",
      coachFirstName: "",
      selectedTargetTypeIds: [],
      selectedExternalEvalIds: [],
      selectedCurrentLevelIds: []
    });
  }

  onSearch() {
    let query: string[] = [];
    if (this.state.familyName !== "") {
      query.push(`${queryFamilyNameKey}=${this.state.familyName}`);
    }
    if (this.state.firstName !== "") {
      query.push(`${queryFirstNameKey}=${this.state.firstName}`);
    }
    const divisions = this.state.selectedDivisionIds
      .map(id => {
        return `${queryAffiliationDivisionKey}=${id}`;
      })
      .join("&");
    if (divisions !== "") {
      query.push(divisions);
    } else if (this.state.divisionName !== "") {
      query.push(
        `${queryAffiliationDivisionNameKey}=${this.state.divisionName}`
      );
    }

    const coaches = this.state.selectedCoachIds
      .map(id => {
        return `${queryCoachKey}=${id}`;
      })
      .join("&");
    if (coaches !== "") {
      query.push(coaches);
    } else {
      if (this.state.coachFamilyName !== "") {
        query.push(`${queryCoachFamilyNameKey}=${this.state.coachFamilyName}`);
      }
      if (this.state.coachFirstName !== "") {
        query.push(`${queryCoachFirstNameKey}=${this.state.coachFirstName}`);
      }
    }

    const targetTypes = this.state.selectedTargetTypeIds
      .map(id => {
        return `${queryTargetTypeKey}=${id}`;
      })
      .join("&");
    if (targetTypes !== "") {
      query.push(targetTypes);
    }

    const externalEvals = this.state.selectedExternalEvalIds
      .map(id => {
        return `${queryExternalEvalKey}=${id}`;
      })
      .join("&");
    if (externalEvals !== "") {
      query.push(externalEvals);
      query.push(
        `${queryExternalEvalSearchTypeKey}=${this.state.externalEvalSearchType}`
      );
    }

    const currentLevels = this.state.selectedCurrentLevelIds
      .map(id => {
        return `${queryAcademicLevelKey}=${id}`;
      })
      .join("&");
    if (currentLevels !== "") {
      query.push(currentLevels);
    }

    this.props.onSearch(query.join("&"));
  }

  onClose() {
    this.props.onClose();
  }
}

export default FilterStudentModal;
