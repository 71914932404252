import * as React from "react";
import { Container } from "./styled";
import { Color } from "../../../styles";

type Props = {
  component?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  color?: string;
  align?: "left" | "center" | "right";
  size?: string;
  weight?: "normal" | "bold";
  lineHeight?: string;
  height?: string;
  width?: string;
  marginTop?: string;
};

const Text: React.FunctionComponent<Props> = ({
  children,
  component: Component = "p",
  color = Color.BLACK,
  align = "left",
  size = "14px",
  weight = "normal",
  lineHeight = "1.4",
  height = "auto",
  width = "auto",
  marginTop = "0"
}) => {
  return (
    <Container
      as={Component}
      color={color}
      align={align}
      size={size}
      weight={weight}
      lineHeight={lineHeight}
      height={height}
      width={width}
      marginTop={marginTop}
    >
      {children}
    </Container>
  );
};

export { Text };
